import React from "react";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import { db } from "../../../Firebase";
import PerformanceForm from "./PerformanceForm";
import "../../../assets/css/PerformanceForm.css";

export default class Performance extends React.Component {
  constructor() {
    super();
    this.state = {
      userEmail: "",
      performanceNameData: [],

      selectedDepartment: "",
      departmentData: [],
      performanceData: [],
      employeeData: [],
      userId: "",
      id: "",
      performanceName: "",
      showFields: false,
      allDataState: [],
      isShowPopup: false,
      isEditData: [],
      showPopup: false,

      tempData: [],
      filtered: [],
    };
  }

  componentDidMount() {
    this.onGetDepartmentData();
  }

  onGetDepartmentData() {
    let departmentDataPromise = new Promise((resolve, reject) => {
      db.ref("/departmentMaster/").on("value", (querySnap) => {
        let deptArray = [];
        querySnap.forEach((deptData) => {
          let data = deptData.val();
          deptArray.push(data);
        });
        resolve(deptArray);
      });
    });
    departmentDataPromise.then((acceptedData) => {
      this.setState(
        {
          departmentData: acceptedData,
        },
        () => console.log("dept data::", this.state.departmentData)
      );
    });
  }

  onGetPerformanceData() {
    let performanceDataPromise = new Promise((resolve, reject) => {
      db.ref("/performance/").on("value", (querySnapShot) => {
        let data = [];
        querySnapShot.forEach((element) => {
          let obj = element.val();
          obj["id"] = element.key;
          data.push(obj);
        });
        resolve(data);
      });
    });
    performanceDataPromise.then((acceptedData) => {
      this.setState({
        performanceData: acceptedData,
      });
    });
  }

  dropDownDepartmentChange = (event) => {
    this.setState({ selectedDepartment: event.target.value }, () => {
      db.ref("/performanceMst/")
        .orderByChild("department")
        .equalTo(this.state.selectedDepartment)
        .on("value", (querySnapShot) => {
          let data = [];
          querySnapShot.forEach((element) => {
            let obj = element.val();
            obj["id"] = element.key;
            obj["marks"] = "";
            data.push(obj);
          });
          this.setState({ performanceNameData: data });
          db.ref("/employees/").on("value", (querySnapShot) => {
            let empData = [];
            querySnapShot.forEach((element) => {
              // console.log("element", element.val())
              if (element.val().status == "active") {
                let obj = element.val();
                obj.department.map((object) => {
                  if (
                    object === this.state.selectedDepartment &&
                    (obj.userRole === "employee" ||
                      obj.userRole === "team_lead")
                  ) {
                    obj["id"] = element.key;
                    obj["performance"] = [...data];
                    empData.push(obj);
                  }
                });
              }
            });
            console.log(empData);
            this.setState({ employeeData: empData }, () => {
              console.log("Employee Data :: ", this.state.employeeData);
            });
          });
        });
    });
  };

  saveData = () => {
    let today = new Date().getTime();
    this.state.employeeData.map((empSave) => {
      let finalMarks = [];
      empSave.performance.map((obj) =>
        finalMarks.push({
          performanceName: obj.performanceName,
          marks: obj.marks,
        })
      );

      db.ref("/performance").push({
        userEmail: empSave.email,
        points: finalMarks,
        entryData: today,
        entryBy: localStorage.getItem("email"),
        updateDate: "",
        updateBy: "",
      });
    });
    this.clearValue();
  };

  clearValue = () => {
    this.setState({
      selectedDepartment: "",
      userEmail: "",
      userId: "",
      id: "",
      departmentName: "",
      performanceName: "",
      showFields: false,
      employeeData: [],
      isEditData: [],
      isShowPopup: false,
    });
  };

  updateMarks = (employeeId, perAllData) => {
    this.setState((prevState) => ({
      employeeData: prevState.employeeData.map((emp) =>
        emp.id === employeeId ? { ...emp, performance: perAllData } : emp
      ),
    }));
  };

  render() {
    return (
      <div>
        <div className="content">
          <Row>
            <Col>
              <div className="page-title">
                <div className="float-left">
                  <h1 className="title">Performance</h1>
                </div>
              </div>
              <div className="row margin-0">
                <div className="col-12">
                  <section className="box">
                    <header className="panel_header">
                      <h2 className="title float-left">About</h2>
                    </header>
                    <div className="content-body">
                      <div className="col-xl-12 col-lg-12 col-12 col-md-12">
                        <form>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label htmlFor="department">Department</label>
                              <select
                                id="department"
                                className="form-control"
                                value={this.state.selectedDepartment}
                                onChange={this.dropDownDepartmentChange}
                              >
                                <option>Choose..</option>
                                {this.state.departmentData.map((obj) => {
                                  return (
                                    <option value={obj.departmentName}>
                                      {obj.departmentName}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="flexperformance">
                        {this.state.employeeData.length > 0 &&
                          this.state.employeeData.map((empData, index) => (
                            <PerformanceForm
                              updateMarks={this.updateMarks}
                              empFormData={empData}
                              empIndex={index}
                            />
                          ))}
                      </div>
                      <div className="row">
                        <div className="col-xl-12 col-lg-12 col-12 col-md-12">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={this.saveData}
                          >
                            Submit
                          </button>
                          <button
                            type="submit"
                            className="btn btn-secondary"
                            onClick={this.clearValue}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
