import React, { useState, useEffect } from "react";
import { db } from "../../Firebase";
import Container from "@material-ui/core/Container";
import { TabContext } from "@material-ui/lab";
import AppBar from "@material-ui/core/AppBar";
import Modal from "react-modal";
import Tab from "@material-ui/core/Tab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import { Grid} from "@material-ui/core";
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import MomentUtils from "@date-io/moment";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Pagination from '@material-ui/lab/Pagination';
import {MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import {
  Box,
  Card,
  CardContent,
  Divider,
  TableContainer,
} from "@material-ui/core";
import noDataImg from "../../assets/img/NoData.png";





const Attendancefeature = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [filterData, serFilterData] = useState([]);
  const [dateWiseData, setDateWiseData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [currentUserId, setCurrentUserId] = useState("");
  const [listOfUsers,setListOfUser] = useState(false);
  const [groupByName,setGroupByName] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectUser,setSelectUser] = useState(false);
  let currentUser = localStorage.getItem("employeeObject")
    ? JSON.parse(localStorage.getItem("employeeObject")).userID
    : " ";
  let currentUserDept = localStorage.getItem("employeeObject")
    ? JSON.parse(localStorage.getItem("employeeObject")).department
    : " ";
  let shilpmisId = localStorage.getItem("employeeObject")
    ? JSON.parse(localStorage.getItem("employeeObject")).email
    : " ";

    let name = localStorage.getItem("employeeObject")
    ? JSON.parse(localStorage.getItem("employeeObject")).firstName + " " + JSON.parse(localStorage.getItem("employeeObject")).lastName 
    : " "; 
  let currentUserRole = localStorage.getItem("employeeObject")
    ? JSON.parse(localStorage.getItem("employeeObject")).userRole
    : " ";

  let loginUserId = localStorage.getItem("employeeObject")
  ? JSON.parse(localStorage.getItem("employeeObject")).userID : "" ;

  const [years, setYear] = useState([]);
  const [selectYear, setselectYear] = useState("");
  const [selectMonth, setselectMonth] = useState("");
  const [selectRange, setselectRange] = useState("");
  const [selectRegReqYear,  setReqReqselectYear] = useState("");
  const [selectRegReqMonth, setReqReqselectMonth] = useState("");
  const [selectRegReqRange, setReqReqselectRange] = useState("");

  const [showMonth, setShowMonth] = useState(true);
  const [showRange, setShowRange] = useState(true);
  const [allAttendenceData, setAllAttendenceData] = useState([]);
  const [selectDates,setSelectDate]=useState( new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [startDateTime,setStartDateTime] = useState("");
  const [endDateTime,setEndDateTime] = useState("");
  const [reson,setReson] = useState("");
  const [regData,setRegData] = useState([]);
  const [tabValue,setTabValue] = useState("1")
  const [regReqData,setRegReqData] = useState({
    id:"",
    userId:"",
    userName:"",
    date:"",
  })
  useEffect(() => {
    checkRegisterOrNot();
    onGetEmployeeData();
    getRegularizationData();
    let s = [];
    for (let i = 2020; i < 2099; i++) {
      s.push(i);
    }
    setYear(s);

  }, []);

  useEffect(() => {
    checkRegisterOrNot();
    setSelectUser(true);
    
  }, [currentUserId, selectYear, selectMonth, selectRange,selectDates]);

  useEffect(()=>{
    
    getRegularizationData();
  },[selectRegReqYear,selectRegReqMonth,selectRegReqRange]) 

  const onGetEmployeeData = () => {
    let employeeDataPromise = new Promise((resolve, reject) => {
      db.ref("/employees/").on("value", (queryEmployee) => {
        let employeeArray = [];
        queryEmployee.forEach((element) => {
          if (element.val().status == "active") {
            let object = element.val();
            object["id"] = element.key;
            employeeArray.push(object);
          }
        });
        resolve(employeeArray);
      });
    });
    employeeDataPromise.then((acceptedData) => {
      setEmployeeData(acceptedData.filter((el)=> el.status=="active"));
    });
  };

  
  const checkRegisterOrNot = () => {
    let data = [];
    
    db.ref("/attendence").on("value", (val) => {
      val.forEach((element) => {
        let obj = element.val();
        obj["id"] = element.key;
        data.push(obj);
      });
      setAttendanceData(data);
      if (data) {
        printAttendence(data);
      }
    });
  };

  const printAttendence = (data) => {

    const month = [
      "January","February","March","April","May","June","July","August","September","October","November","December",
    ];

    let finaldata;
    let currentMonthNumber = month.indexOf(month[new Date().getMonth()]);
    let currentYear = new Date().getFullYear();
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;

    if (selectYear == "" && selectMonth == "" && selectRange == "") {
      if (currentUserId == "") {
        if (currentUserRole !== "HR") {
          console.log("111111111",currentUser);
          finaldata = data.filter((item) => {
            return (
              item.userId === currentUser &&
              new Date(item.checkInTime).toLocaleString().split(",")[0].split("/")[2] == currentYear && 
              new Date(item.checkInTime).toLocaleString().split(",")[0].split("/")[1] == currentMonthNumber + 1 ||
              (item.checkInTime == "Absent" || item.checkInTime == "Regularization Request Rejected") && item.today.split("/")[2] == currentYear && item.today.split("/")[1] == currentMonthNumber + 1
              && item.userId === currentUser
            );
          });
        } else {
          if(selectDates.toLocaleString().split(",")[0]==today){
            console.log("2222222");
            setListOfUser(true);
          finaldata = data.filter((item) => {
            return (
              new Date(item.checkInTime).toLocaleString().split(",")[0] === today || 
              (item.checkInTime == "Absent" || item.checkInTime == "Regularization Request Rejected") && item.today == today
            );
          });
        } else{
          console.log("33333333333");
          finaldata = data.filter((item) => {
          return (
            new Date(item.checkInTime).toLocaleString().split(",")[0] === selectDates.toLocaleString().split(",")[0] || 
            (item.checkInTime == "Absent" || item.checkInTime == "Regularization Request Rejected")&& item.today == selectDates.toLocaleString().split(",")[0]
          );
        });
        }
        }
      } else {
       
         if(selectDates.toLocaleString().split(",")[0]== today.split("/")[1] + "/"+ today.split("/")[0]  + "/"+ today.split("/")[2]){
          console.log("4444444");
        setSelectUser(true);
        finaldata = data.filter((item) => {
          return item.userId === currentUserId;
        });
      } else{
        
        setSelectUser(true);
        finaldata = data.filter((item) => {
        
          return  item.today == selectDates.toLocaleString().split(",")[0] &&
          item.userId === currentUserId 
        });
      }
      }
    } else if (selectYear !== "" && selectMonth === "" && selectRange === "") {
      if (currentUserId == "") {
        if (currentUserRole !== "HR") {
          console.log("5555555");
          finaldata = data.filter((item) => {
            return (
              item.userId === currentUser &&
              new Date(item.checkInTime).toLocaleString().split(",")[0].split("/")[2] == selectYear || 
              (item.checkInTime == "Absent" || item.checkInTime == "Regularization Request Rejected") && item.today.split("/")[2] == selectYear && item.userId === currentUser
            );
          });
        } else {
          console.log("66666666666");
          if(selectDates.toLocaleString().split(",")[0]==new Date().toLocaleString().split(",")[0]){              
          finaldata = data.filter((item) => {
            return (
              new Date(item.checkInTime).toLocaleString().split(",")[0].split("/")[2] == selectYear || 
              (item.checkInTime == "Absent" || item.checkInTime == "Regularization Request Rejected") && item.today.split("/")[2] == selectYear
            );
          });
           } else{
            console.log("77777");
            finaldata = data.filter((item) => {
            return (
              new Date(item.checkInTime).toLocaleString().split(",")[0] === selectDates.toLocaleString().split(",")[0] || 
              (item.checkInTime == "Absent" || item.checkInTime == "Regularization Request Rejected") && item.today == selectDates.toLocaleString().split(",")[0]
            );
          });
          }
        }
      } else {
        console.log("8888888888");
        setListOfUser(true);
        finaldata = data.filter((item) => {
          return (
            item.userId === currentUserId &&
            new Date(item.checkInTime).toLocaleString().split(",")[0].split("/")[2] == selectYear || 
            (item.checkInTime == "Absent" || item.checkInTime == "Regularization Request Rejected") && item.today.split("/")[2] == selectYear && item.userId === currentUserId 
          );
        });
      }
    } else if (selectMonth !== "" && selectYear !== "" && selectRange === "") {
      if (currentUserId == "") {
        if (currentUserRole !== "HR") {
          console.log("9999999");
        finaldata = data.filter((item) => {
          return (
            item.userId === currentUser &&
            new Date(item.checkInTime).toLocaleString().split(",")[0].split("/")[2] == selectYear &&
            new Date(item.checkInTime).toLocaleString().split(",")[0].split("/")[1] == selectMonth || (item.checkInTime == "Absent" || item.checkInTime == "Regularization Request Rejected") && item.today.split("/")[1] == selectMonth && item.today.split("/")[2] == selectYear && item.userId === currentUser
          );
        });
        } else{
          if(selectDates.toLocaleString().split(",")[0]==today){  
            console.log("10101010");
          finaldata = data.filter((item) => {
            return (
              new Date(item.checkInTime).toLocaleString().split(",")[0].split("/")[2] == selectYear &&
              new Date(item.checkInTime).toLocaleString().split(",")[0].split("/")[1] == selectMonth || (item.checkInTime == "Absent" || item.checkInTime == "Regularization Request Rejected") && item.today.split("/")[2] == selectYear && item.today.split("/")[1] == selectMonth
            );
          });
        } else{
          console.log("11-11-11");
          finaldata = data.filter((item) => {
            return (
              new Date(item.checkInTime)
                .toLocaleString().split(",")[0].split("/")[2] == selectYear &&
              new Date(item.checkInTime)
                .toLocaleString().split(",")[0].split("/")[1] == selectMonth && new Date(item.checkInTime).toLocaleString().split(",")[0] ===
                selectDates.toLocaleString().split(",")[0] || (item.checkInTime == "Absent"|| item.checkInTime == "Regularization Request Rejected") &&  item.today.split("/")[2] == selectYear && item.today. split("/")[1] == selectMonth 
            );
          });
        }
        }
      } else {
        setListOfUser(true);
        console.log("12-12-12");
        finaldata = data.filter((item) => {
          return (
            item.userId === currentUserId &&
            new Date(item.checkInTime).toLocaleString().split(",")[0].split("/")[2] == selectYear &&
            new Date(item.checkInTime).toLocaleString().split(",")[0].split("/")[1] == selectMonth || (item.checkInTime == "Absent" || item.checkInTime == "Regularization Request Rejected") &&  item.today.split("/")[2] == selectYear &&
              item.today.split("/")[1] == selectMonth &&   item.userId === currentUserId
          );
        });
      }
    } else if (selectRange !== "" && selectMonth !== "" && selectYear !== "") {
      if (currentUserId == "") {
        if (currentUserRole !== "HR") {
          console.log("13-13-13");
        finaldata = data.filter((item) => {
          return (
            item.userId === currentUser &&
            new Date(item.checkInTime)
              .toLocaleString().split(",")[0].split("/")[2] == selectYear &&
            new Date(item.checkInTime)
              .toLocaleString().split(",")[0].split("/")[1] == selectMonth &&
            new Date(item.checkInTime)
              .toLocaleString().split(",")[0].split("/")[0] >= selectRange.split("-")[0] &&
            new Date(item.checkInTime)
              .toLocaleString().split(",")[0].split("/")[0] <= selectRange.split("-")[1] || (item.checkInTime == "Absent" || item.checkInTime == "Regularization Request Rejected")  && item.today.split("/")[0] >= 
              selectRange.split("-")[0] &&  item.today.split("/")[0] <= selectRange.split("-")[1] && item.userId === currentUser
          );
        }); } else{
          if(selectDates.toLocaleString().split(",")[0]==today){  
            console.log("14-14-14");
          finaldata = data.filter((item) => {
            return (
              new Date(item.checkInTime)
                .toLocaleString().split(",")[0].split("/")[2] == selectYear &&
              new Date(item.checkInTime)
                .toLocaleString().split(",")[0].split("/")[1] == selectMonth &&
              new Date(item.checkInTime)
                .toLocaleString().split(",")[0].split("/")[0] >= selectRange.split("-")[0] &&
              new Date(item.checkInTime)
                .toLocaleString().split(",")[0].split("/")[0] <= selectRange.split("-")[1] || (item.checkInTime == "Absent" || item.checkInTime == "Regularization Request Rejected")  && item.today
                .split("/")[0] >= selectRange.split("-")[0] &&  item.today.split("/")[0] <= selectRange.split("-")[1] && item.userId === currentUser
            );
          });
        } else{
          console.log("15-15-15");
          finaldata = data.filter((item) => {
            return (
              new Date(item.checkInTime)
                .toLocaleString().split(",")[0].split("/")[2] == selectYear &&
              new Date(item.checkInTime)
                .toLocaleString().split(",")[0].split("/")[1] == selectMonth &&
              new Date(item.checkInTime)
                .toLocaleString().split(",")[0].split("/")[0] >= selectRange.split("-")[0] &&
              new Date(item.checkInTime)
                .toLocaleString().split(",")[0].split("/")[0] <= selectRange.split("-")[1] && 
                new Date(item.checkInTime).toLocaleString().split(",")[0] ===
                selectDates.toLocaleString().split(",")[0] || (item.checkInTime == "Absent" || item.checkInTime == "Regularization Request Rejected") && item.today.split("/")[2] == selectYear && item.today.split("/")[1] == selectMonth &&
                item.today.split("/")[0] >= selectRange.split("-")[0] && item.today.split("/")[0] <= selectRange.split("-")[1]
            );
          });
        }}
      } else {
        setListOfUser(true);
        console.log("16-16-16");
        finaldata = data.filter((item) => {
          return (
            item.userId === currentUserId &&
            new Date(item.checkInTime)
              .toLocaleString().split(",")[0].split("/")[2] == selectYear &&
            new Date(item.checkInTime)
              .toLocaleString().split(",")[0].split("/")[1] == selectMonth &&
            new Date(item.checkInTime)
              .toLocaleString().split(",")[0].split("/")[0] >= selectRange.split("-")[0] &&
            new Date(item.checkInTime)
              .toLocaleString().split(",")[0].split("/")[0] <= selectRange.split("-")[1] || (item.checkInTime == "Absent" || item.checkInTime == "Regularization Request Rejected") && item.userId === currentUserId 
              && item.today.split("/")[2] == selectYear && item.today.split("/")[1] == selectMonth &&
                item.today.split("/")[0] >= selectRange.split("-")[0] && item.today.split("/")[0] <= selectRange.split("-")[1]
          );
        });
      }
    }

   
    dayWiseData(finaldata);
    //Date wise calculation
    console.log("finaldata",finaldata);

    let myData;
    if (currentUserRole !== "HR") {
      myData = Object.entries(groupBy(finaldata, "today")).map((e) => ({
        today: e[0],
        data: e[1],
      }));
    } else {    
      if(selectDates.toLocaleString().split(",")[0] != new Date().toLocaleString().split(",")[0]){
        // console.log("NOT SELECT DATES", selectDates.toLocaleString().split(",")[0] +"!="+ new Date().toLocaleString().split(",")[0])
        myData = Object.entries(groupBy(finaldata, "today")).map((e) => ({
          today: e[0],
          data: e[1],
        }));

        let updateData = [];
        for (let i = 0; i < myData.length; i++) {
          let second = myData[i].data;
          for (let j = 0; j < second.length; j++) {
            updateData.push(second[j]);
          }
        }
        console.log("&&&",updateData);
  
        myData = Object.entries(groupBys(updateData, "name")).map((e) => ({
          today: e[0],
          data: e[1],
        }));

        console.log("myData-1",myData);
      } else{
        if(selectUser == false){
        myData = Object.entries(groupBy(finaldata, "today")).map((e) => ({
          today: e[0],
          data: e[1],
        }));

      
        
      let updateData = [];
      for (let i = 0; i < myData.length; i++) {
        let second = myData[i].data;
        for (let j = 0; j < second.length; j++) {
          updateData.push(second[j]);
        }
      }
     

      myData = Object.entries(groupBys(updateData, "name")).map((e) => ({
        checkInTime: e[0],
        data: e[1],
      }));

      console.log("myData-2",myData);
    } else{

      myData = Object.entries(groupBys(finaldata, "name")).map((e) => ({
        checkInTime: e[0],
        data: e[1],
      }));

      let updateData = [];
      for (let i = 0; i < myData.length; i++) {
        let second = myData[i].data;
        for (let j = 0; j < second.length; j++) {
          updateData.push(second[j]);
        }
      }
     
      myData = Object.entries(groupBy(updateData, "today")).map((e) => ({
        today: e[0],
        data: e[1],
      }));
      console.log("myData-3",myData);
    }
  }
    }
   
    if(myData.length!==0){
      let finalDataAll = [];
   
    let timeSpent = 0;
    for (let i = 0; i < myData.length; i++) {
      let second = myData[i].data;
   
        for (let j = 0; j < second.length; j++) {
          if (second[j].checkOut !== "" || second[j].checkOut !== "Absent" && second[j].checkInTime !== "Absent") {
          
            timeSpent += second[j].checkOut - second[j].checkInTime;
        
          } else if(second[j].checkOut == "Absent" && second[j].checkInTime == "Absent"){
            timeSpent = 0
          }
        }

        finalDataAll.push({
          id: second[0].id,
          checkInTime: second[0].checkInTime,
          checkOut: second[second.length - 1].checkOut,
          userId: second[0].userId,
          name: second[0].name,
          location: second[0].location,
          userRole: second[0].userRole,
          totalDuration: timeSpent,
          today:second[0].today
        }); 
      timeSpent = 0;
   
      serFilterData(finalDataAll);
    }
    } else{
      serFilterData([]);
    }
  
    
    function groupBy(objectArray, property) {
      let i = 0;
      return objectArray.reduce((acc, obj) => {

        const key = obj[property];
       
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
    }

    function groupBys(objectArray, property) {
      let i = 0;
      return objectArray.reduce((acc, obj) => {
        const key = obj[property];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
    }
  };

  
  const handleChangeTab = (event, newValue) => {
    if (newValue == "2") {
     setTabValue("2")
    } else if (newValue == "1") {
      setTabValue("1")
    }
  };

  const dayWiseData = (data) => {
    let filterData = [];
  
    for (let i = 0; i < data.length; i++) {
      if (data[i].checkOut !== "") {
        filterData.push(data[i]);
      }
    }
    setAllAttendenceData(filterData);
  };
  

    function secondsToHms(seconds)
    { // day, h, m and s
      var days     = Math.floor(seconds / (24*60*60));
          seconds -= days    * (24*60*60);
      var hours    = Math.floor(seconds / (60*60));
          seconds -= hours   * (60*60);
      var minutes  = Math.floor(seconds / (60));
          seconds -= minutes * (60);
      return ((0<days)?(days+" day, "):"")+hours+"h: "+minutes+"m : "+seconds+"s";
    }

  const handleSelectChange = (event) => {
    setCurrentUserId(event.target.value);
    setShowMonth(false);
    setShowRange(false);
  };

  const onHandleChange = (e) => {
    setselectYear(e.target.value);
    setShowMonth(false);
    setShowRange(true);
  };

  const onRegReqHandleChange = (e) => {
    setReqReqselectYear(e.target.value);
    setShowMonth(false);
    setShowRange(true);
  };

  const onMonthHandleChange = (e) => {
    setselectMonth(e.target.value);
    setShowRange(false);
  };

  const onRegReqMonthHandleChange = (e) => {
    setReqReqselectMonth(e.target.value);
    setShowRange(false);
  };

  const handleDateChange = (selection,e) =>{
    if(selection=="start"){
      setStartDateTime(e.target.value)
    } else{
      setEndDateTime(e.target.value)
    }
  }



  function toggleModal(id,userId,today,name) {
    setIsOpen(!isOpen);
    setRegReqData({
      id:id,
      userId:userId,
      userName:name,
      date:today,
    })
  }

  const handleRequest = ()=>{
    if(startDateTime==""){
      alert("Please select Check In time and date")
    } else if(endDateTime==""){
      alert("Please select Check Out time and date")
    } else{

      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }
      const guid = String(s4() + s4() + s4() + s4());
     
        db.ref("/regularization-Request").push({
            notificationId: guid,
            updateid:regReqData.id,
            userId:regReqData.userId,
            userName:regReqData.userName,
            date:regReqData.date,
            startDateTime:startDateTime,
            endDateTime:endDateTime,
            reson:reson,
            isEnabled: true
          }).then((res)=>{
            let message = "";
            message = regReqData.date +" "+"Regularization Request sent for the approval";
            db.ref("/notification/" + guid)
            .update({
              type: "RequestRegularization",
              msg: message,
              date: Date.now(),
              userRole: currentUserRole,
              userDept: currentUserDept,
              startDateTime:startDateTime,
              endDateTime:endDateTime,
              shilpmisId: shilpmisId,
              firstName: name,
              isEnabled: true,
              userView: false,
              HRView: false,
              HeadView: false,
              requestLeaveid: res.path.pieces_[1],
              leavesid: regReqData.id,
            }) .then((res) => {
              sendEmail(
                regReqData.userName,
                shilpmisId,
                `Leave Approved by ${name}`,
                startDateTime.split("T").join(" "),
                endDateTime.split("T").join(" "),
                currentUserDept,
                "pending",
                "not require",
              );
            })
            .catch((error) => {
              console.log(error);
            });
            getRegularizationData();
            setReson("");
            setIsOpen(!isOpen);
          }).catch((error) => {
            console.log("djcndjkdccdc", error);
          });
      }
  }

  const getRegularizationData = () =>{
    let data = [];

    db.ref("/regularization-Request").on("value", (val) => {
      val.forEach((element) => {
        let obj = element.val();
        obj["id"] = element.key;
        data.push(obj);
      });
    
     printRegData(data);

    });
  }

  const printRegData = (data) =>{
    let finalRegReqdata;
    if (selectRegReqYear == "" && selectMonth == "" && selectRange == "") {
      console.log("1-1-1-1-1")
      finalRegReqdata = data.filter((item) => {
        return (
          item.date.split("/")[2] == new Date().toLocaleString().split(",")[0].split("/")[2] &&
          item.date.split("/")[1] == new Date().toLocaleString().split(",")[0].split("/")[1]          
        );
      });
  } else if(selectRegReqYear !== "" && selectRegReqMonth == "" && selectRegReqRange == "") {
    console.log("2-2-2-2-2")
      finalRegReqdata = data.filter((item) => {
        return (
          item.date.split("/")[2] == selectRegReqYear
        );
      });
  }  else if(selectRegReqYear !== "" && selectRegReqMonth !== "" && selectRegReqRange == "") {
    console.log("3-3-3-3-3")
    finalRegReqdata = data.filter((item) => {
      return (
        item.date.split("/")[2] == selectRegReqYear &&  item.date.split("/")[1] == selectRegReqMonth 
      );
    });
  }
  else if(selectRegReqYear !== "" && selectRegReqMonth !== "" && selectRegReqRange !== "") {
    console.log("4-4-4-4-4")
    finalRegReqdata = data.filter((item) => {
      return (
        item.date.split("/")[2] == selectRegReqYear &&  item.date.split("/")[1] == selectRegReqMonth &&
        item.date.split("/")[0] >= selectRegReqRange.split("-")[0] && item.date.split("/")[0] <= selectRegReqRange.split("-")[1]
      );
    });
  }
    setRegData(finalRegReqdata);
  }

  function sendEmail(
    name,
    email,
    leaveMsg,
    reason,
    startDateTime,
    endDateTime,
    Department,
    HrStatus,
    deptHeadStatus,
    departmentmail,
  ) {
    const commonMsg =
      "Dear sir/Ma'am" +
      "," +
      "<br/><br/>" +
      `<p>${name}, ${leaveMsg}</p>
      <br/>
      <p> Details are as follow ::
      <br></br>` +
      `<table style={{"width:100%",border : "1px solid black"}}>
      <tr>
        <th>Name</th>
        <th>Reason</th>
        <th>From</th>
        <th>To</th>
        <th>Department</th>
        <th>HR Status</th>
        <th>Dept. Head Status</th>
      </tr>
      <tr>
        <td>${name}</td>
        <td>${reason}</td>
        <td>${startDateTime}</td>
        <td>${endDateTime}</td>
        <td>${Department}</td>
        <td>${HrStatus}</td>
        <td>${deptHeadStatus}</td>
        </tr>
        </table>
      <br/>
      <p>Please do not reply this mail. It's system generated mail.</p>
      <br/>
    <p>Have a wonderful day! </p>
    <br/>
    <p>Thank you,</p>
    <p>ShilpMIS Technologies Pvt. Ltd.</p>
    <br/>`;
  
    const adminMsg =
      "Dear sir/Ma'am" +
      "," +
      "<br/><br/>" +
      `<p>${name}, ${leaveMsg}</p>
      <br/>
      <p> Details are as follow ::
      <br></br>` +
      `<table style={{"width:100%",border : "1px solid black"}}>
      <tr>
        <th>Name</th>
        <th>Reason</th>
        <th>From</th>
        <th>To</th>
        <th>Department</th>
        <th>HR Status</th>
        <th>Dept. Head Status</th>
      </tr>
      <tr>
        <td>${name}</td>
        <td>${reason}</td>
        <td>${startDateTime}</td>
        <td>${endDateTime}</td>
        <td>${Department}</td>
        <td>${HrStatus}</td>
        <td>${deptHeadStatus}</td>
        </tr>
        </table>
        <br/>
      <br/>
      <a href="https://dashboard.melzo.com/approve-leaves">Click here to approve Regularization Request...</a>
      <br/>
  
  
      <p>Please do not reply this mail. It's system generated mail.</p>
      <br/>
    <p>Have a wonderful day! </p>
    <br/>
    <p>Thank you,</p>
    <p>ShilpMIS Technologies Pvt. Ltd.</p>
    <br/>`;
  
    var obj = {
      useremail: email, // User Mail Id
      message: commonMsg,
      expoName: "Dashboard",
      senderName: "Melzo Dashboard",
      ccUser: "nikita@melzo.com ", // HR Mail Id + Dept Head Mail Id
      bccUser: "",
      subject: "Apply for leave !",
    };
  
    var adminObj = {
      useremail: email, // User Mail Id
      message: adminMsg,
      expoName: "Dashboard",
      senderName: "Melzo Dashboard",
      bccUser: "",
      subject: "Apply for leave !",
    };
  
    axios
      .post(
        "https://us-central1-sendtoemail-3610d.cloudfunctions.net/sendCompanyEmail",
        obj,
        {
          headers: {
            // 'x-access-token': token
          },
        }
      )
      .then((regEmail) => {
        console.log("Done", regEmail);
        axios
      .post(
        "https://us-central1-sendtoemail-3610d.cloudfunctions.net/sendCompanyEmail",
        adminObj,
        {
          headers: {
            // 'x-access-token': token
          },
        }
      ).then((adminEmail) => {
        console.log("Done", adminEmail);
      }).catch(adminErr => {
        console.log("Not Done", adminErr);
      })
      })
      .catch((error) => {
        console.log("Not Done", error);
      });
  }


  const handleRegRequest = (req,id,regId,checkInTime,checkOutTime) =>{
    if(req=="Approve"){
      db.ref("/regularization-Request/" + id).update({
        isEnabled:false
      }).then(()=>{
        db.ref("/attendence/" + regId).update({
          location:"Regularization Request Approved",
          checkInTime:Number(new Date(checkInTime.split("T").join(" "))),
          checkOut:Number(new Date(checkOutTime.split("T").join(" ")))
        }).then(()=>{
        getRegularizationData();
        })
      })
    } else if(req=="Rejected"){
      db.ref("/regularization-Request/" + id).update({
        isEnabled:"Reject"
      }).then(()=>{
        db.ref("/attendence/" + regId).update({
          location:"Regularization Request Rejected",
          checkInTime:"Regularization Request Rejected",
          checkOut:"Regularization Request Rejected",
        }).then(()=>{
        getRegularizationData();
        })
      })
    }
  }

  function monthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
  
    return date.toLocaleString('en-US', {
      month: 'long',
    });
  }
  return (
    <>
      {/* <Header /> */}
      <div className="content">
        <Container style={{ marginTop: "20px" }}>
          <Divider />
          <Card style={{ marginTop: "60px" }}>
            <CardContent>
            <TabContext value={tabValue}>
            {currentUserRole === "HR" ?
                  <AppBar
                    position="static"
                    style={{ background: "white", color: "black" }}
                  >
                    <TabList
                       onChange={handleChangeTab}
                      aria-label="profiletab"
                    >
                      <Tab
                        label="Attendence List"
                        value="1"
                        style={{ fontFamily: "Montserrat", outline: "0px" }}
                      />
                      
                      
                      <Tab
                        label="Regularization Request"
                        value="2"
                        style={{ fontFamily: "Montserrat", outline: "0px" }}
                      />
                       </TabList>
                  </AppBar>    
                   :
                   <AppBar
                   position="static"
                   style={{ background: "white", color: "black" }}
                 >
                   <TabList
                      onChange={handleChangeTab}
                     aria-label="profiletab"
                   >
                     <Tab
                       label="Attendence List"
                       value="1"
                       style={{ fontFamily: "Montserrat", outline: "0px" }}
                     />
                     
                     <Tab
                        label="Regularization Request"
                        value="2"
                        style={{ fontFamily: "Montserrat", outline: "0px" }}
                      />
                      </TabList>
                 </AppBar>    
                   }
              <TabPanel value="1">

              <AppBar
                position="static"
                style={{
                  background: "white",
                  color: "black",
                  marginTop: "-10px",
                  paddingLeft: "10px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                <div id="filter-row">
                  {(currentUserRole === "HR") && (
                    <select
                      id="inputUserRole"
                      className="form-control"
                      name="userRole"
                      onChange={handleSelectChange}
                      defaultValue="Select User"
                    >
                      <option disabled hidden>
                       Select User
                      </option>
                      {employeeData.map((e) => (
                        <option value={e.userID}>
                          {e.userID + " " + e.firstName + " " + e.lastName}
                        </option>
                      ))}
                    </select>
                  )}

                  <select
                    onChange={(e) => onHandleChange(e)}
                    id="year"
                    className="form-control"
                    name="year"
                  >
                    <option>Select Year</option>
                    {years.map((e) => (
                      <option key={e} value={e}>{e}</option>
                    ))}
                  </select>

                  <select
                    onChange={(e) => onMonthHandleChange(e)}
                    id="viewFormate"
                    className="form-control"
                    name="viewFormate"
                    disabled={showMonth}
                  >
                    <option>Select Month</option>
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>

                  <select
                    onChange={(e) => setselectRange(e.target.value)}
                    id="viewFormate"
                    className="form-control"
                    name="viewFormate"
                    disabled={showRange}
                  >
                    <option>Select Week...</option>
                    <option value="01-07">
                      {"01" +"-" + monthName(selectMonth) + "-" + selectYear +" To " + "07" + "-" + 
                      monthName(selectMonth) + "-" +selectYear}
                    </option>
                    <option value="08-14">
                      {"08" + "-" + monthName(selectMonth) + "-" + selectYear + " To " + "14" +  "-" +
                        monthName(selectMonth) + "-" +selectYear}
                    </option>
                    <option value="15-21">
                      {"15" + "-" + monthName(selectMonth) + "-" + selectYear + " To " + "21" + "-" +
                        monthName(selectMonth) +"-" +  selectYear}
                    </option>
                    <option value="22-28">
                      {"22" + "-" + monthName(selectMonth) +  "-" + selectYear + " To " + "28" + "-" +
                        monthName(selectMonth) + "-" + selectYear}
                    </option>
                    <option value="29-31">
                      {"29" + "-" + monthName(selectMonth) +  "-" + selectYear + " To " + "31" + "-" +
                        monthName(selectMonth) + "-" + selectYear}
                    </option>
                  </select>

                  {(currentUserRole !== "HR") && (

                      <ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className="download-table-xls-button"
                      table="table-to-xls"
                      filename="tablexls"
                      sheet="tablexls"
                      buttonText="Download as XLS"
                      />
                  )}
                </div>

                {(currentUserRole === "HR") && (
                <div style={{ display: "flex", flexDirection: "row",marginTop:"10px",gap:"5px"}}>
                <Grid item md={3} xs={12}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      label="Date Wise"
                      name="fromDate"
                      format="MM/DD/yyyy"
                      animateYearScrolling
                      style={{width:"90%", display:isOpen ? "none":""}}
                      value={selectDates}
                      InputAdornmentProps={{ position: "start" }}
                      inputProps={{ style: { margin: "0" } }}
                      onChange={(e) => setSelectDate(e._d)}
                    
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Download as XLS"
                  />
                </div>
                )}
              </AppBar>

              <TableContainer>
                {filterData.length == 0 && (
                  <img
                    src={noDataImg}
                    style={{
                      height: "300px",
                      margin: "20px auto 5px auto",
                      display: "flex",
                    }}
                  ></img>
                )}

                {filterData.length > 0 && (
                  
                  <Table
                    aria-label="simple table"
                    className="table"
                    id="table-to-xls"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center"></TableCell>
                        {(currentUserRole === "HR") && ( <TableCell align="center">Name</TableCell>)}
                        <TableCell align="center">Location</TableCell>
                        <TableCell align="center">Date</TableCell>
                        
                        <TableCell align="center">Check In Time</TableCell>
                        <TableCell align="center">Check Out Time</TableCell>
                        <TableCell align="center">
                          Duration (HH:MM:SS)
                        </TableCell>
                        <TableCell align="center">User Id</TableCell>
                        {(currentUserRole === "HR") && (<TableCell align="center">User Role</TableCell>)}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filterData &&
                        filterData.map((data, index) => (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
                          <>

                            <TableRow key={data.id} className={data.checkInTime === "Absent" ? "AttandanceTable" :""} onClick={data.checkInTime === "Absent" ? ()=>{toggleModal(data.id,data.userId, data.today,data.name)} :""}>
                              {/* {console.log(new Date(data.checkInTime).toLocaleString().split(",")[0])} */}
                              <TableCell>
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  style={{display: data.checkInTime === "Absent" ? "none": "block" }}
                                  onClick={() =>
                                    setOpen(open === index ? -1 : index)
                                  }
                                >
                                  {open === index ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              </TableCell>
                              <TableCell align="center">{index + 1}</TableCell>
                              {(currentUserRole === "HR") && (<TableCell align="center">{data.name}</TableCell>)}
                              <TableCell align="center"> {data.location} </TableCell>
                              <TableCell align="center"> {data.today} </TableCell>
                              <TableCell align="center">
                                {data.checkInTime === "Absent"
                                  ? "Absent" 
                                  : data.checkInTime === "Regularization Request Rejected" ?
                                  "Regularization Request Rejected"
                                  : new Date(data.checkInTime).toLocaleString()}
                              </TableCell>
                              <TableCell align="center">
                                {data.checkOut === ""
                                  ? "Not Check out yet"
                                  : data.checkOut === "Absent"
                                  ? "Absent"
                                  : data.checkOut === "Regularization Request Rejected" ?
                                  "Regularization Request Rejected"
                                  : new Date(data.checkOut).toLocaleString()}
                              </TableCell>
                              <TableCell align="center">
                                {data.checkOut === ""
                                  ? "Not Check out yet"
                                  : data.checkOut === "Absent"
                                  ? "Absent"
                                  : data.checkOut === "Regularization Request Rejected" ?
                                  "Regularization Request Rejected"
                                  : secondsToHms(
                                      Math.abs(data.totalDuration / 1000)
                                    )}
                              </TableCell>
                              <TableCell align="center">
                                {data.userId}
                              </TableCell>
                              {(currentUserRole === "HR") && (<TableCell align="center">
                                {data.userRole}
                              </TableCell>)}
                            </TableRow>
                              
                            {
                              <TableRow>
                                <TableCell
                                  style={{ paddingBottom: 0, paddingTop: 0 }}
                                  colSpan={10}
                                >
                                  <Collapse
                                    in={open === index}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    <Box margin={1}>
                                      <Table
                                        size="small"
                                        aria-label="purchases"
                                      >
                                        <TableHead>
                                          <TableRow>
                                            <TableCell align="center"></TableCell>
            {(currentUserRole === "HR") && (<TableCell align="center">  </TableCell>)}
                                            <TableCell align="center">  Name </TableCell>
                                            <TableCell align="center">  Location </TableCell>
                                            <TableCell align="center">  Date </TableCell>
                                            <TableCell align="center">  Check In Time </TableCell>
                                            <TableCell align="center">  Check Out Time </TableCell>
                                            <TableCell align="center">  Duration (HH:MM:SS) </TableCell>
                                            <TableCell align="center">  User Id </TableCell>
            {(currentUserRole === "HR") && (<TableCell align="center">  User Role </TableCell>)}
                                          </TableRow>
                                        </TableHead>

                                        <TableBody>
                                          {allAttendenceData
                                            .filter(
                                              (s) =>
                                                new Date(s.checkInTime).toLocaleString().split(",")[0].split("/")[0] 
                                                == new Date(data.checkInTime).toLocaleString().split(",")[0]  .split("/")[0] &&
                                                data.checkInTime !== "" && data.name == s.name
                                            )
                                            .map((e, index) => (
                                              <TableRow key={e.checkInTime}>
                                                <TableCell>{}</TableCell>
                                                <TableCell> {index + 1} </TableCell>
                  {(currentUserRole === "HR") && (<TableCell>{e.name}</TableCell>)}
                                                <TableCell> {e.location}</TableCell>
                                                <TableCell> {e.today}</TableCell>
                                                
                                                <TableCell>
                                                  {" "}
                                                  {e.checkInTime === "Absent"
                                                    ? "Absent"
                                                    : new Date(
                                                        e.checkInTime
                                                      ).toLocaleString()}{" "}
                                                </TableCell>
                                                <TableCell> {" "}
                                                  {e.checkOut === ""
                                                    ? "Not Check out yet"
                                                    : e.checkOut === "Absent"
                                                    ? "Absent"
                                                    : new Date(
                                                        e.checkOut
                                                      ).toLocaleString()}{" "}
                                                </TableCell>
                                                <TableCell> {data.checkOut === ""
                                                    ? "Not Check out yet"
                                                    : data.checkOut === "Absent"
                                                    ? "Absent"
                                                    : secondsToHms(
                                                        Math.abs(
                                                          e.checkOut -
                                                            e.checkInTime
                                                        ) / 1000
                                                      )}
                                                </TableCell>
                                                <TableCell> {e.userId} </TableCell>
                {(currentUserRole === "HR") && (<TableCell> {e.userRole} </TableCell>)}
                                              </TableRow>
                                            ))}
                                        </TableBody>
                                      </Table>
                                    </Box>
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            }
                          </>
                        ))}
                    </TableBody>
                  </Table>
                )}
               
              </TableContainer>

              <Modal isOpen={isOpen}  onRequestClose={toggleModal} contentLabel="My dialog">
                                 
                                    <Container id="modals-containers">
                                    <div className="team-members" style={{width:"50%",margin:"auto"}}>
                                            <div className="modal-inner-div" >
                                
                                              {/* <TextField id="standard-multiline-flexible"  value={regReqData.date}  label="Date" variant="filled" disabled />
                                              <TextField id="standard-multiline-flexible"  value={regReqData.userName}  label="Date" variant="filled" disabled /> */}
                                            {/* <div>
                                              <TextField disabled id="datetime-local2" value={regReqData.date} label="Date" defaultValue="Hello World" />
                                              <TextField disabled id="datetime-local2" value={regReqData.userName} label="Name" defaultValue="Hello World" />
                                            </div> */}

                                            <TextField
                                              id="datetime-local"
                                              label="Check In Time"
                                              type="datetime-local"
                                              style={{minHeight: "1px"}}
                                              defaultValue={new Date().toISOString().substring(0,10)+ "T00:00"}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              onChange={(e)=>handleDateChange("start",e)}
                                            />

                                          <TextField
                                              id="datetime-local"
                                              label="Check Out Time"
                                              type="datetime-local"
                                              style={{minHeight: "1px"}}
                                              defaultValue={new Date().toISOString().substring(0,10) + "T00:00"}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              onChange={(e)=>handleDateChange("end",e)}
                                            />

                                           {/* <textarea id="resonfield" label="Reson" variant="filled"  style={{marginRight:"-1px"}}  onChange={(e)=>setReson(e.target.value)}/>
                                         */}
                                         
                                           <textarea name="comments" variant="outlined" aria-labelledby="comment" aria-label="nkflvfn" id="modal-textfield"
                                                 required autoComplete="off" placeholder="Reason for regularization request"  style={{borderBottom:"1px solid #555555",fontSize:"12px"}}
                                                 onChange={(e)=>setReson(e.target.value)}
                                            ></textarea>
                                      </div>

                                      <div id="modal-btn-div">
                                    <button className="download-table-xls-button modal-btn" onClick={toggleModal}>Cancel</button>
                                    <button className="download-table-xls-button modal-btn" onClick={()=>handleRequest()}>Submit</button>
                                    </div>

                                    </div>
                                   
                                    </Container>
                                
                                  </Modal>
              </TabPanel>
              <TabPanel value="2">
              <AppBar
                position="static"
                style={{
                  background: "white",
                  color: "black",
                  marginTop: "-10px",
                  paddingLeft: "10px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                <div id="filter-row">
                  <select
                    onChange={(e) => onRegReqHandleChange(e)}
                    id="year"
                    className="form-control"
                    name="year"
                  >
                    <option>Select Year</option>
                    {years.map((e) => (
                      <option key={e} value={e}>{e}</option>
                    ))}
                  </select>

                  <select
                    onChange={(e) => onRegReqMonthHandleChange(e)}
                    id="viewFormate"
                    className="form-control"
                    name="viewFormate"
                    disabled={showMonth}
                  >
                    <option>Select Month</option>
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>

                  <select
                    onChange={(e) => setReqReqselectRange(e.target.value)}
                    id="viewFormate"
                    className="form-control"
                    name="viewFormate"
                    disabled={showRange}
                  >
                    <option>Select Week...</option>
                    <option value="01-07">
                      {"01" +"-" + monthName(selectRegReqMonth) + "-" + selectRegReqYear +" To " + "07" + "-" + 
                      monthName(selectRegReqMonth) + "-" +selectRegReqYear}
                    </option>
                    <option value="08-14">
                      {"08" + "-" + monthName(selectRegReqMonth) + "-" + selectRegReqYear + " To " + "14" +  "-" +
                        monthName(selectRegReqMonth) + "-" +selectRegReqYear}
                    </option>
                    <option value="15-21">
                      {"15" + "-" + monthName(selectRegReqMonth) + "-" + selectRegReqYear + " To " + "21" + "-" +
                        monthName(selectRegReqMonth) +"-" +  selectRegReqYear}
                    </option>
                    <option value="22-28">
                      {"22" + "-" + monthName(selectRegReqMonth) +  "-" + selectRegReqYear + " To " + "28" + "-" +
                        monthName(selectRegReqMonth) + "-" + selectRegReqYear}
                    </option>
                    <option value="29-31">
                      {"29" + "-" + monthName(selectRegReqMonth) +  "-" + selectRegReqYear + " To " + "31" + "-" +
                        monthName(selectRegReqMonth) + "-" + selectRegReqYear}
                    </option>
                  </select>

                  {(currentUserRole !== "HR") && (

                      <ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className="download-table-xls-button"
                      table="table-to-xls"
                      filename="tablexls"
                      sheet="tablexls"
                      buttonText="Download as XLS"
                      />
                  )}
                </div>
              </AppBar>

              <TableContainer>
                
              { (currentUserRole == "HR" && regData.length == 0) && (
                  <img
                    src={noDataImg}
                    style={{
                      height: "300px",
                      margin: "20px auto 5px auto",
                      display: "flex",
                    }}
                  ></img>
                )}

              { (currentUserRole !== "HR" && regData.filter((e) => e.userId == loginUserId).length == 0) && (
                  <img
                    src={noDataImg}
                    style={{
                      height: "300px",
                      margin: "20px auto 5px auto",
                      display: "flex",
                    }}
                  ></img>
                )}
              
              { (currentUserRole == "HR" ? regData.length > 0 : regData.filter((e) => e.userId == loginUserId).length > 0) && (
              <Table aria-label="simple table"
              className="table"
              id="table-to-xls">
                    <TableHead>
                      <TableRow>
                      <TableCell></TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">User Id</TableCell>
                      <TableCell align="left">Date</TableCell>
                      <TableCell align="left">Check In Time</TableCell>
                      <TableCell align="left">Check Out Time</TableCell>
                      <TableCell align="left">Reson</TableCell>
                      <TableCell align="left">Action</TableCell>
                      <TableCell align="left">Approve/Reject</TableCell>
                      </TableRow>
                    </TableHead>
                  <TableBody>
                  {

                      currentUserRole == "HR" ? regData.map((data,index)=>(

                      <TableRow key={data.id}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{data.userName}</TableCell>
                      
                      <TableCell align="center">{data.userId}</TableCell>
                      <TableCell align="center">{data.date}</TableCell>
                      <TableCell align="center">{data.startDateTime && data.startDateTime.split("T").join(" ")}</TableCell>
                      <TableCell align="center">{data.endDateTime && data.endDateTime.split("T").join(" ")}</TableCell>
                      <TableCell align="left">{data.reson}</TableCell>
                      <TableCell align="left">
                      <div style={{display:"flex", flexDirection:"row", gap:"5px"}}>
                      <button disabled={!data.isEnabled || data.isEnabled=="Reject"} className="ApproveBtn"  onClick={(e)=>{handleRegRequest("Approve",data.id,data.updateid,data.startDateTime,data.endDateTime)}}>Approve</button>
                      <button disabled={data.isEnabled=="Reject" || !data.isEnabled} className="CancelBtn"  onClick={(e)=>{handleRegRequest("Rejected",data.id,data.updateid,data.startDateTime,data.endDateTime)}}>Rejected</button>
                      </div>
                      </TableCell>
                      <TableCell align="left">{data.isEnabled=="Reject" ?"Rejected" : data.isEnabled ? "Pending" : !data.isEnabled ? "Approve" : ""}</TableCell>
                      </TableRow>
                    )) :
                    regData.filter((e) => e.userId == loginUserId).map((data,index)=>(
                      <TableRow key={data.id}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{data.userName}</TableCell>
                      
                      <TableCell align="center">{data.userId}</TableCell>
                      <TableCell align="center">{data.date}</TableCell>
                      <TableCell align="center">{data.startDateTime && data.startDateTime.split("T").join(" ")}</TableCell>
                      <TableCell align="center">{data.endDateTime && data.endDateTime.split("T").join(" ")}</TableCell>
                      <TableCell align="left">{data.reson}</TableCell>
                      <TableCell align="left">
                      <div style={{display:"flex", flexDirection:"row", gap:"5px"}}>
                        {data.isEnabled=="Reject" ?"Regularization Request Rejected" : data.isEnabled ? "Regularization Request Pending" : !data.isEnabled ? "Regularization Request Approve" : ""}
                      </div>
                      </TableCell>
                      <TableCell align="left">{data.isEnabled=="Reject" ?"Rejected" : data.isEnabled ? "Pending" : !data.isEnabled ? "Approve" : ""}</TableCell>
                      </TableRow>
                    ))
                  }
                   </TableBody>
            </Table>  
              )}
               </TableContainer>
     
              </TabPanel>
              </TabContext>
            </CardContent>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default Attendancefeature;
