import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import { Tab } from "bootstrap";
import { db } from "../../Firebase";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import { TabContext } from "@material-ui/lab";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import MuiAlert from "@material-ui/lab/Alert";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import filterCancelIcon from "../../assets/img/filter-cancel.png";
// import DateRangePicker from '@material-ui/lab/DateRangePicker';
// import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
// import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Snackbar,
  Typography,
  Tooltip,
  Zoom,
  withStyles,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
} from "@material-ui/core";
import noDataImg from "../../assets/img/NoData.png";
import Page from "../Register/page";
import ConfirmDialog from "../Register/ConfirmDialog";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import axios from "axios";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class ApproveLeaves extends Component {
  constructor(props) {
    super(props);

    this.state = {
      requestLeaveData: [],
      addLeaveData: [],
      userRole: "",
      userDept: "",
      firstName: "",
      tabValue: "1",
      filterOpen: false,
      isFiltered: false,
      pendingLeaveList: [],
      pendingLeaveTemp: [],
      approvedLeaveList: [],
      toDate: new Date(),
      fromDate: new Date(),
      notificationId: "",
    };
  }

  componentDidMount = () => {
    if (localStorage.getItem("employeeObject")) {
      if (
        JSON.parse(localStorage.getItem("employeeObject")).userRole ==
        "employee"
      ) {
        window.location.href = "/dashboard";
      } else {
        this.setState({
          userRole: localStorage.getItem("employeeObject")
            ? JSON.parse(localStorage.getItem("employeeObject")).userRole
            : " ",
          userDept: localStorage.getItem("employeeObject")
            ? JSON.parse(localStorage.getItem("employeeObject")).department
            : [],
          firstName: localStorage.getItem("employeeObject")
            ? JSON.parse(localStorage.getItem("employeeObject")).firstName +
              " " +
              JSON.parse(localStorage.getItem("employeeObject")).lastName
            : " ",
        });
        this.onGetLeaveData();
      }
    }
  };

  onGetLeaveData = () => {
    let addLeaveDataPromise = new Promise((resolve, reject) => {
      let leaveArray = [];
      db.ref("/leaves/").on("value", (queryLeave) => {
        queryLeave.forEach((element) => {
          let obj = element.val();
          obj["id"] = element.key;
          leaveArray.push(obj);
        });
        resolve(leaveArray);
      });
    });
    addLeaveDataPromise.then((leaveResultData) => {
      this.setState(
        {
          addLeaveData: leaveResultData,
          // this.onGetRequestedLeave();
        },
        () => {
          this.onGetRequestedLeave();
          console.log("add leave data::", this.state.addLeaveData);
        }
      );
    });
  };

  onGetRequestedLeave = () => {
    let requestedLeaveDataPromise = new Promise((resolve, reject) => {
      db.ref("/requestedLeaves/").on("value", (queryRequest) => {
        let requestLeaveArray = [];
        queryRequest.forEach((element) => {
          if (element.val().isEnabled) {
            let obj = element.val();
            obj["id"] = element.key;
            requestLeaveArray.push(obj);
          }
        });
        resolve(requestLeaveArray);
      });
    });
    requestedLeaveDataPromise.then((acceptedData) => {
      console.log("accepted data", acceptedData);
      // logic for HR

      if (this.state.userRole == "HR") {
        let data = [];
        let employeeData = acceptedData;
        console.log("filter data", employeeData);
        employeeData.map((LeaveData) => {
          if (LeaveData.userDept) {
            let dataStored = true;
            for (let i = 0; i < this.state.userDept.length; i++) {
              if (dataStored) {
                // if (LeaveData.userDept.includes(this.state.userDept[i])) {
                let insideMapLoop = false;
                this.state.addLeaveData.map((leaveDataId) => {
                  // console.log(leaveDataId.employeeEmail, LeaveData.shilpmisId)
                  if (leaveDataId.employeeEmail == LeaveData.shilpmisId) {
                    insideMapLoop = true;
                    data.push({
                      requestLeave: LeaveData,
                      LeaveCount: leaveDataId,
                    });
                  }
                });
                if (!insideMapLoop) {
                  data.push({
                    requestLeave: LeaveData,
                    LeaveCount: { id: "Data not found" },
                  });
                }
                dataStored = false;
                // }
              }
            }
          }
        });
        this.setState(
          {
            requestLeaveData: data,
          },
          () => {
            console.log("request leave data::", this.state.requestLeaveData);
            this.handleTabChangeData("Pending");
            // this.handleFilterData();
          }
        );
      }
      // logic for team_lead
      else if (this.state.userRole == "team_lead") {
        let data = [];
        let employeeData = acceptedData.filter((filterData) => {
          return filterData.userRole === "employee";
        });
        console.log("filter data", employeeData);
        employeeData.map((LeaveData) => {
          if (LeaveData.userDept) {
            let dataStored = true;
            for (let i = 0; i < this.state.userDept.length; i++) {
              if (dataStored) {
                if (LeaveData.userDept.includes(this.state.userDept[i])) {
                  let insideMapLoop = false;
                  this.state.addLeaveData.map((leaveDataId) => {
                    // console.log(leaveDataId.employeeEmail, LeaveData.shilpmisId)
                    if (leaveDataId.employeeEmail == LeaveData.shilpmisId) {
                      insideMapLoop = true;
                      data.push({
                        requestLeave: LeaveData,
                        LeaveCount: leaveDataId,
                      });
                    }
                  });
                  if (!insideMapLoop) {
                    data.push({
                      requestLeave: LeaveData,
                      LeaveCount: { id: "Data not found" },
                    });
                  }
                  dataStored = false;
                }
              }
            }
          }
        });
        this.setState(
          {
            requestLeaveData: data,
          },
          () => {
            console.log("request leave data::", this.state.requestLeaveData);
            this.handleTabChangeData("Pending");
            this.handleFilterData();
          }
        );
      }
      // Logic for Dept_Head
      else if (this.state.userRole == "dept_head") {
        let data = [];
        let teamLeadData = acceptedData.filter((filterData) => {
          return (
            filterData.userRole === "team_lead" ||
            filterData.userRole === "employee" ||
            filterData.userRole === "HR"
          );
        });
        console.log("filter data", teamLeadData);
        teamLeadData.map((LeaveData) => {
          if (LeaveData.userDept) {
            let dataStored = true;
            for (let i = 0; i < this.state.userDept.length; i++) {
              if (dataStored) {
                if (LeaveData.userDept.includes(this.state.userDept[i])) {
                  let insideMapLoop = false;
                  this.state.addLeaveData.map((leaveDataId) => {
                    // console.log(leaveDataId.employeeEmail, LeaveData.shilpmisId)
                    if (leaveDataId.employeeEmail == LeaveData.shilpmisId) {
                      insideMapLoop = true;
                      data.push({
                        requestLeave: LeaveData,
                        LeaveCount: leaveDataId,
                      });
                    }
                  });
                  if (!insideMapLoop) {
                    data.push({
                      requestLeave: LeaveData,
                      LeaveCount: { id: "Data not found" },
                    });
                  }
                  dataStored = false;
                }
              }
            }
          }
        });
        this.setState(
          {
            requestLeaveData: data,
          },
          () => {
            console.log("request leave data::", this.state.requestLeaveData);
            this.handleTabChangeData("Pending");
            // this.handleFilterData();
          }
        );
      }
      // Logic for admin
      else if (this.state.userRole == "admin") {
        let data = [];
        let deptHeadData = acceptedData.filter((filterData) => {
          return (
            filterData.userRole === "dept_head" ||
            filterData.userRole === "team_lead" ||
            filterData.userRole === "employee" ||
            filterData.userRole === "HR"
          );
        });
        console.log("filter data", deptHeadData);

        deptHeadData.map((LeaveData) => {
          if (LeaveData.userDept) {
            let dataStored = true;
            for (let i = 0; i < this.state.userDept.length; i++) {
              if (dataStored) {
                if (LeaveData.userDept.includes(this.state.userDept[i])) {
                  let insideMapLoop = false;
                  this.state.addLeaveData.map((leaveDataId) => {
                    // console.log(leaveDataId.employeeEmail, LeaveData.shilpmisId)
                    if (leaveDataId.employeeEmail == LeaveData.shilpmisId) {
                      insideMapLoop = true;
                      data.push({
                        requestLeave: LeaveData,
                        LeaveCount: leaveDataId,
                      });
                    }
                  });
                  if (!insideMapLoop) {
                    data.push({
                      requestLeave: LeaveData,
                      LeaveCount: { id: "Data not found" },
                    });
                  }
                  dataStored = false;
                }
              }
            }
          }
        });
        this.setState(
          {
            requestLeaveData: data,
          },
          () => {
            console.log("request leave data::", this.state.requestLeaveData);
            this.handleTabChangeData("Pending");
            this.handleFilterData();
          }
        );
      }
      // Logic for superadmin
      else if (this.state.userRole == "superadmin") {
        let data = [];
        let adminData = acceptedData.filter((filterData) => {
          return filterData.userRole !== "superadmin";
        });
        adminData.map((LeaveData) => {
          if (LeaveData.userDept) {
            let dataStored = true;
            for (let i = 0; i < this.state.userDept.length; i++) {
              if (dataStored) {
                if (LeaveData.userDept.includes(this.state.userDept[i])) {
                  let insideMapLoop = false;
                  this.state.addLeaveData.map((leaveDataId) => {
                    // console.log(leaveDataId.employeeEmail, LeaveData.shilpmisId)
                    if (leaveDataId.employeeEmail == LeaveData.shilpmisId) {
                      insideMapLoop = true;
                      data.push({
                        requestLeave: LeaveData,
                        LeaveCount: leaveDataId,
                      });
                    }
                  });
                  if (!insideMapLoop) {
                    data.push({
                      requestLeave: LeaveData,
                      LeaveCount: { id: "Data not found" },
                    });
                  }
                  dataStored = false;
                }
              }
            }
          }
        });
        this.setState(
          {
            requestLeaveData: data,
          },
          () => {
            console.log("request leave data::", this.state.requestLeaveData);
            this.handleTabChangeData("Pending");
            this.handleFilterData();
          }
        );
      }
    });
  };

  onApproved = (
    key,
    categoryOfLeave,
    leaveCountObj,
    HRStatus,
    HeadStatus,
    requestleaveObj,
    notificationId
  ) => {
    console.log("Approved", key, leaveCountObj, requestleaveObj);

    let diffDays = 0;
    let fromDate1 = requestleaveObj.fromDate;
    let toDate1 = requestleaveObj.toDate;
    let diffTime = Math.abs(toDate1 - fromDate1);

    // counting sundays
    let sundays = countSunday(new Date(fromDate1), new Date(toDate1));
    if (requestleaveObj.halfDay) {
      diffDays = 0.5;
    } else {
      diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1 - sundays;
    }
    console.log("DATE DIFF", diffDays);

    let statusForLeaveCount = false;
    const updateStatus = new Promise((resolve, reject) => {
      db.ref("/requestedLeaves/" + key)
        .update({
          status: this.state.userRole === "HR" ? "Approved" : HRStatus,
          deptHeadStatus:
            this.state.userRole !== "HR" ? "Approved" : HeadStatus,
        })
        .then((res) => {
          console.log("Status Updated");
          resolve("success");
        })
        .catch((err) => {
          console.error("error", err);
          reject(err);
        });
    });
    if (this.state.userRole === "HR") {
      if(notificationId !== "0"){
      db.ref("/notification/" + notificationId).update({
        HRView: true,
      });
    }
      HRStatus = "Approved";
    } else {
      if(notificationId !== "0")
      {
      db.ref("/notification/" + notificationId).update({
        HeadView: true,
      });
    }
      HeadStatus = "Approved";
    }
    if (HRStatus == "Approved" && HeadStatus == "Approved") {
      const updateLeaveCount = new Promise((res, rej) => {
        // Leave data counting
        if (
          leaveCountObj.id !== "Data not found" &&
          categoryOfLeave !== "LWP"
        ) {
          db.ref("leaves")
            .child(leaveCountObj.id)
            .child("balanceLeave")
            .transaction((leave) => {
              if (categoryOfLeave == "casual Leave") {
                // console.log(leave.casualLeave, "leave count ca");
                leave.casualLeave = parseFloat(
                  parseFloat(leave.casualLeave).toFixed(1) -
                    parseFloat(diffDays).toFixed(1)
                ).toFixed(1);
              } else if (categoryOfLeave == "marriage Leave") {
                // console.log(leave.marriageLeave, "leave count ma");
                leave.marriageLeave = parseFloat(
                  parseFloat(leave.marriageLeave).toFixed(1) -
                    parseFloat(diffDays).toFixed(1)
                ).toFixed(1);
              } else if (categoryOfLeave == "privilege Leave") {
                // console.log(leave.privilegeLeave, "leave count pr");
                leave.privilegeLeave = parseFloat(
                  parseFloat(leave.privilegeLeave).toFixed(1) -
                    parseFloat(diffDays).toFixed(1)
                ).toFixed(1);
              } else if (categoryOfLeave == "sick Leave") {
                // console.log(leave.sickLeave, "leave count si");
                leave.sickLeave = parseFloat(
                  parseFloat(leave.sickLeave).toFixed(1) -
                    parseFloat(diffDays).toFixed(1)
                ).toFixed(1);
              }
              return leave;
            });
        } else if (categoryOfLeave === "LWP") {
          db.ref("leaves")
            .child(leaveCountObj.id)
            .transaction((leave) => {
              console.log(
                "LWP Database",
                leave,
                leave.leaveWithoutPay,
                "hk",
                diffDays,
                "days",
                parseFloat(parseFloat(leave.leaveWithoutPay) + diffDays)
              );

              leave.leaveWithoutPay = parseFloat(
                parseFloat(leave.leaveWithoutPay) + diffDays
              );

              return leave;
            });
        }
        sendEmail(
          requestleaveObj.firstName,
          requestleaveObj.shilpmisId,
          `Leave Approved`,
          new Date(requestleaveObj.fromDate).toLocaleDateString("en-IN"),
          new Date(requestleaveObj.toDate).toLocaleDateString("en-IN"),
          requestleaveObj.userDept,
          "Approved",
          "Approved"
        );
      });
    }
    new Promise((res, rej) => {
      db.ref("/notification")
        .push({
          type: "ApprovedLeave",
          msg: `Leave Approved by ${this.state.firstName}`,
          date: Date.now(),
          // sendBy: this.state.userRole == "HR" ? "HR" : "Head",
          userRole: requestleaveObj.userRole,
          userDept: requestleaveObj.userDept,
          shilpmisId: requestleaveObj.shilpmisId,
          firstName: requestleaveObj.firstName,
          userView: false,
          HRView: false,
          HeadView: false,
        })
        .then((res) => {
          console.log(res);

          this.onGetRequestedLeave();

          sendEmail(
            requestleaveObj.firstName,
            requestleaveObj.shilpmisId,
            `Leave Approved by ${this.state.firstName}`,
            new Date(requestleaveObj.fromDate).toLocaleDateString("en-IN"),
            new Date(requestleaveObj.toDate).toLocaleDateString("en-IN"),
            requestleaveObj.userDept,
            HRStatus,
            HeadStatus
          );
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  onRejected = (key, requestleaveObj, HRStatus, HeadStatus) => {
    console.log("Rejected", key);
    if (this.state.userRole === "HR") {
      HRStatus = "Rejected";
    } else {
      HeadStatus = "Rejected";
    }
    const rejectStatus = new Promise((resolve, reject) => {
      db.ref("/requestedLeaves/" + key)
        .update({
          status: "Rejected",
        })
        .then((res) => {
          console.log("Status Updated");
          resolve("success");
        })
        .catch((err) => {
          console.error("error", err);
          reject(err);
        });
    });
    rejectStatus.then((res) => {
      new Promise((res, rej) => {
        db.ref("/notification")
          .push({
            type: "RejectedLeave",
            msg: `Leave Rejected by ${this.state.firstName}`,
            date: Date.now(),
            userRole: requestleaveObj.userRole,
            userDept: requestleaveObj.userDept,
            shilpmisId: requestleaveObj.shilpmisId,
            firstName: requestleaveObj.firstName,
            userView: false,
            HRView: false,
            HeadView: false,
          })
          .then((res) => {
            console.log(res);

            this.onGetRequestedLeave();

            sendEmail(
              requestleaveObj.firstName,
              requestleaveObj.shilpmisId,
              `Leave Rejected by ${this.state.firstName}`,
              new Date(requestleaveObj.fromDate).toLocaleDateString("en-IN"),
              new Date(requestleaveObj.toDate).toLocaleDateString("en-IN"),
              requestleaveObj.userDept,
              HRStatus,
              HeadStatus
            );
          })
          .catch((error) => {
            console.log(error);
          });
      });
    });
    this.onGetRequestedLeave();
  };

  handleChangeTab = (event, newValue) => {
    if (newValue == "3") {
      this.handleTabChangeData("Approved");
      this.setState({
        tabValue: "3",
      });
    } else if (newValue == "2") {
      this.handleTabChangeData("Pending");
      this.setState({
        tabValue: "2",
      });
    } else {
      this.setState({
        tabValue: "1",
      });
    }
  };

  handleTabChangeData = (status) => {
    console.log("in tab change", status);
    let filterLeave;
    if (this.state.userRole === "HR") {
      filterLeave = this.state.requestLeaveData.filter((newData) => {
        return newData.requestLeave.status == status;
      });
    } else {
      filterLeave = this.state.requestLeaveData.filter((newData) => {
        return newData.requestLeave.deptHeadStatus == status;
      });
    }
    if (status == "Approved") {
      this.setState({
        approvedLeaveList: filterLeave,
      });
    } else {
      this.setState({
        pendingLeaveList: filterLeave,
      });
    }
  };

  handleFilterOpen = () => {
    this.setState({
      filterOpen: true,
    });
  };

  handleFilterClose = () => {
    this.setState({
      filterOpen: false,
    });
  };

  handleFilterData = () => {
    let filterFrom = new Date(this.state.fromDate).setUTCHours(-5, -30, 0, 0);
    let filterTO = new Date(this.state.toDate).setUTCHours(-5, -30, 0, 0);
    let filterData;

    if (filterFrom == filterTO) {
      filterData = this.state.pendingLeaveList.filter((newData) => {
        return newData.requestLeave.fromDate < filterFrom;
      });
    } else {
      filterData = this.state.pendingLeaveList.filter((newData) => {
        return (
          newData.requestLeave.fromDate >= filterFrom &&
          newData.requestLeave.fromDate < filterTO
        );
      });
    }
    this.setState(
      {
        pendingLeaveList: filterData,
        filterOpen: false,
        isFiltered: true,
      },
      () => console.log("filter Data", this.state.pendingLeaveList, filterData)
    );
  };

  handleClearFilter = () => {
    let previousData = this.state.pendingLeaveTemp;
    this.setState({
      pendingLeaveList: previousData,
      filterOpen: false,
      isFiltered: false,
    });
  };

  handleDateChange = (e, name) => {
    if (name == "fromDate") {
      if (this.state.toDate > e) {
        this.setState({
          // toDate: e,
          [name]: e,
        });
      } else {
        this.setState({
          toDate: e,
          [name]: e,
        });
      }
    } else {
      this.setState({
        [name]: e,
      });
    }
  };

  render() {
    return (
      <div>
        <Page title="Melzo Leave Dashboard">
          <Container style={{ marginTop: "70px" }}>
            <Box
              display="flex"
              style={{ alignItems: "center", margin: "10px" }}
            >
              <Box
                flexGrow={1}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15px",
                }}
              >
                <Typography variant="h5">Leave Dashboard</Typography>
              </Box>

              <Box style={{ float: "right", display: "flex" }}>
                <p style={{ marginTop: "15px" }}>
                  {localStorage.getItem("userName")}
                </p>
              </Box>
            </Box>
            <Divider />
            <Card>
              <CardContent>
                <TabContext value={this.state.tabValue}>
                  <AppBar
                    position="static"
                    style={{ background: "white", color: "black" }}
                  >
                    <TabList
                      onChange={this.handleChangeTab}
                      aria-label="profiletab"
                    >
                      <Tab
                        label="Leave List"
                        value="1"
                        style={{ fontFamily: "Montserrat", outline: "0px" }}
                      />
                      <Tab
                        label="Approved Leave List"
                        value="3"
                        style={{ fontFamily: "Montserrat", outline: "0px" }}
                      />
                      <Tab
                        label={"Pending Leave List "}
                        value="2"
                        style={{ fontFamily: "Montserrat", outline: "0px" }}
                      />
                    </TabList>
                  </AppBar>
                  <TabPanel value="1">
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <TableContainer component={Paper}>
                          {this.state.requestLeaveData.length == 0 && (
                            <img
                              src={noDataImg}
                              style={{
                                height: "400px",
                                margin: "auto",
                                display: "flex",
                              }}
                            ></img>
                          )}
                          {console.log("LEAVE%%%%",this.state.requestLeaveData)}
                          {this.state.requestLeaveData.length > 0 && (
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Name</TableCell>
                                  <TableCell align="left">Email Id</TableCell>
                                  <TableCell align="left">
                                    Requested on
                                  </TableCell>
                                  <TableCell align="left">
                                    Category Of Leave
                                  </TableCell>
                                  <TableCell align="left">
                                    Leave Type
                                  </TableCell>
                                  <TableCell align="left">Reson For Leave</TableCell>
                                  <TableCell align="left">Leave From</TableCell>
                                  <TableCell align="left">Leave To</TableCell>
                                  <TableCell align="left">
                                    No. of Days
                                  </TableCell>
                                  <TableCell align="left">Action</TableCell>
                                  <TableCell align="left">HR Status</TableCell>
                                  <TableCell align="left">
                                    Manager Status
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.requestLeaveData.map(
                                  (data, index) => (
                                    <TableRow key={index}>
                                      <TableCell component="th" scope="row">
                                        {data.requestLeave.firstName}
                                      </TableCell>
                                      <TableCell align="left">
                                        {data.requestLeave.shilpmisId}
                                      </TableCell>
                                      <TableCell align="left">
                                        {new Date(
                                          data.requestLeave.entryTime
                                        ).toLocaleDateString()}
                                      </TableCell>
                                      <TableCell align="left">
                                        {data.requestLeave.categoryOfLeave}
                                      </TableCell>
                                      <TableCell align="left">
                                        {data.requestLeave.halfDay}
                                      </TableCell>
                                      <TableCell align="left">
                                        {data.requestLeave.comments}
                                      </TableCell>
                                      <TableCell align="left">
                                        {new Date(
                                          data.requestLeave.fromDate
                                        ).toLocaleDateString("en-IN")}
                                      </TableCell>
                                      <TableCell align="left">
                                        {new Date(
                                          data.requestLeave.toDate
                                        ).toLocaleDateString("en-IN")}
                                      </TableCell>
                                      <TableCell>
                                        {data.requestLeave.leaveDuration
                                          ? data.requestLeave.leaveDuration
                                          : data.requestLeave.halfDay
                                          ? "0.5"
                                          : Math.ceil(
                                              Math.abs(
                                                data.requestLeave.fromDate -
                                                  data.requestLeave.toDate
                                              ) /
                                                (1000 * 60 * 60 * 24)
                                            ) + 1}
                                      </TableCell>
                                      <TableCell align="left">
                                        <Tooltip title="Approve">
                                          <IconButton
                                            aria-label="Approve"
                                            // disabled={
                                            //   this.state.userRole == "HR"
                                            //     ? data.requestLeave
                                            //         .deptHeadStatus ==
                                            //       "Approved"
                                            //       ? data.requestLeave.status !=
                                            //         "Pending"
                                            //         ? true
                                            //         : false
                                            //       : true
                                            //     : data.requestLeave
                                            //         .deptHeadStatus != "Pending"
                                            //     ? true
                                            //     : false
                                            // }
                                            onClick={() =>
                                              this.onApproved(
                                                data.requestLeave.id,
                                                data.requestLeave
                                                  .categoryOfLeave,
                                                data.LeaveCount,
                                                data.requestLeave.status,
                                                data.requestLeave
                                                  .deptHeadStatus,
                                                data.requestLeave,
                                                data.requestLeave.notificationId ? data.requestLeave.notificationId : "0"
                                              )
                                            }
                                          >
                                            <CheckIcon />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Reject">
                                          <IconButton
                                            aria-label="Reject"
                                            disabled={
                                              this.state.userRole == "HR"
                                                ? data.requestLeave.status !=
                                                  "Pending"
                                                  ? true
                                                  : false
                                                : data.requestLeave
                                                    .deptHeadStatus != "Pending"
                                                ? true
                                                : false
                                            }
                                            onClick={() =>
                                              this.onRejected(
                                                data.requestLeave.id,
                                                data.requestLeave,
                                                data.requestLeave.status,
                                                data.requestLeave.deptHeadStatus
                                              )
                                            }
                                          >
                                            <CloseIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </TableCell>
                                      <TableCell align="left">
                                        {data.requestLeave.status}
                                      </TableCell>
                                      <TableCell align="left">
                                        {data.requestLeave.deptHeadStatus
                                          ? data.requestLeave.deptHeadStatus
                                          : "Pending"}
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          )}
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  {/* Only Approved Leave List */}
                  <TabPanel value="3">
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <TableContainer component={Paper}>
                          {this.state.approvedLeaveList.length == 0 && (
                            <img
                              src={noDataImg}
                              style={{
                                height: "400px",
                                margin: "auto",
                                display: "flex",
                              }}
                            ></img>
                          )}
                          {this.state.approvedLeaveList.length > 0 && (
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>First Name</TableCell>
                                  <TableCell align="left">ShilpmisId</TableCell>
                                  <TableCell align="left">
                                    Requested on
                                  </TableCell>
                                  <TableCell align="left">
                                    Category Of Leave
                                  </TableCell>
                                  <TableCell align="left">comment</TableCell>
                                  <TableCell align="left">Leave From</TableCell>
                                  <TableCell align="left">Leave To</TableCell>
                                  <TableCell align="left">
                                    No. of Days
                                  </TableCell>
                                  <TableCell align="left">Action</TableCell>
                                  <TableCell align="left">HR Status</TableCell>
                                  <TableCell align="left">
                                    Manager Status
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.approvedLeaveList.map(
                                  (data, index) => (
                                    // if (this.state.userRole == "HR" && data.requestLeave.status == "Approved") {
                                    //   return (
                                    <TableRow key={index}>
                                      <TableCell component="th" scope="row">
                                        {data.requestLeave.firstName}
                                      </TableCell>
                                      <TableCell align="left">
                                        {data.requestLeave.shilpmisId}
                                      </TableCell>
                                      <TableCell align="left">
                                        {new Date(
                                          data.requestLeave.entryTime
                                        ).toLocaleDateString()}
                                      </TableCell>
                                      <TableCell align="left">
                                        {data.requestLeave.categoryOfLeave}
                                      </TableCell>
                                      <TableCell align="left">
                                        {data.requestLeave.comments}
                                      </TableCell>
                                      <TableCell align="left">
                                        {new Date(
                                          data.requestLeave.fromDate
                                        ).toLocaleDateString("en-IN")}
                                      </TableCell>
                                      <TableCell align="left">
                                        {new Date(
                                          data.requestLeave.toDate
                                        ).toLocaleDateString("en-IN")}
                                      </TableCell>
                                      <TableCell>
                                        {data.requestLeave.leaveDuration
                                          ? data.requestLeave.leaveDuration
                                          : data.requestLeave.halfDay
                                          ? "0.5"
                                          : Math.ceil(
                                              Math.abs(
                                                data.requestLeave.fromDate -
                                                  data.requestLeave.toDate
                                              ) /
                                                (1000 * 60 * 60 * 24)
                                            ) + 1}
                                      </TableCell>
                                      <TableCell align="left">
                                        <Tooltip title="Approve">
                                          <IconButton
                                            aria-label="Approve"
                                            disabled={
                                              this.state.userRole == "HR"
                                                ? data.requestLeave.status !=
                                                  "Pending"
                                                  ? true
                                                  : false
                                                : data.requestLeave
                                                    .deptHeadStatus != "Pending"
                                                ? true
                                                : false
                                            }
                                            onClick={() =>
                                              this.onApproved(
                                                data.requestLeave.id,
                                                data.requestLeave
                                                  .categoryOfLeave,
                                                data.LeaveCount,
                                                data.requestLeave.status,
                                                data.requestLeave
                                                  .deptHeadStatus,
                                                data.requestLeave,
                                                data.requestLeave.notificationId ? data.requestLeave.notificationId : "0"
                                              )
                                            }
                                          >
                                            <CheckIcon />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Reject">
                                          <IconButton
                                            aria-label="Reject"
                                            disabled={
                                              this.state.userRole == "HR"
                                                ? data.requestLeave.status !=
                                                  "Pending"
                                                  ? true
                                                  : false
                                                : data.requestLeave
                                                    .deptHeadStatus != "Pending"
                                                ? true
                                                : false
                                            }
                                            onClick={() =>
                                              this.onRejected(
                                                data.requestLeave.id,
                                                data.requestLeave,
                                                data.requestLeave.status,
                                                data.requestLeave.deptHeadStatus
                                              )
                                            }
                                          >
                                            <CloseIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </TableCell>
                                      <TableCell align="left">
                                        {data.requestLeave.status}
                                      </TableCell>
                                      <TableCell align="left">
                                        {data.requestLeave.deptHeadStatus
                                          ? data.requestLeave.deptHeadStatus
                                          : "Pending"}
                                      </TableCell>
                                    </TableRow>
                                    //   )
                                    // }
                                  )
                                )}
                              </TableBody>
                            </Table>
                          )}
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  {/* Only Pending Leave List */}
                  <TabPanel value="2">
                    {!this.state.isFiltered &&
                      !this.state.pendingLeaveList.length == 0 && (
                        <Button onClick={this.handleFilterOpen}>
                          Filter
                          <img src="https://img.icons8.com/ios/24/000000/filter--v1.png" />
                        </Button>
                      )}
                    {this.state.isFiltered && (
                      <Button onClick={this.handleClearFilter}>
                        Clear Filter
                        <img src={filterCancelIcon} width="24" height="24" />
                      </Button>
                    )}
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <TableContainer component={Paper}>
                          {this.state.pendingLeaveList.length == 0 && (
                            <img
                              src={noDataImg}
                              style={{
                                height: "400px",
                                margin: "auto",
                                display: "flex",
                              }}
                            ></img>
                          )}
                          {this.state.pendingLeaveList.length > 0 && (
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>First Name</TableCell>
                                  <TableCell align="left">ShilpmisId</TableCell>
                                  <TableCell align="left">
                                    Requested on
                                  </TableCell>
                                  <TableCell align="left">
                                    Category Of Leave
                                  </TableCell>
                                  <TableCell align="left">comment</TableCell>
                                  <TableCell align="left">
                                    Leave From
                                    {/* {!this.state.isFiltered && (
                                      <img
                                        src="https://img.icons8.com/ios/24/000000/filter--v1.png"
                                        onClick={this.handleFilterOpen}
                                        style={{ cursor: "pointer" }}
                                      />
                                    )}
                                    {this.state.isFiltered && (
                                      <img
                                        src={filterCancelIcon}
                                        width="24"
                                        height="24"
                                        onClick={this.handleClearFilter}
                                        style={{ cursor: "pointer" }}
                                      />
                                    )} */}
                                  </TableCell>
                                  <TableCell align="left">Leave To</TableCell>
                                  <TableCell align="left">
                                    No. of Days
                                  </TableCell>
                                  <TableCell align="left">Action</TableCell>
                                  <TableCell align="left">HR Status</TableCell>
                                  <TableCell align="left">
                                    Manager Status
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.pendingLeaveList.map(
                                  (data, index) => (
                                    // if (this.state.userRole == "HR" && data.requestLeave.status == "Pending") {
                                    //   return (
                                    <TableRow key={index}>
                                      <TableCell component="th" scope="row">
                                        {data.requestLeave.firstName}
                                      </TableCell>
                                      <TableCell align="left">
                                        {data.requestLeave.shilpmisId}
                                      </TableCell>
                                      <TableCell align="left">
                                        {new Date(
                                          data.requestLeave.entryTime
                                        ).toLocaleDateString()}
                                      </TableCell>
                                      <TableCell align="left">
                                        {data.requestLeave.categoryOfLeave}
                                      </TableCell>
                                      <TableCell align="left">
                                        {data.requestLeave.comments}
                                      </TableCell>
                                      <TableCell align="left">
                                        {new Date(
                                          data.requestLeave.fromDate
                                        ).toLocaleDateString("en-IN")}
                                      </TableCell>
                                      <TableCell align="left">
                                        {new Date(
                                          data.requestLeave.toDate
                                        ).toLocaleDateString("en-IN")}
                                      </TableCell>
                                      <TableCell>
                                        {data.requestLeave.leaveDuration
                                          ? data.requestLeave.leaveDuration
                                          : data.requestLeave.halfDay
                                          ? "0.5"
                                          : Math.ceil(
                                              Math.abs(
                                                data.requestLeave.fromDate -
                                                  data.requestLeave.toDate
                                              ) /
                                                (1000 * 60 * 60 * 24)
                                            ) + 1}
                                      </TableCell>
                                      <TableCell align="left">
                                        <Tooltip title="Approve">
                                          <IconButton
                                            aria-label="Approve"
                                            // disabled={
                                            //   this.state.userRole == "HR"
                                            //     ? data.requestLeave.status !=
                                            //       "Pending"
                                            //       ? true
                                            //       : false
                                            //     : data.requestLeave
                                            //         .deptHeadStatus != "Pending"
                                            //     ? true
                                            //     : false
                                            // }
                                            disabled={
                                              this.state.userRole == "HR"
                                                ? data.requestLeave
                                                    .deptHeadStatus ==
                                                  "Approved"
                                                  ? data.requestLeave.status !=
                                                    "Pending"
                                                    ? true
                                                    : false
                                                  : true
                                                : data.requestLeave
                                                    .deptHeadStatus != "Pending"
                                                ? true
                                                : false
                                            }
                                            onClick={() =>
                                              this.onApproved(
                                                data.requestLeave.id,
                                                data.requestLeave
                                                  .categoryOfLeave,
                                                data.LeaveCount,
                                                data.requestLeave.status,
                                                data.requestLeave
                                                  .deptHeadStatus,
                                                data.requestLeave,
                                                data.requestLeave.notificationId ? data.requestLeave.notificationId : "0"
                                              )
                                            }
                                          >
                                            <CheckIcon />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Reject">
                                          <IconButton
                                            aria-label="Reject"
                                            disabled={
                                              this.state.userRole == "HR"
                                                ? data.requestLeave.status !=
                                                  "Pending"
                                                  ? true
                                                  : false
                                                : data.requestLeave
                                                    .deptHeadStatus != "Pending"
                                                ? true
                                                : false
                                            }
                                            onClick={() =>
                                              this.onRejected(
                                                data.requestLeave.id,
                                                data.requestLeave,
                                                data.requestLeave.status,
                                                data.requestLeave.deptHeadStatus
                                              )
                                            }
                                          >
                                            <CloseIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </TableCell>
                                      <TableCell align="left">
                                        {data.requestLeave.status}
                                      </TableCell>
                                      <TableCell align="left">
                                        {data.requestLeave.deptHeadStatus
                                          ? data.requestLeave.deptHeadStatus
                                          : "Pending"}
                                      </TableCell>
                                    </TableRow>
                                    //   )
                                    // }
                                  )
                                )}
                              </TableBody>
                            </Table>
                          )}
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </TabContext>
                <Dialog
                  open={this.state.filterOpen}
                  onClose={this.handleFilterClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Filter by Date"}
                  </DialogTitle>
                  <DialogContent style={{ height: "250px" }}>
                    <DialogContentText id="alert-dialog-description">
                      <ul>
                        <li>
                          If you select same date on both date picker then it
                          will filter previous date data.
                        </li>
                        <br></br>
                        <li>
                          <strong>To date (Filter Upto) is excluded</strong>
                        </li>
                      </ul>
                      <hr></hr>
                    </DialogContentText>
                    <Grid container spacing={3}>
                      {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateRangePicker
                          startText="Check-in"
                          endText="Check-out"
                          value={this.state.fromDate}
                          onChange={(newValue) => {
                            this.setState({
                              fromDate : newValue
                            })
                          }}
                          renderInput={(startProps, endProps) => (
                            <React.Fragment>
                              <TextField {...startProps} variant="standard" />
                              <Box sx={{ mx: 2 }}> to </Box>
                              <TextField {...endProps} variant="standard" />
                            </React.Fragment>
                          )}
                        />
                      </LocalizationProvider> */}
                      <Grid item md={6} xs={12}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            autoOk
                            variant="inline"
                            required
                            inputVariant="outlined"
                            label="Filter From"
                            name="fromDate"
                            format="MM/DD/yyyy"
                            animateYearScrolling
                            value={this.state.fromDate}
                            InputAdornmentProps={{ position: "start" }}
                            inputProps={{ style: { margin: "0" } }}
                            onChange={(date) =>
                              this.handleDateChange(date, "fromDate")
                            }
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            autoOk
                            required
                            variant="inline"
                            inputVariant="outlined"
                            label="Filter Upto"
                            name="toDate"
                            format="MM/DD/yyyy"
                            animateYearScrolling
                            minDate={this.state.fromDate}
                            value={this.state.toDate}
                            InputAdornmentProps={{ position: "start" }}
                            inputProps={{ style: { margin: "0" } }}
                            onChange={(date) =>
                              this.handleDateChange(date, "toDate")
                            }
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    {/* <Button onClick={this.handleClearFilter} color="primary">
                      Clear Filter
          </Button> */}
                    <Button
                      onClick={this.handleFilterData}
                      color="primary"
                      autoFocus
                    >
                      ApplyFilter
                    </Button>
                  </DialogActions>
                </Dialog>
              </CardContent>
            </Card>

            {/* <Snackbar
              open={this.state.showSnackbar}
              autoHideDuration={4000}
              key="topcenter"
              onClose={this.closeSnackbar}
            >
              <Alert onClose={this.closeSnackbar} severity="success">
                {this.state.snackbarText}
              </Alert>
            </Snackbar> */}

            {/* <ConfirmDialog
              confirmDialog={this.state.confirmDialog}
              setConfirmDialog={this.setConfirmDialog}
            /> */}
          </Container>
        </Page>
      </div>
    );
  }
}
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);
export default ApproveLeaves;

function countSunday(start, finish) {
  var weekendDays = 0;
  let dayMilliseconds = 24 * 60 * 60 * 1000;
  while (start <= finish) {
    var day = start.getDay();
    if (day == 0) {
      weekendDays++;
    }
    console.log("1", start);
    start = new Date(+start + dayMilliseconds);
    console.log("2", start);
  }
  return weekendDays;
}

function sendEmail(
  name,
  email,
  leaveMsg,
  fromDate,
  toDate,
  Department,
  HrStatus,
  deptHeadStatus
) {
  var obj = {};

  const commonMsg =
    "Dear sir/Ma'am" +
    "," +
    "<br/><br/>" +
    `<p>${name}, ${leaveMsg}</p>
    <br/>
    <p> Details are as follow ::
    <br></br>` +
    `<table style={{"width:100%",border : "1px solid black"}}>
    <tr>
      <th>Name</th>
      <th>Message</th>
      <th>From</th>
      <th>To</th>
      <th>Department</th>
      <th>HR Status</th>
      <th>Dept. Head Status</th>
    </tr>
    <tr>
      <td>${name}</td>
      <td>${leaveMsg}</td>
      <td>${fromDate}</td>
      <td>${toDate}</td>
      <td>${Department}</td>
      <td>${HrStatus}</td>
      <td>${deptHeadStatus}</td>
      </tr>
      </table>
    <br/>
    <p>Please do not reply this mail. It's system generated mail.</p>
    <br/>
  <p>Have a wonderful day! </p>
  <br/>
  <p>Thank you,</p>
  <p>Shilpmis Pvt. Ltd.</p>
  <br/>`;

  const approvalMsg =
    "Dear sir/Ma'am" +
    "," +
    "<br/><br/>" +
    `<p>${name}, ${leaveMsg}</p>
    <br/>
    <p> Details are as follow ::
    <br></br>` +
    `<table style={{"width:100%",border : "1px solid black"}}>
    <tr>
      <th>Name</th>
      <th>Message</th>
      <th>From</th>
      <th>To</th>
      <th>Department</th>
      <th>HR Status</th>
      <th>Dept. Head Status</th>
    </tr>
    <tr>
      <td>${name}</td>
      <td>${leaveMsg}</td>
      <td>${fromDate}</td>
      <td>${toDate}</td>
      <td>${Department}</td>
      <td>${HrStatus}</td>
      <td>${deptHeadStatus}</td>
      </tr>
      </table>
    <br/>
    <p>Please do not reply this mail. It's system generated mail.</p>
    <br/>
  <p>Have a wonderful day! </p>
  <br/>
  <p>Thank you,</p>
  <p>Shilpmis Technology Pvt. Ltd.</p>
  <br/>`;

  if ((HrStatus == deptHeadStatus) == "Approved") {
    var obj = {
      useremail: email, // User Mail Id
      message: approvalMsg,
      expoName: "Dashboard",
      senderName: "Melzo Dashboard",
      ccUser: "", // HR Mail Id + Dept Head Mail Id
      bccUser: "",
      subject: `${leaveMsg}`,
    };
  } else {
    var obj = {
      useremail: email, // User Mail Id
      message: commonMsg,
      expoName: "Dashboard",
      senderName: "Melzo Dashboard",
      ccUser: "bhavik1363@gmail.com,operations@shilpmis.com", // HR Mail Id + Dept Head Mail Id
      bccUser: "",
      subject: `${leaveMsg}`,
    };
  }
  axios
    .post(
      "https://us-central1-sendtoemail-3610d.cloudfunctions.net/sendCompanyEmail",
      obj,
      {
        headers: {
          // 'x-access-token': token
        },
      }
    )
    .then((regEmail) => {
      console.log("Done", regEmail);
    })
    .catch((error) => {
      console.log("Not Done", error);
    });
}
