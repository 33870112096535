import React from 'react';
import {

  Row, Col,
} from 'reactstrap';

import {

} from '../../../components';

import Dropzone from 'react-dropzone'
import { db, firebaseApp } from '../../../Firebase';

export default class Vouchers extends React.Component {

  constructor() {
    super()
    this.state = {
      files: [],
      status: "pending",
      voucherData: [],
      employeeData: [],
      date: new Date().getTime(),
      empName: "",
      pictures: [],
    }
  }

  componentDidMount() {
    this.onGetEmployeeData();
  }

  onDrop(files) {
    this.setState({
      files,
    }, () => console.log("files:::", files));
  }

  onGetEmployeeData() {
    let employeeDataPromise = new Promise((resolve, reject) => {
      db.ref("/employees/")
        .on("value", (queryEmployee) => {
          let employeeArray = [];
          queryEmployee.forEach(element => {
            if(element.val().status == "active"){

              let object = element.val();
              object["id"] = element.key;
              employeeArray.push(object);
            
            }
          })
          resolve(employeeArray);
        })
    });
    employeeDataPromise.then(acceptedData => {
      this.setState({
        employeeData: acceptedData,
      }, () => console.log("emp Data", this.state.employeeData))
    })
  }


  onHandleUpload = (myUserID) => {
    let myPromise = new Promise((resolve, reject) => {
      const myGuid = myUserID;
      const storageURL = firebaseApp.storage("gs://melzo-dashboard.appspot.com");
      const storageRef = storageURL.ref();

      const uploadTask = storageRef
        .child("images")
        .child("Melzo-Dashboard")
        .child(myGuid)
        .put(this.state.files[0]);

      uploadTask.on("state_changed", (snapShot) => {

      }, (err) => {
        console.log(err);
        reject(err);
      }, () => {
        firebaseApp
          .storage("gs://melzo-dashboard.appspot.com")
          .ref()
          .child("images")
          .child("Melzo-Dashboard")
          .child(myGuid)
          .getDownloadURL()
          .then((firebaseUrl) => {
            resolve(firebaseUrl)
          })
          .catch((err) => {
            console.log("caught error::", err);
          })
      })
    });
    myPromise
      .then((url) => {
        this.setState({
          pictures: url,
        }, () => {
          db.ref("/vouchers/").push({
            empName: JSON.parse(localStorage.getItem("employeeObject")).firstName + "" + JSON.parse(localStorage.getItem("employeeObject")).lastName,
            department: JSON.parse(localStorage.getItem("employeeObject")).department[0],
            pictures: this.state.pictures,
          })
        }
        )
      });
  }


  saveVouchers = () => {
    this.onHandleUpload("1");
  }

  render() {

    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>

              <div className="page-title">
                <div className="float-left">
                  <h1 className="title">Expense Payout</h1>
                </div>
              </div>


              <div className="col-12">
                <section className="box">
                  <header className="panel_header">
                    <h2 className="title float-left">Vouchers</h2>
                  </header>
                  <div className="content-body">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-12 col-md-12">
                        <form>
                          <div className="form-row">
                            <div className="form-group col-md-4">
                              <label htmlFor="inputFirstName">Department</label>
                              <input type="text" className="form-control" id="inputFirstName" placeholder="Department" value={JSON.parse(localStorage.getItem("employeeObject")).department[0]} disabled />
                            </div>
                            <div className="form-group col-md-4">
                              <label htmlFor="inputFirstName">Name</label>
                              <input type="text" className="form-control" id="inputFirstName" placeholder="Name" value={JSON.parse(localStorage.getItem("employeeObject")).firstName + "" + JSON.parse(localStorage.getItem("employeeObject")).lastName} disabled />
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="col-lg-12">
                        <h5>File Upload</h5>
                        <div className="dropzone">
                          <Dropzone onDrop={this.onDrop.bind(this)} className="droparea">
                            <p>Try dropping some files here, or click to select files to upload.</p>
                          </Dropzone>
                        </div>
                        <aside>
                          <h5>Dropped files</h5>
                          <ul>
                            {
                              this.state.files.map(f => <li key={f.name}>{f.name} - {f.size} bytes</li>)
                            }
                          </ul>
                        </aside>
                        <button type="submit" className="btn btn-primary mb-2" onClick={this.saveVouchers}>Submit</button>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}