import React from "react";
import { useState } from "react";
import Button from "@material-ui/core/Button";
import { TextField, Grid, InputLabel, TableContainer } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FormLabel from "@material-ui/core/FormLabel";
import { db } from "../../Firebase";
import { Card, CardContent } from "@material-ui/core";
import { TabContext } from "@material-ui/lab";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@material-ui/lab/TabPanel";
import noDataImg from "../../assets/img/NoData.png";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import ConfirmDialog from "../Register/ConfirmDialog";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
  },
  tableCell: {
    width: 400,
  }
}));

const travelUserRequest = () => {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();
  const [viewTravelStatus, setViewTravelStatus] = useState(true);
  const [userRole, setUserRole] = useState("");
  const [userName, setUserName] = useState("");
  const [userDept, setUserDept] = useState("");
  const [userStatus, setUserStatus] = useState("");
  const [shilpmisId, setShilpmisId] = useState("");
  const [locationTo, setLocationTo] = useState("");
  const [locationFrom, setLocationFrom] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [checkedItems, setCheckedItems] = useState([]);
  const [noOfDays, setNoOfDays] = useState("");
  const [meetingDetails, setMeetingDetails] = useState("");
  const [tabValue, setTabValue] = useState("1");
  const [travelList, setTravelList] = useState([]);
  const [open, setOpen] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [modifyStatus, setModifyStatus] = useState(false);
  const [convertUrl, setConvertUrl] = useState("");
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => {},
  });

  const handleChange = (event) => {
    setCheckedItems({
      ...checkedItems,
//event.target.name,
         [event.target.name]: event.target.checked,
        });
  };

  const validateData = () => {
    if (userName.trim() == "") {
      alert("userName can not be blank");
      return false;
    } else if (shilpmisId.trim() == "") {
      alert("shilpmisId can not be blank");
      return false;
    } else if (userRole.trim() == "") {
      alert("shilpmisId can not be blank");
      return false;
    } else if (locationTo.trim() == "") {
      alert("location To can not be blank");
      return false;
    } else if (locationFrom.trim() == "") {
      alert("location From can not be blank");
      return false;
    } else if (
      new Date(new Date(fromDate).toDateString("en-IN"))
        .toLocaleString()
        .split(",")[0] == new Date().toLocaleString().split(",")[0]
    ) {
      alert("please select 2 days before date");
      return false;
    } else if (noOfDays.trim() == "") {
      alert("Please Select Number Of Days");
      return false;
    } else if (Object.keys(checkedItems).length === 0) {
      alert("please select Travel planing");
      return false;
    } else if (meetingDetails.trim() == "") {
      alert("meeting Details To can not be blank");
      return false;
    } else {
      return true;
    }
  };
  const onSubmit = () => {
    if (validateData()) {
      db.ref("/requestedTravel")
        .push({
          name: userName,
          deparmentName:userDept,
          email: shilpmisId,
          role: userRole,
          locationTo: locationTo,
          locationFrom: locationFrom,
          date: new Date(fromDate).toDateString("en-IN"),
          noOfDays: noOfDays,
          planings: checkedItems,
          meetingDetails: meetingDetails,
          tickets: "",
          status: "Pending",
          deptHeadStatus: "Pending",
          isEnabled: true,
        })
        .then((res) => {
          function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
              .toString(16)
              .substring(1);
          }
          const guid = String(s4() + s4() + s4() + s4());
          let message = "";
          message = "sent travel request approval for " + locationFrom +" to "+ locationTo + " in this " + new Date(fromDate).toDateString();
          db.ref("/notification/" + guid)
          .update({
            type: "TravelRequest",
            msg: message,
            date: new Date(Date.now()).toDateString(),
            userRole: userRole,
            userDept: userDept,
            fromDate:new Date(fromDate).toDateString(),
            noOfDays:noOfDays,
            shilpmisId: shilpmisId,
            firstName: userName,
            status: "Pending",
            deptHeadStatus: "Pending",
            isEnabled: true,
            userView: false,
            HRView: false,
            HeadView: false,
            TravelRequestId: res.path.pieces_[1],
          }) 
          .then((res) => {
            
          //   sendEmail(
          //     regReqData.userName,
          //     shilpmisId,
          //     `Leave Approved by ${name}`,
          //     startDateTime.split("T").join(" "),
          //     endDateTime.split("T").join(" "),
          //     currentUserDept,
          //     "pending",
          //     "not require",
          //   );
          setViewTravelStatus(true);
          onGetTravelData();
          clearData();
          })
          // .catch((error) => {
          //   console.log(error);
          // });
        });
    }
  };

  const onGetTravelData = () => {

    let travelDataPromise = new Promise((resolve, reject) => {
      db.ref("/requestedTravel").on("value", (querySnapShot) => {
        let data = [];
        querySnapShot.forEach((element) => {
          let obj = element.val();
          obj["id"] = element.key;
          data.push(obj);
        });
        resolve(data);
      });
    });
    travelDataPromise.then((acceptedData) => {
      setTravelList(acceptedData);
    });
  };

  console.log("DATA", userName);

  useState(() => {
    setUserRole(
      localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).userRole
        : " "
    );

    setShilpmisId(
      localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).email
        : " "
    );

    setUserName(
      localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).firstName +
            " " +
            JSON.parse(localStorage.getItem("employeeObject")).lastName
        : " "
    );

    setUserDept(
      localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).department : ""
    );

    setUserStatus(
      localStorage.getItem("employeeObject")
      ? JSON.parse(localStorage.getItem("employeeObject")).status : ""
    )
    onGetTravelData();
  }, []);

  const showPopup = (key) => {
    console.log("Delete", key);

    const deletePromise = new Promise((resolve, reject) => {
      db.ref("/requestedTravel/" + key)
        .update({
          isEnabled: false,
          status: "Cancelled",
          deptHeadStatus: "Cancelled",
          updateTime: new Date().getTime(),
        })
        .then((res) => {
          console.log("Deleted");
          resolve("success");
        })
        .catch((error) => {
          console.error("Delete Error", error);
          reject(error);
        });
    });

    deletePromise
      .then((res) => {
        setConfirmDialog({
          isOpen: false,
        });
        onGetTravelData();
      })
      .catch((error) => {
        console.log("error", error);
        setConfirmDialog({
          isOpen: false,
        });
      });
  };

  const clearData = () => {
    setModifyStatus(!modifyStatus);
    setLocationTo("");
    setLocationFrom("");
    setFromDate(new Date());
    setNoOfDays("");
    setCheckedItems([]);
    setMeetingDetails("");
    setViewTravelStatus(!viewTravelStatus);

  };

  //modifyData(data.id,data.locationFrom,data.locationTo,data.date,data.noOfDays,data.planings,data.meetingDetails)
  const modifyData = (
    id,
    locationFrom,
    locationTo,
    date,
    noOfDays,
    planings,
    meetingDetails
  ) => {
    setViewTravelStatus(!viewTravelStatus);
    setModifyStatus(true);
    setLocationTo(locationTo);
    setLocationFrom(locationFrom);
    setFromDate(date);
    setNoOfDays(noOfDays);
    setCheckedItems(planings);
    setMeetingDetails(meetingDetails);
    setUpdateId(id);
  };

  const onUpdate = () => {
    console.log("PLANINGS",checkedItems);
    db.ref("/requestedTravel/" + updateId)
      .update({
        locationTo: locationTo,
        locationFrom: locationFrom,
        date: new Date(fromDate).toDateString("en-IN"),
        noOfDays: noOfDays,
        planings: checkedItems,
        meetingDetails: meetingDetails,
      })
      .then(() => {
        onGetTravelData();
        setViewTravelStatus(!viewTravelStatus);
        setModifyStatus(!modifyStatus);
        setLocationTo("");
        setLocationFrom("");
        setFromDate(new Date());
        setNoOfDays("");
        setCheckedItems([]);
        setMeetingDetails("");
        setUpdateId("");
      });
  };

  const planings = ["Hotel", "Train","Bus", "Bike"];

  const onChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file);
  };

  const onLoad = (fileString) => {
    setConvertUrl(fileString);
  };

  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
    };
  };

  const onUploadTickets = () => {
    db.ref("/requestedTravel/" + updateId)
      .update({
        tickets: convertUrl,
      })
      .then(() => {
       handleClose();
      });
  };

  console.log("USER STATUS",userStatus);
 
  const CustomModal = () => {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper}>
          <div className="modalFields">
            <div className="modalFieldLable"> Name : </div>
            <div className="modalFieldValue"> {userName} </div>
          </div>
          <div className="modalFields">
            <div className="modalFieldLable"> Email : </div>
            <div className="modalFieldValue"> {shilpmisId} </div>
          </div>

          <div className="modalFields">
            <div className="modalFieldLable"> Location From : </div>
            <div className="modalFieldValue"> {locationFrom} </div>
          </div>

          <div className="modalFields">
            <div className="modalFieldLable"> Location To : </div>
            <div className="modalFieldValue"> {locationTo} </div>
          </div>

          <div className="modalFields">
            <div className="modalFieldLable"> Date : </div>
            <div className="modalFieldValue"> {fromDate} </div>
          </div>
          <div className="modalFields">
            <div className="modalFieldLable"> No. Of Days : </div>
            <div className="modalFieldValue"> {noOfDays} Days</div>
          </div>
          <div className="modalFields">
            <div className="modalFieldLable"> Planings : </div>
            <div className="modalFieldValue"> {planings.join(" ")} </div>
          </div>

          <div className="modalFields">
            <div className="modalFieldLable"> Meeting Details : </div>
            <div className="modalFieldValue"> {meetingDetails} </div>
          </div>

          <div className="modalFields" style={{marginTop:"5px"}}>
            <input type="file" onChange={onChange} />
          </div>

          <div className="modalFields" style={{marginTop:"10px"}}>
            <Button
              id="submit"
              variant="contained"
              color="primary"
              className="marginrightbtn"
              onClick={onUploadTickets}
            >
              Submit
            </Button>

            <Button
              id="submit"
              variant="contained"
              color="primary"
              className="marginrightbtn"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    );
  };
  const handleOpen = (
    id,
    locationFrom,
    locationTo,
    date,
    noOfDays,
    planings,
    meetingDetails
  ) => {
    setOpen(true);
    setLocationTo(locationTo);
    setLocationFrom(locationFrom);
    setFromDate(date);
    setNoOfDays(noOfDays);
    setCheckedItems(planings);
    setMeetingDetails(meetingDetails);
    setUpdateId(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="content">
        <div>
          <h4 className="header inrowtitle" style={{ marginTop: "15px" }}>
            {viewTravelStatus ? "View Travel Request Status" : "Travel Request"}
          </h4>

          {(viewTravelStatus && userStatus=="active") && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                id="cancel"
                variant="contained"
                color="primary"
                className="marginrightbtn"
                onClick={() => {
                  setViewTravelStatus(false);
                }}
              >
                Travel Request
              </Button>
            </div>
          )}

          {!viewTravelStatus && (
            <>
              <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                  <TextField
                    id="firstName"
                    name="firstName"
                    label="First Name *"
                    style={{ width: "100%" }}
                    variant="outlined"
                    inputProps={{ style: { margin: "0" } }}
                    value={userName}
                    disabled
                  ></TextField>
                </Grid>

                <Grid item md={3} xs={12}>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <TextField
                      id="Email Id"
                      name="Email Id"
                      label="Email Id"
                      style={{ width: "100%" }}
                      variant="outlined"
                      inputProps={{ style: { margin: "0" } }}
                      value={shilpmisId}
                      disabled
                    ></TextField>
                  </FormControl>
                </Grid>

                <Grid item md={3} xs={12}>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <TextField
                      id="Location-From"
                      name="Location-From"
                      label="Location From"
                      style={{ width: "100%" }}
                      variant="outlined"
                      inputProps={{ style: { margin: "0" } }}
                      value={locationFrom}
                      onChange={(e) => setLocationFrom(e.target.value)}
                    ></TextField>
                  </FormControl>
                </Grid>

                <Grid item md={3} xs={12}>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <TextField
                      id="Location-To"
                      name="Location-To"
                      label="Location To"
                      style={{ width: "100%" }}
                      variant="outlined"
                      inputProps={{ style: { margin: "0" } }}
                      value={locationTo}
                      onChange={(e) => setLocationTo(e.target.value)}
                    ></TextField>
                  </FormControl>
                </Grid>

                <Grid item md={3} xs={12}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      disablePast                  
                      minDate = {new Date()}
                      autoOk
                      required
                      variant="inline"
                      inputVariant="outlined"
                      label="Date From"
                      name="fromDate"
                      format="MM/DD/yyyy"
                      animateYearScrolling
                      style={{ width: "90%" }}
                      id="datetime-local2"
                      value={fromDate}
                      InputAdornmentProps={{ position: "start" }}
                      inputProps={{ style: { margin: "0" } }}
                      onChange={(date) => setFromDate(date)}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item md={3} xs={12}>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Select No of Days
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-label"
                      id="shilpmisId"
                      name="shilpmisId"
                      label="Select User"
                      value={noOfDays}
                      onChange={(e) => setNoOfDays(e.target.value)}
                    >
                      <MenuItem disabled>
                        <em>Select ShilpmisId</em>
                      </MenuItem>
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                      <MenuItem value="6">6</MenuItem>
                      <MenuItem value="7">7</MenuItem>
                      <MenuItem value="8">8</MenuItem>
                      <MenuItem value="9">9</MenuItem>
                      <MenuItem value="10">10</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={3} xs={12}>
                  <FormControl
                    required
                    component="fieldset"
                    style={{ height: "20%" }}
                  >
                    <FormLabel component="legend">Travelling Throgh or Staying</FormLabel>
                    <FormGroup
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {planings.map((item) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={item}
                              checked={checkedItems[item]}
                              onChange={(e) => handleChange(e)}
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                              }
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                            />
                          }
                          label={item}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </Grid>

                <Grid item md={3} xs={12}>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <textarea
                      className="form-control marginbottomten"
                      id="planing"
                      name="meeting-details"
                      variant="outlined"
                      aria-labelledby="meeting-details"
                      aria-label="nkflvfn"
                      required
                      autoComplete="off"
                      placeholder="Meeting Details"
                      style={{ width: "100%" }}
                      value={meetingDetails}
                      onChange={(e) => setMeetingDetails(e.target.value)}
                    ></textarea>
                  </FormControl>
                </Grid>
              </Grid>
            </>
          )}

          {!viewTravelStatus && (
            <div className="flexbtn">
              {!modifyStatus ? (
                <Button
                  id="submit"
                  variant="contained"
                  color="primary"
                  className="marginrightbtn"
                  onClick={onSubmit}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  id="submit"
                  variant="contained"
                  color="primary"
                  className="marginrightbtn"
                  onClick={onUpdate}
                >
                  Modify
                </Button>
              )}

              <Button
                id="cancel"
                variant="contained"
                color="primary"
                className="marginrightbtn"
                onClick={clearData}
              >
                Cancel
              </Button>
            </div>
          )}

          {(viewTravelStatus && userStatus=="active") && (
            <Card style={{ marginTop: "60px" }}>
              <CardContent>
                <TabContext value={tabValue}>
                  <AppBar
                    position="static"
                    style={{ background: "white", color: "black" }}
                  >
                    <Tab
                      label="Request List"
                      value="1"
                      style={{ fontFamily: "Montserrat", outline: "0px" }}
                    />
                  </AppBar>

                  <TabPanel id="tab-panels" value="1">
                    <TableContainer>
                      {travelList.filter((s) => s.email == shilpmisId).length == 0 && (
                        <img
                          src={noDataImg}
                          style={{
                            height: "300px",
                            margin: "20px auto 5px auto",
                            display: "flex",
                          }}
                        ></img>
                      )}

                      {travelList.filter((s) => s.email == shilpmisId).length > 0 && (
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell align="center">
                                <b>Name</b>
                              </TableCell>
                              <TableCell align="center">
                                <b>Email</b>
                              </TableCell>
                              <TableCell align="center">
                                <b>Date</b>
                              </TableCell>
                              <TableCell align="center">
                                <b>No. of Days</b>
                              </TableCell>
                              <TableCell align="center">
                                <b>Location From</b>
                              </TableCell>
                              <TableCell align="center">
                                <b>Location To</b>
                              </TableCell>
                              <TableCell align="center">
                                <b>Planings</b>
                              </TableCell>
                              <TableCell align="center">
                                <b>Meeting Details</b>
                              </TableCell>
                              <TableCell align="center">
                                <b>Hr Status</b>
                              </TableCell>
                              <TableCell align="center">
                                <b>Manager Status</b>
                              </TableCell>
                              <TableCell align="center">
                                <b>Cancel/Modify</b>
                              </TableCell>

                              <TableCell align="center">
                                <b>Upload Tickets(After Approval)</b>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {travelList.filter((s) => s.email == shilpmisId).map((data, index) => (
                              <TableRow key={data.id}>
                                <TableCell align="center">
                                  {index + 1}
                                </TableCell>
                                <TableCell align="center">
                                  {data.name}
                                </TableCell>
                                <TableCell align="center">
                                  {data.email}
                                </TableCell>
                                <TableCell align="center">
                                  {new Date(data.date).toLocaleDateString(
                                    "en-IN"
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  {data.noOfDays}
                                </TableCell>
                                <TableCell align="center">
                                  {data.locationFrom}
                                </TableCell>
                                <TableCell align="center">
                                  {data.locationTo}
                                </TableCell>
                                <TableCell align="center">
                                  {Object.keys(data.planings).filter(k => data.planings[k] === true).join(" ")}
                                  {/* {Object.keys(data.planings).join(" ")} */}
                                </TableCell>
                                <TableCell align="center">
                                  {data.meetingDetails}
                                </TableCell>
                                <TableCell align="center">
                                  {data.status}
                                </TableCell>
                                <TableCell align="center">
                                  {data.deptHeadStatus}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ display: "flex",flexDirection:"column" }}
                                >
                                  <button
                                    className="ApproveBtn"
                                    style={{ width:"85%",margin:"auto" }}
                                    onClick={() =>
                                      setConfirmDialog({
                                        isOpen: true,
                                        title:
                                          "Are you sure you want to cancel this Travel Request?",
                                        subTitle:
                                          "Once canceled, you can't undo this action",
                                        onConfirm: () => {
                                          showPopup(data.id);
                                        },
                                      })
                                    }
                                  >
                                    Cancel
                                  </button>
                                  &nbsp; &nbsp;
                                  <button
                                    className="CancelBtn"
                                     style={{ width:"85%",margin:"auto" }}
                                    onClick={() =>
                                      modifyData(
                                        data.id,
                                        data.locationFrom,
                                        data.locationTo,
                                        data.date,
                                        data.noOfDays,
                                        data.planings,
                                        data.meetingDetails
                                      )
                                    }
                                  >
                                    Update
                                  </button>
                                </TableCell>

                                <TableCell align="center" className={classes.tableCell}   >
                                  {(data.status == "Approved" &&  data.deptHeadStatus == "Approved") ?
                                  <Button
                                    id="UploadBtns"
                                    onClick={() => {
                                      handleOpen(
                                        data.id,
                                        data.locationFrom,
                                        data.locationTo,
                                        data.date,
                                        data.noOfDays,
                                        data.planings,
                                        data.meetingDetails
                                      );
                                    }}
                                    variant="contained"
                                  >
                                    Upload Tickets
                                  </Button> : data.status == "Pending" && data.deptHeadStatus == "Pending" ? "Pending at Hr and Dept Head stage" :
                                   data.status == "Approved" &&  data.deptHeadStatus == "Pending" ? "Pending at Department Head" :
                                   data.status == "Pending" &&  data.deptHeadStatus == "Approved" ? "Pending at HR stage" :
                                   ((data.status == "Rejected" && data.deptHeadStatus == "Pending") ||
                                   (data.status == "Rejected" && data.deptHeadStatus == "Approved")
                                   ) ? "Rejected By HR" :
                                   ((data.status == "Pending" && data.deptHeadStatus == "Rejected") ||
                                   (data.status == "Approved" && data.deptHeadStatus == "Rejected")
                                   ) ? "Rejected By Department Head " : ""}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      )}
                    </TableContainer>
                    <ConfirmDialog
                      confirmDialog={confirmDialog}
                      setConfirmDialog={setConfirmDialog}
                    />
                    <CustomModal />
                  </TabPanel>
                </TabContext>
              </CardContent>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};

export default travelUserRequest;
