import React, { useEffect, useState } from "react";
import FormFildsForEditForm from "./formFildsforEditing";
import ExpenseFormV2 from "../Dashboard/expenseFormV2";
import { makeStyles } from "@material-ui/core/styles";
import "../styling/table.css";
import {
  Box,
  Button,
  IconButton,
  Paper,
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
} from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";

import { db } from "../../../Firebase";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    margin: theme.spacing(4),
    display: "flex",
    justifyContent: "CENTER",
  },
}));

const NormalViewOfTable = () => {
  const [dataForNormalView, setDataForNormalView] = useState([]);
  const [idOfBill2, setIdOfBill2] = useState("");
  const [openDrover, setOpenDrover] = useState(false);
  const userMailId = localStorage.getItem("usersLoginEmail");
  let [showViewOnlyForm, setShowViewOnlyForm] = useState(false);
  const [valueUpdated, setValueUpdate] = useState([]);
  const [skeleton, setSkeleton] = useState(false);
  const [showNoBills, setShowNoBills] = useState(false);

  const [drover, droverFunction] = useState(false);
  const [userInfo, setUserInfo] = useState({ email: "", designation: "" });
  const [closeForm, setCloseForm] = useState(true);

  // const [switchView, setSwitchView] = React.useState(true);

  useEffect(() => {
    setSkeleton(true);
    let employeeData = JSON.parse(localStorage.getItem("employeeObject"));

    console.log("employee data", {
      email: employeeData.email,
      designation: employeeData.designation,
    });

    setUserInfo({
      ...userInfo,
      email: employeeData.email,
      designation: employeeData.designation,
    });

    let promiseAllArray = [];
   
    // Get data from firebase
    let requestedExpenseDataPromise = new Promise((resolve, reject) => {
      db.ref("ExpenseBill/").once("value", (queryRequest) => {
        let requestLeaveArray = [];
        queryRequest.forEach((element) => {
          if (element.val().email == employeeData.email) {
            let obj = element.val();
            // console.log("data element", obj);
            obj["id"] = element.key;
            // promiseAllArray.push(obj);
            promiseAllArray.push({ key: element.key, ...element.val() });
          }
        });
        console.log("ALL DATA",promiseAllArray)
        resolve(promiseAllArray);
      });
    });

    requestedExpenseDataPromise.then((response) => {
      setSkeleton(false);
      const sortedData = promiseAllArray;
      sortedData.sort((a, b) => {
        return b.timeinMiliSecond - a.timeinMiliSecond;
      });
      console.log("unsorted data", promiseAllArray);

      console.log("sorted data", sortedData);

      if (sortedData.length == 0) {
        setShowNoBills(true);
      }

      // sortedData.length == 0 ? setShowNoBills(true) : null;
      setDataForNormalView(sortedData);
      // console.log("data", response);
      // console.log("data array==>", promiseAllArray);
    });
  }, [valueUpdated]);

  // const onDroverOpenHandler = (idOfBill) => {
  //   // setIdOfBill2(idOfBill);
  //   setOpenDrover(!openDrover);
  //   console.log("idOfBill", idOfBill);
  //   console.log("step 1 ");
  // };

  const onExpenseFoemClose = () => {
    setShowViewOnlyForm(false);
    setValueUpdate([..."I am "]);
  };

  const onViewFormHandler = (idOfBill) => {
    console.log("VIEWBUTTON click", idOfBill);
    // setSwitchView(!switchView);
    localStorage.setItem("readOnlyFormID", idOfBill);
    // window.location.href = "/viewonlyform";
    setShowViewOnlyForm(true);
  };

  const addedFormDataInLocalState = (data) => {
  
    setDataForNormalView([...dataForNormalView, data]);
    setValueUpdate([..."I am "]);
  };

  console.log("DATA",dataForNormalView);

  const list = (anchor, id) => (
    <Box role="presentation" onClick={() => setOpenDrover(!openDrover)}>
      <FormFildsForEditForm
        idOfInstanse={id}
        closeDroverFunction={setOpenDrover}
        closeDroverState={openDrover}
      />
    </Box>

    // 1. [map] we don`t write ant popup inside the maping items.
  );

  const droverOpenFunction = (key) => {
    console.log("Normal view key", key);
    localStorage.setItem("selectedObject", key);
    setIdOfBill2(key);
    droverFunction(true);
  };

  const closedrover = () => {
    droverFunction(false);
    setValueUpdate([..."I am "]);
    console.log("Normal view");
  };
  const classes = useStyles();

  return (
    <>
      {" "}
      <div className="tableView" id="tableView">
        <Box component="main" style={{ height: "90vh" }}>
          <Toolbar />

          <ExpenseFormV2
            viewOnly={showViewOnlyForm}
            formCloseFunction={onExpenseFoemClose}
            dataGetingFunction={addedFormDataInLocalState}
          />

          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right" style={{ width: "10%" }}>
                    Name
                  </TableCell>
                  <TableCell align="right" style={{ width: "12%" }}>
                    Title
                  </TableCell>
                  <TableCell align="right" style={{ width: "10%" }}>
                    Location
                  </TableCell>
                  <TableCell align="right" style={{ width: "10%" }}>
                    Date of Added
                  </TableCell>
                  <TableCell align="right" style={{ width: "10%" }}>
                    From
                  </TableCell>
                  <TableCell align="right" style={{ width: "10%" }}>
                    Till
                  </TableCell>
                  <TableCell align="right" style={{ width: "10%" }}>
                    Amount
                  </TableCell>
                  <TableCell align="right" style={{ width: "10%" }}>
                    Status
                  </TableCell>
                  <TableCell style={{ width: "10%" }}>Edit</TableCell>
                  <TableCell style={{ width: "10%" }}>View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataForNormalView && dataForNormalView.length > 0
                  ? dataForNormalView.map((arrayItem) => {
                      return (
                        <TableRow
                          key={arrayItem.id}
                          // sx={{
                          //   "&:last-child td, &:last-child th": { border: 0 },
                          // }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ width: "10%" }}
                          >
                            {arrayItem.name}
                          </TableCell>
                          <TableCell align="center" style={{ width: "12%" }}>
                            {arrayItem.expanceCategory}
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {arrayItem.location}
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {arrayItem.currentDate}
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {arrayItem.selectDate}
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {arrayItem.selectDateTill}
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {arrayItem.amount}
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {arrayItem.status}
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            <IconButton
                              disabled={
                                arrayItem.status == "Approved" ||
                                arrayItem.status == "reimbursed"
                                  ? true
                                  : false
                              }
                              // onClick={() => onDroverOpenHandler(arrayItem.id)}
                              onClick={() => droverOpenFunction(arrayItem.key)}
                            >
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            <Button
                              variant="outlined"
                              onClick={() => onViewFormHandler(arrayItem.key)}
                            >
                              View
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : ""}

                {skeleton &&
                  (() => {
                    const arr = [];
                    for (let i = 0; i < 5; i++) {
                      arr.push(
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ width: "10%" }}
                          >
                            {console.log("Table form")}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center" style={{ width: "12%" }}>
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {" "}
                            <Skeleton />
                          </TableCell>
                        </TableRow>
                      );
                    }
                    return arr;
                  })()}
              </TableBody>
            </Table>
          </TableContainer>

          {showNoBills && <div className={classes.root}>{"No Bills !"}</div>}

          {showViewOnlyForm && (
            <ExpenseFormV2
              viewOnly={showViewOnlyForm}
              formCloseFunction={onExpenseFoemClose}
            />
          )}

          <SwipeableDrawer anchor={"right"} open={drover && drover}>
            <Box role="presentation">
              <FormFildsForEditForm
                idOfInstanse={idOfBill2}
                droverFunction={closedrover}
                droverState={drover}
              />
            </Box>
          </SwipeableDrawer>
        </Box>
      </div>
    </>
  );
};

export default NormalViewOfTable;
