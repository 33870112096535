import React from "react";
import moment from 'moment';
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Table from "reactstrap/lib/Table";
import DataGrid from "react-data-grid";
import Datatable from 'react-bs-datatable';
import ReactDataGrid from "react-data-grid";
import { db } from "../../../Firebase";

const defaultColumnProperties = {
    resizable: true,
    width: 120
};
const columns = [
    { key: "userID", name: "Employee ID", editable: true },
    { key: "attendanceId", name: "Attendance ID", editable: true },
    { key: "firstName", name: "Name", editable: true },
    { key: "email", name: "SMIS-ID", editable: true },
    { key: "gmail", name: "Email-ID", editable: true },
    { key: "mobile", name: "Mobile No.", editable: true },
    { key: "department", name: "Department", editable: true },
    { key: "designation", name: "Designation", editable: true },
    { key: "reportingTo", name: "Reporting To", editable: true },
    { key: "address", name: "Address", editable: true },
    { key: "joiningDate", name: "Joining Date", editable: true },
    { key: "dateOfBirth", name: "Date Of Birth", editable: true },
    { key: "remotely", name: "Remotely", editable: true },
].map(c => ({ ...c, ...defaultColumnProperties }));

export default class UserFormTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            employeeData: [],

        }
    }

    componentDidMount() {
        this.onGetEmployeeData();
    }

    onGetEmployeeData() {
        let employeeDataPromise = new Promise((resolve, reject) => {
            db.ref("/employees/")
                .on("value", (queryEmployee) => {
                    let employeeArray = [];
                    queryEmployee.forEach(element => {
                        if(element.val().status == "active"){

                            let object = element.val();
                            object["id"] = element.key;
                            employeeArray.push(object);
                        
                        }
                    })
                    resolve(employeeArray);
                })
        });
        employeeDataPromise.then(acceptedData => {
            this.setState({
                employeeData: acceptedData,
            }, () => console.log("emp Data", this.state.employeeData))
        })
    }

    onGridRowsUpdated = ({ fromRow, toRow, updated }) => {

        const rows = this.state.employeeData.slice();
        for (let i = fromRow; i <= toRow; i++) {
            rows[i] = { ...rows[i], ...updated };
        }

        this.setState({ employeeData: rows })
    };

    onSaveUpdatedData = () => {
        console.log("saved clicked");

        for (let i = 0; i < this.state.employeeData.length; i++) {
            db.ref("/employees/" + this.state.employeeData[i].id).update({
                email: this.state.employeeData[i].email,
                gmail: this.state.employeeData[i].gmail,
                address: this.state.employeeData[i].address,
                department: this.state.employeeData[i].department,
                designation: this.state.employeeData[i].designation,
                reportingTo: this.state.employeeData[i].reportingTo,
            })
        }

        // if(this.state.id === "") {
        //     alert("Id not found");
        //     return;
        // }
        // db.ref("/employees/" + this.state.id).update({
        //     email: this.state.email,
        // }, (error) => {
        //     if (error) {
        //       alert("The write failed...") 
        //     } else {
        //       alert("Data saved successfully!") 
        //     }
        // });
    }

    render() {
        return (
            <div>
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <div className="page-title">
                                <div className="float-left">
                                    <h1 className="title">Employee Table</h1>
                                </div>
                            </div>

                            <div className="col-12">
                                <section className="box" style={{ maxWidth: "100%" }}>
                                    <header className="panel_header">
                                        <h2 className="title float-left">Table</h2>
                                    </header>
                                    <div className="content-body" style={{ width: '100%' }}>
                                        <div className="row" style={{ width: '100%' }}>
                                            <div className="col-lg-12">
                                                <button className="btn btn-primary btn-sm profile-btn" onClick={this.onSaveUpdatedData} style={{ marginBottom: "15px" }}>Save</button>
                                            </div>
                                            <div className="col-lg-12" style={{ width: '100%' }}>
                                                <ReactDataGrid style={{ width: '100%' }}
                                                    //minHeight={this.state.employeeData.length * 25 + 50}
                                                    rowHeight={50}
                                                    rowWidth={30}
                                                    columns={columns}
                                                    rowGetter={i => this.state.employeeData[i]}
                                                    rowsCount={50}
                                                    onGridRowsUpdated={this.onGridRowsUpdated}
                                                    enableCellSelect={true}
                                                    onColumnResize={(idx, width) =>
                                                        console.log(`Column ${idx} has been resized to ${width}`)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}