import React, { useState } from "react";
import { db, firebaseApp, firebaseAppcloud } from "../../../Firebase";

const firebaseConection = firebaseApp.firestore();

export const addBillToDataBase = (data) => {
  console.log("add bill call fun");

  const uploadedResponse = db.ref("/ExpenseBill/" + data.name + "-" + data.currentDate + "-" + data.uniqueID)
  .set(data)
  .then((res) => {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    const guid = String(s4() + s4() + s4() + s4());
    let message = "";
    message =  " has sent " + data.expanceCategory + " expense request approval of Rs. " + data.amount + "."
    db.ref("/notification/" + guid)
    .update({
  
      type: "ExpenseRequest",
      msg: message,
      date: new Date(Date.now()).toDateString(),
      userDept: data.deparmentName,
      fromDate:data.selectDate,
      toDate:data.selectDateTill,
      amount:data.amount,
      shilpmisId: data.email,
      firstName: data.name,
      status: "Pending",
      deptHeadStatus: "Pending",
      isEnabled: true,
      userView: false,
      HRView: false,
      HeadView: false,
    //  ExpenseRequestId: res.path.pieces_[1],
    }) 
    .then((res) => {
        console.log("SUCESS")
    //   sendEmail(
    //     regReqData.userName,
    //     shilpmisId,
    //     `Leave Approved by ${name}`,
    //     startDateTime.split("T").join(" "),
    //     endDateTime.split("T").join(" "),
    //     currentUserDept,
    //     "pending",
    //     "not require",
    //   );
    })
    .catch((error) => {
      console.log(error);
    });
  });

  // const uploadedResponse = db.ref("ExpenseBill").push().set(data);
  uploadedResponse.then((response) => {
    console.log("API response od added bill expense", response);
  });

  return uploadedResponse;
  // .then((res) => {
  //   console.log("then res");
  //   console.log(res);
  // })
  // .catch((err) => console.log("then res", err));
  // return firebaseApp.collection("Users").add(data);
};

export const updateBillInDataBase = async (id, data) => {
  return await firebaseConection.collection("Users").doc(id).set(data);
};

export const readSingleData = async (id) => {
  var docRef = firebaseConection.firestore().collection("Users").doc(id);
  docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        // console.log("Document data:", doc.data());
        return doc.data();
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        return "no such data found";
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
      return error;
    });
};

export const getDataOfNormalView = async (email) => {
  // const userEmail = localStorage.getItem("usersLoginEmail");
  let data = [];
  let responsed;

  let promiseAllArray = [];

  // Get data from firebase
  let requestedLeaveDataPromise = new Promise((resolve, reject) => {
    db.ref("ExpenseBill").on("value", (queryRequest) => {
      let requestLeaveArray = [];
      queryRequest.forEach((element) => {
        // let obj = element.val();
        // obj["id"] = element.key;
        promiseAllArray.push({ key: element.key, ...element.val() });
      });
      resolve(promiseAllArray);
    });
  });

  db.ref("ExpenseBill")
    .get()
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        data.map((item, index) => {
          console.log("item");
          console.dir(item);
        });

        // console.log(snapshot.val());
      } else {
        console.log("No data available");
      }
    })
    .catch((error) => {
      console.error(error);
    });

  // db.ref("ExpenseBill/").on("child_added", (querySnapshot) => {
  //   querySnapshot.forEach((doc) => {
  //     data.push({ ...doc.val(), id: doc.key });
  //     console.log(doc.key, " => ", doc.val());
  //   });
  // });

  const requireddata = data.filter((item) => {
    return item.email == email ? true : false;
  });

  console.log("requireddata", requireddata);

  return requireddata;
};

export const getDataOfApprovedView = async (email) => {
  // const userEmail = localStorage.getItem("usersLoginEmail");
  const data = [];
  await db
    .collection("Users")
    .where("status", "==", "reimbursed")
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
        console.log(doc.id, " => ", doc.data());
      });
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });

  return data;
};

export const getDataOfPendingView = async (email) => {
  const data = [];
  await db
    .collection("Users")
    .where("status", "!=", "reimbursed")
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
        console.log(doc.id, " => ", doc.data());
      });
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });

  return data;
};

export const updateManagerApprovedInstance = (id, valueOfInstance) => {
  return new Promise((resolve, reject) => {
    console.log("data valueOfInstance", valueOfInstance);

    db.ref("ExpenseBill")
      .child(id)
      .update(valueOfInstance, (err) => {
        if (err) {
          console.log("data is not updated");
          reject("data is not found");
          return "data is not found";
        } else {
          console.log("data is  updated");
          resolve("data is found");
          return "data is found";
        }
      });
  });
};
export const updateHrApprovedInstance = (id, valueOfInstance) => {
  let cityRef = firebaseApp.collection("Users").doc(id);

  console.log("data valueOfInstance", valueOfInstance);

  let setWithMerge = cityRef.set(
    { hrApproved: valueOfInstance },
    { merge: true }
  );
  return setWithMerge;
};
export const updateReimApprovedInstance = (id, valueOfInstance) => {
  let cityRef = firebaseApp.collection("Users").doc(id);

  let setWithMerge = cityRef.set(
    { reimbursementApproved: valueOfInstance },
    { merge: true }
  );
  return setWithMerge;
};

export const approvedStatus = (id) => {
  let cityRef = firebaseApp.collection("Users").doc(id);

  let setWithMerge = cityRef.set({ status: "Approved" }, { merge: true });
  return setWithMerge;
};

export const updateStatusToreimbersed = (id) => {
  let cityRef = firebaseApp.collection("Users").doc(id);

  let setWithMerge = cityRef.set({ status: "reimbursed" }, { merge: true });
  return setWithMerge;
};

const data = [{ first: "Ada", last: "Lovelace", born: 1815 }];

// export const updateManagerApprovedInstance = (id, valueOfInstance) => {
//   const cityRef = doc(db, "Users", id);

//   return setDoc(cityRef, { managerApproved: valueOfInstance }, { merge: true });
// };
// ------------

// export const approvedStatus = (id) => {
//   const cityRef = doc(db, "Users", id);
//   return setDoc(cityRef, { status: "Approved" }, { merge: true });
// };
// ------------

// export const updateStatusInstance = (id) => {
//   const cityRef = doc(db, "Users", id);
//   return setDoc(cityRef, { status: "reimbursed" }, { merge: true });
// };

// export const updateHrApprovedInstance = (id, valueOfItem) => {
//   const cityRef = doc(db, "Users", id);
//   return setDoc(cityRef, { hrApproved: valueOfItem }, { merge: true });
// };
// ------------

// export const updateReimApprovedInstance = (id, valueOfItem) => {
//   const cityRef = doc(db, "Users", id);
//   return setDoc(
//     cityRef,
//     { reimbursementApproved: valueOfItem },
//     { merge: true }
//   );
// };
// ------------

// export const updateInstanseOfUser = () => {
//   return;
// };

// export const addBillToDataBase = async (data) => {
//   const newCityRef = doc(collection(db, "Users"));
//   return await setDoc(newCityRef, data);
// };
// ------------

// export const updateBillInDataBase = async (id, data) => {
//   return await setDoc(doc(db, "Users", id), data);
// };
// ------------

// export const readSingleData = async (id) => {
//   const docRef = doc(db, "Users", id);
//   const docSnap = await getDoc(docRef);
//   if (docSnap.exists()) {
//     console.log("Document data:", docSnap.data());
//     return docSnap.data();
//   } else {
//     return console.log("No such document!");
//   }
// };
// ------------

// export const getDataOfNormalView = async () => {
//   const userEmail = localStorage.getItem("usersLoginEmail");
//   const data = [];
//   const q = query(collection(db, "Users"), where("email", "==", userEmail));
//   const querySnapshot = await getDocs(q);
//   querySnapshot.forEach((doc) => {
//     data.push({ id: doc.id, ...doc.data() });
//   });

//   return data;
// };
// ------------

// export const getDataOfApprovedView = async () => {
//   const data = [];
//   const q = query(collection(db, "Users"), where("status", "==", "reimbursed"));
//   const querySnapshot = await getDocs(q);
//   querySnapshot.forEach((doc) => {
//     data.push({ id: doc.id, ...doc.data() });
//   });

//   return data;
// };
// ------------

// export const getDataOfPendingView = async () => {
//   const data = [];
//   const q = query(collection(db, "Users"), where("status", "!=", "reimbursed"));
//   const querySnapshot = await getDocs(q);
//   querySnapshot.forEach((doc) => {
//     data.push({ id: doc.id, ...doc.data() });
//   });

//   return data;
// };
// ------------
