import React, { Component } from "react";
import Col from "reactstrap/lib/Col";
import Form from "reactstrap/lib/Form";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
import Row from "reactstrap/lib/Row";
import { db } from "../../../Firebase";

export default class DesignationMaster extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            designationName: '',
            designationCode: '',
            id: "",

            filtered: [],
            designationTempData: [],
            designationData: [],
            showPopup: false
        }
    }

    validateData() {
        if (this.state.designationName.trim() == "") {
            alert("Designation name Can't be blank");
            return false;
        } else if (this.state.designationCode.trim() == "") {
            alert("Designation code Can't be blank");
            return false;
        } else {
            return true;
        }
    }

    saveData = () => {
        if (this.validateData()) {
            let today = new Date().getTime();

            db.ref('/designationMaster').push({
                designationName: this.state.designationName,
                designationCode: this.state.designationCode,
                entryDate: today,
                entryBy: localStorage.getItem('email'),
                updateDate: '',
                updateBy: ''
            });
            this.clearValue();
        }
    }

    clearValue = () => {
        this.setState({
            designationName: '',
            designationCode: '',
            id: ""
        });
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="content">
                    <Row>
                        <Col>
                            <div className="page-title">
                                <div className="float-left">
                                    <h1 className="title">Designation Master</h1>
                                </div>
                            </div>
                            <div className="col-12">
                                <section className="box">
                                    <header className="panel_header">
                                        <h2 className="title float-left">Designation Master</h2>
                                    </header>

                                    <div className="content-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <Form inline>
                                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                                        <Label htmlFor="designationName" className="mr-sm-2">Designation Name</Label>
                                                        <input type="text" onChange={(e) => this.setState({ designationName: e.target.value })} name="designationName" id="designationName" placeholder="Designation Name" />
                                                    </FormGroup>
                                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                                        <Label htmlFor="designationCode" className="mr-sm-2">Designation Code</Label>
                                                        <input type="text" onChange={(e) => this.setState({ designationCode: e.target.value })} name="designationCode" id="designationCode" placeholder="Designation Code" />
                                                    </FormGroup>
                                                </Form>
                                                <button className="btn btn-primary" onClick={this.saveData} style={{ marginTop: "10px" }}>Submit</button>
                                                <button className="btn btn-secondary" onClick={this.clearValue} style={{ marginTop: "10px" }}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}