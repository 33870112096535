import React from "react";
import { Row, Col, Label, Input } from "reactstrap";

import InputMask from "react-input-mask";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import axios from "axios";
import firebaseStore from "../../../Firebase2";
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { db, firebaseApp } from "../../../Firebase";
import uploadFiles from "../../../components/uploadFile";
// import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
let department = "";
class AddLeads extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // default state for this application
      firstName: "",
      shilpmisId: "",
      userRole: "",
      userDept: [],

      // Old State
      // ComplaintSuject: "",
      // issueType: "",
      // complaintDes: "",
      // FileCollection: [],
      // ticketStatus: "Open",
      // loading: false,
      // attachment: [],

      // New State
      leadId: "",
      customerName: "",
      customerEmail: "",
      customerProduct: [],
      customerMobile: 91,
      referredBy: "",
      country: "",
      region: "",
      leadId: "",
      leadType: "",
      city: "",
      customerCompanyName: "",
      website: "",
      leadOwner: "",
      leadStage: "",
      leadStatus: "",
      leadHandOverDate: moment(),
      actionTaken: "",
      nextActionTaken: "",
      actionRemark: "",
      nextActionRemark: "",
      proposalAmount: 0,
      closureAmount: 0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.OnSubmitData = this.OnSubmitData.bind(this);
  }

  componentDidMount() {
    this.setState(
      {
        firstName: localStorage.getItem("employeeObject")
          ? JSON.parse(localStorage.getItem("employeeObject")).firstName +
            " " +
            JSON.parse(localStorage.getItem("employeeObject")).lastName
          : " ",
        shilpmisId: localStorage.getItem("employeeObject")
          ? JSON.parse(localStorage.getItem("employeeObject")).email
          : " ",
        userRole: localStorage.getItem("employeeObject")
          ? JSON.parse(localStorage.getItem("employeeObject")).userRole
          : " ",
        userDept: localStorage.getItem("employeeObject")
          ? JSON.parse(localStorage.getItem("employeeObject")).department
          : [],
      },
      () => {
        this.setState({
          leadId: this.guid(),
          leadOwner: this.state.firstName,
        });
        if (
          this.state.userRole == "employee" ||
          this.state.userRole == "team_lead" ||
          this.state.userRole == "HR"
        ) {
          department = this.state.userDept.includes("Digital & Tendering")
            ? "Digital"
            : this.state.userDept.includes("Sales & Marketing")
            ? "Sales"
            : "Other";
        } else if (
          this.state.userRole == "dept_head" ||
          this.state.userRole == "admin"
        ) {
          if (
            this.state.userDept.includes("Digital & Tendering") &&
            this.state.userDept.includes("Sales & Marketing")
          ) {
            department = "All";
          } else {
            department = this.state.userDept.includes("Digital & Tendering")
              ? "Digital"
              : this.state.userDept.includes("Sales & Marketing")
              ? "Sales"
              : "Other";
          }
        } else if (this.state.userRole == "superadmin") {
          department = "All";
        }
      }
    );

    db.ref("/melzo-sales/").on("value", (queryTicket) => {
      let ticketArray = [];
      queryTicket.forEach((ticket) => {
        let obj = ticket.val();
        obj["id"] = ticket.key;
        ticketArray.push(obj);
      });
      console.log(ticketArray, "salesArray");
    });
  }

  guid = () => {
    let dept = this.state.userDept.includes("Digital & Tendering")
      ? "Digital"
      : this.state.userDept.includes("Sales & Marketing")
      ? "Sales"
      : "Other";
    let role =
      this.state.userRole == "employee"
        ? "emp"
        : this.state.userRole == "team_lead"
        ? "lead"
        : this.state.userRole == "dept_head"
        ? "head"
        : this.state.userRole == "admin"
        ? "admin"
        : this.state.userRole == "superadmin"
        ? "super"
        : this.state.userRole;
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return String("#SMIS-" + role + "-" + dept + "-" + s4() + "-" + s4());
  };

  handleChange(e, productValue) {
    if (productValue) {
      this.setState({
        customerProduct: productValue,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  selectCountry(val) {
    this.setState({ country: val });
  }

  selectRegion(val) {
    this.setState({ region: val });
  }

  OnSubmitData() {
    if (this.validateSubmit()) {
      db.ref("/melzo-sales")
        .push({
          leadType: this.state.leadType,
          leadOwner: this.state.leadOwner,
          leadOwnerRole: this.state.userRole,
          leadOwnerDept: this.state.userDept,
          leadId: this.state.leadId,
          customerName: this.state.customerName,
          customerEmail: this.state.customerEmail,
          customerProduct: this.state.customerProduct,
          customerMobile: this.state.customerMobile,
          country: this.state.country,
          region: this.state.region,
          city: this.state.city,
          customerCompanyName: this.state.customerCompanyName,
          website: this.state.website,
          leadStage: this.state.leadStage,
          leadStatus: this.state.leadStatus,
          referredBy: this.state.referredBy,
          leadHandOverDate: new Date(this.state.leadHandOverDate).getTime(),
          actionTaken: this.state.actionTaken,
          nextActionTaken: this.state.nextActionTaken,
          proposalAmount: this.state.proposalAmount,
          closureAmount: this.state.closureAmount,
          actionRemark: [
            {
              remark: this.state.actionRemark,
              actionDate: new Date().getTime(),
              actionBy: this.state.firstName,
            },
          ],
          nextActionRemark: [
            {
              remark: this.state.nextActionRemark,
              actionDate: new Date().getTime(),
              actionBy: this.state.firstName,
            },
          ],
          createdOn: new Date().getTime(),
          updatedOn: new Date().getTime(),
        })
        .then((res) => {
          // db.ref("/dashboard-logs")
          //   .push({
          //     type: "ticket", // for filter
          //     changeBy: this.state.firstName,
          //     ticketIssuedBy: this.state.firstName, // for heading
          //     ticketAssignTo: "",
          //     ticketAssignBy: "",
          //     ticketNo: this.state.ticketNo, // for heading
          //     customerProduct: this.state.customerProduct, // for heading
          //     customerName: this.state.customerName, // for heading
          //     complaintSuject: this.state.ComplaintSuject, // for heading
          //     status: this.state.ticketStatus,
          //     createdOn: new Date().getTime(), // for heading
          //     UpdatedOn: new Date().getTime(),
          //     issueType: this.state.issueType, // for heading
          //     remark: "",
          //   })
          //   .then((res) => {
          //     console.log(res, "res");
          //     console.log(res.key);
          //     this.clearInput();
          //   })
          //   .catch((err) => {
          //     console.log(err, "err");
          //   });
          console.log("done", res);
          this.clearInput();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  validateSubmit = () => {
    if (this.state.customerName === "") {
      alert("Please Enter Customer Name");
      return false;
    } else if (this.state.customerEmail == "") {
      alert("Please Enter Customer Email");
      return false;
    } else if (this.state.customerProduct == "") {
      alert("Please Enter Customer Product");
      return false;
    } else if (this.state.customerMobile == "") {
      alert("Please Enter Customer Mobile no.");
      return false;
    } else if (this.state.country == "") {
      alert("Please Enter Customer Country");
      return false;
    } else if (this.state.region == "") {
      alert("Please Enter Customer State/Region");
      return false;
    } else if (this.state.city == "") {
      alert("Please Enter Customer City");
      return false;
    } else if (this.state.customerCompanyName == "") {
      alert("Please Enter Customer Company Name");
      return false;
    } else if (this.state.leadType == "") {
      alert("Please Select Lead Type");
      return false;
    } else if (this.state.leadStage == "") {
      alert("Please Select Lead Stage");
      return false;
    } else if (this.state.leadStatus == "") {
      alert("Please Select Lead Status");
      return false;
    } else if (this.state.leadHandOverDate == "") {
      alert("Please Enter Lead HandOver Date");
      return false;
    } else if (this.state.actionTaken == "") {
      alert("Please Select what action taken for this lead ?");
      return false;
    } else if (this.state.nextActionTaken == "") {
      alert("Please Select what next action should you take for this lead ?");
      return false;
    } else if (this.state.proposalAmount == 0) {
      alert("Please Enter the proposal amount");
      return false;
    } else {
      return true;
    }
  };

  clearInput = () => {
    this.setState({
      leadId: this.guid(),
      customerName: "",
      customerEmail: "",
      customerProduct: [],
      customerMobile: 91,
      country: "",
      region: "",
      leadId: "",
      leadType: "",
      city: "",
      customerCompanyName: "",
      website: "",
      referredBy: "",
      leadOwner: "",
      leadStage: "",
      leadStatus: "",
      leadHandOverDate: moment(),
      actionTaken: "",
      nextActionTaken: "",
      nextActionRemark: "",
      proposalAmount: 0,
      closureAmount: 0,
      actionRemark: "",
    });
  };

  sendNotification = (ticketId) => {
    db.ref("/notification")
      .push({
        type: "TicketRaised",
        msg: "New Support Ticket Raised by " + this.state.firstName,
        date: Date.now(),
        userRole: this.state.userRole,
        userDept: ["Project Management"],
        shilpmisId: this.state.shilpmisId,
        firstName: this.state.firstName,
        userView: false,
        HeadView: false,
        ticketId: ticketId,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="page-title">
                <div className="float-left">
                  <h1 className="title">Add Lead</h1>
                </div>
              </div>

              <div className="row margin-0">
                <div className="col-12">
                  <section className="box ">
                    <header className="panel_header">
                      <h2 className="title float-left">
                        Customer Basic Details
                      </h2>
                    </header>
                    <div className="content-body">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <form>
                            <div className="form-row">
                              <div className="form-group col-md-4">
                                <label htmlFor="inputname423">
                                  Customer Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="inputname423"
                                  placeholder=""
                                  name="customerName"
                                  value={this.state.customerName}
                                  onChange={(e) => this.handleChange(e)}
                                />
                              </div>

                              <div
                                className="form-group col-md-4"
                                style={{ display: "flex" }}
                              >
                                <div style={{ width: "100%" }}>
                                  <label htmlFor="inputname425">
                                    Customer Email
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="inputname425"
                                    placeholder=""
                                    name="customerEmail"
                                    value={this.state.customerEmail}
                                    onChange={(e) => this.handleChange(e)}
                                  ></input>
                                </div>
                                <div
                                  style={{
                                    position: "relative",
                                    top: "50px",
                                    left: "-30px",
                                  }}
                                >
                                  {this.state.loading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                </div>
                              </div>

                              <div className="form-group col-md-4">
                                <Label htmlFor="field-11">Mobile No</Label>
                                <InputMask
                                  id="field-11"
                                  className="form-control"
                                  mask="+99 99999 99999"
                                  maskChar="_"
                                  name="customerMobile"
                                  value={this.state.customerMobile}
                                  onChange={(e) => this.handleChange(e)}
                                />
                              </div>

                              <div className="form-group col-md-4">
                                <Label htmlFor="field-11">Select Country</Label>
                                <CountryDropdown
                                  style={{ width: "100%" }}
                                  value={this.state.country}
                                  onChange={(val) => this.selectCountry(val)}
                                />
                              </div>

                              <div className="form-group col-md-4">
                                <Label htmlFor="field-11">Select State</Label>
                                <RegionDropdown
                                  style={{ width: "100%" }}
                                  country={this.state.country}
                                  value={this.state.region}
                                  placeholder="select Country First"
                                  onChange={(val) => this.selectRegion(val)}
                                />
                              </div>

                              <div className="form-group col-md-4">
                                <label htmlFor="selectCity">Select City</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="selectCity"
                                  placeholder=""
                                  name="city"
                                  value={this.state.city}
                                  onChange={(e) => this.handleChange(e)}
                                />
                              </div>
                              {/* <div className="form-group col-md-12">
                                <label htmlFor="inputname425">Customer Product Name</label>
                                <input type="text" className="form-control" id="inputname425" placeholder="" />
                              </div> */}

                              <div className="form-group col-md-4">
                                <label htmlFor="product">
                                  Customer Product Name
                                </label>
                                <Autocomplete
                                  multiple
                                  id="checkboxes-tags-demo"
                                  options={[
                                    "Noor",
                                    "Mela",
                                    "Udyog",
                                    "Kala",
                                    "Auto",
                                    "Aakar",
                                    "Walkthrough",
                                    "Service",
                                  ]}
                                  disableCloseOnSelect
                                  getOptionLabel={(option) => option}
                                  value={this.state.customerProduct}
                                  name="customerProduct"
                                  onChange={(e, newValue) =>
                                    this.handleChange(e, newValue)
                                  }
                                  // renderValue={(dept) => dept.join(",")}
                                  renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option}
                                    </React.Fragment>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="Product"
                                      placeholder=""
                                    />
                                  )}
                                />
                              </div>

                              <div className="form-group col-md-4">
                                <label htmlFor="inputname423">
                                  Customer Company Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="inputname423"
                                  placeholder=""
                                  name="customerCompanyName"
                                  value={this.state.customerCompanyName}
                                  onChange={(e) => this.handleChange(e)}
                                />
                              </div>

                              <div className="form-group col-md-4">
                                <label htmlFor="inputname423">
                                  Customer Web-Site
                                </label>
                                <input
                                  type="url"
                                  className="form-control"
                                  id="inputname423"
                                  placeholder=""
                                  name="website"
                                  value={this.state.website}
                                  onChange={(e) => this.handleChange(e)}
                                />
                              </div>

                              {/* Lead Data Entry */}

                              <header className="panel_header">
                                <h2 className="title float-left">Lead Info</h2>
                              </header>

                              {/*  */}

                              <div className="form-group col-md-4">
                                <label htmlFor="leadType">Lead Type</label>
                                <FormControl
                                  style={{ width: "100%" }}
                                  variant="outlined"
                                >
                                  <Select
                                    id="leadType"
                                    name="leadType"
                                    value={this.state.leadType}
                                    onChange={(e) => this.handleChange(e)}
                                  >
                                    {/* {(department == "All" ||
                                      department == "Digital") && ( */}
                                    <MenuItem value="Digital-Leads">
                                      Digital-Lead
                                    </MenuItem>
                                    {/* )} */}
                                    {/* {(department == "All" ||
                                      department == "Sales") && ( */}
                                    <MenuItem value="Event-Leads">
                                      Event-Lead
                                    </MenuItem>
                                    {/* )} */}
                                    {/* {(department == "All" ||
                                      department == "Sales") && ( */}
                                    <MenuItem value="CP-Leads">
                                      CP-Lead
                                    </MenuItem>
                                    {/* )} */}
                                    {/* {(department == "All" ||
                                      department == "Sales") && ( */}
                                    <MenuItem value="Cold-Leads">
                                      Cold-Lead
                                    </MenuItem>
                                    {/* )} */}
                                    {/* {(department == "All" ||
                                      department == "Sales") && ( */}
                                    <MenuItem value="Ref-Leads">
                                      Ref-Lead
                                    </MenuItem>
                                    {/* )} */}
                                    {/* {(department == "All" ||
                                      department == "Sales") && ( */}
                                    <MenuItem value="Expo-Leads">
                                      Expo-Lead
                                    </MenuItem>
                                    {/* )} */}
                                  </Select>
                                </FormControl>
                              </div>

                              <div className="form-group col-md-4">
                                <label htmlFor="inputname4">Lead Id</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="inputname4"
                                  placeholder=""
                                  value={this.state.leadId}
                                  disabled
                                />
                              </div>

                              <div className="form-group col-md-4">
                                <label htmlFor="inputname42">Lead Owner</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="inputname42"
                                  placeholder=""
                                  name="leadOwner"
                                  value={this.state.leadOwner}
                                  // onChange={(e) => this.handleChange(e)}
                                  disabled
                                />
                              </div>

                              <div className="form-group col-md-4">
                                <label htmlFor="product">Lead Stage</label>
                                <FormControl
                                  variant="outlined"
                                  style={{ width: "100%" }}
                                >
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="leadStage"
                                    name="leadStage"
                                    title={this.state.leadStage}
                                    value={this.state.leadStage}
                                    onChange={(e) => this.handleChange(e)}
                                    // renderValue={(dept) => dept.join(",")}
                                  >
                                    <MenuItem disabled>
                                      <em>Select Lead Stage</em>
                                    </MenuItem>
                                    <MenuItem value={"Subscriber"}>
                                      Subscriber
                                    </MenuItem>
                                    <MenuItem value={"Lead/Nurturing"}>
                                      Lead/Nurturing
                                    </MenuItem>
                                    <MenuItem value={"MQL"}>
                                      Market Qualified Lead
                                    </MenuItem>
                                    <MenuItem value={"SQL"}>
                                      Sales Qualified Lead
                                    </MenuItem>
                                    <MenuItem value={"OL"}>
                                      Opportunity Lead
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </div>

                              <div className="form-group col-md-4">
                                <label htmlFor="product">Lead Status</label>
                                <FormControl
                                  style={{ width: "100%" }}
                                  variant="outlined"
                                >
                                  <Select
                                    id="leadStatus"
                                    name="leadStatus"
                                    value={this.state.leadStatus}
                                    onChange={(e) => this.handleChange(e)}
                                    // error={!this.state.isValidLeadStatus}
                                    // helperText={this.state.leadStatusHelperText}
                                  >
                                    <MenuItem value="Connected ">
                                      Connected{" "}
                                    </MenuItem>
                                    <MenuItem value="Demo schedule ">
                                      Demo schedule{" "}
                                    </MenuItem>
                                    <MenuItem value="Demo Completed">
                                      Demo Completed
                                    </MenuItem>
                                    <MenuItem value="Proposal Sent">
                                      Proposal Sent
                                    </MenuItem>
                                    <MenuItem value="Price Negotiation">
                                      Price Negotiation
                                    </MenuItem>
                                    <MenuItem value="★ Win ★">★ Win ★</MenuItem>
                                    <MenuItem value="〆 Lost 〆 ">
                                      〆 Lost 〆{" "}
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </div>

                              {/* <div className="form-group col-md-12">
                                <label htmlFor="inputname42">
                                  Complaint Subject
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="inputname42"
                                  placeholder=""
                                  name="ComplaintSuject"
                                  value={this.state.ComplaintSuject}
                                  onChange={(e) => this.handleChange(e)}
                                />
                              </div> */}

                              <div className="form-group col-md-4">
                                <label>Lead HandOver Date</label>
                                <div
                                  className="controls"
                                  style={{ margin: "0px" }}
                                >
                                  <DatePicker
                                    selected={this.state.leadHandOverDate}
                                    value={this.state.leadHandOverDate}
                                    onChange={(e) => {
                                      this.setState({
                                        leadHandOverDate: moment(e),
                                      });
                                    }}
                                  />
                                </div>
                              </div>

                              {/* <div className="form-group col-md-12">
                                <Label htmlFor="exampleSelect">Type</Label>
                                <Input
                                  type="select"
                                  name="issueType"
                                  id="exampleSelect"
                                  value={this.state.issueType}
                                  onChange={(e) => this.handleChange(e)}
                                >
                                  <option>Select</option>
                                  <option>Login Issue</option>
                                  <option>File Upload Not Working</option>
                                  <option>Wrong Details</option>
                                  <option>Software Related Issue</option>
                                  <option>Other</option>
                                </Input>
                              </div> */}
                              {this.state.leadType == "Ref-Leads" && (
                                <div className="form-group col-md-4">
                                  <label htmlFor="inputname42">
                                    Referred By
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="inputname42"
                                    placeholder=""
                                    name="referredBy"
                                    value={this.state.referredBy}
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                </div>
                              )}

                              <div className="form-group col-md-4">
                                <label htmlFor="product">Action</label>
                                <FormControl
                                  style={{ width: "100%" }}
                                  variant="outlined"
                                >
                                  <Select
                                    id="actionTaken"
                                    name="actionTaken"
                                    value={this.state.actionTaken}
                                    onChange={(e) => this.handleChange(e)}
                                  >
                                    <MenuItem value="WhatsApp">
                                      WhatsApp
                                    </MenuItem>
                                    <MenuItem value="Online Meeting">
                                      Online Meeting
                                    </MenuItem>
                                    <MenuItem value="Physical Meeting">
                                      Physical Meeting
                                    </MenuItem>
                                    <MenuItem value="Proposal Sent">
                                      Proposal Sent
                                    </MenuItem>
                                    <MenuItem value="Followup Mail">
                                      Followup Mail
                                    </MenuItem>
                                    <MenuItem value="Followup Call">
                                      Followup Call
                                    </MenuItem>
                                    <MenuItem value="Followup WhatsApp">
                                      Followup WhatsApp
                                    </MenuItem>
                                    <MenuItem value="Call">Call</MenuItem>
                                  </Select>
                                </FormControl>
                              </div>

                              <div
                                className={
                                  this.state.leadType == "Ref-Leads"
                                    ? "form-group col-md-4"
                                    : "form-group col-md-8"
                                }
                              >
                                <Label htmlFor="exampleText">
                                  Action Remark
                                </Label>
                                <Input
                                  type="textarea"
                                  name="actionRemark"
                                  id="exampleText"
                                  value={this.state.actionRemark}
                                  onChange={(e) => this.handleChange(e)}
                                />
                              </div>

                              <div className="form-group col-md-4">
                                <label htmlFor="product">
                                  Next Action Plan
                                </label>
                                <FormControl
                                  style={{ width: "100%" }}
                                  variant="outlined"
                                >
                                  <Select
                                    id="nextActionTaken"
                                    name="nextActionTaken"
                                    value={this.state.nextActionTaken}
                                    onChange={(e) => this.handleChange(e)}
                                  >
                                    <MenuItem value="WhatsApp">
                                      WhatsApp
                                    </MenuItem>
                                    <MenuItem value="Online Meeting">
                                      Online Meeting
                                    </MenuItem>
                                    <MenuItem value="Physical Meeting">
                                      Physical Meeting
                                    </MenuItem>
                                    <MenuItem value="Proposal Sent">
                                      Proposal Sent
                                    </MenuItem>
                                    <MenuItem value="Followup Mail">
                                      Followup Mail
                                    </MenuItem>
                                    <MenuItem value="Followup Call">
                                      Followup Call
                                    </MenuItem>
                                    <MenuItem value="Followup WhatsApp">
                                      Followup WhatsApp
                                    </MenuItem>
                                    <MenuItem value="Call">Call</MenuItem>
                                  </Select>
                                </FormControl>
                              </div>

                              <div className={"form-group col-md-8"}>
                                <Label htmlFor="exampleText">
                                  Next Action Plan Remark
                                </Label>
                                <Input
                                  type="textarea"
                                  name="nextActionRemark"
                                  id="exampleText"
                                  value={this.state.nextActionRemark}
                                  onChange={(e) => this.handleChange(e)}
                                />
                              </div>

                              <div className="form-group col-md-6">
                                <label htmlFor="inputname42">
                                  Proposal Amount
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="inputname42"
                                  placeholder=""
                                  name="proposalAmount"
                                  value={this.state.proposalAmount}
                                  onChange={(e) => this.handleChange(e)}
                                />
                              </div>

                              <div className="form-group col-md-6">
                                <label htmlFor="inputname42">
                                  Closure Amount
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="inputname42"
                                  placeholder=""
                                  name="closureAmount"
                                  value={this.state.closureAmount}
                                  onChange={(e) => this.handleChange(e)}
                                />
                              </div>

                              {/* <div className="form-group col-md-12">
                                <Label htmlFor="exampleFile">Attachments</Label>
                                <Input
                                  type="file"
                                  name="FileCollection"
                                  id="exampleFile"
                                  multiple
                                  onChange={(event) => {
                                    this.setState(
                                      {
                                        FileCollection: event.target.files,
                                      },
                                      () =>
                                        console.log(
                                          this.state.FileCollection,
                                          "file collection"
                                        )
                                    );
                                  }}
                                />
                              </div> */}

                              {/* <div className="form-group col-md-12">
                                <Label htmlFor="exampleSelect">
                                  Action
                                </Label>
                                <Input
                                  type="select"
                                  name="select"
                                  id="exampleSelect"
                                  value={this.state.ActionTaken}
                                  disabled
                                >
                                  <option>Select</option>
                                  <option>WhatsApp</option>
                                  <option>Online Meeting</option>
                                  <option>InProgress</option>
                                  <option>Resolved</option>
                                </Input>
                              </div> */}
                            </div>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => this.OnSubmitData()}
                            >
                              Submit
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default AddLeads;
