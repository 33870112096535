import firebase from "firebase";

// second firebase database

// let firebaseStore = null;

// function SetupFirebase() {

    const firebaseConfig = {
        apiKey: 'AIzaSyA3quzqT7-E8mB5ok1LgT-HM-5PvwHgss0',
        authDomain: 'melzo-app.firebaseapp.com',
        databaseURL: 'https://melzo-app.firebaseio.com',
        projectId: 'melzo-app',
        storageBucket: 'melzo-app.appspot.com',
        messagingSenderId: '126598841107'
    };

firebase.initializeApp(firebaseConfig);
// }

// SetupFirebase();

export default firebase;


// // let firebaseConfig2 = {
// //   apiKey: 'AIzaSyCZom22SCaq1uuerI2mDcdpTxqwE0od9CA',
// //   authDomain: 'ppt-vr.firebaseapp.com',
// //   databaseURL: 'https://ppt-vr.firebaseio.com',
// //   projectId: 'ppt-vr',
// //   storageBucket: 'ppt-vr.appspot.com'
// // };

// // const firebaseApp2 = firebase.initializeApp(firebaseConfig2);
// // const db2 = firebaseApp2.database()

// // export default {firebaseApp2,db2};
