import React from "react";
import ReactDataGrid from "react-data-grid";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import { db } from "../../../Firebase";

const columns = [
    { key: "date", name: "Date", editable: true },
    { key: "attendanceId", name: "Attendance ID", editable: true },
    { key: "entryTime", name: "Entry Time", editable: true },
    { key: "leaveTime", name: "Leave Time", editable: true },

]

export default class AttendanceTable extends React.Component {
    constructor() {
        super();

        this.state = {
            attendanceData: [],
        }
    }

    onGetAttendanceData() {
        let attendanceDataPromise = new Promise((resolve, reject) => {
            db.ref("/Attendance/").on("value", (queryAttendance) => {
                let attendanceArray = [];
                queryAttendance.forEach(element => {
                    let object = element.val();
                    object["id"] = element.key;
                    attendanceArray.push(object);
                });
                resolve(attendanceArray);
            })
        });
        attendanceDataPromise.then(resolvedData => {
            this.setState({
                attendanceData: resolvedData,
            })
        })
    }

    onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        // console.log("From row ", fromRow, "To row", toRow, "Updated", updated);
        // let state = this.state.employeeData;
        // this.setState(employeeData => {
        const rows = this.state.attendanceData.slice();
        for (let i = fromRow; i <= toRow; i++) {
            rows[i] = { ...rows[i], ...updated };
        }
        // console.log("Rows", rows)
        this.setState({ attendanceData: rows })
        // return { rows };
        // });
    };

    render() {

        return (
            <div>
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <div className="page-title">
                                <div className="float-left">
                                    <h1 className="title">Attendance</h1>
                                </div>
                            </div>



                            <div className="col-12">
                                <section className="box ">
                                    <header className="panel_header">
                                        <h2 className="title float-left">Sheet Data</h2>
                                    </header>
                                    <div className="content-body">
                                        <div className="row">
                                            <div className="col-lg-12">

                                                <ReactDataGrid
                                                    minHeight={this.state.attendanceData.length * 25 + 50}
                                                    rowHeight={50}
                                                    columns={columns}
                                                    rowGetter={i => this.state.attendanceData[i]}
                                                    rowsCount={50}
                                                    onGridRowsUpdated={this.onGridRowsUpdated}
                                                    enableCellSelect={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );

    }
}