import React from "react";
import { ListGroup, ListGroupItem } from "reactstrap";

// used for making the prop types of this component
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import stdImage from "../../../assets/img/notification.png";
import { db } from "../../../Firebase";
// import Moment from 'react-moment';
import moment from "moment";
import axios from "axios";

class Favcontact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: "",
      notification: [],
      userDept: [],
      shilpmisId: "",
      firstName: "",
    };
  }

  componentDidMount() {
    this.setState({
      userRole: localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).userRole
        : " ",
      shilpmisId: localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).email
        : " ",
      userDept: localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).department
        : " ",
      firstName: localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).firstName +
          " " +
          JSON.parse(localStorage.getItem("employeeObject")).lastName
        : " ",
    });

    this.onGetLeaveMsg();
  }

  onGetLeaveMsg = () => {
    const self = this;
    db.ref("/notification/").on("value", (queryLeave) => {
      let leaveResultData = [];
      queryLeave.forEach((element) => {
        let obj = element.val();
        obj["id"] = element.key;
        leaveResultData.push(obj);
      });
      let filterNotification = [];
      let notified = [];
      if (self.state.userRole != "HR") {


        filterNotification = leaveResultData.filter((msgdata) => {
          return (
            msgdata.msg != "" &&
            msgdata.userView == false &&
            msgdata.shilpmisId == self.state.shilpmisId
          );
        });
      }
      if (self.state.userRole == "HR") {
        filterNotification = leaveResultData.filter((msgdata) => {
          console.log("filterNotification", msgdata);
          return msgdata.msg != "" && msgdata.HRView == false;
        });
      }
      if (self.state.userRole == "team_lead") {
        filterNotification = leaveResultData.filter((msgdata) => {
          let dept = "Default";
          // console.log("msgData", msgdata);
          for (let i = 0; i < self.state.userDept.length; i++) {
            if (msgdata.userDept.includes(self.state.userDept[i])) {
              dept = self.state.userDept[i];
            }
          }
          return (
            msgdata.msg != "" &&
            msgdata.HeadView == false &&
            msgdata.userRole == "employee" &&
            msgdata.userDept == dept
          );
        });
      }
      if (self.state.userRole == "dept_head") {
        
        filterNotification = leaveResultData.filter((msgdata) => {
          let dept = "Default";
         { console.log("DEPT" + msgdata.userDept + "---" + self.state.userDept)}
          for (let i = 0; i < self.state.userDept.length; i++) {
            if (msgdata.userDept.includes(self.state.userDept[i])) {
              dept = self.state.userDept[i];
            }
          }
          return (
            msgdata.msg != "" &&
            msgdata.HeadView == false &&
            (msgdata.userRole == "team_lead" ||
              msgdata.userRole == "HR" ||
              msgdata.userRole == "employee") &&
            msgdata.userDept == dept
          );
        });
      }
      if (self.state.userRole == "admin") {
        filterNotification = leaveResultData.filter((msgdata) => {
          let dept = "Default";

          for (let i = 0; i < self.state.userDept.length; i++) {
            if (msgdata.userDept.includes(self.state.userDept[i])) {
              dept = self.state.userDept[i];
            }
          }
          return (
            msgdata.msg != "" &&
            msgdata.HeadView == false &&
            msgdata.userRole == "team_head" &&
            msgdata.userDept == dept
          );
        });
      }
      if (self.state.userRole == "superadmin") {
        filterNotification = leaveResultData.filter((msgdata) => {
          let dept = "Default";

          for (let i = 0; i < self.state.userDept.length; i++) {
            if (msgdata.userDept.includes(self.state.userDept[i])) {
              dept = self.state.userDept[i];
            }
          }
          return (
            msgdata.msg != "" &&
            msgdata.HeadView == false &&
            msgdata.userRole == "admin" &&
            msgdata.userDept == dept
          );
        });
      }

      new Promise((res, rej) => {
        filterNotification.map((allData) => {
          db.ref("/requestedLeaves/").on("value", (queryRequest) => {
            let requestLeaveArray = [];
            queryRequest.forEach((element) => {
              if (element.key == allData.requestLeaveid) {
                let obj = element.val();
                obj["id"] = element.key;
                requestLeaveArray.push(obj);
              }
            });
            if (allData.requestLeaveid != "Modified") {
              notified.push({ msgD: allData, reqLeave: requestLeaveArray[0] });
              res("success");
            }
          });
        });
      })
        .then((res) => {
          //  console.log("notified", notified);
          setTimeout(() => {
            this.setState(
              {
                notification: notified,
              },
              () => {
                //  console.log("leave new database fav", this.state.notification);
              }
            );
          }, 1000);
        })
        .catch((err) => {
          console.log("error", err);
        });
    });
  };

  clearNotification = (id, shilpmisId) => {
    if (this.state.userRole != "HR")
      if (shilpmisId == this.state.shilpmisId) {
        db.ref("/notification/" + id).update({
          userView: true,
        });
      } else {
        db.ref("/notification/" + id).update({
          HeadView: true,
          HRView: false,
        });
      }
    if (this.state.userRole == "HR") {
      db.ref("/notification/" + id).update({
        HRView: true,
      });
    }
  };

  // onApproved = () => {
  //     window.location.href = "/approve-leaves"
  // };

  onTravelApproved = (Notificationkey, email, id) => {
    if (this.state.userRole == "HR") {
      db.ref("/requestedTravel/" + id)
        .update({
          status: "Approved",
        })
        .then(() => {
          db.ref("/notification/" + Notificationkey)
            .update({
              status: "Approved",
            })
            .then(() => {
              this.clearNotification(Notificationkey, email);
            });
        });
    } else if (
      this.state.userRole !== "HR" &&
      this.state.userRole == "dept_head"
    ) {
      db.ref("/requestedTravel/" + id)
        .update({
          deptHeadStatus: "Approved",
        })
        .then(() => {
          db.ref("/notification/" + Notificationkey)
            .update({
              deptHeadStatus: "Approved",
            })
            .then(() => {
              this.clearNotification(Notificationkey, email);
            });
        });
    }
  };
  onApprovedRegularization = (
    Notificationkey,
    id,
    updateid,
    startDateTime,
    endDateTime,
    shilpmisId
  ) => {
    db.ref("/regularization-Request/" + updateid)
      .update({
        isEnabled: false,
      })
      .then(() => {
        db.ref("/attendence/" + id)
          .update({
            location: "Regularization Request Approved",
            checkInTime: Number(new Date(startDateTime.split("T").join(" "))),
            checkOut: Number(new Date(endDateTime.split("T").join(" "))),
          })
          .then(() => {
            this.clearNotification(Notificationkey, shilpmisId);
          });
      });
  };

  // notified.msgD.id,
  // notified.msgD.shilpmisId,
  // notified.msgD.ExpenseRequestId

  onExpenceApproved = ( Notificationkey,ExpenseRequestId,email) =>{
    if (this.state.userRole == "HR") {
      db.ref("/ExpenseBill/" + ExpenseRequestId).update({
        hrApproved: true,
      }).then((res)=>{
        db.ref("/notification/" + Notificationkey)
        .update({
          status: "Approved",
        })
        .then(() => {
          this.clearNotification(Notificationkey, email);
        });
      })
  } else if ( this.state.userRole !== "HR" && this.state.userRole == "dept_head"){
    db.ref("/ExpenseBill/" + ExpenseRequestId).update({
      managerApproved: true,
    }).then((res)=>{
      db.ref("/notification/" + Notificationkey)
      .update({
        deptHeadStatus: "Approved",
      })
      .then(() => {
        this.clearNotification(Notificationkey, email);
      });
    }).catch((Err) =>{
      console.log(Err);
    })

  }
  }
  onApproved = (shilpmisId, Notificationkey, requestedLeaveKey, leaveKey) => {
    console.log(
      "Approved",
      shilpmisId,
      Notificationkey,
      requestedLeaveKey,
      leaveKey
    );

    // Get data from firebase
    let requestedLeaveDataPromise = new Promise((resolve, reject) => {
      db.ref("/requestedLeaves/").on("value", (queryRequest) => {
        let requestLeaveArray = [];
        queryRequest.forEach((element) => {
          if (element.key == requestedLeaveKey) {
            let obj = element.val();
            obj["id"] = element.key;
            requestLeaveArray.push(obj);
          }
        });
        resolve(requestLeaveArray[0]);
      });
    });
    requestedLeaveDataPromise.then((res) => {
      let diffDays = 0;
      let fromDate1 = res.fromDate;
      let toDate1 = res.toDate;
      let diffTime = Math.abs(toDate1 - fromDate1);

      // counting sundays
      let sundays = countSunday(new Date(fromDate1), new Date(toDate1));
      if (res.halfDay) {
        diffDays = 0.5;
      } else {
        diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1 - sundays;
      }
      console.log("DATE DIFF", diffDays);

      console.log(res, "data of leave");
      // let diffDays = res.diffDays;
      let HRStatus = res.status;
      let HeadStatus = res.deptHeadStatus;
      let categoryOfLeave = res.categoryOfLeave;
      let userRole = res.userRole;
      let userDept = res.userDept;
      let shilpmisId = res.shilpmisId;
      let firstName = res.firstName;

      const updateStatus = new Promise((resolve, reject) => {
        db.ref("/requestedLeaves/" + requestedLeaveKey)
          .update({
            status: this.state.userRole === "HR" ? "Approved" : HRStatus,
            deptHeadStatus:
              this.state.userRole !== "HR" ? "Approved" : HeadStatus,
          })
          .then((res) => {
            console.log("Status Updated");
            resolve("success");
          })
          .catch((err) => {
            console.error("error", err);
            reject(err);
          });
      });
      if (this.state.userRole === "HR") {
        HRStatus = "Approved";
      } else {
        HeadStatus = "Approved";
      }

      if (HRStatus == "Approved" && HeadStatus == "Approved") {
        const updateLeaveCount = new Promise((accept, reject) => {
          // Leave data counting
          if (leaveKey !== "Data not found" && categoryOfLeave != "LWP") {
            db.ref("leaves")
              .child(leaveKey)
              .child("balanceLeave")
              .transaction((leave) => {
                if (categoryOfLeave == "casual Leave") {
                  leave.casualLeave = parseFloat(
                    parseFloat(leave.casualLeave).toFixed(1) -
                      parseFloat(diffDays).toFixed(1)
                  ).toFixed(1);
                } else if (categoryOfLeave == "marriage Leave") {
                  leave.marriageLeave = parseFloat(
                    parseFloat(leave.marriageLeave).toFixed(1) -
                      parseFloat(diffDays).toFixed(1)
                  ).toFixed(1);
                } else if (categoryOfLeave == "privilege Leave") {
                  leave.privilegeLeave = parseFloat(
                    parseFloat(leave.privilegeLeave).toFixed(1) -
                      parseFloat(diffDays).toFixed(1)
                  ).toFixed(1);
                } else if (categoryOfLeave == "sick Leave") {
                  leave.sickLeave = parseFloat(
                    parseFloat(leave.sickLeave).toFixed(1) -
                      parseFloat(diffDays).toFixed(1)
                  ).toFixed(1);
                }
                return leave;
              });
          } else if (categoryOfLeave === "LWP") {
            db.ref("leaves")
              .child(leaveKey)
              .transaction((leave) => {
                leave.leaveWithoutPay = parseFloat(
                  parseFloat(leave.leaveWithoutPay) + diffDays
                );

                return leave;
              });
          }
          sendEmail(
            res.firstName,
            res.shilpmisId,
            `Leave Approved`,
            new Date(res.fromDate).toLocaleDateString("en-IN"),
            new Date(res.toDate).toLocaleDateString("en-IN"),
            res.userDept,
            "Approved",
            "Approved"
          );
        });
      }
      // this.onGetRequestedLeave();  requestleaveObj this.state.firstName leaveCountObj

      new Promise((res, rej) => {
        db.ref("/notification")
          .push({
            type: "ApprovedLeave",
            msg:
              firstName == this.state.firstName
                ? "Leave Approved"
                : `Leave Approved by ${this.state.firstName}`,
            date: Date.now(),
            userRole: userRole,
            userDept: userDept,
            shilpmisId: shilpmisId,
            firstName: firstName,
            userView: false,
            HRView: false,
            HeadView: false,
          })
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    });
    this.clearNotification(Notificationkey, shilpmisId);
  };

  render() {
    // const { notification } = this.state;
    return (
      <div>
        {/* {this.state.notification.length > 0 && ( */}
        <ListGroup>
          {this.state.notification.map((notified, i) => {
            return (
              <ListGroupItem key={i} className="contact">
                <div className="user-img">
                  <img
                    src={stdImage}
                    alt="Chat Icon"
                    className="avatar-image img-inline"
                  />
                  <span style={{ color: "aquamarine" }}>
                    {moment(notified.msgD.date).fromNow()}
                  </span>
                </div>
                <div className="user-info">
                  <span className="name">
                    {notified.msgD.firstName == this.state.firstName
                      ? notified.msgD.type == "RequestLeave"
                        ? "You " + notified.msgD.msg
                        : "Your " + notified.msgD.msg
                      : notified.msgD.firstName + ", " + notified.msgD.msg}
                  </span>
                  <span className="status small">
                    {
                      // notified.msgD.type == "RequestLeave"
                      // ? notified.msgD.type == "RequestLeave" ? "Leave Request" : "Leave Approved"
                      // : notified.msgD.type == "RequestRegularization" ? "Regularization Request": "Regularization Approved"
                      // : notified.msgD.type == "TravelRequest" ? "Travel Request": "Travel Request Approved"
                      // : ""
                      notified.msgD.type == "RequestLeave"
                        ? notified.msgD.type == "RequestLeave"
                          ? "Leave Request"
                          : "Leave Approved"
                        : notified.msgD.type == "RequestRegularization"
                        ? notified.msgD.type == "RequestRegularization"
                          ? "Regularization Request"
                          : "Regularization Approved"
                        : notified.msgD.type == "TravelRequest"
                        ? notified.msgD.type == "TravelRequest"
                          ? "Travel Request"
                          : "Travel Request Approved"
                          : notified.msgD.type == "ExpenseRequest"
                          ? notified.msgD.type == "ExpenseRequest"
                            ? "Expense Request"
                            : "Expense Request Approved"
                          : ""
                    }
                  </span>
                  <span
                    data-status={
                      notified.msgD.type == "RequestLeave"
                        ? "available"
                        : "busy"
                    }
                    className="profile-status float-right"
                  ></span>
                  <Button
                    title={"Clear Notification"}
                    onClick={() =>
                      this.clearNotification(
                        notified.msgD.id,
                        notified.msgD.shilpmisId
                      )
                    }
                  >
                    Clear
                  </Button>
                  {this.state.userRole != "employee" &&
                    notified.msgD.type == "RequestLeave" && (
                      <Button
                        disabled={
                          this.state.userRole == "HR"
                            ? notified.reqLeave.deptHeadStatus == "Pending"
                              ? true
                              : false
                            : false
                        }
                        style={
                          this.state.userRole == "HR"
                            ? notified.reqLeave.deptHeadStatus == "Pending"
                              ? { cursor: "not-allowed" }
                              : {}
                            : {}
                        }
                        title={
                          this.state.userRole == "HR"
                            ? notified.reqLeave.deptHeadStatus == "Pending"
                              ? "Head Approval Pending"
                              : "Approve Leave"
                            : "Approve Leave"
                        }
                        onClick={() =>
                          this.onApproved(
                            notified.msgD.shilpmisId,
                            notified.msgD.id,
                            notified.msgD.requestLeaveid,
                            notified.msgD.leavesid
                          )
                        }
                      >
                        Approve
                      </Button>
                    )}

          
                  {this.state.userRole != "employee" &&
                    notified.msgD.type == "TravelRequest" && (
                      <Button
                        disabled={
                          this.state.userRole == "HR" &&
                          notified.msgD.deptHeadStatus == "Pending"
                            ? true
                            : false
                        }
                        style={
                          this.state.userRole == "HR" &&
                          notified.msgD.deptHeadStatus == "Pending"
                            ? { cursor: "not-allowed" }
                            : {}
                        }
                        title={
                          this.state.userRole == "HR"
                            ? notified.msgD.deptHeadStatus == "Pending"
                              ? "Head Approval Pending"
                              : "Approve Leave"
                            : "Approve Leave"
                        }
                        onClick={() =>
                          this.onTravelApproved(
                            notified.msgD.id,
                            notified.msgD.shilpmisId,
                            notified.msgD.TravelRequestId
                          )
                        }
                      >
                        Approve
                      </Button>
                    )}
                  
                {/* {console.log("notified.msgD",notified.msgD)}
                {this.state.userRole != "employee" &&
                    notified.msgD.type == "ExpenseRequest" && (
                      <Button
                        disabled={
                          this.state.userRole == "HR" &&
                          notified.msgD.deptHeadStatus == "Pending"
                            ? true
                            : false
                        }
                        style={
                          this.state.userRole == "HR" &&
                          notified.msgD.deptHeadStatus == "Pending"
                            ? { cursor: "not-allowed" }
                            : {}
                        }
                        title={
                          this.state.userRole == "HR"
                            ? notified.msgD.deptHeadStatus == "Pending"
                              ? "Head Approval Pending"
                              : "Approve Request"
                            : "Approve Request"
                        }
                        onClick={() =>
                          this.onExpenceApproved(
                            notified.msgD.id,
                            notified.msgD.ExpenseRequestId,
                            notified.msgD.shilpmisId,
                          )
                        }
                      >
                        Approve
                      </Button>
                    )} */}


                  {this.state.userRole != "employee" &&
                    notified.msgD.type == "RequestRegularization" && (
                      <Button
                        disabled={
                          this.state.userRole == "HR" &&
                          notified.msgD.isEnabled == true
                            ? false
                            : true
                        }
                        title={
                          this.state.userRole == "HR" &&
                          notified.msgD.isEnabled == true
                            ? "HR Approval Pending"
                            : "Approve Regularization request"
                        }
                        onClick={() =>
                          this.onApprovedRegularization(
                            notified.msgD.id,
                            notified.msgD.leavesid,
                            notified.msgD.requestLeaveid,
                            notified.msgD.startDateTime,
                            notified.msgD.endDateTime,
                            notified.msgD.endDateTime,
                            notified.msgD.shilpmisId
                          )
                        }
                      >
                        Approve
                      </Button>
                    )}
                </div>
              </ListGroupItem>
            );
          })}
        </ListGroup>
        {/* )} */}
      </div>
    );
  }
}

Favcontact.propTypes = {
  favcontacts: PropTypes.arrayOf(PropTypes.object),
};

export default Favcontact;

function countSunday(start, finish) {
  var weekendDays = 0;
  let dayMilliseconds = 24 * 60 * 60 * 1000;
  while (start <= finish) {
    var day = start.getDay();
    if (day == 0) {
      weekendDays++;
    }
    console.log("1", start);
    start = new Date(+start + dayMilliseconds);
    console.log("2", start);
  }
  return weekendDays;
}

function sendEmail(
  name,
  email,
  leaveMsg,
  fromDate,
  toDate,
  Department,
  HrStatus,
  deptHeadStatus
) {
  var obj = {};

  const commonMsg =
    "Dear sir/Ma'am" +
    "," +
    "<br/><br/>" +
    `<p>${name}, ${leaveMsg}</p>
    <br/>
    <p> Details are as follow ::
    <br></br>` +
    `<table style={{"width:100%",border : "1px solid black"}}>
    <tr>
      <th>Name</th>
      <th>Message</th>
      <th>From</th>
      <th>To</th>
      <th>Department</th>
      <th>HR Status</th>
      <th>Dept. Head Status</th>
    </tr>
    <tr>
      <td>${name}</td>
      <td>${leaveMsg}</td>
      <td>${fromDate}</td>
      <td>${toDate}</td>
      <td>${Department}</td>
      <td>${HrStatus}</td>
      <td>${deptHeadStatus}</td>
      </tr>
      </table>
    <br/>
    <p>Please do not reply this mail. It's system generated mail.</p>
    <br/>
  <p>Have a wonderful day! </p>
  <br/>
  <p>Thank you,</p>
  <p>Shilpmis Pvt. Ltd.</p>
  <br/>`;

  const approvalMsg =
    "Dear sir/Ma'am" +
    "," +
    "<br/><br/>" +
    `<p>${name}, ${leaveMsg}</p>
    <br/>
    <p> Details are as follow ::
    <br></br>` +
    `<table style={{"width:100%",border : "1px solid black"}}>
    <tr>
      <th>Name</th>
      <th>Message</th>
      <th>From</th>
      <th>To</th>
      <th>Department</th>
      <th>HR Status</th>
      <th>Dept. Head Status</th>
    </tr>
    <tr>
      <td>${name}</td>
      <td>${leaveMsg}</td>
      <td>${fromDate}</td>
      <td>${toDate}</td>
      <td>${Department}</td>
      <td>${HrStatus}</td>
      <td>${deptHeadStatus}</td>
      </tr>
      </table>
    <br/>
    <p>Please do not reply this mail. It's system generated mail.</p>
    <br/>
  <p>Have a wonderful day! </p>
  <br/>
  <p>Thank you,</p>
  <p>Shilpmis Technology Pvt. Ltd.</p>
  <br/>`;

  if ((HrStatus == deptHeadStatus) == "Approved") {
    var obj = {
      useremail: email, // User Mail Id
      message: approvalMsg,
      expoName: "Dashboard",
      senderName: "Melzo Dashboard",
      ccUser: "", // HR Mail Id + Dept Head Mail Id
      bccUser: "",
      subject: `${leaveMsg}`,
    };
  } else {
    var obj = {
      useremail: email, // User Mail Id
      message: commonMsg,
      expoName: "Dashboard",
      senderName: "Melzo Dashboard",
      ccUser: "bhavik1363@gmail.com,operations@shilpmis.com", // HR Mail Id + Dept Head Mail Id
      bccUser: "",
      subject: `${leaveMsg}`,
    };
  }
  axios
    .post(
      "https://us-central1-sendtoemail-3610d.cloudfunctions.net/sendCompanyEmail",
      obj,
      {
        headers: {
          // 'x-access-token': token
        },
      }
    )
    .then((regEmail) => {
      console.log("Done", regEmail);
    })
    .catch((error) => {
      console.log("Not Done", error);
    });
}
