import React, { Component } from "react";
import Dropzone from 'react-dropzone';
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import { db } from '../../../Firebase';
import * as XLSX from "xlsx";

export default class Attendance extends React.Component {
    constructor() {
        super();

        this.state = {
            entryTime: "10:00",
            leaveTime: "18:30",
            lateBy: "",
            email: "",
            leaveEarlyBy: "",
            shilpmisId: "",
            deptData: [],
            empArray: [],
            dept: "",
            entry: 0,
            leave: 0,
            date: new Date(),
            statusOfLeave: "",
            reasonOfLeave: "",
            file: "",
            attendanceId: "",
            userEntryTime: "",
            userLeaveTime: "",

            attendenceData: [],
            employeeData: [],
            id: "",
            attendenceTempData: [],
            filtered: [],
            Attendence: [],
            // tempArray: []
            importedExcelData: [],
        };
        this.filePathset = this.filePathset.bind(this);
        this.readFile = this.readFile.bind(this);
    }

    componentDidMount() {
        this.onGetEmployeeData();
        this.onGetDepartmentData();
    }

    onGetDepartmentData() {
        let departmentDataPromise = new Promise((resolve, reject) => {
            db.ref("/departmentMaster/").on("value", (queryDept) => {
                let deptData = [];
                queryDept.forEach((element) => {
                    let obj = element.val();
                    obj["id"] = element.key;
                    deptData.push(obj);
                });
                resolve(deptData);
            })
        })
        departmentDataPromise.then(acceptedData => {
            this.setState({
                deptData: acceptedData,
            }, () => console.log("department data::", this.state.deptData));
        })
    }

    onGetEmployeeData = () => {
        let employeeDataPromise = new Promise((resolve, reject) => {
            db.ref("/employees/")
                .on("value", (queryEmp) => {
                    let empArray = [];
                    queryEmp.forEach(element => {
                        if(element.val().status == "active"){

                            let obj = element.val();
                            obj["id"] = element.key;
                            empArray.push(obj);
                        
                        }
                    })
                    resolve(empArray)
                })
        })
        employeeDataPromise.then(acceptedData => {
            this.setState({
                employeeData: acceptedData,
            }, () => {
                console.log("employee data without dept::", this.state.employeeData);
                this.setState({
                    userEntryTime: this.state.employeeData.entryTime,
                    userLeaveTime: this.state.employeeData.leaveTime,
                })
            });
        })
    }

    getEmployee = (event) => {
        this.setState({ dept: event.target.value }, () => {
            db.ref("/employees/")
                .orderByChild("department")
                .on("value", (querySnap) => {
                    let empArray = [];
                    querySnap.forEach((empEle) => {
                        if(empEle.val().status == "active"){

                            empEle.val().department.map((obj) => {
                                if (obj === this.state.dept) {
                                    let empData = empEle.val();
                                    // empData["id"] = empEle.key;
                                    empArray.push(empData);
                                }
                            })
                            
                        }
                    });
                    setTimeout(() => {
                        this.setState({ empArray: empArray }, () => console.log("emp data::", this.state.empArray));
                        // console.warn(this.state.deptData);
                    }, 1000);
                });
        });
    };

    onDrop(files) {
        this.setState({
            files,
        });
    }

    handleChange = (event) => {
        // console.log(event.target.value);
        this.setState(
            {
                [event.target.name]: event.target.value,
            }
        );
    };

    setValueforUser = (event) => {
        let filteredData = this.state.empArray.filter(
            (employee) => employee.email === event.target.value
        );
        this.setState(
            {
                shilpmis_id: filteredData[0].userID,
                entry_time: filteredData[0].entryTime,
                email: filteredData[0].email,
                leave_time: filteredData[0].leaveTime,
            },
            () => {
                let entry_hour = parseInt(this.state.entry_time.split(":")[0]);
                let entry_min = parseInt(this.state.entry_time.split(":")[1]);
                let entry = entry_hour * 60 + entry_min;
                let leave_hour = parseInt(this.state.leave_time.split(":")[0]);
                let leave_min = parseInt(this.state.leave_time.split(":")[1]);
                let leave = leave_hour * 60 + leave_min;
                this.setState(
                    {
                        leave: leave,
                        entry: entry,
                    });
            });
    };

    handleClick(e) {
        this.refs.fileUploader.click();
    }

    filePathset(e) {
        e.stopPropagation();
        e.preventDefault();
        var file = e.target.files[0];
        console.log(file);
        this.setState({ file });
        // this.readFile();
    }

    isExistingUser = (userToTest, existingUsers) => {
        let statusExisting = false;
        for (let i = 0; i < existingUsers.length; i++) {
            if (userToTest === existingUsers[i].attendanceId) {
                statusExisting = i + 1;
                break;
            }
        }
        return statusExisting;
    }

    onCompareAttendanceId = (attendanceIdOne, attendanceIdTwo) => {
        let checkUserAttendanceId = false;
        if (attendanceIdOne === attendanceIdTwo) {
            checkUserAttendanceId = true;
        } else {
            checkUserAttendanceId = false;
        }
        return checkUserAttendanceId;
    }

    getEmployee = (attendanceId) => {
        let userData = false;
        for (let i = 0; i < this.state.employeeData.length; i++) {
            if (this.state.employeeData[i].attendanceId === attendanceId) {
                userData = this.state.employeeData[i];
                break;
            }
        }
        return userData;
    }

    getEntryTimeStatus = (empEntryTime, excelEmpEntryTime) => {
        let tempEntryTimeStr = '';
        this.state.importedExcelData.map((obj) => {
            let tempEntryTime = tempEntryTimeStr.split(":");
            let tempExcelEntryTime = obj.entryTime.split(":");
            console.log("2. excel sheet attendance id::", obj.attendanceId)

            if (parseInt(tempEntryTime[1]) === parseInt(tempExcelEntryTime[1]) &&
                parseInt(tempEntryTime[0]) === parseInt(tempExcelEntryTime[0])) {
                console.log("you are on time");
            } else if (parseInt(tempEntryTime[1]) < parseInt(tempExcelEntryTime[1]) &&
                parseInt(tempEntryTime[0]) <= parseInt(tempExcelEntryTime[0])
            ) {
                console.warn("you are late");
            } else {
                console.log("you are early");
            }
        })
    }

    getLeaveTimeStatus = (empLeaveTime, excelEmpLeaveTime) => {
        let tempLeaveTimeStr = '';
        this.state.importedExcelData.map((obj) => {
            let tempLeaveTime = tempLeaveTimeStr.split(":");
            let tempExcelLeaveTime = obj.leaveTime.split(":");
            console.log("excel sheet attendance id::", obj.attendanceId)

            if (parseInt(tempLeaveTime[1]) === parseInt(tempExcelLeaveTime[1]) &&
                parseInt(tempLeaveTime[0]) === parseInt(tempExcelLeaveTime[0])) {
                console.log("emp leave time::", tempLeaveTime)
                console.log("you left according to your leave time");
            } else if (parseInt(tempLeaveTime[1]) < parseInt(tempExcelLeaveTime[1]) &&
                parseInt(tempLeaveTime[0]) <= parseInt(tempExcelLeaveTime[0])) {
                console.log("you left late");
            } else {
                console.warn("you left early");
            }
        })

    }


    validateData = () => {
        let isNotexiest = false;
        // eslint-disable-next-line array-callback-return
        this.state.attendenceData.map((objAttendence) => {
            if (
                new Date(objAttendence.date).toLocaleDateString("en-IN") ===
                new Date(this.state.date).toLocaleDateString("en-IN") &&
                objAttendence.email === this.state.email
            ) {
                isNotexiest = true;
            }
        });
        if (isNotexiest) {
            alert("Entry already exist");
        } else if (this.state.email === "") {
            alert("Please Select Employee");
            isNotexiest = true;
        }
        return isNotexiest;
    };

    readFile() {
        var f = this.state.file;
        var name = f.name;
        const reader = new FileReader();
        reader.onload = (evt) => {
            // evt = on_file_select event
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            // const data = XLSX.utils.sheet_to_csv(ws, { header: 1 , blankRows: false});
            const data = XLSX.utils.sheet_to_json(ws, {
                blankRows: false,
                defval: '',
            });
            /* Update state */
            console.log("Data get:: ", data);
            JSON.parse(JSON.stringify(data));
            console.log("JSON ::", JSON.stringify(data))
            //this.saveToDataBase(data);
            this.formatExcelSheetData(data);
            console.log("final data::", data);
            // console.log("Data = ",this.convertToJson(data)); // shows data in json format
        };
        reader.readAsBinaryString(f);
    }

    formatExcelSheetData = (excelData) => {
        let tempUserEntryTime = "";
        let tempUserLeaveTime = "";
        this.state.employeeData.map((object) => {
            this.setState({
                tempUserEntryTime: object.entryTime,
                tempUserLeaveTime: object.leaveTime,
            })

            let attendanceArray = [];
            for (let i = 0; i < excelData.length; i++) {
                let result = this.isExistingUser(excelData[i].Attendance_Id, attendanceArray);
                if (result === false) {
                    let tempObject = {
                        attendanceId: excelData[i].Attendance_Id,
                        entryTime: excelData[i].Time,
                        leaveTime: '',
                        date: excelData[i].Date
                    }
                    let currentUserDetail = this.getEmployee(excelData[i].Attendance_Id);
                    console.log(currentUserDetail);

                    // Get Entry Time Status Here
                    this.getEntryTimeStatus(tempObject["entryTime"], this.state.userEntryTime);
                    attendanceArray.push(tempObject);
                } else {
                    // Get Leave Time Status Here
                    attendanceArray[result - 1].leaveTime = excelData[i].Time
                    this.getLeaveTimeStatus(attendanceArray[result - 1].leaveTime, this.state.userLeaveTime);
                }
            }
            console.log('Formatted Data::', attendanceArray);
            this.saveToDataBase(attendanceArray);
            this.setState({
                importedExcelData: attendanceArray,
            })
        })
    }

    saveToDataBase = (excelData) => {
        let attendArray = [];

        //console.log("temp data::", TempAttendanceId);
        db.ref("/Attendence/").push({
            excelData
        }, () => console.log("Done ", excelData));

    }

    handleSubmit = () => {
        if (!this.validateData()) {
            let entryDate = new Date(this.state.date).getTime();
            let toDay = new Date().getTime();
            db.ref("/Attendence/").push(
                {
                    date: entryDate,
                    entryTime: this.state.entryTime,
                    leaveTime: "",
                    leaveVarianceBy: "",
                    lateBy: this.state.lateBy,
                    email: this.state.email,
                    department: this.state.dept,
                    statusOfLeave: this.state.statusOfLeave,
                    reasonOfLeave: this.state.reasonOfLeave,
                    entryData: toDay,
                    entryBy: localStorage.getItem("email"),
                    updateDate: "",
                    updateBy: "",
                },
                () => {
                    this.clearState();
                }
            );
        }
    };

    clearState = () => {
        this.setState({
            date: "",
            entryTime: "10:00",
            leaveTime: "18:30",
            lateBy: "",
            leaveEarlyBy: "",
            statusOfLeave: "",
            reasonOfLeave: "",
            email: "",
            id: "",
            dept: "",
            file: "",
        });
    };

    render() {
        return (
            <div>
                <div className="content">
                    <Row>
                        <Col>
                            <div className="page-title">
                                <div className="float-left">
                                    <h1 className="title">Attendance</h1>
                                </div>
                            </div>
                            <div className="col-12">
                                <section className="box">
                                    <header className="panel_header">
                                        <h2 className="title float-left">Attendance Data</h2>
                                    </header>
                                    <div className="content-body">
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 col-12 col-md-12">
                                                <form>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="Date">Date</label>
                                                            <input type="date" className="form-control" id="Date" name="date" value={this.state.date} onChange={this.handleChange} />
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputDepartment">Department</label>
                                                            <select className="form-control" value={this.state.dept} name="dept" onChange={this.getEmployee}>
                                                                <option>Choose...</option>
                                                                {this.state.deptData.map((obj) => {
                                                                    return (<option value={obj.departmentName}>{obj.departmentName}</option>)
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="Employee">Employee</label>
                                                            <select className="form-control" value={this.state.email} name="email" onChange={this.setValueforUser}>
                                                                <option>Choose...</option>
                                                                {this.state.empArray.map((obj) => {
                                                                    return (<option value={obj.email}>{obj.firstName + " " + obj.lastName}</option>)
                                                                })}
                                                            </select>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="status">Status Of Leave</label>
                                                            <select id="status" className="form-control" value={this.state.statusOfLeave} name="statusOfLeave" onChange={this.handleChange}>
                                                                <option>Choose...</option>
                                                                <option value="workFromHome">Work From Home</option>
                                                                <option value="halfDay">Half Day</option>
                                                                <option value="leave">Leave</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    {(this.state.statusOfLeave === "workFromHome" || this.state.statusOfLeave === "halfDay") &&
                                                        <div className="form-row">
                                                            <div className="form-group col-md-3">
                                                                <label htmlFor="entryTime">Entry Time</label>
                                                                <input type="time" className="form-control" id="entryTime" name="entryTime" value={this.state.entryTime} onChange={this.handleChange} />
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label htmlFor="lateBy">Late By</label>
                                                                <input type="time" className="form-control" id="lateBy" name="lateBy" value={this.state.lateBy} onChange={this.handleChange} />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="reasonOfLeave">Reason</label>
                                                                <textarea id="reasonOfLeave" className="form-control" rows="1" placeholder="Reason" name="reasonOfLeave" value={this.state.reasonOfLeave} onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                    }

                                                    <div className="row">
                                                        <button type="submit" className="btn btn-primary" onClick={this.handleSubmit}>Submit</button>
                                                        <button type="submit" className="btn btn-secondary" onClick={this.clearState}>Cancel</button>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div className="col-12">
                                <section className="box">
                                    <header className="panel_header">
                                        <h2 className="title float-left">Excel Sheet</h2>
                                    </header>
                                    <div className="content-body">
                                        <div className="col-lg-12">
                                            <h5>File Upload</h5>
                                            <input type="file"
                                                id="file"
                                                ref="fileUploader"
                                                onChange={this.filePathset} />

                                            <button type="submit" className="btn btn-primary" onClick={this.readFile}>Upload</button>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div >
        )
    }
}