import { get } from "jquery";
import React from "react";
import { Row, Col } from "reactstrap";

import {} from "../../../components";
import { db } from "../../../Firebase";
import melzologo from "../../../assets/img/melzologo.png";

class Login extends React.Component {
  constructor() {
    super();

    this.state = {
      email: "",
      password: "",
      loginData: [],
      employeeList: [],
    };
    // this.setLoginData = this.setLoginData.bind(this)
  }

  componentDidMount() {
    this.onGetEmployeeData();
    // localStorage.setItem('IsloggedIn',false)
  }

  onGetEmployeeData() {
    let employeeDataPromise = new Promise((resolve, reject) => {
      db.ref("/employees/").on("value", (queryEmployee) => {
        let EmpArray = [];
        queryEmployee.forEach((element) => {
          if (element.val().status == "active") {
            let object = element.val();
            object["id"] = element.key;
            EmpArray.push(object);
          }
        });
        resolve(EmpArray);
      });
    });
    employeeDataPromise.then((acceptedData) => {
      this.setState(
        {
          loginData: acceptedData,
        },
        () => console.log("empdata ::", this.state.loginData)
      );
    });
  }

  setLoginData = (e) => {
    e.preventDefault();
    let redirectURL = "";

    let Promi = new Promise((res, rej) => {
      db.ref("/employees/")
        .orderByChild("email")
        .equalTo(this.state.email)
        .once("value", (snapshot) => {
          res(
            snapshot.val()
              ? Object.values(snapshot.val())[0].status == "active"
                ? Object.values(snapshot.val())[0]
                : "nope"
              : "nope"
          );
        });
    });
    Promi.then((res) => {
      if (res !== "nope") {
        if (res.password == this.state.password) {
          redirectURL = "/dashboard";
          window.location.href = redirectURL;
          console.log(res, "res dats");
          localStorage.setItem("employeeObject", JSON.stringify(res));
          localStorage.setItem("IsloggedIn", "true");
        } else {
          alert("you entered the wrong password");
        }
      } else {
        alert(
          "Either Username is not registered or the password is incorrect. Please contact Administrator."
        );
      }
    });
  };

  render() {
    return (
      <div>
        <div className="loginContainer">
          <Row>
            <Col xs={12} md={12}>
              <div className="container-fluid">
                <div className="login-wrapper row">
                  <div
                    id="login"
                    className="login loginpage offset-xl-4 offset-lg-3 offset-md-3 offset-0 col-12 col-md-6 col-xl-4"
                  >
                    <div className="logo-img">
                      <a href="/" className="logo-full">
                        <img
                          src={melzologo}
                          alt="react-logo"
                          className="light-logo"
                          style={{ marginTop: "7%",width:'40%'}}
                        />
                        <img
                          src={melzologo}
                          alt="react-logo"
                          className="dark-logo"
                        />
                      </a>
                    </div>

                    <form name="loginform" id="loginform">
                      <p>
                        <label
                          style={{ color: "#36cca3" }}
                          htmlFor="user_login"
                        >
                          Email ID
                          <br />
                          <input
                            type="text"
                            name="un"
                            id="email"
                            className="form-control"
                            onChange={(e) =>
                              this.setState({ email: e.target.value })
                            }
                          />
                        </label>
                      </p>
                      <p>
                        <label style={{ color: "#36cca3" }} htmlFor="user_pass">
                          Password
                          <br />
                          <input
                            type="password"
                            name="pwd"
                            id="user_pass"
                            className="input"
                            size="20"
                            onChange={(e) =>
                              this.setState({ password: e.target.value })
                            }
                          />
                        </label>
                      </p>
                      <p className="submit">
                        <input
                          type="button"
                          name="wp-submit"
                          id="wp-submit"
                          className="btn btn-accent btn-block"
                          value="Sign In"
                          onClick={this.setLoginData}
                        />
                      </p>
                    </form>
                    <p id="nav">
                      <a href="#!" title="Password Lost and Found">
                        Forgot password?
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Login;
