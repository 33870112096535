import moment from "moment"; // Example for onSort prop
import React from "react"; // Import React
//import { render } from 'react-dom'; // Import render method
import Datatable from "react-bs-datatable"; // Import this package
// import { Row, Col } from "reactstrap";
import { Row, Col, Label, Input } from "reactstrap";
import { db } from "../../../Firebase";
import noDataImg from "../../../assets/img/NoData.png";
import {
  BottomNavigation,
  BottomNavigationAction,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  withStyles,
  Zoom,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import AttachmentImageDialog from "./AttachmentDialog.jsx";
import Popover from "@material-ui/core/Popover";
// import { DatePicker } from "@material-ui/pickers";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

class LeadsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // New State
      firstName: "",
      shilpmisId: "",
      userRole: "",
      ticketNo: "",
      ticketArray: [],
      value: "",
      employeeData: [],
      assignPopUp: false,
      assignPopUpData: [],
      assignPopUpPreviousData: [],
      attachmentData: [],
      openAttachmentImage: false,
      remark: "",
      submitDataspin: false,
      customerDetails: {},
      // for Edit Data
      id: "",
      name: "",
      leadStage: "",
      leadStatus: "",
      closureAmount: 0,
      leadHandOverDate: "",
      actionTaken: "",
      nextActionTaken: "",
      actionRemark: "",
      nextActionRemark: "",
      remarkView: { remark: [], name: "" },
      nextRemarkView: { remark: [], name: "" },
      createdOn: "",
    };
  }

  componentDidMount() {
    this.setState({
      firstName: localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).firstName +
          " " +
          JSON.parse(localStorage.getItem("employeeObject")).lastName
        : " ",
      shilpmisId: localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).email
        : " ",
      userRole: localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).userRole
        : " ",
    });

    this.onSalesLeadData();
    // this.onGetEmployeeData();
  }

  // onGetEmployeeData = () => {
  //   let employeeDataPromise = new Promise((resolve, reject) => {
  //     db.ref("/employees/").on("value", (queryEmployee) => {
  //       let employeeArray = [];
  //       queryEmployee.forEach((element) => {
  //         let object = element.val();
  //         object["id"] = element.key;
  //         employeeArray.push(object);
  //       });
  //       resolve(employeeArray);
  //     });
  //   });
  //   employeeDataPromise.then((acceptedData) => {
  //     let filterDepartmentData = acceptedData.filter((data) => {
  //       return data.department.includes("Project Management");
  //     });
  //     this.setState(
  //       {
  //         employeeData: filterDepartmentData,
  //       },
  //       () => {
  //         console.log("emp Data", this.state.employeeData);
  //       }
  //     );
  //   });
  // };

  onSalesLeadData = () => {
    db.ref("/melzo-sales/").on("value", (queryLead) => {
      let salesLeadData = [];
      let filterSalesLeadData = [];
      queryLead.forEach((leads) => {
        let obj = leads.val();
        obj["id"] = leads.key;
        salesLeadData.push(obj);
      });

      if (this.state.userRole == "employee") {
        filterSalesLeadData = salesLeadData.filter((data) => {
          return data.leadOwner == this.state.firstName;
        });
      } else {
        filterSalesLeadData = salesLeadData;
      }
      this.setState(
        {
          ticketArray: filterSalesLeadData,
        },
        () => console.log(this.state.ticketArray, "salesLeadData")
      );
    });
  };

  editTicketData = (
    id,
    customerName,
    leadStage,
    leadStatus,
    leadHandOverDate,
    actionTaken,
    actionRemark,
    nextActionTaken,
    nextActionRemark,
    // product,
    // cusName,
    // compSubject,
    createdOn
  ) => {
    let data = {
      id: id,
      name: customerName,
      leadStage: leadStage,
      leadStatus: leadStatus,
      leadHandOverDate: leadHandOverDate,
      actionTaken: actionTaken,
      nextActionTaken: nextActionTaken,
      actionRemark: actionRemark[actionRemark.length - 1].remark,
      nextActionRemark: nextActionRemark
        ? nextActionRemark[nextActionRemark.length - 1].remark
        : "",
      createdOn: createdOn,
      // compSubject: compSubject,
      // issueType: issueType,
    };
    this.setState(
      {
        // assignPopUpData: data,
        assignPopUpPreviousData: data,
        id: id,
        name: customerName,
        leadStage: leadStage,
        leadStatus: leadStatus,
        leadHandOverDate: moment(leadHandOverDate),
        actionTaken: actionTaken,
        nextActionTaken: nextActionTaken,
        actionRemark: actionRemark[actionRemark.length - 1].remark,
        nextActionRemark: nextActionRemark
          ? nextActionRemark[nextActionRemark.length - 1].remark
          : "",
        createdOn: createdOn,
      },
      () => {
        this.setState({
          assignPopUp: true,
        });
        console.log("edit Data =<>", this.state.assignPopUpData.leadStage);
      }
    );
  };

  // openAttachmentImage = (id, attachment, ticketId, description) => {
  //   if (attachment) {
  //     let data = {
  //       id: id,
  //       imagesCollection: attachment,
  //       ticketId: ticketId,
  //       complaintDes: description,
  //     };
  //     this.setState({
  //       attachmentData: [data],
  //       openAttachmentImage: true,
  //     });
  //   } else {
  //     alert("No attachment available for selected ticket.");
  //   }
  // };

  // handleAssignTo = (e) => {
  //   if (e.target.name == "user") {
  //     let data = {
  //       id: this.state.assignPopUpData.id,
  //       assignTo: e.target.value.assignTo,
  //       status: "Assign",
  //       issuedBy: this.state.assignPopUpData.issuedBy,
  //       ticketNo: this.state.assignPopUpData.ticketNo,
  //       product: this.state.assignPopUpData.product,
  //       cusName: this.state.assignPopUpData.cusName,
  //       compSubject: this.state.assignPopUpData.compSubject,
  //       createdOn: this.state.assignPopUpData.createdOn,
  //       issueType: this.state.assignPopUpData.issueType,
  //     };
  //     this.setState(
  //       {
  //         assignPopUpData: data,
  //       },
  //       () => console.log("state data", this.state.assignPopUpData)
  //     );
  //   } else if (e.target.name == "status") {
  //     let data = {
  //       id: this.state.assignPopUpData.id,
  //       assignTo: this.state.assignPopUpData.assignTo,
  //       status: e.target.value.status,
  //       issuedBy: this.state.assignPopUpData.issuedBy,
  //       ticketNo: this.state.assignPopUpData.ticketNo,
  //       product: this.state.assignPopUpData.product,
  //       cusName: this.state.assignPopUpData.cusName,
  //       compSubject: this.state.assignPopUpData.compSubject,
  //       createdOn: this.state.assignPopUpData.createdOn,
  //       issueType: this.state.assignPopUpData.issueType,
  //     };
  //     this.setState(
  //       {
  //         assignPopUpData: data,
  //       },
  //       () => console.log("state data", this.state.assignPopUpData)
  //     );
  //   } else if (e.target.name == "remark") {
  //     this.setState(
  //       {
  //         remark: e.target.value,
  //       },
  //       () => console.log("state data", this.state.remark)
  //     );
  //   }
  // };

  handleAssignSubmit = () => {
    if (this.state.leadStatus == "") {
      alert("Please select the status of the Lead");
    } else if (this.state.leadStage == "") {
      alert("Please select the stage of the Lead");
    } else if (this.state.leadHandOverDate == "") {
      alert("Please select Lead HandOver Date");
    } else if (this.state.actionTaken == "") {
      alert("Please select Action Taken");
    } else if (this.state.nextActionTaken == "") {
      alert("Please select next action plan");
    } else if (
      this.state.actionRemark == this.state.assignPopUpPreviousData.actionRemark
    ) {
      alert("Please action remark should not be same as previous");
    } else if (
      this.state.nextActionRemark ==
      this.state.assignPopUpPreviousData.nextActionRemark
    ) {
      alert("Please next action plan's remark should not be same as previous");
    } else {
      this.setState({
        submitDataspin: true,
      });
      let key = this.state.id;

      console.log("in submit");
      new Promise((resolve, reject) => {
        db.ref("/melzo-sales/" + key)
          .update({
            leadStage: this.state.leadStage,
            leadStatus: this.state.leadStatus,
            leadHandOverDate: new Date(this.state.leadHandOverDate).getTime(),
            actionTaken: this.state.actionTaken,
            nextActionTaken: this.state.nextActionTaken
              ? this.state.nextActionTaken
              : this.state.actionTaken,
            // proposalAmount: this.state.proposalAmount,
            closureAmount: this.state.closureAmount,
            UpdatedOn: new Date().getTime(),
          })
          .then((res) => {
            db.ref("melzo-sales")
              .child(key)
              .child("actionRemark")
              .transaction((remark) => {
                console.log(remark);

                remark.push({
                  remark: this.state.actionRemark,
                  actionDate: new Date().getTime(),
                  actionBy: this.state.firstName,
                });
                return remark;
              });
            resolve("success");
            // this.setState({
            //   submitDataspin: false,
            //   assignPopUp: false,
            // });
          })
          .catch((err) => {
            console.error("error", err);
            reject(err);
          });
      })
        .then((res) => {
          db.ref("melzo-sales")
            .child(key)
            .child("nextActionRemark")
            .transaction((remark) => {
              console.log(remark);

              remark.push({
                remark: this.state.nextActionRemark,
                actionDate: new Date().getTime(),
                actionBy: this.state.firstName,
              });
              return remark;
            });
          this.setState({
            submitDataspin: false,
            assignPopUp: false,
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };

  onCustomerDetailsView = (
    event,
    name,
    email,
    mobile,
    country,
    region,
    city,
    companyName,
    website,
    leadId
  ) => {
    event.preventDefault();
    let customerData = {
      name: name,
      email: email,
      mobile: mobile,
      country: country,
      region: region,
      city: city,
      companyName: companyName,
      website: website,
      leadId: leadId,
    };
    let myTarget = event.currentTarget;
    this.setState({
      customerDetails: customerData,
      popOver: myTarget,
    });
  };

  onRemarkView = (event, remark, name, id, nextRemark, type) => {
    event.preventDefault();
    let remarkTarget = event.currentTarget;
    if (type == "remark") {
      this.setState({
        remarkView: { remark: remark, name: name, id: id },
        popOver2: remarkTarget,
      });
    } else if (type == "nextRemark") {
      this.setState({
        nextRemarkView: { remark: nextRemark, name: name, id: id },
        popOver3: remarkTarget,
      });
    }
  };

  handleChange(e, productValue) {
    if (productValue) {
      this.setState({
        customerProduct: productValue,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  render() {
    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="page-title">
                <div className="float-left">
                  <h1 className="title">Leads</h1>
                </div>
              </div>

              <div className="col-12">
                <section className="box " style={{ display: "block" }}>
                  <header className="panel_header">
                    <h2 className="title float-left">View All Leads</h2>
                    {/* <BottomNavigation
                      value={this.state.value}
                      onChange={(e, newvalue) =>
                        this.setState({
                          value: newvalue,
                        })
                      }
                      //   className={classes.root}
                      showLabels
                    >
                      <BottomNavigationAction
                        label="Open Ticket"
                        value="Open"
                        onClick={() => console.log("Open")}
                      />
                      <BottomNavigationAction
                        label="Assign"
                        value="Assign"
                        onClick={() => console.log("Assign")}
                      />
                      <BottomNavigationAction
                        label="In Progress"
                        value="InProgress"
                        onClick={() => console.log("InProgress")}
                      />
                      <BottomNavigationAction
                        label="Resolved"
                        value="Resolved"
                        onClick={() => console.log("Resolved")}
                      />
                    </BottomNavigation> */}
                  </header>
                  <div className="content-body">
                    <div className="row">
                      <div className="col-lg-12 dt-disp">
                        <TableContainer
                          style={
                            this.state.ticketArray.length === 0
                              ? {
                                  height: "400px",
                                  display: "flex",
                                  justifyContent: "center",
                                }
                              : {}
                          }
                        >
                          {this.state.ticketArray.length === 0 && (
                            <img
                              src={noDataImg}
                              style={{ height: "400px" }}
                            ></img>
                          )}
                          {this.state.ticketArray.length > 0 && (
                            <Table aria-label="simple table" id="table-data">
                              <TableHead
                                style={{
                                  fontWeight: "bold",
                                  background: "lightgrey",
                                }}
                              >
                                <TableRow>
                                  <TableCell data-f-bold>
                                    <b>Lead Id</b>
                                  </TableCell>
                                  <TableCell data-f-bold>
                                    <b>Customer Details</b>
                                  </TableCell>
                                  <TableCell data-f-bold>
                                    <b>Lead Type</b>
                                  </TableCell>
                                  <TableCell data-f-bold>
                                    <b>Lead Owner</b>
                                  </TableCell>
                                  <TableCell data-f-bold>
                                    <b>Product</b>
                                  </TableCell>
                                  <TableCell data-f-bold>
                                    <b>Lead Stage</b>
                                  </TableCell>
                                  <TableCell data-f-bold>
                                    <b>Lead Status</b>
                                  </TableCell>
                                  <TableCell data-f-bold>
                                    <b>Referred By</b>
                                  </TableCell>
                                  <TableCell data-f-bold>
                                    <b>Lead HandOver Date</b>
                                  </TableCell>
                                  <TableCell data-f-bold>
                                    <b>Action Taken</b>
                                  </TableCell>
                                  <TableCell data-f-bold>
                                    <b>Action Remark</b>
                                  </TableCell>
                                  <TableCell data-f-bold>
                                    <b>Next Action Plan</b>
                                  </TableCell>
                                  <TableCell data-f-bold>
                                    <b>Next Action Plan Remark</b>
                                  </TableCell>
                                  <TableCell data-f-bold>
                                    <b>Proposal Amount</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>Closure Amount</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>Last Update On</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>Created On</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>Days Tracked</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>Update Lead</b>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.ticketArray.map((data, index) => {
                                  return (
                                    <TableRow
                                      key={index}
                                      // style={
                                      //   data.status == "Assign"
                                      //     ? {
                                      //         background:
                                      //           "lightgoldenrodyellow",
                                      //       }
                                      //     : data.status == "Open"
                                      //     ? { background: "aliceblue" }
                                      //     : data.status == "Resolved"
                                      //     ? { background: "#c4e5c4ab" }
                                      //     : data.status == "InProgress"
                                      //     ? { background: "#e5737382" }
                                      //     : {}
                                      // }
                                    >
                                      <TableCell>{data.leadId}</TableCell>
                                      <TableCell>
                                        {
                                          <Button
                                            variant="outlined"
                                            style={{ border: "0px" }}
                                            aria-describedby={data.leadId}
                                            onClick={(event) =>
                                              this.onCustomerDetailsView(
                                                event,
                                                data.customerName,
                                                data.customerEmail,
                                                data.customerMobile,
                                                data.country,
                                                data.region,
                                                data.city,
                                                data.customerCompanyName,
                                                data.website,
                                                data.leadId
                                              )
                                            }
                                          >
                                            <u>{data.customerName}</u>
                                          </Button>
                                        }
                                      </TableCell>
                                      <TableCell>{data.leadType}</TableCell>
                                      <TableCell>{data.leadOwner}</TableCell>
                                      <TableCell style={{}}>
                                        {data.customerProduct.join(`\n`)}
                                      </TableCell>
                                      <TableCell>{data.leadStage}</TableCell>
                                      <TableCell>{data.leadStatus}</TableCell>
                                      <TableCell>{data.referredBy}</TableCell>
                                      <TableCell>
                                        {new Date(
                                          data.leadHandOverDate
                                        ).toLocaleDateString("en-IN")}
                                      </TableCell>
                                      <TableCell>{data.actionTaken}</TableCell>
                                      <TableCell>
                                        {
                                          <Button
                                            variant="outlined"
                                            style={{
                                              border: "0px",
                                              display: "block",
                                            }}
                                            aria-describedby={"remarkView"}
                                            onClick={(event) =>
                                              this.onRemarkView(
                                                event,
                                                data.actionRemark,
                                                data.customerName,
                                                data.leadId,
                                                data.nextActionRemark,
                                                "remark"
                                              )
                                            }
                                          >
                                            <u>
                                              {
                                                data.actionRemark[
                                                  data.actionRemark.length - 1
                                                ].remark
                                              }{" "}
                                            </u>{" "}
                                            {" On " +
                                              new Date(
                                                data.actionRemark[
                                                  data.actionRemark.length - 1
                                                ].actionDate
                                              ).toLocaleString("en-IN")}
                                          </Button>
                                        }
                                      </TableCell>
                                      <TableCell>
                                        {data.nextActionTaken
                                          ? data.nextActionTaken
                                          : "NaN"}
                                      </TableCell>
                                      <TableCell>
                                        {data.nextActionRemark ? (
                                          <Button
                                            variant="outlined"
                                            style={{
                                              border: "0px",
                                              display: "block",
                                            }}
                                            aria-describedby={"nextRemarkView"}
                                            onClick={(event) =>
                                              this.onRemarkView(
                                                event,
                                                data.actionRemark,
                                                data.customerName,
                                                data.leadId,
                                                data.nextActionRemark,
                                                "nextRemark"
                                              )
                                            }
                                          >
                                            <u>
                                              {
                                                data.nextActionRemark[
                                                  data.nextActionRemark.length -
                                                    1
                                                ].remark
                                              }{" "}
                                            </u>{" "}
                                            {" On " +
                                              new Date(
                                                data.nextActionRemark[
                                                  data.nextActionRemark.length -
                                                    1
                                                ].actionDate
                                              ).toLocaleString("en-IN")}
                                          </Button>
                                        ) : (
                                          "NaN"
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {data.proposalAmount}
                                      </TableCell>
                                      <TableCell>
                                        {data.closureAmount}
                                      </TableCell>
                                      <TableCell>
                                        {new Date(
                                          data.updatedOn
                                        ).toLocaleDateString("en-IN")}
                                      </TableCell>
                                      <TableCell>
                                        {new Date(
                                          data.createdOn
                                        ).toLocaleDateString("en-IN")}
                                      </TableCell>
                                      <TableCell>
                                        {Math.ceil(
                                          Math.abs(
                                            data.updatedOn - data.createdOn
                                          ) /
                                            (1000 * 60 * 60 * 24)
                                        ) + 1}
                                      </TableCell>
                                      <TableCell data-exclude="true">
                                        <EditIcon
                                          titleAccess="Click to Update Information"
                                          color="inherit"
                                          onClick={() =>
                                            this.editTicketData(
                                              data.id,
                                              data.customerName,
                                              data.leadStage,
                                              data.leadStatus,
                                              data.leadHandOverDate,
                                              data.actionTaken,
                                              data.actionRemark,
                                              data.nextActionTaken,
                                              data.nextActionRemark,
                                              // data.customerProduct,
                                              // data.customerName,
                                              // data.complaintSuject,
                                              data.createdOn
                                            )
                                          }
                                          variant="contained"
                                          size="sm"
                                        />
                                      </TableCell>
                                      {/* <TableCell data-exclude="true">
                                        <ImageSearchIcon
                                          variant="contained"
                                          color={
                                            data.attachment
                                              ? data.attachment.length > 0
                                                ? "inherit"
                                                : "disabled"
                                              : "disabled"
                                          }
                                          titleAccess={
                                            data.attachment
                                              ? data.attachment.length > 0
                                                ? "View attachment files"
                                                : "No attachment"
                                              : "No attachment"
                                          }
                                          onClick={() =>
                                            this.openAttachmentImage(
                                              data.id,
                                              data.attachment,
                                              data.ticketNo,
                                              data.complaintDes
                                            )
                                          }
                                        />
                                      </TableCell> */}
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          )}
                          <Popover
                            id={this.state.customerDetails.leadId}
                            open={Boolean(this.state.popOver)}
                            anchorEl={this.state.popOver}
                            onClose={() => {
                              this.setState({ popOver: null });
                            }}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                          >
                            <div
                              className="form-group col-md-12"
                              style={{ display: "flex" }}
                            >
                              {/* <div style={{ width: "100%" }}>
                                <label htmlFor="inputname425">
                                  Customer Email
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="inputname425"
                                  placeholder=""
                                  name="customerEmail"
                                ></input>
                              </div> */}
                              <div style={{ width: "100%" }}>
                                <div>
                                  <span>
                                    {"Name :" + this.state.customerDetails.name}
                                  </span>
                                </div>
                                <br></br>
                                <div>
                                  <span>
                                    {"Email : " +
                                      this.state.customerDetails.email}
                                  </span>
                                </div>
                                <br></br>
                                <div>
                                  <span>
                                    {"Mobile : " +
                                      this.state.customerDetails.mobile}
                                  </span>
                                </div>
                                <br></br>
                                <div>
                                  <span>
                                    {"Country : " +
                                      this.state.customerDetails.country}
                                  </span>
                                </div>
                                <br></br>
                                <div>
                                  <span>
                                    {"State : " +
                                      this.state.customerDetails.region}
                                  </span>
                                </div>
                                <br></br>
                                <div>
                                  <span>
                                    {"City : " +
                                      this.state.customerDetails.city}
                                  </span>
                                </div>
                                <br></br>
                                <div>
                                  <span>
                                    {"Company Name : " +
                                      this.state.customerDetails.companyName}
                                  </span>
                                </div>
                                <br></br>
                                <div>
                                  <span>
                                    {"WebSite : "}
                                    <a
                                      href={this.state.customerDetails.website}
                                    >
                                      {this.state.customerDetails.website}
                                    </a>
                                  </span>
                                </div>
                                <br></br>
                              </div>
                            </div>
                          </Popover>
                          {this.state.remarkView.remark.length > 0 && (
                            <Popover
                              id={"remarkView"}
                              open={Boolean(this.state.popOver2)}
                              anchorEl={this.state.popOver2}
                              onClose={() => {
                                this.setState({ popOver2: null });
                              }}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                            >
                              <div
                                className="form-group col-md-12"
                                style={{ display: "flex" }}
                              >
                                <div style={{ width: "100%" }}>
                                  <div>
                                    <span>
                                      {"Lead Remark of " +
                                        this.state.remarkView.name +
                                        "( " +
                                        this.state.remarkView.id +
                                        " )"}
                                    </span>
                                  </div>
                                  <br></br>
                                  {console.log(
                                    "this.state.remarkView.remark",
                                    this.state.remarkView.remark
                                  )}
                                  {this.state.remarkView.remark.map((data) => {
                                    console.log("data", data);
                                    return (
                                      <>
                                        <Grid container spacing={3}>
                                          <Grid item md={12} xs={12}>
                                            <Typography variant="body1">
                                              {"Remark :: " + data.remark}
                                            </Typography>
                                          </Grid>
                                          <Grid item md={6} xs={12}>
                                            <Typography variant="caption">
                                              {"Date ::" +
                                                new Date(
                                                  data.actionDate
                                                ).toLocaleString("en-IN")}
                                            </Typography>
                                          </Grid>
                                          <Grid item md={6} xs={12}>
                                            <Typography variant="caption">
                                              {" By ::" + data.actionBy}
                                            </Typography>
                                          </Grid>
                                          <br></br>
                                        </Grid>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </Popover>
                          )}
                          {this.state.nextRemarkView.remark.length > 0 && (
                            <Popover
                              id={"nextRemarkView"}
                              open={Boolean(this.state.popOver3)}
                              anchorEl={this.state.popOver3}
                              onClose={() => {
                                this.setState({ popOver3: null });
                              }}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                            >
                              <div
                                className="form-group col-md-12"
                                style={{ display: "flex" }}
                              >
                                <div style={{ width: "100%" }}>
                                  <div>
                                    <span>
                                      {"Lead Remark of " +
                                        this.state.nextRemarkView.name +
                                        "( " +
                                        this.state.nextRemarkView.id +
                                        " )"}
                                    </span>
                                  </div>
                                  <br></br>
                                  {console.log(
                                    "this.state.remarkView.remark",
                                    this.state.nextRemarkView.remark
                                  )}
                                  {this.state.nextRemarkView.remark.map(
                                    (data) => {
                                      console.log("data", data);
                                      return (
                                        <>
                                          <Grid container spacing={3}>
                                            <Grid item md={12} xs={12}>
                                              <Typography variant="body1">
                                                {"Remark :: " + data.remark}
                                              </Typography>
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                              <Typography variant="caption">
                                                {"Date ::" +
                                                  new Date(
                                                    data.actionDate
                                                  ).toLocaleString("en-IN")}
                                              </Typography>
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                              <Typography variant="caption">
                                                {" By ::" + data.actionBy}
                                              </Typography>
                                            </Grid>
                                            <br></br>
                                          </Grid>
                                        </>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </Popover>
                          )}
                        </TableContainer>
                        <Dialog
                          open={this.state.assignPopUp}
                          onClose={() => this.setState({ assignPopUp: false })}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            {`Update Sales Lead of ${this.state.name}`}
                          </DialogTitle>
                          <DialogContent style={{ height: "250px" }}>
                            <DialogContentText id="alert-dialog-description">
                              {
                                "All your actions are logged so be carefull while submitting data..."
                              }
                            </DialogContentText>
                            <hr></hr>
                            <Grid container spacing={3}>
                              <Grid item md={6} xs={12}>
                                <label htmlFor="leadstage">Lead Stage</label>
                                <FormControl
                                  variant="outlined"
                                  style={{ width: "100%" }}
                                >
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="leadStage"
                                    name="leadStage"
                                    title={this.state.leadStage}
                                    value={this.state.leadStage}
                                    onChange={(e) => this.handleChange(e)}
                                    // renderValue={(dept) => dept.join(",")}
                                  >
                                    <MenuItem disabled>
                                      <em>Select Lead Stage</em>
                                    </MenuItem>
                                    <MenuItem value={"Subscriber"}>
                                      Subscriber
                                    </MenuItem>
                                    <MenuItem value={"Lead/Nurturing"}>
                                      Lead/Nurturing
                                    </MenuItem>
                                    <MenuItem value={"MQL"}>
                                      Market Qualified Lead
                                    </MenuItem>
                                    <MenuItem value={"SQL"}>
                                      Sales Qualified Lead
                                    </MenuItem>
                                    <MenuItem value={"OL"}>
                                      Opportunity Lead
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <label htmlFor="product">Lead Status</label>
                                <FormControl
                                  style={{ width: "100%" }}
                                  variant="outlined"
                                >
                                  <Select
                                    id="leadStatus"
                                    name="leadStatus"
                                    value={this.state.leadStatus}
                                    onChange={(e) => this.handleChange(e)}
                                  >
                                    <MenuItem value="Connected ">
                                      Connected{" "}
                                    </MenuItem>
                                    <MenuItem value="Demo schedule ">
                                      Demo schedule{" "}
                                    </MenuItem>
                                    <MenuItem value="Demo Completed">
                                      Demo Completed
                                    </MenuItem>
                                    <MenuItem value="Proposal Sent">
                                      Proposal Sent
                                    </MenuItem>
                                    <MenuItem value="Price Negotiation">
                                      Price Negotiation
                                    </MenuItem>
                                    <MenuItem value="★ Win ★">★ Win ★</MenuItem>
                                    <MenuItem value="〆 Lost 〆 ">
                                      〆 Lost 〆{" "}
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <label>Lead HandOver Date</label>
                                <div
                                  className="controls"
                                  style={{ margin: "0px" }}
                                >
                                  <DatePicker
                                    selected={this.state.leadHandOverDate}
                                    value={this.state.leadHandOverDate}
                                    onChange={(e) => {
                                      this.setState({
                                        leadHandOverDate: moment(e),
                                      });
                                    }}
                                  />
                                </div>
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <label htmlFor="product">Action</label>
                                <FormControl
                                  style={{ width: "100%" }}
                                  variant="outlined"
                                >
                                  <Select
                                    id="actionTaken"
                                    name="actionTaken"
                                    value={this.state.actionTaken}
                                    onChange={(e) => this.handleChange(e)}
                                  >
                                    <MenuItem value="WhatsApp">
                                      WhatsApp
                                    </MenuItem>
                                    <MenuItem value="Online Meeting">
                                      Online Meeting
                                    </MenuItem>
                                    <MenuItem value="Physical Meeting">
                                      Physical Meeting
                                    </MenuItem>
                                    <MenuItem value="Proposal Sent">
                                      Proposal Sent
                                    </MenuItem>
                                    <MenuItem value="Followup Mail">
                                      Followup Mail
                                    </MenuItem>
                                    <MenuItem value="Followup Call">
                                      Followup Call
                                    </MenuItem>
                                    <MenuItem value="Followup WhatsApp">
                                      Followup WhatsApp
                                    </MenuItem>
                                    <MenuItem value="Call">Call</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <Label htmlFor="exampleText">
                                  Action Remark
                                </Label>
                                <Input
                                  type="textarea"
                                  name="actionRemark"
                                  id="exampleText"
                                  value={this.state.actionRemark}
                                  onChange={(e) => this.handleChange(e)}
                                />
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <label htmlFor="product">
                                  Next Action Plan
                                </label>
                                <FormControl
                                  style={{ width: "100%" }}
                                  variant="outlined"
                                >
                                  <Select
                                    id="nextActionTaken"
                                    name="nextActionTaken"
                                    value={this.state.nextActionTaken}
                                    onChange={(e) => this.handleChange(e)}
                                  >
                                    <MenuItem value="WhatsApp">
                                      WhatsApp
                                    </MenuItem>
                                    <MenuItem value="Online Meeting">
                                      Online Meeting
                                    </MenuItem>
                                    <MenuItem value="Physical Meeting">
                                      Physical Meeting
                                    </MenuItem>
                                    <MenuItem value="Proposal Sent">
                                      Proposal Sent
                                    </MenuItem>
                                    <MenuItem value="Followup Mail">
                                      Followup Mail
                                    </MenuItem>
                                    <MenuItem value="Followup Call">
                                      Followup Call
                                    </MenuItem>
                                    <MenuItem value="Followup WhatsApp">
                                      Followup WhatsApp
                                    </MenuItem>
                                    <MenuItem value="Call">Call</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <Label htmlFor="exampleText">
                                  Next Action Plan Remark
                                </Label>
                                <Input
                                  type="textarea"
                                  name="nextActionRemark"
                                  id="exampleText"
                                  value={this.state.nextActionRemark}
                                  onChange={(e) => this.handleChange(e)}
                                />
                              </Grid>

                              <Grid item md={6} xs={12}>
                                <label htmlFor="inputname42">
                                  Closure Amount
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="inputname42"
                                  placeholder=""
                                  name="closureAmount"
                                  value={this.state.closureAmount}
                                  onChange={(e) => this.handleChange(e)}
                                />
                              </Grid>
                            </Grid>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={() =>
                                this.setState({ assignPopUp: false })
                              }
                              color="primary"
                            >
                              Cancel
                            </Button>
                            {this.state.submitDataspin && (
                              <CircularProgress
                                color="primary"
                                style={{ margin: "5px" }}
                                size={20}
                              />
                            )}
                            <Button
                              onClick={() => this.handleAssignSubmit()}
                              color="primary"
                              autoFocus
                            >
                              Submit
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </Col>
          </Row>
          <AttachmentImageDialog
            open={this.state.openAttachmentImage}
            Product={this.state.attachmentData}
            onClose={() => this.setState({ openAttachmentImage: false })}
          />
        </div>
      </div>
    );
  }
}

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);

export default LeadsView;
