// export default Timeoff;
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import "../Leave/Userform.css";
import "../Leave/AddLeaveForm.css";
import { TextField, Grid, InputLabel, TableContainer } from "@material-ui/core";
// import { DashboardLayout } from "../layout/Layout";
import { Form, Row, Col, FormGroup, Table } from "react-bootstrap";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { db } from "../../Firebase";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Popup from "./DeletePopup";
import ConfirmDialog from "../Register/ConfirmDialog";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import { TabContext } from "@material-ui/lab";
import TabList from "@material-ui/lab/TabList";
import Paper from "@material-ui/core/Paper";
import TabPanel from "@material-ui/lab/TabPanel";
import {
  Card,
  CardContent
} from "@material-ui/core";
// import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { ContactSupport } from "@material-ui/icons";
import { date } from "@linways/table-to-excel";
import axios from "axios";
import noDataImg from "../../assets/img/NoData.png";

export default class RequestLeave extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: "2",
      id: "",
      toDate: new Date(),
      fromDate: new Date(),
      categoryOfLeave: "",
      comments: "",
      firstName: "",
      shilpmisId: "",
      userRole: "",
      userStatus:"",
      dummyRole: "",
      employeeData: [],
      members: [],
      userDept: [],
      departmentList: [
        "Information Technology",
        "Digital & Tendering",
        "Sales & Marketing",
        "Design",
        " HR & Admin",
        "Project Management",
        "Customer Support",
      ],
      viewLeaveStatus: true,
      leaveApplication: false,
      halfDay: "",
      requestLeaveData: [],
      addLeaveData: [],
      leaveMsg: [],
      casualLeave: 0,
      marriageLeave: 0,
      privilegeLeave: 0,
      sickLeave: 0,
      LWP: 0,
      modifyLeave: false,
      modifyLeaveId: "",
      notificationId: "",
      leaveId: "",
      leaveIds: "",
      requestLeaveId: "",
      leaveDuration: 0,
      halfDayVisible:false,
      confirmDialog: {
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: () => {},
      },
      halfLeave:false,
      casualLeaves: 0,
      marriageLeaves: 0,
      privilegeLeaves: 0,
      sickLeaves: 0,
      LWPs: 0,
    };
  }

  componentDidMount() {
    // this.onSaveLeave();
    this.setState({
      firstName: localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).firstName +
          " " +
          JSON.parse(localStorage.getItem("employeeObject")).lastName
        : " ",
      shilpmisId: localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).email
        : " ",
      userRole: localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).userRole
        : " ",
      dummyRole: localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).userRole
        : " ",
      userDept: localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).department
        : [],
      userStatus: localStorage.getItem("employeeObject")
      ? JSON.parse(localStorage.getItem("employeeObject")).status
      : "",
    });
    this.onGetLeaveData();
    this.onGetRequestedLeave();
    this.onGetEmployeeData();
    this.onGetLeaveMsg();
    this.onGetAllLeaveData();
  }

  // Leave Msg

  onGetAllLeaveData = () => {
   
    let addLeaveDataPromise = new Promise((resolve, reject) => {
      let leaveArray = [];
      db.ref("/leaves/").on("value", (queryLeave) => {
        queryLeave.forEach((element) => {
          let obj = element.val();
          obj["id"] = element.key;
          leaveArray.push(obj);
        });
        resolve(leaveArray);
      });
    });
  
    addLeaveDataPromise.then((leaveResultData) => {
 
      this.setState({
        addLeaveData: leaveResultData,
      });
     
      let leave = leaveResultData.filter((userdata) => {
        return userdata.employeeEmail == this.state.shilpmisId;
      });
      
      console.log("leave+++", leave);
      if (leave.length > 0) {
        this.setState(
          {
            casualLeaves: leave[0].balanceLeave.casualLeave,
            marriageLeaves: leave[0].balanceLeave.marriageLeave,
            privilegeLeaves: leave[0].balanceLeave.privilegeLeave,
            sickLeaves: leave[0].balanceLeave.sickLeave,
            LWPs: leave[0].leaveWithoutPay,
            leaveIds: leave[0].id,
          },
          () => console.log("USER LEAVE DATA::", this.state.addLeaveData)
        );
      }
    });
  };

  onGetLeaveMsg = () => {
    let LeaveMsgPromise = new Promise((resolve, reject) => {
      let leaveArray = [];
      db.ref("/notification/").on("value", (queryLeave) => {
        queryLeave.forEach((element) => {
          let obj = element.val();
          obj["id"] = element.key;
          leaveArray.push(obj);
        });
        resolve(leaveArray);
      });
    });
    LeaveMsgPromise.then((leaveResultData) => {
      this.setState(
        {
          leaveMsg: leaveResultData,
        },
        () => {
          console.log("leave new database", this.state.leaveMsg);
        }
      );
    });
  };

  // Leave count data get from database

  onGetLeaveData = () => {
    let addLeaveDataPromise = new Promise((resolve, reject) => {
      let leaveArray = [];
      db.ref("/leaves/").on("value", (queryLeave) => {
        queryLeave.forEach((element) => {
          let obj = element.val();
          obj["id"] = element.key;
          leaveArray.push(obj);
        });
        resolve(leaveArray);
      });
    });
    addLeaveDataPromise.then((leaveResultData) => {
      this.setState({
        addLeaveData: leaveResultData,
      });
      let leave = leaveResultData.filter((userdata) => {
        return userdata.employeeEmail == this.state.shilpmisId;
      });
      console.log("leave", leave);
      if (leave.length > 0) {
        this.setState(
          {
            casualLeave: leave[0].balanceLeave.casualLeave,
            marriageLeave: leave[0].balanceLeave.marriageLeave,
            privilegeLeave: leave[0].balanceLeave.privilegeLeave,
            sickLeave: leave[0].balanceLeave.sickLeave,
            LWP: leave[0].leaveWithoutPay,
            leaveId: leave[0].id,
          },
          () => console.log("add leave data::", this.state.addLeaveData)
        );
      }
    });
  };

  // get employee data from database
  onGetEmployeeData = () => {
    let employeeDataPromise = new Promise((resolve, reject) => {
      db.ref("/employees/").on("value", (queryEmployee) => {
        let employeeArray = [];
        queryEmployee.forEach((element) => {
          if (element.val().status == "active") {
            let object = element.val();
            object["id"] = element.key;
            employeeArray.push(object);
          }
        });
        resolve(employeeArray);
      });
    });
    employeeDataPromise.then((acceptedData) => {
      this.setState(
        {
          employeeData: acceptedData,
        },
        () => {
          console.log("emp Data", this.state.employeeData);
          let membersList = [];
          this.state.employeeData.map((obj) => {
            let data = {
              name: obj.firstName + " " + obj.lastName,
              designation: obj.designation,
              department: obj.department,
              userID: obj.userID,
              shilpmisId: obj.email,
              userRole: obj.userRole,
            };
            membersList.push(data);
          });
          this.setState(
            {
              members: membersList,
            },
            () => console.log("members::", this.state.members)
          );
        }
      );
    });
  };

  onSaveLeave = () => {
    if (this.validationLeave()) {
      this.onRegisterLeave(
        this.state.toDate,
        this.state.fromDate,
        this.state.categoryOfLeave,
        this.state.comments,
        this.state.firstName,
        this.state.shilpmisId,
        this.state.userDept,
        this.state.dummyRole,
        this.state.halfDay,
        this.state.modifyLeaveId,
        this.state.modifyLeave
      );
    }
  };

  setConfirmDialog = (newValue) => {
    this.setState({ confirmDialog: newValue });
  };

  onRegisterLeave = (
    toDate,
    fromDate,
    categoryOfLeave,
    comments,
    firstName,
    shilpmisId,
    userDept,
    userRole,
    halfDay,
    leaveId,
    modifyLeave
  ) => {
    console.log("dept", userDept);
    let tempToDate = new Date(this.state.toDate).getTime();
    let tempFromDate = new Date(this.state.fromDate).getTime();

    let filterEmployee = this.state.employeeData.filter(emp => {
      return emp.email == shilpmisId
    })

    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    const guid = String(s4() + s4() + s4() + s4());
    new Promise((res, rej) => {
      this.countDays(tempFromDate, tempToDate);
      res("success");
    }).then((res) => {
      new Promise((res, rej) => {
        if (this.state.modifyLeave) {
          db.ref("/requestedLeaves/" + leaveId)
            .update(
              {
                toDate: tempToDate,
                fromDate: tempFromDate,
                categoryOfLeave: categoryOfLeave,
                leaveDuration: this.state.leaveDuration,
                comments: comments,
                firstName: firstName,
                shilpmisId: shilpmisId,
                userDept: userDept,
                userRole: userRole,
                status: "Pending",
                deptHeadStatus: "Pending",
                isEnabled: true,
                halfDay: halfDay,
                notificationId: guid,
                updateTime: new Date().getTime(),
              },
              () => this.clearData()
            )
            .then((response) => {
              db.ref("/notification/" + this.state.notificationId).update({
                userView: true,
                HeadView: true,
                HRView: true,
              });
            });
          this.setState({
            requestLeaveId: leaveId,
          });
          res(leaveId);
        } else {
          db.ref("/requestedLeaves")
            .push({
              toDate: tempToDate,
              fromDate: tempFromDate,
              categoryOfLeave: categoryOfLeave,
              leaveDuration: this.state.leaveDuration,
              comments: comments,
              firstName: firstName,
              shilpmisId: shilpmisId,
              userDept: userDept,
              userRole: userRole,
              status: "Pending",
              deptHeadStatus: "Pending",
              isEnabled: true,
              halfDay: halfDay,
              notificationId: guid,
              entryTime: new Date().getTime(),
            })
            .then((resolve) => {
              res(resolve.key);
              this.setState({
                requestLeaveId: resolve.key,
              });
              this.onGetRequestedLeave();
              this.clearData();
            });
        }
      })
        .then((res) => {
          console.log(db.ref);
          let message = "";
          if (modifyLeave == true) {
            message = "Modified the leave, sent for the approval";
          } else if (halfDay == true) {
            message = `applied for Half Leave on ${new Date(
              tempFromDate
            ).toDateString("en-IN")} for ${comments} , sent for the approval`;
          } else {
            message = `applied ${categoryOfLeave} from ${new Date(
              tempFromDate
            ).toDateString("en-IN")} to ${new Date(tempToDate).toDateString(
              "en-IN"
            )} for ${comments} , sent for the approval`;
          }

          db.ref("/notification/" + guid)
            .update({
              type: "RequestLeave",
              msg: message,
              date: Date.now(),
              userRole: userRole,
              userDept: userDept,
              shilpmisId: shilpmisId,
              firstName: firstName,
              userView: false,
              HRView: false,
              HeadView: false,
              requestLeaveid: res,
              leavesid: this.state.leaveId,
            })
            .then((res) => {
              console.log(res);
              sendEmail(
                firstName,
                shilpmisId,
                message,
                comments,
                new Date(tempFromDate).toDateString("en-IN"),
                new Date(tempToDate).toDateString("en-IN"),
                userDept,
                "Pending",
                "Pending",
                filterEmployee.reportingTo,
              );
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log("djcndjkdccdc", error);
        });
    });
  };

  countDays = (fromDate, toDate) => {
    let diffDays = 0;
    let fromDate1 = fromDate;
    let toDate1 = toDate;
    let diffTime = Math.abs(toDate1 - fromDate1);

    // counting sundays
    let sundays = countSunday(new Date(fromDate1), new Date(toDate1));
    if (this.state.halfDay) {
      diffDays = 0.5;
    } else {
      diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1 - sundays;
    }
    console.log("DATE DIFF", diffDays);
    this.setState({
      leaveDuration: diffDays,
    });

    return diffDays;
  };

  clearData = () => {
    this.setState({
      // toDate: "",
      // fromDate: "",
      categoryOfLeave: "",
      comments: "",
      leaveApplication: false,
      halfDay: "",
      viewLeaveStatus: true,
    });
  };

  visibleChecked = (e) =>{
    this.setState({
      halfDayVisible:!this.state.halfDayVisible,
      halfDay:"",
    })
  }
  handleChecked = (e) => {
    this.setState(
      {
        halfDay: e.target.value,
        
      },
      () => {
        console.log(this.state.halfDay);
      }
    );
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleChangeForHR = (userSelected) => {
    let data = this.state.members.filter((userData) => {
      return userData.name === userSelected.target.value;
    });
    console.log(data);
    this.setState({
      userDept: data[0].department,
      firstName: data[0].name,
      shilpmisId: data[0].shilpmisId,
      dummyRole: data[0].userRole,
    });
    let userLeave = this.state.addLeaveData.filter((userdata) => {
      return userdata.employeeEmail == data[0].shilpmisId;
    });
    console.log("userLeave", userLeave);
    if (userLeave.length > 0) {
      this.setState(
        {
          casualLeave: userLeave[0].balanceLeave.casualLeave,
          marriageLeave: userLeave[0].balanceLeave.marriageLeave,
          privilegeLeave: userLeave[0].balanceLeave.privilegeLeave,
          sickLeave: userLeave[0].balanceLeave.sickLeave,
          LWP: userLeave[0].leaveWithoutPay,
        },
        () => console.log("add userLeave data::", this.state.addLeaveData)
      );
    }
  };

  handleDateChange = (e, name) => {
    if (name == "fromDate") {
      if (this.state.toDate > e) {
        this.setState({
          // toDate: e,
          [name]: e,
        });
      } else {
        this.setState({
          toDate: e,
          [name]: e,
        });
      }
    } else {
      this.setState({
        [name]: e,
      });
    }
  };

  handleChangeTab = (event, newValue) => {
    if (newValue == "2") {
      this.setState({
        tabValue: "2",
      });
    } else if (newValue == "1") {
      this.setState({
        tabValue: "1",
      });
    }
  };
  
  validationLeave = () => {
    let stack = this.state;
    let diff = stack.halfDay
      ? 0.5
      : Math.ceil(
          Math.abs(stack.fromDate - stack.toDate) / (1000 * 60 * 60 * 24)
        ) + 1;

    // filter previous leave data based on current apply leave from date
    let fromDateFilter = this.state.requestLeaveData.filter((filterData) => {
      return (
        new Date(filterData.fromDate).setHours(0, 0, 0, 0) ==
          new Date(stack.fromDate).setHours(0, 0, 0, 0) &&
        filterData.isEnabled !== false
      );
    });
    if (stack.toDate === "") {
      alert("Please select the to date");
      return false;
    } else if (stack.fromDate === "") {
      alert("Please enter from date");
      return false;
    } else if (stack.categoryOfLeave === "") {
      alert("Select category of leave");
      return false;
    } else if (stack.comments === "") {
      alert("Enter comment/reason for leave");
      return false;
    } else if (
      stack.categoryOfLeave === "sick Leave" &&
      stack.sickLeave < diff
    ) {
      alert("You exhaust your quota of sick Leave");
      return false;
    } else if (
      stack.categoryOfLeave === "casual Leave" &&
      stack.casualLeave < diff
    ) {
      alert("You exhaust your quota of casual Leave");
      return false;
    } else if (
      stack.categoryOfLeave === "marriage Leave" &&
      stack.marriageLeave < diff
    ) {
      alert("You exhaust your quota of marriage Leave");
      return false;
    } else if (
      stack.categoryOfLeave === "privilege Leave" &&
      stack.privilegeLeave < diff
    ) {
      alert("You exhaust your quota of sick Leave");
      return false;
    } else if (fromDateFilter.length > 0 && !this.state.modifyLeave) {
      alert(
        "Your Previous leave overlapping current apply leave..." +
          `\n\n Kindly check it !!`
      );
      return false;
    } else {
      return true;
    }
  };

  onGetRequestedLeave = () => {
    let requestedLeaveDataPromise = new Promise((resolve, reject) => {
      db.ref("/requestedLeaves/").on("value", (queryRequest) => {
        let requestLeaveArray = [];
        queryRequest.forEach((element) => {
          let obj = element.val();
          obj["id"] = element.key;
          requestLeaveArray.push(obj);
        });
        resolve(requestLeaveArray);
      });
    });
    requestedLeaveDataPromise.then((acceptedData) => {
      // if (this.state.userRole == 'employee') {
      let leave = [];
      leave = acceptedData.filter((leaveData) => {
        return leaveData.shilpmisId == this.state.shilpmisId;
      });

      let sortedLeave = leave.sort((a, b) => b.entryTime - a.entryTime);
      this.setState(
        {
          requestLeaveData: leave,
        },
        () => console.log("request leave data::", this.state.requestLeaveData)
      );
      // }
    });
  };

  showPopup = (key) => {
    console.log("Delete", key);
    const deletePromise = new Promise((resolve, reject) => {
      db.ref("/requestedLeaves/" + key)
        .update({
          isEnabled: false,
          status: "Cancelled",
          deptHeadStatus: "Cancelled",
          updateTime: new Date().getTime(),
        })
        .then((res) => {
          console.log("Deleted");
          resolve("success");
        })
        .catch((error) => {
          console.error("Delete Error", error);
          reject(error);
        });
    });
    deletePromise
      .then((res) => {
        this.setConfirmDialog({
          isOpen: false,
        });
        this.onGetRequestedLeave();
      })
      .catch((error) => {
        console.log("error", error);
        this.setConfirmDialog({
          isOpen: false,
        });
      });
  };

  modifyLeave = (data) => {
    console.log(data);
    let filterData = this.state.requestLeaveData.filter((id) => {
      return id.id == data;
    });
    filterData = filterData[0];
    console.log("filter modi data", filterData);
    this.setState({
      toDate: new Date(filterData.toDate),
      fromDate: new Date(filterData.fromDate),
      categoryOfLeave: filterData.categoryOfLeave,
      comments: filterData.comments,
      firstName: filterData.firstName,
      shilpmisId: filterData.shilpmisId,
      halfDay: filterData.halfDay,
      dummyRole: filterData.userRole,
      userDept: filterData.userDept,
      modifyLeave: true,
      viewLeaveStatus: false,
      modifyLeaveId: data,
      notificationId: filterData.notificationId
        ? filterData.notificationId
        : "",
    });
  };

  // deleteData = () => {
  //     if(this.state.id === "") {
  //         return;
  //     }
  //     this.setState({
  //         showPopup: !this.state.showPopup,
  //     });
  //     db.ref("/requestedLeaves/" + this.state.id).remove();
  //     this.clearData();
  // }

  onCancelLeave = () => {
    if (this.state.id === "") {
      return;
    }
    this.setState({
      showPopup: !this.state.showPopup,
      status: "cancel",
      requestLeaveData: [],
    });
  };

  render() {
    return (
      <div>
        <div className="content">
          <div>
            <h4 className="header inrowtitle" style={{ marginTop: "15px" }}>
              {this.state.viewLeaveStatus
                ? "View Leave Status"
                : "Request Leave"}
            </h4>
            {(this.state.viewLeaveStatus && this.state.userStatus) && (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  id="cancel"
                  variant="contained"
                  color="primary"
                  className="marginrightbtn"
                  onClick={() => {
                    this.setState({
                      viewLeaveStatus: false,
                    });
                  }}
                >
                  Request Leave
                </Button>
              </div>
            )}
            {!this.state.viewLeaveStatus && (
              <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                  {this.state.userRole !== "HR" && (
                    <TextField
                      id="firstName"
                      name="firstName"
                      label="First Name *"
                      style={{ width: "100%" }}
                      variant="outlined"
                      inputProps={{ style: { margin: "0" } }}
                      value={this.state.firstName}
                      disabled
                    ></TextField>
                  )}
                  {this.state.userRole === "HR" && (
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-label">
                        Select User
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="firstName"
                        name="firstName"
                        label="Select User"
                        value={this.state.firstName}
                        onChange={this.handleChangeForHR}
                        disabled={this.state.modifyLeave}
                      >
                        <MenuItem disabled>
                          <em>Select User</em>
                        </MenuItem>
                        {this.state.members.map((user) => {
                          return (
                            <MenuItem value={user.name}>{user.name}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
                <Grid item md={3} xs={12}>
                  {this.state.userRole !== "HR" && (
                    <TextField
                      id="shilpmisId"
                      name="shilpmisId"
                      variant="outlined"
                      inputProps={{ style: { margin: "0" } }}
                      value={this.state.shilpmisId}
                      label="Shilpmis Id *"
                      style={{ width: "100%" }}
                      disabled
                    ></TextField>
                  )}
                  {this.state.userRole === "HR" && (
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-label">
                        Select ShilpmisId
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="shilpmisId"
                        name="shilpmisId"
                        label="Select User"
                        disabled
                        value={this.state.shilpmisId}
                        onChange={this.handleChange}
                      >
                        <MenuItem disabled>
                          <em>Select ShilpmisId</em>
                        </MenuItem>
                        {this.state.members.map((user) => {
                          return (
                            <MenuItem value={user.shilpmisId}>
                              {user.shilpmisId}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
                {this.state.userRole === "HR" && (
                  <Grid item md={3} xs={12}>
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-label">
                        Select User Department
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="userDept"
                        name="userDept"
                        label="Select User Department"
                        title={this.state.userDept}
                        value={this.state.userDept}
                        onChange={this.handleChange}
                        disabled
                        renderValue={(dept) => dept.join(",")}
                      >
                        <MenuItem disabled>
                          <em>Select User Department</em>
                        </MenuItem>
                        {this.state.departmentList.map((dept) => {
                          return <MenuItem value={dept}>{dept}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                <Grid item md={3} xs={12}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      required
                      inputVariant="outlined"
                      label="Leave From"
                      name="fromDate"
                      format="MM/DD/yyyy"
                      animateYearScrolling
                      // disablePast
                      value={this.state.fromDate}
                      InputAdornmentProps={{ position: "start" }}
                      inputProps={{ style: { margin: "0" } }}
                      onChange={(date) =>
                        this.handleDateChange(date, "fromDate")
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item md={3} xs={12}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      autoOk
                      required
                      variant="inline"
                      inputVariant="outlined"
                      label="Leave Upto"
                      name="toDate"
                      format="MM/DD/yyyy"
                      animateYearScrolling
                      // disablePast
                      minDate={this.state.fromDate}
                      value={this.state.toDate}
                      InputAdornmentProps={{ position: "start" }}
                      inputProps={{ style: { margin: "0" } }}
                      onChange={(date) => this.handleDateChange(date, "toDate")}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item md={3} xs={12}>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Type of leave
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="categoryOfLeave"
                      name="categoryOfLeave"
                      displayEmpty
                      value={this.state.categoryOfLeave}
                      label="Select Leave *"
                      onChange={this.handleChange}
                    >
                      <MenuItem disabled>
                        <em>Select Leave Type</em>
                      </MenuItem>
                      <MenuItem
                        value="casual Leave"
                        disabled={this.state.casualLeave > 0 ? false : true}
                      >
                        {"Casual Leave ( " + this.state.casualLeave + " )"}
                      </MenuItem>
                      <MenuItem
                        value="sick Leave"
                        disabled={this.state.sickLeave > 0 ? false : true}
                      >
                        {"Sick Leave ( " + this.state.sickLeave + " )"}
                      </MenuItem>
                      <MenuItem
                        value="marriage Leave"
                        disabled={this.state.marriageLeave > 0 ? false : true}
                      >
                        {"Marriage Leave ( " + this.state.marriageLeave + " )"}
                      </MenuItem>
                      <MenuItem
                        value="privilege Leave"
                        disabled={this.state.privilegeLeave > 0 ? false : true}
                      >
                        {"Privilege Leave ( " +
                          this.state.privilegeLeave +
                          " )"}
                      </MenuItem>
                      <MenuItem value="LWP">
                        {"Leave Without Pay (LWP) ( " + this.state.LWP + " )"}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <textarea
                      className="form-control marginbottomten"
                      id="comments"
                      name="comments"
                      variant="outlined"
                      aria-labelledby="comment"
                      aria-label="nkflvfn"
                      required
                      autoComplete="off"
                      placeholder="Reason for taking leave"
                      style={{ width: "100%" }}
                      value={this.state.comments}
                      onChange={this.handleChange}
                    ></textarea>
                  </FormControl>
                </Grid>
                {new Date(this.state.toDate).toDateString("en-IN") ==
                new Date(this.state.fromDate).toDateString("en-IN") ? (
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.halfDayVisible}
                          onChange={this.visibleChecked}
                          name="Half Day"
                          value={this.state.halfDayVisible}
                        />
                      }
                      label="Half-Day"
                    />
                    { this.state.halfDayVisible && 
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                  Select Half-Day Leave Type
                  </InputLabel>
                    <Select name="LeaveType"  labelId="demo-simple-select-label" label="Select Half-Day Leave Type" value={this.state.halfDay}   onChange={this.handleChecked} id="LeaveType">
                    <MenuItem value="First-Half">First Half</MenuItem>
                    <MenuItem value="Second-Half">Second Half</MenuItem>
     
                    </Select>
                    </FormControl>
                    }
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            )}
            {!this.state.viewLeaveStatus && (
              <div className="flexbtn">
                <Button
                  id="submit"
                  variant="contained"
                  color="primary"
                  className="marginrightbtn"
                  onClick={this.onSaveLeave}
                >
                  {this.state.modifyLeave ? "Modify" : "Submit"}
                </Button>

                <Button
                  id="cancel"
                  variant="contained"
                  color="primary"
                  className="marginrightbtn"
                  onClick={this.clearData}
                >
                  Cancel
                </Button>
                <Button
                  id="cancel"
                  variant="contained"
                  color="primary"
                  className="marginrightbtn"
                  onClick={() => {
                    this.setState({
                      viewLeaveStatus: true,
                    });
                  }}
                >
                  View Previous Leave
                </Button>
              </div>
            )}
       {this.state.viewLeaveStatus && (
            <TableContainer>
          {/* <TabContext />value={this.state.tabValue}> */}
            <Card style={{marginTop:"10px"}}>
              <CardContent>
          <TabContext value={this.state.tabValue}>
                  <AppBar
                    position="static"
                    style={{ background: "white", color: "black" }}
                  >
                    <TabList
                       onChange={this.handleChangeTab}
                      aria-label="profiletab"
                    >
                      <Tab
                        label="Leave List"
                        value="1"
                        style={{ fontFamily: "Montserrat", outline: "0px" }}
                      />
                      <Tab
                        label="Leave Tracker"
                        value="2"
                        style={{ fontFamily: "Montserrat", outline: "0px" }}
                      />
                       </TabList>
                  </AppBar>
              <TabPanel value="1">

           
              
              {this.state.requestLeaveData.length == 0 && (
                <img
                  src={noDataImg}
                  style={{ height: "400px", margin: "auto", display: "flex" }}
                ></img>
              )}

                {this.state.requestLeaveData.length > 0 && (
                  <Table aria-label="simple table">
                    <thead>
                      <tr>
                        <th>First Name</th>
                        <th>Employee Id</th>
                        <th>From-date</th>
                        <th>To-date</th>
                        <th>No. of Days</th>
                        <th>Category Of Leave</th>
                        <th>Reason</th>
                        <th>HR Status</th>
                        <th>Manager Status</th>
                        <th>Cancel Request</th>
                      </tr>
                    </thead>
                    {this.state.requestLeaveData.map((dbValue) => {
                      return (
                        <tbody>
                          <tr value={dbValue.id}>
                            <td>{dbValue.firstName}</td>
                            <td>{dbValue.shilpmisId}</td>
                            <td>
                              {new Date(dbValue.fromDate).toLocaleDateString(
                                "en-IN"
                              )}
                            </td>
                            <td>
                              {new Date(dbValue.toDate).toLocaleDateString(
                                "en-IN"
                              )}
                            </td>
                            <td>
                              {dbValue.leaveDuration
                                ? dbValue.leaveDuration
                                : dbValue.halfDay
                                ? "0.5"
                                : Math.ceil(
                                    Math.abs(
                                      dbValue.fromDate - dbValue.toDate
                                    ) /
                                      (1000 * 60 * 60 * 24)
                                  ) + 1}
                            </td>
                            <td>{dbValue.categoryOfLeave}</td>
                            <td>{dbValue.comments}</td>
                            <td>{dbValue.status}</td>
                            <td>
                              {dbValue.deptHeadStatus
                                ? dbValue.deptHeadStatus
                                : "Pending"}
                            </td>
                            <td>
                              <Button
                                variant="contained"
                                color={
                                  // dbValue.deptHeadStatus == "Approved" &&
                                  // dbValue.status == "Approved"
                                  // ?
                                  "secodary"
                                  // : "secodary"
                                }
                                style={
                                  dbValue.deptHeadStatus == "Approved" &&
                                  dbValue.status == "Approved"
                                    ? { color: "white", cursor: "not-allowed" }
                                    : { color: "black" }
                                }
                                disabled={
                                  dbValue.deptHeadStatus == "Approved" &&
                                  dbValue.status == "Approved"
                                    ? true
                                    : // : dbValue.fromDate > Date.now()
                                      false
                                  // : true
                                }
                                onClick={() =>
                                  this.setConfirmDialog({
                                    isOpen: true,
                                    title:
                                      "Are you sure you want to cancel this leave?",
                                    subTitle:
                                      "Once canceled, you can't undo this action",
                                    onConfirm: () => {
                                      this.showPopup(dbValue.id);
                                    },
                                  })
                                }
                              >
                                Cancel Leave
                              </Button>
                            </td>
                            <td>
                              <Button
                                variant="contained"
                                color={
                                  dbValue.deptHeadStatus == "Approved" &&
                                  dbValue.status == "Approved"
                                    ? "secodary"
                                    : "secodary"
                                }
                                style={
                                  dbValue.deptHeadStatus == "Approved" &&
                                  dbValue.status == "Approved"
                                    ? { color: "white", cursor: "not-allowed" }
                                    : { color: "black" }
                                }
                                disabled={
                                  dbValue.deptHeadStatus == "Approved" &&
                                  dbValue.status == "Approved"
                                    ? true
                                    : // : dbValue.fromDate > Date.now()
                                      false
                                  // : true
                                }
                                onClick={() => this.modifyLeave(dbValue.id)}
                              >
                                Modify Leave
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </Table>
                )}
            </TabPanel>
                <TabPanel value="2">
                <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <TableContainer component={Paper}>
                   
                        <div className="content-body">
                        <div className="col-md-6 col-lg-4" style={{display:"flex", flexDirection:"row", gap: "15px"}}>
                          <div className="team-member" style={{width: "100%", height: "100%",flexWrap: "wrap", margin: " 0 0 0 0",}}>
                            <div className="team-img" style={{ display: "flex", flexDirection:"column", justifyContent: "center", textAlign:"center", width:"150px", height : "250px", padding:"5px"}}>
                               <h5>Casual Leave</h5>

                               <div>
                                    <p> Available : {" "+ this.state.casualLeaves} </p>
                                    {/* <p> Booked : </p> */}
                              </div>
                            </div>
                          </div>

                            <div className="team-member" style={{width: "100%", height: "100%",flexWrap: "wrap", margin: " 0 0 0 0"}}>
                            <div className="team-img" style={{ display: "flex", flexDirection:"column", justifyContent: "center", textAlign:"center", width:"150px", height : "250px", padding:"5px" }}>
                               <h5>Sick Leave</h5>

                               <div>
                                    <p> Available : {" "+ this.state.sickLeaves}</p>
                                    {/* <p> Booked : </p> */}
                              </div>
                            </div>
                            </div>

                              <div className="team-member" style={{width: "100%", height: "100%",flexWrap: "wrap", margin: " 0 0 0 0"}}> 
                            <div className="team-img" style={{ display: "flex", flexDirection:"column", justifyContent: "center", textAlign:"center", width:"150px", height : "250px", padding:"5px" }}>
                               <h5>Marriage Leave</h5>
                             
                               <div>
                                    <p> Available : {" "+ this.state.marriageLeaves}</p>
                                    {/* <p> Booked : </p> */}
                              </div>
                            </div>
                            </div>

                            <div className="team-member" style={{width: "100%", height: "100%",flexWrap: "wrap", margin: " 0 0 0 0"}}>
                            <div className="team-img" style={{ display: "flex", flexDirection:"column", justifyContent: "center", textAlign:"center",  width:"150px",height : "250px", padding:"5px" }}>
                               <h5>Privilage Leave</h5>

                               <div>
                                    <p> Available : {" "+ this.state.privilegeLeaves}</p>
                                    {/* <p> Booked : </p> */}
                              </div>
                            </div>
                            </div>

                            <div className="team-member" style={{width: "100%", height: "100%",flexWrap: "wrap", margin: " 0 0 0 0"}}>
                            <div className="team-img" style={{ display: "flex", flexDirection:"column", justifyContent: "center", textAlign:"center", width:"150px", height : "250px", padding:"5px" }}>
                               <h5>Leave Without Pay</h5>

                               <div>
                                    <p> Available : {" "+ this.state.LWPs} </p>
                                    {/* <p> Booked : </p> */}
                              </div>
                            </div>
                          </div>         
                        </div>
                        </div>

                    </TableContainer>
                    </Grid>
                </Grid>
              </TabPanel>
            </TabContext>
            </CardContent>
          </Card>
          </TableContainer>
       )}

            {this.state.showPopup ? (
              <Popup
                text=""
                closePopup={this.showPopup.bind(this)}
                okPopup={this.onCancelLeave.bind(this)}
              />
            ) : null}

            <ConfirmDialog
              confirmDialog={this.state.confirmDialog}
              setConfirmDialog={this.setConfirmDialog}
            />
          </div>
        </div>
      </div>
    );
  }
}
function countSunday(start, finish) {
  var weekendDays = 0;
  let dayMilliseconds = 24 * 60 * 60 * 1000;
  while (start <= finish) {
    var day = start.getDay();
    if (day == 0) {
      weekendDays++;
    }
    console.log("1", start);
    start = new Date(+start + dayMilliseconds);
    console.log("2", start);
  }
  return weekendDays;
}

function sendEmail(
  name,
  email,
  leaveMsg,
  reason,
  fromDate,
  toDate,
  Department,
  HrStatus,
  deptHeadStatus,
  departmentmail,
) {
  const commonMsg =
    "Dear sir/Ma'am" +
    "," +
    "<br/><br/>" +
    `<p>${name}, ${leaveMsg}</p>
    <br/>
    <p> Details are as follow ::
    <br></br>` +
    `<table style={{"width:100%",border : "1px solid black"}}>
    <tr>
      <th>Name</th>
      <th>Reason</th>
      <th>From</th>
      <th>To</th>
      <th>Department</th>
      <th>HR Status</th>
      <th>Dept. Head Status</th>
    </tr>
    <tr>
      <td>${name}</td>
      <td>${reason}</td>
      <td>${fromDate}</td>
      <td>${toDate}</td>
      <td>${Department}</td>
      <td>${HrStatus}</td>
      <td>${deptHeadStatus}</td>
      </tr>
      </table>
    <br/>
    <p>Please do not reply this mail. It's system generated mail.</p>
    <br/>
  <p>Have a wonderful day! </p>
  <br/>
  <p>Thank you,</p>
  <p>ShilpMIS Technologies Pvt. Ltd.</p>
  <br/>`;

  const adminMsg =
    "Dear sir/Ma'am" +
    "," +
    "<br/><br/>" +
    `<p>${name}, ${leaveMsg}</p>
    <br/>
    <p> Details are as follow ::
    <br></br>` +
    `<table style={{"width:100%",border : "1px solid black"}}>
    <tr>
      <th>Name</th>
      <th>Reason</th>
      <th>From</th>
      <th>To</th>
      <th>Department</th>
      <th>HR Status</th>
      <th>Dept. Head Status</th>
    </tr>
    <tr>
      <td>${name}</td>
      <td>${reason}</td>
      <td>${fromDate}</td>
      <td>${toDate}</td>
      <td>${Department}</td>
      <td>${HrStatus}</td>
      <td>${deptHeadStatus}</td>
      </tr>
      </table>
      <br/>
    <br/>
    <a href="https://dashboard.melzo.com/approve-leaves">Click here to approve the leave...</a>
    <br/>


    <p>Please do not reply this mail. It's system generated mail.</p>
    <br/>
  <p>Have a wonderful day! </p>
  <br/>
  <p>Thank you,</p>
  <p>ShilpMIS Technologies Pvt. Ltd.</p>
  <br/>`;

  var obj = {
    useremail: email, // User Mail Id
    message: commonMsg,
    expoName: "Dashboard",
    senderName: "Melzo Dashboard",
    ccUser: "bhavik1363@gmail.com", // HR Mail Id + Dept Head Mail Id
    bccUser: "",
    subject: "Apply for leave !",
  };

  var adminObj = {
    useremail: "operations@shilpmis.com",departmentmail, // User Mail Id
    message: adminMsg,
    expoName: "Dashboard",
    senderName: "Melzo Dashboard",
    bccUser: "",
    subject: "Apply for leave !",
  };

  axios
    .post(
      "https://us-central1-sendtoemail-3610d.cloudfunctions.net/sendCompanyEmail",
      obj,
      {
        headers: {
          // 'x-access-token': token
        },
      }
    )
    .then((regEmail) => {
      console.log("Done", regEmail);
      axios
    .post(
      "https://us-central1-sendtoemail-3610d.cloudfunctions.net/sendCompanyEmail",
      adminObj,
      {
        headers: {
          // 'x-access-token': token
        },
      }
    ).then((adminEmail) => {
      console.log("Done", adminEmail);
    }).catch(adminErr => {
      console.log("Not Done", adminErr);
    })
    })
    .catch((error) => {
      console.log("Not Done", error);
    });
}
