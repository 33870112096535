import React from "react";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import ReactDataGrid from "react-data-grid";
import { db } from "../../../Firebase";

const columns = [
    { key: "email", name: "Employee", editable: true },
    { key: "taskDate", name: "Assigned Date", editable: true },
    { key: "projectName", name: "Project Name", editable: true },
    { key: "taskTitle", name: "Task", editable: true },
    { key: "taskDetail", name: "About Task", editable: true },
    { key: "remarks", name: "Remarks", editable: true },
    { key: "status", name: "Status of Task", editable: true },
    { key: "completionDate", name: "Completion Date", editable: true },
    { key: "approved", name: "Task Approval", editable: true },
];

export default class TaskTable extends React.Component {
    constructor() {
        super();

        this.state = {
            taskData: [],
        }
    }

    componentDidMount() {
        this.onGetTaskData();
    }

    onGetTaskData() {
        let taskDataPromise = new Promise((resolve, reject) => {
            db.ref("/task/").on("value", (queryTask) => {
                let taskArray = [];
                queryTask.forEach(element => {
                    let object = element.val();
                    object["id"] = element.key;
                    taskArray.push(object);
                });
                resolve(taskArray);
            })
        });
        taskDataPromise.then(acceptedData => {
            this.setState({
                taskData: acceptedData,
            })
        })
    }


    onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        // console.log("From row ", fromRow, "To row", toRow, "Updated", updated);
        // let state = this.state.employeeData;
        // this.setState(employeeData => {
        const rows = this.state.taskData.slice();
        for (let i = fromRow; i <= toRow; i++) {
            rows[i] = { ...rows[i], ...updated };
        }
        // console.log("Rows", rows)
        this.setState({ taskData: rows })
        // return { rows };
        // });
    };

    onSaveUpdatedData = () => {
        for(let i = 0; i< this.state.taskData.length; i++) {
            db.ref("/task/" + this.state.taskData[i].id).update({
                projectName: this.state.taskData[i].projectName,
                email: this.state.taskData[i].email,
                taskDate: this.state.taskData[i].taskDate,
                status: this.state.taskData[i].status,
                taskTitle: this.state.taskData[i].taskTitle,
                taskDetail: this.state.taskData[i].taskDetail,
                remarks: this.state.taskData[i].remarks,
            })
        }
    }

    render() {
        return (
            <div>
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>

                            <div className="page-title">
                                <div className="float-left">
                                    <h1 className="title">Task Table</h1>
                                </div>
                            </div>



                            <div className="col-12">
                                <section className="box ">
                                    <header className="panel_header">
                                        <h2 className="title float-left">Data Table</h2>

                                    </header>
                                    <div className="content-body">
                                        <div className="row">

                                            <div className="col-lg-12">
                                                <button className="btn btn-primary btn-sm profile-btn" style={{ marginBottom: "15px" }} onClick={this.onSaveUpdatedData}>Save</button>
                                            </div>
                                            <div className="col-lg-12">

                                                <ReactDataGrid
                                                    //minHeight={this.state.taskData.length * 25 + 50}
                                                    rowHeight={50}
                                                    columns={columns}
                                                    rowGetter={i => this.state.taskData[i]}
                                                    rowsCount={50}
                                                    onGridRowsUpdated={this.onGridRowsUpdated}
                                                    enableCellSelect={true}
                                                />




                                            </div>
                                        </div>


                                    </div>
                                </section>
                            </div>

                        </Col>

                    </Row>
                </div>
            </div>



        );

    }
}