import React, { Component } from "react";
import { db } from "../../Firebase";
// import { DashboardLayout } from "../layout/Layout";
import { Form, Row, Col, FormGroup, Table } from "react-bootstrap";
import Select from "@material-ui/core/Select";
import {
  TextField,
  Grid,
  InputLabel,
  TableContainer,
  Paper,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "../../components/Leave/AddLeaveForm.css";
import { event } from "jquery";
import Popup from "../../components/Leave/DeletePopup";
import noDataImg from "../../assets/img/NoData.png";
import ConfirmDialog from "../Register/ConfirmDialog";

export default class AddLeave extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      employeeEmail: "",
      pendingLeave: "unapproved",
      casualLeave: 0.0,
      sickLeave: 0.0,
      marriageLeave: 0.0,
      privilegeLeave: 0.0,
      approvedLeave: 0.0,
      totalLeave: 0.0,
      leaveWithoutPay: 0.0,
      addLeaveData: [],
      allEmployeeData: [],
      reqestedLeaveData: [],
      tempArray: [],
      databaseId: "",
      confirmDialog: {
        isOpen: false,
        title: "",
        subTitle: "",
      },
    };
  }

  componentDidMount() {
    this.onGetEmployeeData();
    this.onGetRequestLeaveData();
    this.onGetLeaveData();
  }

  onGetEmployeeData = () => {
    let getEmployeeDataPromise = new Promise((resolve, reject) => {
      db.ref("/employees").on("value", (queryEmployee) => {
        let employeeData = [];
        queryEmployee.forEach((element) => {
          if (element.val().status == "active") {
            let obj = element.val();
            obj["id"] = element.key;
            employeeData.push(obj);
          }
        });
        resolve(employeeData);
      });
    });
    getEmployeeDataPromise.then((resolvedData) => {
      this.setState(
        {
          allEmployeeData: resolvedData,
        },
        () => console.log("employee data::", this.state.allEmployeeData)
      );
    });
  };

  onGetRequestLeaveData = () => {
    let requestLeavePromise = new Promise((accept, reject) => {
      db.ref("/requestedLeaves").on("value", (queryRequest) => {
        let leaveData = [];
        queryRequest.forEach((element) => {
          let obj = element.val();
          obj["id"] = element.key;
          leaveData.push(obj);
        });
        accept(leaveData);
      });
    });
    requestLeavePromise.then(
      (resolved) => {
        this.setState({
          reqestedLeaveData: resolved,
        });
      },
      () => console.log("Request Leave data::", this.state.reqestedLeaveData)
    );
  };

  handleChange = (event) => {
    let name = event.target.name;
    this.setState(
      {
        [event.target.name]: parseFloat(event.target.value).toFixed(1),
      },
      () => {
        if (
          name === "casualLeave" ||
          name === "sickLeave" ||
          name === "marriageLeave" ||
          name === "privilegeLeave"
        ) {
          let casualLeaveTemp = parseFloat(this.state.casualLeave);
          let sickLeaveTemp = parseFloat(this.state.sickLeave);
          let marriageLeaveTemp = parseFloat(this.state.marriageLeave);
          let privilegeLeaveTemp = parseFloat(this.state.privilegeLeave);
          this.setState({
            totalLeave: parseFloat(
              casualLeaveTemp +
                sickLeaveTemp +
                marriageLeaveTemp +
                privilegeLeaveTemp
            ).toFixed(1),
          });
        }
      }
    );
  };

  handleUserSelect = (user) => {
    let usermail = user.target.value;
    let data = this.state.addLeaveData.filter((userData) => {
      return userData.employeeEmail == usermail;
    });
    console.log(data);
    this.setState({
      employeeEmail: user.target.value,
      casualLeave: data[0]
        ? parseFloat(data[0].balanceLeave.casualLeave).toFixed(1)
        : parseFloat(0.0).toFixed(1),
      sickLeave: data[0]
        ? parseFloat(data[0].balanceLeave.sickLeave).toFixed(1)
        : parseFloat(0.0).toFixed(1),
      marriageLeave: data[0]
        ? parseFloat(data[0].balanceLeave.marriageLeave).toFixed(1)
        : parseFloat(0.0).toFixed(1),
      privilegeLeave: data[0]
        ? parseFloat(data[0].balanceLeave.privilegeLeave).toFixed(1)
        : parseFloat(0.0).toFixed(1),
      totalLeave: data[0]
        ? parseFloat(
            parseFloat(data[0].balanceLeave.casualLeave) +
              parseFloat(data[0].balanceLeave.sickLeave) +
              parseFloat(data[0].balanceLeave.marriageLeave) +
              parseFloat(data[0].balanceLeave.privilegeLeave)
          ).toFixed(1)
        : parseFloat(0.0).toFixed(1),
      databaseId: data[0] ? data[0].id : "",
      leaveWithoutPay : data[0] ? parseFloat(data[0].leaveWithoutPay) : parseFloat("0.0")
    });
  };

  editData = (key) => {
    this.state.addLeaveData.map((dbValue) => {
      if (dbValue.id === key) {
        this.setState(
          {
            employeeEmail: dbValue.employeeEmail,
            // pendingLeave: dbValue.pendingLeave,
            // approvedLeave: dbValue.approvedLeave,
            casualLeave: dbValue
              ? parseFloat(dbValue.balanceLeave.casualLeave).toFixed(1)
              : parseFloat(0.0).toFixed(1),
            sickLeave: dbValue
              ? parseFloat(dbValue.balanceLeave.sickLeave).toFixed(1)
              : parseFloat(0.0).toFixed(1),
            marriageLeave: dbValue
              ? parseFloat(dbValue.balanceLeave.marriageLeave).toFixed(1)
              : parseFloat(0.0).toFixed(1),
            privilegeLeave: dbValue
              ? parseFloat(dbValue.balanceLeave.privilegeLeave).toFixed(1)
              : parseFloat(0.0).toFixed(1),
            totalLeave: dbValue
              ? parseFloat(
                  parseFloat(dbValue.balanceLeave.casualLeave) +
                    parseFloat(dbValue.balanceLeave.sickLeave) +
                    parseFloat(dbValue.balanceLeave.marriageLeave) +
                    parseFloat(dbValue.balanceLeave.privilegeLeave)
                ).toFixed(1)
              : parseFloat(0.0).toFixed(1),
            leaveWithoutPay: parseFloat(dbValue.leaveWithoutPay),
            databaseId: dbValue ? dbValue.id : "",
          }, 
          () => {
            document.body.scrollTop = 0;
          }
        );
      }
    });
  };

  onGetLeaveData = () => {
    let addLeaveDataPromise = new Promise((resolve, reject) => {
      let leaveArray = [];
      db.ref("/leaves/").on("value", (queryLeave) => {
        leaveArray = [];
        queryLeave.forEach((element) => {
          let obj = element.val();
          obj["id"] = element.key;
          leaveArray.push(obj);
        });
        resolve(leaveArray);
      });
    });
    addLeaveDataPromise.then((leaveResultData) => {
      this.setState(
        {
          addLeaveData: leaveResultData,
        },
        () => console.log("add leave data::", this.state.addLeaveData)
      );
    });
  };

  validateAddLeave = () => {
    let isReturn = false;

    this.state.addLeaveData.map((leaveSave) => {
      leaveSave.reqLeaveData.map((obj) => {
        if (obj.balanceLeave === "" || obj.balanceLeave === 0) {
          isReturn = true;
        }
      });
    });
    return isReturn;
  };

  handleSubmit = () => {
    const stack = this.state;
    console.log(
      "leave data state",
      stack.casualLeave,
      stack.sickLeave,
      stack.privilegeLeave,
      stack.marriageLeave,
      stack.totalLeave,
      stack.databaseId
    );
    new Promise((res, rej) => {
      if (stack.databaseId !== "") {
        db.ref("/leaves/" + stack.databaseId).set({
          employeeEmail: this.state.employeeEmail,
          pendingLeave: this.state.pendingLeave,
          balanceLeave: {
            casualLeave: parseFloat(this.state.casualLeave).toFixed(1),
            sickLeave: parseFloat(this.state.sickLeave).toFixed(1),
            marriageLeave: parseFloat(this.state.marriageLeave).toFixed(1),
            privilegeLeave: parseFloat(this.state.privilegeLeave).toFixed(1),
          },
          approvedLeave: parseFloat(this.state.approvedLeave),
          leaveWithoutPay: parseFloat(this.state.leaveWithoutPay),
          totalLeave: parseFloat(this.state.totalLeave).toFixed(1),
        });
        res("successfuly Updated");
      } else {
        db.ref("/leaves").push({
          employeeEmail: this.state.employeeEmail,
          pendingLeave: this.state.pendingLeave,
          balanceLeave: {
            casualLeave: parseFloat(this.state.casualLeave).toFixed(1),
            sickLeave: parseFloat(this.state.sickLeave).toFixed(1),
            marriageLeave: parseFloat(this.state.marriageLeave).toFixed(1),
            privilegeLeave: parseFloat(this.state.privilegeLeave).toFixed(1),
          },
          approvedLeave: parseFloat(this.state.approvedLeave),
          leaveWithoutPay: parseFloat(this.state.leaveWithoutPay),
          totalLeave: parseFloat(this.state.totalLeave).toFixed(1),
        });
        res("successfuly Added");
      }
    }).then((res) => {
      this.clearData();
      this.onGetLeaveData();
    });
  };

  handleleaveAnnually = () => {
    this.state.addLeaveData.map((userLeaveId) => {
      //   console.log("userLeaveId", userLeaveId);
      //   if (userLeaveId.employeeEmail == "abc@shilpmis.com") {
      db.ref("leaves")
        .child(userLeaveId.id)
        .child("balanceLeave")
        .transaction((leave) => {
          // console.log(leave, `leave count for ${categoryOfLeave}`)
          //   console.log(leave.casualLeave, "leave count ca")
          console.log("inside leave ::", leave);
          leave.casualLeave = parseFloat(leave.casualLeave) + parseFloat("7");
          //   console.log(leave.marriageLeave, "leave count ma")
          leave.marriageLeave =
            parseFloat(leave.marriageLeave) + parseFloat("5");
          //   console.log(leave.privilegeLeave, "leave count pr")
          leave.privilegeLeave =
            parseFloat(leave.privilegeLeave) + parseFloat("18");
          //   console.log(leave.sickLeave, "leave count si")
          leave.sickLeave = parseFloat(leave.sickLeave) + parseFloat("7");
          return leave;
        });
      //   }
    });
    this.onGetLeaveData();
    this.setState({
      confirmDialog: {
        isOpen: false,
        title: "",
        subTitle: "",
      },
    });
  };

  updateLeave = (empId, perallData) => {
    this.setState((prevState) => ({
      addLeaveData: this.state.addLeaveData.map((emp) =>
        emp.id == empId ? { ...emp, requestData: perallData } : emp
      ),
    }));
  };

  deleteData = (key) => {
    console.log("key",key)
    if (this.state.id === "") {
      return;
    } else {
      this.setState({ showPopup: !this.state.showPopup });
      db.ref("/leaves/" + key).remove();
      // this.clearData();  
    }
  };

  showPopup = (key) => {
    this.setState(
      {
        showPopup: !this.state.showPopup,
        id: key,
      },
      () => {
        if (this.state.showPopup === false) {
          this.clearInput();
        }
      }
    );
  };

  countLeaves = () => {
    if (this.state.balanceLeave["casualLeave"] !== "") {
      let finalCasualLeave = this.state.casualLeave;
    }
  };

  clearData = () => {
    this.setState({
      employeeEmail: "",
      pendingLeave: "unapproved",
      balanceLeave: 0.0,
      approvedLeave: 0.0,
      totalLeave: 0.0,
      leaveWithoutPay: 0.0,
    });
  };

  setConfirmDialog = (newStatus) => {
    this.setState((prevState) => ({
      ...prevState,
      confirmDialog: {
        isOpen: false,
        title: "",
        subTitle: "",
      },
    }));
  };

  render() {
    return (
      <div>
        {/* <div className="paddingcontainer"> */}
        <div className="content">
          <div>
            <h4 className="header inrowtitle" style={{ marginTop: "15px" }}>
              Leave Register
            </h4>
            <Row>
              <Col>
                <FormGroup>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Employee Name
                    </InputLabel>
                    <Select
                      as="select"
                      id="employeeEmail"
                      name="employeeEmail"
                      value={this.state.employeeEmail}
                      onChange={this.handleUserSelect}
                    >
                      {this.state.allEmployeeData.map((obj) => {
                        return (
                          <MenuItem value={obj.email}>
                            {obj.firstName} - {obj.email}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>

            {this.state.employeeEmail !== "" && (
              <FormGroup>
                <div className="paddingleave" ref={this.myRef}>
                  <div className="box">
                    <label className="labelperformance">Casual Leave</label>
                    <hr className="hrline"></hr>
                    <div className="paddingperformance">
                      <TextField
                        type="number"
                        name="casualLeave"
                        value={this.state.casualLeave}
                        onChange={(e) => this.handleChange(e)}
                        style={{ width: "100%" }}
                        color="primary"
                        inputProps={{ step: "0.5" }}
                      ></TextField>
                    </div>
                  </div>

                  <div className="box">
                    <label className="labelperformance">Sick Leave</label>
                    <hr className="hrline"></hr>
                    <div className="paddingperformance">
                      <TextField
                        type="number"
                        name="sickLeave"
                        value={this.state.sickLeave}
                        onChange={this.handleChange}
                        style={{ width: "100%" }}
                        color="primary"
                        inputProps={{ step: "0.5" }}
                      ></TextField>
                    </div>
                  </div>

                  <div className="box">
                    <label className="labelperformance">Marriage Leave</label>
                    <hr className="hrline"></hr>
                    <div className="paddingperformance">
                      <TextField
                        type="number"
                        name="marriageLeave"
                        value={this.state.marriageLeave}
                        onChange={this.handleChange}
                        style={{ width: "100%" }}
                        color="primary"
                        inputProps={{ step: "0.5" }}
                        ></TextField>
                    </div>
                  </div>

                  <div className="box">
                    <label className="labelperformance">Privilege Leave</label>
                    <hr className="hrline"></hr>
                    <div className="paddingperformance">
                      <TextField
                        type="number"
                        name="privilegeLeave"
                        value={this.state.privilegeLeave}
                        onChange={this.handleChange}
                        style={{ width: "100%" }}
                        color="primary"
                        inputProps={{ step: "0.5" }}
                        ></TextField>
                    </div>
                  </div>

                  <div className="box">
                    <label className="labelperformance">Total Leave</label>
                    <hr className="hrline"></hr>
                    <div className="paddingperformance">
                      <TextField
                        type="number"
                        name="totalLeave"
                        value={this.state.totalLeave}
                        style={{ width: "100%" }}
                        color="primary"
                      ></TextField>
                    </div>
                  </div>

                  <div className="box">
                    <label className="labelperformance" value="privilegeLeave">
                      Leave Without Pay
                    </label>
                    <hr className="hrline"></hr>
                    <div className="paddingperformance">
                      <TextField
                        type="number"
                        name="leaveWithoutPay"
                        value={this.state.leaveWithoutPay}
                        onChange={this.handleChange}
                        style={{ width: "100%" }}
                        color="primary"
                        inputProps={{ step: "0.5" }}
                      ></TextField>
                    </div>
                  </div>
                </div>
              </FormGroup>
            )}

            <div className="flexbtn">
              <Button
                id="submit"
                variant="contained"
                color="primary"
                className="marginrightbtn"
                onClick={this.handleSubmit}
              >
                Submit
              </Button>
              <Button
                id="cancel"
                variant="contained"
                color="primary"
                className="marginrightbtn"
                onClick={this.clearData}
              >
                Cancel
              </Button>
              <Button
                id="leave"
                variant="contained"
                color="primary"
                className="marginrightbtn"
                onClick={() =>
                  this.setState({
                    confirmDialog: {
                      isOpen: true,
                      title:
                        "Are you sure you want to add leave quota to all employee.!!",
                      subTitle: "Once proceed, you can't revert alloted leave",
                      onConfirm: () => {
                        this.handleleaveAnnually();
                      },
                    },
                  })
                }
              >
                Add Leave Annually
              </Button>
            </div>

            <TableContainer component={Paper}>
              {this.state.addLeaveData.length == 0 && (
                <img
                  src={noDataImg}
                  style={{ height: "400px", margin: "auto", display: "flex" }}
                ></img>
              )}
              {this.state.addLeaveData.length > 0 && (
                <Table aria-label="simple table">
                  <thead>
                    <tr>
                      <th>Employee Name</th>
                      <th>Casual Leave</th>
                      <th>Sick Leave</th>
                      <th>Marriage Leave</th>
                      <th>Privilege Leave</th>
                      <th>Balanced Leave</th>
                      <th>Edit Leave</th>
                      {/* <th>Delete Leave</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.addLeaveData.map((dbValue) => {
                      return (
                        <tr key={dbValue.id}>
                          <td>{dbValue.employeeEmail}</td>
                          <td>
                            {parseFloat(
                              dbValue.balanceLeave.casualLeave
                            ).toFixed(1)}
                          </td>
                          <td>
                            {parseFloat(dbValue.balanceLeave.sickLeave).toFixed(
                              1
                            )}
                          </td>
                          <td>
                            {parseFloat(
                              dbValue.balanceLeave.marriageLeave
                            ).toFixed(1)}
                          </td>
                          <td>
                            {parseFloat(
                              dbValue.balanceLeave.privilegeLeave
                            ).toFixed(1)}
                          </td>
                          {/* <td>{dbValue.pendingLeave}</td> */}
                          <td>
                            {parseFloat(
                              parseFloat(dbValue.balanceLeave.casualLeave) +
                                parseFloat(dbValue.balanceLeave.sickLeave) +
                                parseFloat(dbValue.balanceLeave.marriageLeave) +
                                parseFloat(dbValue.balanceLeave.privilegeLeave)
                            ).toFixed(1)}
                          </td>
                          <td>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              onClick={() => {
                                this.editData(dbValue.id);
                              }}
                            >
                              Edit
                            </Button>
                          </td>
                          {/* <td>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              onClick={() => {
                                this.deleteData(dbValue.id);
                              }}
                            >
                              Delete
                            </Button>
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </TableContainer>
            <ConfirmDialog
              confirmDialog={this.state.confirmDialog}
              setConfirmDialog={this.setConfirmDialog}
            />
          </div>
        </div>
      </div>
    );
  }
}
