import React from 'react';
import Button from '@material-ui/core/Button';
import "../Leave/DepartmentMaster.css";

class Popup extends React.Component {
    render() {
        return (
            <div className='popup'>
                <div className='popup_inner'>

                    <div style={{ marginBottom: "30px" }}>
                        <h1 style={{ marginTop:"5px",marginBottom:"5px",fontSize: "16px" }}>Are you sure you want to delete this item {this.props.text} ?</h1>
                    </div>

                    <div className="flexbtn">
                        <Button variant="contained" color="secondary" type="submit" className="marginrightbtn" onClick={this.props.okPopup}>Delete</Button>
                        <Button variant="contained" color="primary" type="submit" className="marginrightbtn" onClick={this.props.closePopup}>Cancel</Button>
                    </div>
                </div>
            </div>
        );
    }
}


export default Popup;