/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import "../../../assets/css/PerformanceForm.css";
import TextField from '@material-ui/core/TextField';

export default class PerformanceForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            performanceNameDa: this.props.performanceData
        }
    }

    marksChange = (event) => {
        let tmpData = {};
        let AllData = {};
        AllData = this.props.empFormData.performance;

        this.props.empFormData.performance.map((dbValue, index) => {
            tmpData = Object.assign({}, dbValue);

            if (tmpData.performanceName + this.props.empFormData.id == [event.target.name]) {
                tmpData.marks = event.target.value;
                AllData[index] = tmpData;
            }
        })
        this.props.updateMarks(this.props.empFormData.id, AllData);
    }


    render() {
        console.log(this.props.empFormData.firstName)
        return (
            <div className="beatbox">
                <div>
                    <label className="labelperformance">{this.props.empFormData.firstName}</label>
                </div>
                <hr className="hrline"></hr>
                {this.props.empFormData.performance.map((dbValue) =>
                    // <div className="row">
                    //     <div className="col-md-6 col-sm-12 col-lg-3">
                    <div className="paddingperformance">

                        <TextField 
                        type="number" 
                        label={dbValue.performanceName} 
                        onKeyPress={this.numberValidation} 
                        onChange={this.marksChange} 
                        value={dbValue.marks} 
                        name={dbValue.performanceName + this.props.empFormData.id} 
                        color="primary" 
                        style={{ width: '100%' }} />
                        {/* <Form.Text className="text-muted">
                                    {dbValue.performanceName}
                                </Form.Text>
                                <Form.Control
                                    onKeyPress={this.numberValidation}
                                    type="text"
                                    onChange={this.marksChange}
                                    name={dbValue.performanceName + this.props.empFormData.id}
                                    value={dbValue.marks} /> */}
                        {/* //     </div> */}
                    </div>
                )
                }
            </div>
        )
    }
}
