import React from 'react';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { db } from '../../../Firebase';

export default class Sales extends React.Component {
    constructor() {
        super();

        this.state = {
            date: "",
            dealName: "",
            dealValue: "",
            dealDoneBy: "",
        };
    }

    handleChange = (event) => {
        this.setState({
          [event.target.name]: event.target.value,
        });
      };

      submitSales = () => {
        if (this.validateSales()) {
          db.ref("/sales").push(
            {
              email: localStorage.getItem("email"),
              date: this.state.date,
              dealName: this.state.dealName,
              dealValue: this.state.dealValue,
              dealDoneBy: this.state.dealDoneBy,
            },    
    
            () => {
              // console.log("data sent");
              // alert("data sent successfully");
              this.clearSales();
            });
        }    
      };
    
      clearSales = () => {
        this.setState({
          date: "",
          dealName: "",
          dealValue: "",
          dealDoneBy: "",
        });
      }
    
      validateSales = () => {
        if (this.state.date === "") {
          alert("Please select date");
          return false;
        } else if (this.state.dealName === "") {
          alert("Please add name of the deal");
          return false;
        } else if (this.state.dealValue === "") {
          alert("Please add value of the deal");
          return false;
        } else {
          return true;
        }
      }
    

    render() {
        return (
            <div>
                <div className="content">
                    <Row>
                        <Col>
                            <div className="page-title">
                                <div className="float-left">
                                    <h1 className="title">Sales</h1>
                                </div>
                            </div>

                            <div className="row margin-0">
                                <div className="col-12">
                                    <section className="box">
                                        <header className="panel_header">
                                            <h2 className="title float-left">Details</h2>
                                        </header>
                                        <div className="content-body">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-12 col-md-12">
                                                    <form>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="dealName">Deal Name</label>
                                                                <input type="text" name="dealName" id="dealName" className="form-control" placeholder="Deal Name" value={this.state.dealName} onChange={this.handleChange} />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="dealValue">Deal Value</label>
                                                                <input type="number" name="dealValue" id="dealValue" className="form-control" placeholder="Value" value={this.state.dealValue} onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="date">Deal Date</label>
                                                                <input type="date" name="date" id="date" className="form-control" placeholder="Deal Name" value={this.state.date} onChange={this.handleChange} />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="dealDoneBy">Deal Accquired by</label>
                                                                <input type="text" name="dealDoneBy" id="dealDoneBy" className="form-control" placeholder="Name" value={this.state.dealDoneBy} onChange={this.handleChange} />
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                            <button className="btn btn-primary" onClick={this.submitSales}>Submit</button>
                                            <button className="btn btn-secondary" onClick={this.clearSales}>Cancel</button>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}