import React, { useEffect, useState } from "react";
import { updateManagerApprovedInstance } from "../API`s/apis";

import { db, firebaseApp } from "../../../Firebase";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import "../styling/table.css";

import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
} from "@material-ui/core";

import ExpenseFormV2 from "../Dashboard/expenseFormV2";
import AlertDialog from "../strecture/alertDilog";

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    margin: theme.spacing(4),
    display: "flex",
    justifyContent: "CENTER",
  },
  table: {
    minWidth: 700,
  },
}));

const PendingViewOfTable = () => {
  const [dataForNormalView, setDataForPendingView] = useState({});
  const userMailId = localStorage.getItem("usersLoginEmail");
  const [selectedValue, setSelectedValue] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [approvalStatus, setApprovalStatus] = useState("");
  const [loader, setLoader] = React.useState("");
  const [userInfo, setUserInfo] = useState({ email: "", designation: "" });
  const [valueUpdated, setValueUpdate] = useState([]);
  const [skeleton, setSkeleton] = useState(false);
  const [showNoBills, setShowNoBills] = useState(false);

  const [indexOfObject, setIndexOfObject] = useState(true);
  const [closeView, setCloseView] = useState(false);

  let [showViewOnlyForm, setShowViewOnlyForm] = useState(false);
  const [alertDialog, setAlertDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    dual: false,
    result: false,
  });
  const [alertDialog2, setAlertDialog2] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    dual: false,
    result: false,
  });

  let update1;

  useEffect(() => {
    setSkeleton(true);
    let employeeData = JSON.parse(localStorage.getItem("employeeObject"));
    console.log("employee data", {
      email: employeeData.email,
      designation: employeeData.designation,
    });

    setUserInfo({
      ...userInfo,
      email: employeeData.email,
      designation: employeeData.designation,
    });

    let promiseAllArray = [];

    let requestedExpenseDataPromise = new Promise((resolve, reject) => {
      db.ref("ExpenseBill").on("value", (queryRequest) => {
        let requestLeaveArray = [];
        queryRequest.forEach((element) => {
          if (element.val().status !== "reimbursed") {
            promiseAllArray.push({ key: element.key, ...element.val() });
          }
        });
        resolve(promiseAllArray);
      });
    });

    requestedExpenseDataPromise.then((response) => {
      setSkeleton(false);
      const sortedData = promiseAllArray;
      sortedData.sort((a, b) => {
        return b.timeinMiliSecond - a.timeinMiliSecond;
      });
      console.log("unsorted data", promiseAllArray);

      console.log("sorted data", sortedData);
      if (sortedData.length == 0) {
        setShowNoBills(true);
      }

      setDataForPendingView(sortedData);
    });
    return () => {
      promiseAllArray = [];
    };
  }, [valueUpdated]);

  const uploaddataMGR = (index) => {
    // const update1 = {
    //   ...arrayItem,
    //   ["managerApproved"]: valueOfInstance,
    // };

    const data0 = dataForNormalView;
    console.log("dataForNormalView", dataForNormalView);
    console.log("data0", data0);

    const dataWithUpdatedCurrentObject = [
      ...data0.slice(0, index),
      update1,
      ...data0.slice(index + 1),
    ];
    console.log("data0.slice(0, index)", data0.slice(0, index));

    console.log("update1", update1);

    console.log("index", index);

    console.log("data0.slice(index )", data0.slice(index));
    console.log(
      "dataWithUpdatedCurrentObject==>",
      dataWithUpdatedCurrentObject
    );
    setDataForPendingView(dataWithUpdatedCurrentObject);
    setLoader("false");
  };

  const uploadInFirebase = () => {
    return new Promise((resolve, reject) => {
      const arrayItem = selectedItem;
      const idOfInstance = arrayItem.key;
      const valueOfInstance = selectedValue;
      console.log("arrayItem", arrayItem);
      switch (approvalStatus) {
        case "Mgr":
          update1 = {
            ...arrayItem,
            ["managerApproved"]: valueOfInstance,
          };

          updateManagerApprovedInstance(idOfInstance, update1)
            .then((response) => {
              if (
                arrayItem.hrApproved &&
                arrayItem.reimbursementApproved &&
                valueOfInstance
              ) {
                update1 = {
                  ...update1,
                  ["status"]: "reimbursed",
                };

                updateManagerApprovedInstance(idOfInstance, update1);
              }
              if (arrayItem.hrApproved && valueOfInstance) {
                update1 = {
                  ...update1,
                  ["status"]: "Approved",
                };

                updateManagerApprovedInstance(idOfInstance, update1);
              }

              uploaddataMGR(indexOfObject);
              resolve();
              setAlertDialog({
                ...alertDialog,
                isOpen: false,
                result: true,
                dual: false,
              });
              setValueUpdate([..."I am "]);
            })
            .catch((error) => {
              alert(error);
              reject();
            });
          break;

        case "HrAdmin":
          let update2 = {
            ...arrayItem,
            ["hrApproved"]: valueOfInstance,
          };
          console.log("update2", update2);

          if (arrayItem.managerApproved) {
            updateManagerApprovedInstance(idOfInstance, update2)
              .then((response) => {
                console.log("MR updatation done");

                if (arrayItem.managerApproved && valueOfInstance) {
                  update2 = {
                    ...update2,
                    ["status"]: "Approved",
                  };
                  updateManagerApprovedInstance(idOfInstance, update2);
                }

                if (
                  arrayItem.managerApproved &&
                  arrayItem.reimbursementApproved &&
                  valueOfInstance
                ) {
                  update2 = {
                    ...update2,
                    ["status"]: "reimbursed",
                  };
                  updateManagerApprovedInstance(idOfInstance, update2);
                }
                const uploaddataHR = (update2) => {
                  const data0 = dataForNormalView;

                  const dataWithUpdatedCurrentObject = [
                    ...data0.slice(0, indexOfObject),
                    update2,
                    ...data0.slice(indexOfObject + 1),
                  ];

                  setDataForPendingView([...dataWithUpdatedCurrentObject]);

                  setLoader("false");
                };
                uploaddataHR(update2);
                resolve();
                setAlertDialog({
                  ...alertDialog,
                  isOpen: false,
                  result: true,
                  dual: false,
                });
                setValueUpdate([..."I am "]);
              })
              .catch((error) => {
                alert(error);
                reject();
              });
          } else {
            setAlertDialog({
              ...alertDialog,
              isOpen: false,
              title: "Confirmation !",
              subTitle: "want to update the  value",
              dual: false,
              result: false,
            });

            setAlertDialog2({
              ...alertDialog2,
              isOpen: true,
              title: "Alert",
              subTitle: "The manager has not approved the Bill!",
              dual: false,
              result: false,
            });
          }

          break;

        case "Reimb":
          let update3 = {
            ...arrayItem,
            ["reimbursementApproved"]: valueOfInstance,
          };
          if (arrayItem.hrApproved && arrayItem.managerApproved) {
            updateManagerApprovedInstance(idOfInstance, update3)
              .then((response) => {
                console.log("reimb done");

                if (
                  arrayItem.managerApproved &&
                  arrayItem.hrApproved &&
                  valueOfInstance
                ) {
                  update3 = {
                    ...arrayItem,
                    ["status"]: "reimbursed",
                  };
                  updateManagerApprovedInstance(idOfInstance, update3);
                }

                const uploaddataRemim = () => {
                  // const update1 = {
                  //   ...arrayItem,
                  //   ["reimbursementApproved"]: valueOfInstance,
                  // };
                  const data0 = dataForNormalView;
                  // console.log("dataForNormalView state value", dataForNormalView);

                  const dataWithUpdatedCurrentObject = [
                    ...data0.slice(0, indexOfObject),
                    update3,
                    ...data0.slice(indexOfObject + 1),
                  ];

                  setDataForPendingView([...dataWithUpdatedCurrentObject]);
                  setLoader("false");
                  setAlertDialog({
                    ...alertDialog,
                    isOpen: false,
                    result: true,
                    dual: false,
                  });
                  console.log("jjjj");
                };

                uploaddataRemim();
                resolve();
                setValueUpdate([..."I am "]);
              })
              .catch((error) => {
                alert(error);
                reject();
              });
          } else {
            setAlertDialog({
              ...alertDialog,
              isOpen: false,
              title: "Confirmation !",
              subTitle: "want to update the  value",
              dual: false,
              result: false,
            });

            setAlertDialog2({
              ...alertDialog2,
              isOpen: true,
              title: "Alert",
              subTitle: "The manager or HR has not approved the Bill!",
              dual: false,
              result: false,
            });
          }

          break;

        default:
          break;
      }

      console.log("firebase value of current");
    });
  };

  const setAlertDialogfunc = async () => {
    uploadInFirebase()
      .then(() => {
        setAlertDialog({
          ...alertDialog,
          isOpen: false,
          result: true,
          dual: false,
        });
        console.log("jjjj", { ...alertDialog });
      })
      .catch(() => {
        setAlertDialog({
          ...alertDialog,
          isOpen: false,
          result: true,
          dual: false,
        });
        console.log("jjjj");
      });
  };

  const setAlertDialogfunc2 = () => {
    setAlertDialog2({
      ...alertDialog2,
      isOpen: false,
      result: true,
      dual: false,
    });
    console.log("jjjj");
  };

  const setAlertDialogfunc11 = () => {
    setAlertDialog({
      ...alertDialog,
      isOpen: false,
      result: true,
      dual: false,
    });
    console.log("jjjj");
  };

  const onViewFormHandler = (idOfBill) => {
    console.log("VIEWBUTTON click");
    localStorage.setItem("readOnlyFormID", idOfBill);

    setCloseView(!closeView);
    setShowViewOnlyForm(true);
  };
  const classes = useStyles();
  return (
    <>
      <div className="tableView" style={{ height: "70vh" }}>
        {/* <Toolbar /> */}
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="right" style={{ width: "10%" }}>
                  Name
                </TableCell>
                <TableCell align="right" style={{ width: "10%" }}>
                  Title
                </TableCell>
                <TableCell align="right" style={{ width: "10%" }}>
                  Location
                </TableCell>
                <TableCell align="right" style={{ width: "10%" }}>
                  Date of Added
                </TableCell>
                <TableCell align="right" style={{ width: "10%" }}>
                  From
                </TableCell>
                <TableCell align="right" style={{ width: "10%" }}>
                  Till
                </TableCell>
                <TableCell align="right" style={{ width: "10%" }}>
                  Amount
                </TableCell>
                <TableCell align="right" style={{ width: "10%" }}>
                  Status
                </TableCell>
                <TableCell style={{ width: "12%" }} align="right">
                  Approval
                  <TableCell align="right" style={{ width: "10%" }}>
                    Manager
                  </TableCell>
                  <TableCell align="right" style={{ width: "10%" }}>
                    HR
                  </TableCell>
                  <TableCell align="right" style={{ width: "10%" }}>
                    Reimbursed
                  </TableCell>
                </TableCell>
                <TableCell align="right" style={{ width: "10%" }}>
                  View
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataForNormalView && dataForNormalView.length > 0
                ? dataForNormalView.map((arrayItem, index) => {
                    return (
                      <TableRow
                        key={arrayItem.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ width: "10%" }}
                        >
                          {arrayItem.name}
                        </TableCell>
                        <TableCell align="center" style={{ width: "10%" }}>
                          {arrayItem.expanceCategory}
                        </TableCell>
                        <TableCell align="center" style={{ width: "10%" }}>
                          {arrayItem.location}
                        </TableCell>
                        <TableCell align="center" style={{ width: "10%" }}>
                          {arrayItem.currentDate}
                        </TableCell>
                        <TableCell align="center" style={{ width: "10%" }}>
                          {arrayItem.selectDate}
                        </TableCell>
                        <TableCell align="center" style={{ width: "10%" }}>
                          {arrayItem.selectDateTill}
                        </TableCell>
                        <TableCell align="center" style={{ width: "10%" }}>
                          {arrayItem.amount}
                        </TableCell>
                        <TableCell align="center" style={{ width: "10%" }}>
                          {arrayItem.status}
                        </TableCell>
                        <TableCell align="center" style={{ width: "12%" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "10%",
                            }}
                            className="approval"
                          >
                            <FormControlLabel
                              disabled={
                                userInfo.designation == "Human Resource"
                                  ? false
                                  : true
                              }
                              control={
                                <Checkbox
                                  checked={arrayItem.managerApproved}
                                  onChange={(event) => {
                                    setAlertDialog({
                                      ...alertDialog,
                                      isOpen: true,
                                      title: "Alert",
                                      subTitle: "want to update the  value",
                                      dual: true,
                                      result: true,
                                    });

                                    setSelectedItem(arrayItem);
                                    setIndexOfObject(index);
                                    setSelectedValue(event.target.checked);
                                    console.log(
                                      "event.target.checked",
                                      event.target.checked
                                    );
                                    setApprovalStatus("Mgr");
                                    console.log(
                                      "index in onclick",
                                      index,
                                      arrayItem
                                    );
                                  }}
                                />
                              }
                              // label="Manager"
                            />

                            <FormControlLabel
                              disabled={
                                userInfo.designation == "Human Resource"
                                  ? false
                                  : true
                              }
                              control={
                                <Checkbox
                                  checked={arrayItem.hrApproved}
                                  onChange={(event) => {
                                    console.log("index in onclick", index);
                                    setSelectedValue(event.target.checked);
                                    setSelectedItem(arrayItem);

                                    setIndexOfObject(index);
                                    setApprovalStatus("HrAdmin");

                                    setAlertDialog({
                                      ...alertDialog,
                                      isOpen: true,
                                      title: "Confirmation !",
                                      subTitle: "want to update the  value",
                                      dual: true,
                                      result: false,
                                    });
                                  }}
                                />
                              }
                              // label="HR"
                            />

                            <FormControlLabel
                              disabled={
                                userInfo.designation == "Human Resource"
                                  ? false
                                  : true
                              }
                              control={
                                <Checkbox
                                  checked={arrayItem.reimbursementApproved}
                                  onChange={(event) => {
                                    setAlertDialog({
                                      ...alertDialog,
                                      isOpen: true,
                                      title: "Alert",
                                      subTitle: "want to update the  value",
                                      dual: true,
                                      result: false,
                                    });

                                    console.log("index in onclick", index);
                                    setSelectedValue(event.target.checked);
                                    setSelectedItem(arrayItem);
                                    setIndexOfObject(index);
                                    setApprovalStatus("Reimb");
                                  }}
                                />
                              }
                              // label="Reimbursed"
                            />
                          </div>
                        </TableCell>
                        <TableCell align="center" style={{ width: "10%" }}>
                          <Button
                            variant="outlined"
                            onClick={() => onViewFormHandler(arrayItem.key)}
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                : ""}

              {skeleton &&
                (() => {
                  const arr = [];
                  for (let i = 0; i < 5; i++) {
                    arr.push(
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ width: "10%" }}
                        >
                          {console.log("Table form")}
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center" style={{ width: "12%" }}>
                          {" "}
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center" style={{ width: "10%" }}>
                          {" "}
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center" style={{ width: "10%" }}>
                          {" "}
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center" style={{ width: "10%" }}>
                          {" "}
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center" style={{ width: "10%" }}>
                          {" "}
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center" style={{ width: "10%" }}>
                          {" "}
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center" style={{ width: "10%" }}>
                          {" "}
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center" style={{ width: "10%" }}>
                          {" "}
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center" style={{ width: "10%" }}>
                          {" "}
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return arr;
                })()}
            </TableBody>
          </Table>
        </TableContainer>

        {showNoBills && <div className={classes.root}>{"No Bills !"}</div>}

        {showViewOnlyForm && (
          <ExpenseFormV2
            viewOnly={true}
            formCloseFunction={setShowViewOnlyForm}
          />
        )}
        <AlertDialog
          alertDialog={alertDialog}
          setAlertDialog={setAlertDialogfunc}
          closeAlertDilog={setAlertDialogfunc11}
        />
        <AlertDialog
          alertDialog={alertDialog2}
          setAlertDialog={setAlertDialogfunc2}
        />
      </div>
    </>
  );
};

export default PendingViewOfTable;
