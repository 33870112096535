import { event } from "jquery";
import React, { Component } from "react";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import { db } from "../../../Firebase";

export default class PerformanceMaster extends React.Component {
    constructor() {
        super();
        this.state = {
            head: '',
            performanceName: '',
            departmentName: '',
            deptData: [],

            performanceData: [],
            userId: '',
            id: '',
            filtered: [],
            demoData: []
        };
    }

    componentDidMount() {
        this.onGetDepartmentData();
    }

    onGetDepartmentData() {
        let departmentDataPromise = new Promise((resolve, reject) => {
            db.ref("/departmentMaster/").on("value", (querySnap) => {
                let deptArray = [];
                querySnap.forEach((deptData) => {
                    let data = deptData.val();
                    deptArray.push(data);
                });
                resolve(deptArray);
            });
        })
        departmentDataPromise.then(acceptedData => {
            this.setState({
                deptData: acceptedData,
            }, () => console.log("dept data::", this.state.deptData));
        })
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    deptChange = (event) => {
        this.setState({
            departmentName: event.target.value,
        })
    }

    saveData = (e) => {
        let today = new Date().getTime();

        db.ref('/performanceMst').push({
            department: this.state.departmentName,
            head: "harsh@shilpmis.com",
            performanceName: this.state.performanceName,
            entryData: today,
            entryBy: localStorage.getItem('email'),
            updateDate: '',
            updateBy: ''
        }, () => this.clearValue());
    }

    clearValue = () => {
        this.setState({
            head: "",
            performanceName: "",
            departmentName: "",
            id: ""
        });
    }

    render() {
        return (
            <div>
                <div className="content">
                    <Row>
                        <Col>
                            <div className="page-title">
                                <div className="float-left">
                                    <h1 className="title">Performance Master</h1>
                                </div>
                            </div>
                            <div className="row margin-0">
                                <div className="col-12">
                                    <section className="box">
                                        <header className="panel_header">
                                            <h2 className="title float-left">About</h2>
                                        </header>
                                        <div className="content-body">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-12 col-md-12">
                                                    <form>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="department">Department</label>
                                                                <select id="department" className="form-control" name="departmentName" value={this.state.departmentName} onChange={this.deptChange}>
                                                                    <option>Choose..</option>
                                                                    {this.state.deptData.map((obj) => {
                                                                        return (<option value={obj.departmentName}>{obj.departmentName}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="performanceName">Performance Name</label>
                                                                <input type="text" className="form-control" id="performanceName" name="performanceName" value={this.state.performanceName} onChange={this.handleChange} ></input>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-12 col-md-12">
                                                    <button type="submit" className="btn btn-primary" onClick={this.saveData} >Submit</button>
                                                    <button type="submit" className="btn btn-secondary" onClick={this.clearValue} >Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}