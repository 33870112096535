import React from "react";
import ReactDataGrid from "react-data-grid";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import { db } from "../../../Firebase";

const columns = [
    { key: "projectName", name: "Project Name", editable: true },
    { key: "projectClient", name: "Project Company", editable: true },
    { key: "projectDescription", name: "Project Description", editable: true },
    { key: "projectStatus", name: "Project ID", editable: true },
    { key: "assignedDate", name: "Assigned Date", editable: true },
    { key: "endDate", name: "End Date", editable: true },
    { key: "employeeInvolved", name: "Employees", editable: true },
];

export default class ProjectTable extends React.Component {
    constructor() {
        super();

        this.state = {
            projectArray: []
        }
    }

    componentDidMount() {
        this.onGetProjectData();
    }

    onGetProjectData() {
        let projectDataPromise = new Promise((resolve, reject) => {
            db.ref("/projectMaster/")
                .on("value", (queryProject) => {
                    let projectData = [];
                    queryProject.forEach((element) => {
                        let object = element.val();
                        object["id"] = element.key;
                        projectData.push(object);
                    });
                    resolve(projectData);
                })
        })
        projectDataPromise.then(acceptedData => {
            this.setState({
                projectArray: acceptedData,
            }, () => console.log("all project data::", this.state.projectArray));
        })
    }

    onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        // console.log("From row ", fromRow, "To row", toRow, "Updated", updated);
        // let state = this.state.employeeData;
        // this.setState(employeeData => {
        const rows = this.state.projectArray.slice();
        for (let i = fromRow; i <= toRow; i++) {
            rows[i] = { ...rows[i], ...updated };
        }
        // console.log("Rows", rows)
        this.setState({ projectArray: rows })
        // return { rows };
        // });
    };


    render() {
        return (
            <div>
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>

                            <div className="page-title">
                                <div className="float-left">
                                    <h1 className="title">Project Table</h1>
                                </div>
                            </div>



                            <div className="col-12">
                                <section className="box ">
                                    <header className="panel_header">
                                        <h2 className="title float-left">Table</h2>
                                    </header>
                                    <div className="content-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <button className="btn btn-primary btn-sm profile-btn" style={{ marginBottom: "15px" }}>Save</button>
                                            </div>
                                            <div className="col-lg-12">

                                                <ReactDataGrid
                                                    minHeight={this.state.projectArray.length * 25 + 50}
                                                    rowHeight={50}
                                                    columns={columns}
                                                    rowGetter={i => this.state.projectArray[i]}
                                                    rowsCount={50}
                                                    onGridRowsUpdated={this.onGridRowsUpdated}
                                                    enableCellSelect={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}