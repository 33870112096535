/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './dashboard.css';
import { db } from "../../../Firebase";
import employeeimg from '../../../assets/img/profile.png';
// import colorimg from '../../images/color.png';
// import Designteamimg from '../image/Select 02/Design.png';
// import HRAdminteamimg from '../image/Select 02/HR & Admin.png';
// import Technicalteamimg from '../image/Select 01/Technical.png';
import techimg from '../../../assets/img/vr1.jpeg'

import { Redirect } from "react-router-dom";
// import { fade, Slide } from '@material-ui/core';
import { useEffect, useState } from "module";
// import InfiniteScroll from 'react-infinite-scroll-component';
import melzologo from '../../../assets/img/melzologo.png';
import melzologomini from "../../../assets/img/color.png";

// import video from '../image/Giphy Diamond-1.m4v';

let employeeInterval = null;
let deptInterval = null;
let departmentIndex = 1;
let performanceInterval = null;

export default class Dashboard1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            performance: [],
            empDetail: [],
            tasks: [],
            department: [],
            activeDepartment: [],
            activePerformer: [],
            currentDepartment: "",
            activeEmployee: { "firstName": "", "department": "", "designation": "", "activeEmployeeImg": "" },
            highestPerformer: { "name": "", "email": "", "department": "", "highestPerformerImg": "" },
            lowestPerformer: { "name": "", "email": "", "department": "", "lowestPerformerImg": "" },
            isScroll: false,
            limit: null,
        };
    }

    componentDidMount() {

        console.log("user logged in => ",localStorage.getItem('IsloggedIn'))

        if (localStorage.getItem('IsloggedIn')) {
        }
        else {
            console.log("user logged in => ",localStorage.getItem('IsloggedIn'))
            window.location.href = '/'
        }
        this.onGetDept();
    }

    onGetDept = () => {
        let departmentDataPromise = new Promise((resolve, reject) => {
            db.ref("/departmentMaster/").on("value", (queryDept) => {
                let depData = [];
                queryDept.forEach((ele) => {
                    let obj = ele.val();
                    obj["id"] = ele.key;
                    depData.push(obj);
                });
                resolve(depData)
            });
        });
        departmentDataPromise.then(resolveData => {
            this.setState({
                department: resolveData
            }, () => {
                console.log("department ::", this.state.department)
                this.autosetActiveDepartment();
            });
        });
    }

    setActiveDepartment = (index, departmentName) => {
        departmentIndex = index;
        console.log("set active department", departmentName);
        this.onGetEmpData(departmentName);
        this.getEmployeePerformance(departmentName);
    };

    onGetEmpData = (departmentName) => {
        clearInterval(employeeInterval);
        console.log("Get Data for", departmentName)
        let employeeDataPromise = new Promise((accept, reject) => {
            db.ref("/employees/")
                .orderByChild("/department")
                .equalTo(departmentName)
                .on("value", (queryEmp) => {
                    let empData = [];
                    queryEmp.forEach((e) => {
                        if(e.val().status == "active"){

                            let obj = e.val();
                            obj["id"] = e.key;
                            empData.push(obj);
                        
                        }
                    });
                    accept(empData);
                });
        });
        employeeDataPromise.then(resultData => {
            this.setState({
                empDetail: resultData
            }, () => console.log("employee::", this.state.empDetail));

            if (this.state.activeEmployee > 0) {
                let i = 0;
                this.setState({ activeEmployee: resultData[0] });
                employeeInterval = setInterval(() => {
                    i++;
                    if (i === resultData.length) {
                        i = 0
                    }
                    this.setState({
                        activeEmployee: resultData[i],
                    }, () => {
                        console.log("active employee", this.state.activeEmployee);
                        this.onGetTaskData(resultData[i].email);
                    });
                }, 3000);
            }
        });
    }

    onGetTaskData = (userEmail) => {
        let taskDataPromise = new Promise((resolve, reject) => {
            db.ref("/task/")
                .orderByChild("/userEmail")
                .equalTo(userEmail)
                .on("value", (querySnapShot) => {
                    let taskTemp = [];
                    querySnapShot.forEach((ele) => {
                        let obj = [];
                        obj["id"] = ele.key;
                        var totalTasks = 0;
                        ele.val().taskTitle.map((objectData) => {
                            if (objectData.taskTitle !== "") {
                                totalTasks++;
                            }
                        })
                        obj["totalTask"] = totalTasks;
                        console.log("total tasks", totalTasks);
                        taskTemp.push(obj);
                    });
                    resolve(taskTemp);
                });
        });
        taskDataPromise.then(taskResultData => {
            this.setState({
                tasks: taskResultData,
            }, () => {
            });
        });
    }

    autosetActiveDepartment = () => {
        console.log("All Department List", this.state.department);

        this.setActiveDepartment(departmentIndex, this.state.department[departmentIndex].departmentName)
        deptInterval = setInterval(() => {
            departmentIndex++;
            if (departmentIndex === this.state.department.length) {
                departmentIndex = 0;
            }
            this.setActiveDepartment(departmentIndex, this.state.department[departmentIndex].departmentName);
            let departmentList = document.getElementById('departmentListMain');
            let firstItemToRemove = departmentList.childNodes[1];
            departmentList.removeChild(firstItemToRemove);
            departmentList.appendChild(firstItemToRemove);
        }, 10000)
    }

    getEmployeePerformance = (departmentName) => {
        let obj = [];
        db.ref("/employees")
            .orderByChild("department")
            .equalTo(departmentName)
            .on("value", (querySnap) => {

                console.log("called", querySnap)

                querySnap.forEach(element => {
                    if(element.val().status == "active"){

                        obj.push(element.val());
                    
                    }
                });

                let performanceTotal = [];
                console.log(obj);
                setTimeout(() => {
                    obj.map((empEmail) => {

                        db.ref('/performance')
                            .orderByChild("userEmail")
                            .equalTo(empEmail.email)
                            .on("value", (querySnap) => {

                                querySnap.forEach(element => {
                                    let object = {}
                                    object["id"] = element.key;
                                    object['name'] = empEmail.firstName;
                                    object["email"] = element.val().userEmail;
                                    var totalMarks = 0;
                                    element.val().points.map((objdta) => {
                                        // object[objdta.performanceName] = objdta.marks;
                                        totalMarks += parseInt(objdta.marks);
                                    })

                                    object["total"] = totalMarks;
                                    performanceTotal.push(object);

                                });

                                console.log("Performance :: ", performanceTotal);
                                if (this.state.performanceTotal > 0) {
                                    this.getHighestLowestPerformer(performanceTotal);
                                    setTimeout(() => {
                                        this.setState({
                                            performance: performanceTotal
                                        })
                                    }, 100);
                                }
                            })
                    })

                }, 100);
            })
    }

    getHighestLowestPerformer = (departmentPerformanceTotal) => {
        let sortPerformanceList = departmentPerformanceTotal.sort((a, b) => a.total - b.total);
        this.setState({ lowestPerformer: sortPerformanceList[0] });
        this.setState({ highestPerformer: sortPerformanceList[sortPerformanceList.length - 1] });
    }

    closeDashboard = () => {
        window.location.href = "/dashboard"
    }

    componentWillUnmount() {
        clearInterval(deptInterval);
        clearInterval(performanceInterval);
    }

    render() {
        return (
            <section className="Section" style={{display : "block", overflow : 'scroll'}}>
                <div className="row" style={{ background: '#5b5a54' }} >
                    <div className="col-12 ">
                        <div className="row">
                            {/* logo */}
                            <div className="col-3 logocenter">
                                <div className="flexlogo">
                                    <img src={melzologo} className="melzologowidth"></img>
                                    {/* <h1 className="animationlabel">Task desk</h1> */}
                                </div>
                            </div>
                            {/* employee img */}
                            <div className="col-3 paddingleftcol">
                                <p className="performer" style={{ color: '#5b5a54' }}>employee </p>

                                <div className="employeebg boxshadow" >
                                    {/* <img src={employeeimg} alt="" className="widthemployeeimg animation" /> */}

                                </div>
                            </div>
                            {/* top performer */}
                            <div className="col-3">
                                <p className="performer"> Top Performer</p>
                                <div className="employeebg boxshadow">
                                    {/* <img src={employeeimg} alt="" className="widthemployeeimg animation" /> */}

                                </div>
                            </div>
                            {/* bottom performer */}
                            <div className="col-3 paddingrightcol">
                                <p className="performer"> Bottom Performer</p>
                                <div className="employeebg boxshadow" >
                                    {/* <img src={employeeimg} alt="" className="widthemployeeimg animation" /> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 ">
                        <div className="row">
                            <div className="col-3">
                                <div className="row">
                                    <div className="col-12">
                                        <h1 className="animationlabel">
                                            Task Desk
                                        </h1>
                                    </div>
                                </div>
                            </div>
                            {/* employee detail */}
                            <div className="col-3 paddingleftcol">
                                <div className="employeeinfo employeeinfocolor1 boxshadow borderradiusbottom">
                                    <label className="employeetext marginbottomlabel animationtext" value={this.state.activeEmployee.firstName}>Avani{this.state.activeEmployee.firstName}</label>
                                    <p className="employeesubtext" value={this.state.activeEmployee.department}>
                                        dummy text{this.state.activeEmployee.department}
                                    </p>
                                    <p className="employeesubtext" value={this.state.activeEmployee.designation}>
                                        dummy text{this.state.activeEmployee.designation}

                                    </p>
                                </div>
                            </div>
                            {/* top performer detail */}

                            <div className="col-3">
                                <div className="employeeinfo employeeinfocolor2 boxshadow borderradiusbottom">
                                    <label className="employeetext marginbottomlabel animationtext">Avani{this.state.highestPerformer.name}</label>
                                    <p className="employeesubtext">
                                        dummy text{this.state.highestPerformer.email}
                                    </p>
                                    <p className="employeesubtext ">
                                        dummy text{this.state.highestPerformer.department}

                                    </p>
                                </div>
                            </div>
                            {/* bottom performer detail */}

                            <div className="col-3 paddingrightcol">
                                <div className="employeeinfo employeeinfocolor3 boxshadow borderradiusbottom" >
                                    <label className="employeetext marginbottomlabel animationtext">Avani{this.state.lowestPerformer.name}</label>
                                    <p className="employeesubtext">
                                        dummy text{this.state.lowestPerformer.email}
                                    </p>
                                    <p className="employeesubtext ">
                                        dummy text{this.state.lowestPerformer.department}

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-3 paddingzero margintoplogo">

                                <div className="row">
                                    {/* team logo */}
                                    <div className="col-7 paddingzero">
                                        {/* <span className="closeicon" onClick={this.closeDashboard}>&times;</span> */}
                                        {this.state.department.map((item, index) => {
                                            return (<>
                                                <div className="row border_bottom heightlogo" onClick={() => this.setActiveDepartment(index, item.departmentName)}>
                                                    <div className="col-12  alignself paddingzero">
                                                        {/* <img src={Technicalteamimg} className="widthteamlogo" /> */}
                                                        {/* <div className="overlay" id="deptname">{item.departmentName}</div> */}
                                                    </div>
                                                </div>
                                            </>)
                                        })}


                                    </div>
                                    {/* total task section */}
                                    <div className="col-5">
                                        <div className="flexcircle">
                                            <div className="circle circlecolor1">
                                                <label className="margintopbottomzero">1</label>
                                            </div>
                                            <hr className="hrline"></hr>
                                            <div className="circle circlecolor2">
                                                <label className="margintopbottomzero">2</label>
                                            </div>
                                            <div className="circle circlecolor3">
                                                <label className="margintopbottomzero">3</label>
                                            </div>
                                            <div className="circle circlecolor4">
                                                <label className="margintopbottomzero">4</label>
                                            </div>
                                            <div className="circle circlecolor5">
                                                <label className="margintopbottomzero">5</label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* task section */}
                            <div className="col-9">
                                {/* <div className="row">
                                    <div className="col-4 padding1">
                                        <div className="flexborder">
                                            <div className="activeborder">

                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-4 paddingzero">
                                        <div className="borderbottom">

                                        </div>
                                    </div> <div className="col-4 paddingzero">
                                        <div className="borderbottom">

                                        </div>
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-12 bordertask">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="flexcolumntask">
                                                    <label className="tasklabel teambackground bordercolor" >tasktasktask1</label>
                                                    <label className="tasklabel taskbackground bordercolor" >tasktasktask2</label>
                                                    <label className="tasklabel taskbackground bordercolor" >tasktasktask3</label>
                                                    <label className="tasklabel taskbackground bordercolor" >tasktasktask4</label>
                                                    <label className="tasklabel taskbackground bordercolor" >tasktasktask5</label>
                                                    <label className="tasklabel teambackground bordercolor" >tasktasktask1</label>
                                                    <label className="tasklabel taskbackground bordercolor" >tasktasktask2</label>
                                                    <label className="tasklabel taskbackground bordercolor" >tasktasktask3</label>
                                                    <label className="tasklabel taskbackground bordercolor" >tasktasktask4</label>
                                                    <label className="tasklabel taskbackground bordercolor" >tasktasktask4</label>
                                                    <label className="tasklabel taskbackground bordercolor" >tasktasktask4</label>
                                                    <label className="tasklabel taskbackground bordercolor" >tasktasktask4</label>
                                                    <label className="tasklabel taskbackground bordercolor" >tasktasktask4</label>
                                                    <label className="tasklabel taskbackground bordercolor" >tasktasktask4</label>

                                                    <label className="tasklabel taskbackground bordercolor" >tasktasktask5</label>
                                                    <>
                                                        {this.state.tasks.map((obj) => {
                                                            return (obj['status'] === "Pending" &&
                                                                <label className="tasklabel taskbackground bordercolor" value={obj.taskTitle}>{obj.taskTitle}</label>)
                                                        })}
                                                        {this.state.tasks.map((obj) => {
                                                            return (obj['status'] === "Bug" &&
                                                                <label className="tasklabel taskbackground bordercolor" value={obj.taskTitle}>{obj.taskTitle}</label>)
                                                        })}
                                                        {this.state.tasks.map((obj) => {
                                                            return (obj['status'] === "Running" &&
                                                                <label className="tasklabel taskbackground bordercolor" value={obj.taskTitle}>{obj.taskTitle}</label>)
                                                        })}
                                                        {this.state.tasks.map((obj) => {
                                                            return (obj['status'] === "Completed" &&
                                                                <label className="tasklabel Completedbackground bordercolor" value={obj.taskTitle}>{obj.taskTitle}</label>)
                                                        })}

                                                        {this.state.tasks.map((obj) => {
                                                            return ((obj['status'] === "Completed" && obj['approved'] === "false") &&
                                                                <label className="tasklabel Notapproved bordercolor" value={obj.taskTitle}>{obj.taskTitle}</label>)
                                                        })}  </>
                                                </div>
                                            </div>
                                            {/* <div className="col-6">
                                                <div className="flexcolumntask">
                                                  
                                                  
                                                </div>
                                            </div> */}
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* first section performance*/}

                <div className="col-lg-5 col-md-5 col-12 border_right ">
                    {/* employee performance section */}

                    <div className="row team__cards heighttopsection border_bottom ">
                        {/* top employee details section */}

                        <div className="col-md-6 col-lg-6 border_right topbackground">
                            <div className="flexcolumnsection ">
                                <div className="col-md-12 col-lg-12 paddingcol">
                                    <img src={employeeimg} alt="" className="widthemployeeimg animation" />
                                </div>
                                <div className="col-md-12 col-lg-12">
                                    <div className="flexcolumnsection  textanimation">
                                        <label className="employeetext marginbottomlabel animationtext"></label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* bottom employee details section */}

                        <div className="col-md-6 col-lg-6 bottombackground">
                            <div className="flexcolumnsection">
                                <div className="col-md-12 col-lg-12 paddingcol">
                                    <img src={employeeimg} className="widthemployeeimg animation" />
                                </div>
                                <div className="col-md-12 col-lg-12">
                                    <div className="flexcolumnsection textanimation">
                                        <label className="employeetext marginbottomlabel animationtext"></label>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="row heightdetailsec whitebackground">
                        {/* details section */}
                        <div className="flexrowsection fullheight">
                            <div className="col-md-5 col-lg-5">
                                <img src={employeeimg} alt="" className="widthemployeeimg animation" />
                            </div>

                            <div className="col-md-7 col-lg-7">
                                <div className="flexcolumnsection paddingleftten textanimation1">
                                    <>
                                        <label className="employeetext marginbottomlabel color" value={this.state.activeEmployee.firstName}>{this.state.activeEmployee.firstName}</label>
                                        <label className="marginbottomlabel color" value={this.state.activeEmployee.department}>{this.state.activeEmployee.department}</label>
                                        <label className="marginbottomlabel color" value={this.state.activeEmployee.designation}>{this.state.activeEmployee.designation}</label>
                                    </>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Second section task list*/}

                <div className="col-lg-5 col-md-5 col-12 border_right ">
                    <div className="row">
                        <div className="col-12 heighttitle flexrowsection">
                            <div className="">
                                <h3 className="margintopbottomzero animationlabel">TASK</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 border_right">
                            <div className="flexcolumntask">
                                <>
                                    {this.state.tasks.map((obj) => {
                                        return (obj['status'] === "Pending" &&
                                            <label className="tasklabel taskbackground bordercolor" value={obj.taskTitle}>{obj.taskTitle}</label>)
                                    })}
                                    {this.state.tasks.map((obj) => {
                                        return (obj['status'] === "Bug" &&
                                            <label className="tasklabel taskbackground bordercolor" value={obj.taskTitle}>{obj.taskTitle}</label>)
                                    })}
                                    {this.state.tasks.map((obj) => {
                                        return (obj['status'] === "Running" &&
                                            <label className="tasklabel taskbackground bordercolor" value={obj.taskTitle}>{obj.taskTitle}</label>)
                                    })}
                                    {this.state.tasks.map((obj) => {
                                        return (obj['status'] === "Completed" &&
                                            <label className="tasklabel Completedbackground bordercolor" value={obj.taskTitle}>{obj.taskTitle}</label>)
                                    })}

                                    {this.state.tasks.map((obj) => {
                                        return ((obj['status'] === "Completed" && obj['approved'] === "false") &&
                                            <label className="tasklabel Notapproved bordercolor" value={obj.taskTitle}>{obj.taskTitle}</label>)
                                    })}  </>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Third section */}

                <div className="col-lg-2 col-md-2 col-12 overflowsection scrollbar-style teambackground" id="departmentListMain">
                    <span className="closeicon" onClick={this.closeDashboard}>&times;</span>
                    {this.state.department.map((item, index) => {
                        return (<>
                            <div className="row border_bottom heightlogo" onClick={() => this.setActiveDepartment(index, item.departmentName)}>
                                <div className="col-12 paddinglogo alignself">
                                    <img src={techimg} className="widthteamlogo" />
                                    <div className="overlay" id="deptname">{item.departmentName}</div>
                                </div>
                            </div>
                        </>)
                    })}
                </div>
                {/* </div> */}

            </section >
        )
    }
};