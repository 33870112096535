import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import { Tab } from "bootstrap";
import LeadForm from "./LeadForm";
import { db } from "../../Firebase";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import { TabContext } from "@material-ui/lab";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@material-ui/core";
import noDataImg from "../../assets/img/NoData.png";

export default class LeadLock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allData: [],
    };
  }
  componentDidMount = () => {
    this.onGetLeadData();
  };
  componentWillUnmount = () => {};
  onGetLeadData = () => {
    let getLeadDataPromise = new Promise((resolve, reject) => {
      db.ref("/leadList")
        .orderByChild("cpEmail")
        .equalTo(localStorage.getItem("Email"))
        .on("value", (query) => {
          let leadData = [];
          query.forEach((element) => {
            let obj = element.val();
            obj["id"] = element.key;
            leadData.push(obj);
          });
          resolve(leadData);
        });
    });
    getLeadDataPromise.then((resolvedData) => {
      this.setState(
        {
          allData: resolvedData,
        },
        () => console.log("All data::", this.state.allData)
      );
    });
  };

  onLogout = () => {
    localStorage.clear();
    window.location.href = "/leads";
  };

  render() {
    return (
      <div>
        <Container>
          <Box display="flex" style={{ alignItems: "center", margin: "10px" }}>
            <Box flexGrow={1}>
              <Typography variant="h5">Melzo Leads Dashboard</Typography>
            </Box>

            <Box style={{ float: "right", display: "flex" }}>
              <p style={{ marginTop: "15px" }}>
                {localStorage.getItem("user Name")}
              </p>
            </Box>
            <Divider />
            <Card>
              <CardContent>
                <TabContext value="1">
                  <AppBar position="static">
                    <TabList aria-label="profiletab">
                      <Tab
                        label="Lead List"
                        value="1"
                        style={{ fontFamily: "Montserrat" }}
                      />
                    </TabList>
                  </AppBar>
                  <TabPanel value="1">
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <TableContainer>
                          {this.state.allData.length === 0 && (
                            <img
                              src={noDataImg}
                              style={{ width: "100%" }}
                            ></img>
                          )}
                          {this.state.allData.length > 0 && (
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>CP Name</TableCell>
                                  <TableCell>Client Name</TableCell>
                                  <TableCell>Mobile No</TableCell>
                                  <TableCell>Email</TableCell>
                                  <TableCell>Product</TableCell>
                                  <TableCell>City</TableCell>
                                  <TableCell>Lead Date</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.allData.map((data, index) => {
                                  return (
                                    <TableRow key={index}>
                                      <TableCell>{data.cpName}</TableCell>
                                      <TableCell>{data.clientName}</TableCell>
                                      <TableCell>{data.mobileNumber}</TableCell>
                                      <TableCell>{data.email}</TableCell>
                                      <TableCell>{data.product}</TableCell>
                                      <TableCell>{data.city}</TableCell>
                                      <TableCell>
                                        {new Date(
                                          data.leadDate
                                        ).toLocaleDateString("en-IN")}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          )}
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </TabContext>
              </CardContent>
            </Card>
          </Box>
        </Container>
      </div>
    );
  }
}
