import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import { Tab } from "bootstrap";
import LeadForm from "./LeadForm";
import { db } from "../../Firebase";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import { TabContext } from "@material-ui/lab";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import MuiAlert from "@material-ui/lab/Alert";
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TableToExcel from '@linways/table-to-excel';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Snackbar,
  Typography,
  Tooltip,
  Zoom,
  withStyles,
} from "@material-ui/core";
import noDataImg from "../../assets/img/NoData.png";
import Page from "./page";
import ConfirmDialog from "./ConfirmDialog";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default class LeadLock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leadLockForm: false,
      allData: [],
      showSnackbar: false,
      snackbarText: "",
      confirmDialog: {
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: () => { }
      },
      selectedLead: false,
    };
  }
  componentDidMount = () => {
    this.onGetLeadData();
  };
  componentWillUnmount = () => {
    db.ref("/leadList").off();
  };

  onGetLeadData = () => {
    let getLeadDataPromise = new Promise((resolve, reject) => {
      db.ref("/leadList")
        .orderByChild("cpEmail")
        .equalTo(localStorage.getItem("email"))
        .on("value", (query) => {
          let leadData = [];
          query.forEach((element) => {
            if (element.val().isEnabled) {
              let obj = element.val();
              obj["id"] = element.key;
              leadData.push(obj);
            }
          });
          resolve(leadData);
        });
    });
    getLeadDataPromise.then((resolvedData) => {
      this.setState(
        {
          allData: resolvedData,
        },
        () => console.log("employee data::", this.state.allData)
      );
    });
  };
  onOpenForm = () => {
    this.setState({
      leadLockForm: true,
      selectedLead: false
    });
  };
  onCloseForm = () => {
    this.setState({
      leadLockForm: false,
      selectedLead: false,
    });
  };
  expotData = () => {
    TableToExcel.convert(document.getElementById('table-data'), {
      name: "lead.xlsx",
      sheet: {
        name: "Leads"
      }
    })
  }

  closeFormAndRefresh = () => {
    if (this.state.selectedLead) {
      this.setState({
        leadLockForm: false,
        showSnackbar: true,
        snackbarText: "Lead updated successfully!"
      });
    } else {
      this.setState({
        leadLockForm: false,
        showSnackbar: true,
        snackbarText: "New lead added successfully!"
      });
    }

    this.onGetLeadData();
  };

  onLogout = () => {
    localStorage.clear();
    window.location.href = "/leads"
  }

  closeSnackbar = () => {
    this.setState({ showSnackbar: false })
  }

  setConfirmDialog = (newValue) => {
    this.setState({ confirmDialog: newValue })
  }

  editLead = (leadId) => {
    console.log("Edit", leadId);
    this.setState({ selectedLead: leadId, leadLockForm: true })
  }

  deleteLead = (leadId) => {
    console.log("Delete", leadId);
    const deletePromise = new Promise((resolve, reject) => {

      db.ref('leadList/' + leadId).update({
        isEnabled: false,
      }, (error) => {
        if (error) {
          // The write failed...
          console.error("Delete Error", error);
          reject(error);
        } else {
          // Data update successfully!
          console.log("Deleted");
          resolve("success");
        }
      });
    })

    deletePromise.then(res => {
      this.setConfirmDialog({
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: () => { }
      });
      this.onGetLeadData();
    }).catch(err => {
      console.error("Error in deleting form", err);
      this.setConfirmDialog({
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: () => { }
      });
    })
  }

  render() {
    return (
      <div>
        {this.state.leadLockForm && <LeadForm
          closeAddForm={this.onCloseForm}
          successCallback={this.closeFormAndRefresh}
          selectedLead={this.state.selectedLead}
        />}
        {!this.state.leadLockForm && (
          <Page title="Melzo Leads Dashboard">
            <Container style={{ marginTop: "70px" }}>

              <Box display="flex" style={{ alignItems: "center", margin: "10px" }}>
                <Box flexGrow={1}>
                  <Typography variant="h5">Dashboard</Typography>
                </Box>

                <Box style={{ float: "right", display: 'flex' }}>
                  <p style={{ marginTop: "15px" }}>{localStorage.getItem('userName')}</p>
                  <Button
                    style={{ margin: "10px" }}
                    variant="contained"
                    // color="Secondary"
                    onClick={() => this.onLogout()}
                  >
                    Log Out
            </Button>
                </Box>
                <Button
                  style={{ float: "right", margin: "10px" }}
                  variant="contained"
                  color="primary"
                  onClick={() => this.onOpenForm()}
                >
                  Add New Lead
              </Button>
              </Box>
              <Divider />
              <Card>
                <CardContent>
                  <TabContext value="1">
                    <AppBar position="static">
                      <TabList aria-label="profiletab">
                        <Tab
                          label="Lead List"
                          value="1"
                          style={{ fontFamily: "Montserrat" }}
                        />
                        <div style={{
                          margin: 'auto 0',
                          display: this.state.allData.length > 0 ? 'flex' : 'none',
                          position: 'relative',
                          justifyContent: 'flex-end',
                          width: '100%'
                        }}>
                          <LightTooltip
                            TransitionComponent={Zoom}
                            title="Click to download data"
                          >
                            <GetAppIcon style={{}}
                              onClick={() => this.expotData()}
                            />
                          </LightTooltip>
                        </div>
                      </TabList>
                    </AppBar>
                    <TabPanel value="1">
                      <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                          {!this.state.leadLockForm && (

                            <TableContainer style={this.state.allData.length === 0 ? { height: '400px', display: 'flex', justifyContent: 'center' } : {}}>
                              {this.state.allData.length === 0 && (
                                <img src={noDataImg} style={{ height: '400px' }}></img>
                              )}
                              {this.state.allData.length > 0 &&
                                <Table aria-label="simple table" id="table-data">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell data-f-bold>Client Name</TableCell>
                                      <TableCell data-f-bold>
                                        Mobile No
                                    </TableCell>
                                      <TableCell data-f-bold>
                                        Email
                                  </TableCell>
                                      <TableCell data-f-bold>
                                        Product
                                    </TableCell>
                                      <TableCell data-f-bold>City</TableCell>
                                      <TableCell data-f-bold>Lead Date</TableCell>
                                      <TableCell data-f-bold>
                                        Status
                                    </TableCell>
                                    <TableCell data-f-bold>
                                        Remark
                                    </TableCell>
                                    <TableCell data-f-bold style={{display : "none"}}>
                                        Entry Date
                                    </TableCell>
                                    <TableCell data-f-bold style={{display : "none"}}>
                                        Update Date
                                    </TableCell>
                                      <TableCell data-exclude="true">
                                        Action
                                    </TableCell>
                                      <TableCell>

                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {this.state.allData.map((data, index) => {
                                      return (
                                        <TableRow key={index}>
                                          <TableCell>{data.clientName}</TableCell>
                                          <TableCell>
                                            {data.clientMobileNumber}
                                          </TableCell>
                                          <TableCell>
                                            {data.clientEmail}
                                          </TableCell>
                                          <TableCell>
                                            {data.product}
                                          </TableCell>
                                          <TableCell>
                                            {data.clientCity}
                                          </TableCell>
                                          <TableCell>
                                            {new Date(data.leadDate).toLocaleDateString(
                                              "en-IN"
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {data.status}
                                          </TableCell>
                                          <TableCell>
                                            {data.remark}
                                          </TableCell>
                                          <TableCell style={{display : 'none'}}>
                                            {new Date(data.entryTime).toLocaleString('en-IN')}
                                          </TableCell>
                                          <TableCell style={{display : 'none'}}>
                                            {data.statusUpdateTime ? new Date(data.statusUpdateTime).toLocaleString('en-IN') : "No Status Change"}
                                          </TableCell>
                                          <TableCell data-exclude="true">
                                            <LightTooltip
                                              // className={classes.tooltip}
                                              TransitionComponent={Zoom}
                                              title="Click to Edit Information"
                                            >
                                              <EditIcon
                                                onClick={() => this.editLead(data.id)}
                                                variant="contained"
                                                size="sm"
                                              />
                                            </LightTooltip>
                                          </TableCell>
                                          <TableCell data-exclude="true">
                                            <LightTooltip
                                              TransitionComponent={Zoom}
                                              title="Remove this lead"
                                            >
                                              <DeleteIcon
                                                variant="contained"
                                                onClick={() =>
                                                  this.setConfirmDialog({
                                                    isOpen: true,
                                                    title:
                                                      "Are you sure you want to delete this lead?",
                                                    subTitle:
                                                      "Once deleted, you can't undo this action",
                                                    onConfirm: () => {
                                                      this.deleteLead(data.id);
                                                    }
                                                  })
                                                }
                                              >
                                                Delete
                                              </DeleteIcon>
                                            </LightTooltip>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              }
                            </TableContainer>
                          )}
                        </Grid>
                      </Grid>
                    </TabPanel>
                  </TabContext>
                </CardContent>

              </Card>

              <Snackbar
                open={this.state.showSnackbar}
                autoHideDuration={4000}
                key="topcenter"
                onClose={this.closeSnackbar}
              >
                <Alert onClose={this.closeSnackbar} severity="success">
                  {this.state.snackbarText}
                </Alert>
              </Snackbar>

              <ConfirmDialog
                confirmDialog={this.state.confirmDialog}
                setConfirmDialog={this.setConfirmDialog}
              />
            </Container>
          </Page>
        )}
      </div>
    );
  }
}


const LightTooltip = withStyles(theme => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 13
  }
}))(Tooltip);