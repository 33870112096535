import General from '../views/general/Dashboard/General.jsx';
import Hospital from '../views/hospital/Dashboard/Hospital.jsx';
import Music from '../views/music/Dashboard/Music.jsx';
import Crm from '../views/crm/Dashboard/Crm.jsx';
import Social from '../views/social/Dashboard/Social.jsx';
import Freelance from '../views/freelance/Dashboard/Freelance.jsx';
import University from '../views/university/Dashboard/University.jsx';
import Ecommerce from '../views/ecommerce/Dashboard/Ecommerce.jsx';
import Blog from '../views/blog/Dashboard/Blog.jsx';

import Dashboard1 from '../views/general/Dashboard/dashboard1.jsx';
import Dashboard2 from '../views/general/Dashboard/Dashboard2.jsx';
import Dashboard3 from '../views/general/Dashboard/Dashboard3.jsx';
import Dashboard4 from '../views/general/Dashboard/Dashboard4.jsx';
import Dashboard5 from '../views/general/Dashboard/Dashboard5.jsx';
import Dashboard6 from '../views/general/Dashboard/Dashboard6.jsx';
import Dashboard7 from '../views/general/Dashboard/Dashboard7.jsx';
import Dashboard8 from '../views/general/Dashboard/Dashboard8.jsx';
import Dashboard9 from '../views/general/Dashboard/Dashboard9.jsx';


import Maps from '../views/general/Maps/Maps.jsx';
import UIVectorMap from '../views/general/Maps/VectorMap.jsx';

import Calendar from '../views/general/Calendar/Calendar.jsx';

import Mailinbox from '../views/general/Mail/Inbox.jsx';
import Mailcompose from '../views/general/Mail/Compose.jsx';
import Mailview from '../views/general/Mail/View.jsx';

import Widgetsmisc from '../views/general/Widgets/Misc.jsx';
import Widgetscountertiles from '../views/general/Widgets/CounterTiles.jsx';
import Widgetsprogresstiles from '../views/general/Widgets/ProgressTiles.jsx';
import Widgetssocialmedia from '../views/general/Widgets/SocialMedia.jsx';
import Widgetsloops from '../views/general/Widgets/Loops.jsx';

import Chartjsline from '../views/general/Chartjs/Line.jsx';
import Chartjsbar from '../views/general/Chartjs/Bar.jsx';
import Chartjspolar from '../views/general/Chartjs/Polar.jsx';
import Chartjspie from '../views/general/Chartjs/Pie.jsx';
import Chartjsradar from '../views/general/Chartjs/Radar.jsx';
import Chartjsdoughnut from '../views/general/Chartjs/Doughnut.jsx';
import Chartjsscatter from '../views/general/Chartjs/Scatter.jsx';
import Chartjsbubble from '../views/general/Chartjs/Bubble.jsx';
import Chartjshorizontalbar from '../views/general/Chartjs/Horizontalbar.jsx';

import FormElements from '../views/general/Forms/Elements.jsx';
//import FormGrid from '../views/general/Forms/Grid.jsx'; 
// import FormPremade from '../views/general/Forms/Premade.jsx'; 
import FormDatePicker from '../views/general/Forms/DatePicker.jsx';
import FormTimePicker from '../views/general/Forms/TimePicker.jsx';
import FormDateTimePicker from '../views/general/Forms/DateTimePicker.jsx';
import FormDateRangePicker from '../views/general/Forms/DateRangePicker.jsx';
import FormColorPicker from '../views/general/Forms/ColorPicker.jsx';
import FormSlider from '../views/general/Forms/Slider.jsx';
import FormTagsinput from '../views/general/Forms/Tagsinput.jsx';
import FormAutosuggest from '../views/general/Forms/Autosuggest.jsx';
//import FormValidation from '../views/general/Forms/Validation.jsx'; 
import FormWysiwygEditor from '../views/general/Forms/WysiwygEditor.jsx';
import FormCKEditor from '../views/general/Forms/CKEditor.jsx';
import FormInputMask from '../views/general/Forms/InputMask.jsx';
import FormNumericInput from '../views/general/Forms/NumericInput.jsx';

import UITabs from '../views/general/UI/Tabs.jsx';
import UICollapse from '../views/general/UI/Collapse.jsx';
import UIProgress from '../views/general/UI/Progress.jsx';
import UIButtons from '../views/general/UI/Buttons.jsx';
import UIModals from '../views/general/UI/Modals.jsx';
import UIBadges from '../views/general/UI/Badges.jsx';
import UIAlerts from '../views/general/UI/Alerts.jsx';
import UINotifications from '../views/general/UI/Notifications.jsx';
import UIBreadcrumbs from '../views/general/UI/Breadcrumbs.jsx';
import UIButtonDropdowns from '../views/general/UI/ButtonDropdowns.jsx';
import UIButtonGroup from '../views/general/UI/ButtonGroup.jsx';
//import UICards from '../views/general/UI/Cards.jsx'; 
import UICarousel from '../views/general/UI/Carousel.jsx';
import UIDropdowns from '../views/general/UI/Dropdowns.jsx';
import UIFade from '../views/general/UI/Fade.jsx';
import UIJumbotron from '../views/general/UI/Jumbotron.jsx';
import UIGrid from '../views/general/UI/Grid.jsx';
import UIListGroup from '../views/general/UI/ListGroup.jsx';
//import UINavbar from '../views/general/UI/Navbar.jsx'; 
//import UINav from '../views/general/UI/Nav.jsx'; 
import UIPagination from '../views/general/UI/Pagination.jsx';
import UIPopovers from '../views/general/UI/Popovers.jsx';
import UITooltips from '../views/general/UI/Tooltips.jsx';

import UITypography from '../views/general/UI/Typography.jsx';
import UIVideoEmbeds from '../views/general/UI/VideoEmbeds.jsx';
import UICode from '../views/general/UI/Code.jsx';
//import UIResponsiveImages from '../views/general/UI/ResponsiveImages.jsx';

import FontAwesome from '../views/general/Icons/FontAwesome.jsx';
import SimpleLineIcons from '../views/general/Icons/SimpleLineIcons.jsx';



import UITables from '../views/general/Tables/Tables.jsx';
/*import UIDatatables from '../views/general/Tables/Datatables.jsx';*/
import UIDatagrid from '../views/general/Tables/Datagrid.jsx';
/*import UIDatagridsort from '../views/general/Tables/Datagridsort.jsx';*/
import UIBSDatatable from '../views/general/Tables/BSDatatable.jsx';

import Login from '../views/general/Pages/Login.jsx';
import Register from '../views/general/Pages/Register.jsx';
import LockScreen from '../views/general/Pages/LockScreen.jsx';

import Page403 from '../views/general/Pages/403.jsx';
import Page404 from '../views/general/Pages/404.jsx';
import Page405 from '../views/general/Pages/405.jsx';
import Page408 from '../views/general/Pages/408.jsx';
import Page500 from '../views/general/Pages/500.jsx';
import Page503 from '../views/general/Pages/503.jsx';
import Offline from '../views/general/Pages/Offline.jsx';


import Blogs from '../views/general/Blogs/Blogs.jsx';
import BlogSearch from '../views/general/Blogs/Search.jsx';
import SingleBlog from '../views/general/Blogs/SingleBlog.jsx';

import UserProfile from '../views/general/SocialMedia/UserProfile.jsx';
import Members from '../views/general/SocialMedia/Members.jsx';

import CenteredTimeline from '../views/general/Timeline/Centered.jsx';
import LeftTimeline from '../views/general/Timeline/Leftalign.jsx';

import PricingExpanded from '../views/general/Pricing/Expanded.jsx';
import PricingNarrow from '../views/general/Pricing/Narrow.jsx';

import Invoice from '../views/general/Invoice/Invoice.jsx';
import MasonryGallery from '../views/general/Gallery/Masonry.jsx';

import FileDropzone from '../views/general/FileUploader/Dropzone.jsx';

//import Doctor from '../views/hospital/Doctor/Doctor.jsx';

import DefaultLayout from '../views/general/PageLayouts/DefaultLayout.jsx';
import FoldedMenu from '../views/general/PageLayouts/FoldedMenu.jsx';
import TransparentLayout from '../views/general/PageLayouts/TransparentLayout.jsx';
import LightMenu from '../views/general/PageLayouts/LightMenu.jsx';
import ChatOpen from '../views/general/PageLayouts/ChatOpen.jsx';
import Layout1 from '../views/general/PageLayouts/Layout1.jsx';
import Layout2 from '../views/general/PageLayouts/Layout2.jsx';
import Layout3 from '../views/general/PageLayouts/Layout3.jsx';
import Layout4 from '../views/general/PageLayouts/Layout4.jsx';
import Layout5 from '../views/general/PageLayouts/Layout5.jsx';
import Layout6 from '../views/general/PageLayouts/Layout6.jsx';
import Layout7 from '../views/general/PageLayouts/Layout7.jsx';
import Layout8 from '../views/general/PageLayouts/Layout8.jsx';
import Layout9 from '../views/general/PageLayouts/Layout9.jsx';
import Layout10 from '../views/general/PageLayouts/Layout10.jsx';
import Layout11 from '../views/general/PageLayouts/Layout11.jsx';
import Layout12 from '../views/general/PageLayouts/Layout12.jsx';
import Layout13 from '../views/general/PageLayouts/Layout13.jsx';
import Layout14 from '../views/general/PageLayouts/Layout14.jsx';
import Layout15 from '../views/general/PageLayouts/Layout15.jsx';
import DepartmentMaster from '../views/general/Forms/DepartmentMaster';
import UserForm from '../views/general/Forms/UserForm';
import DesignationMaster from '../views/general/Forms/DesignationMaster';
import ProjectList from '../views/general/Forms/ProjectList';
import AnnouncementandPolocies from '../views/general/UI/AnnouncementandPolicies.jsx';
import Vouchers from '../views/general/FileUploader/Dropzone.jsx';
import PayRoll from '../views/general/Forms/Payroll.jsx';
import GenerateSalary from '../views/general/Forms/GenerateSalary.jsx';
// import RequestLeave from '../views/general/Forms/RequestLeave.jsx';
import Task from '../views/general/Forms/Task.jsx';
import Attendance from '../views/general/Forms/Attendance.jsx';
import PerformanceMaster from '../views/general/Forms/PerformanceMater.jsx';
import Performance from '../views/general/Forms/Performance.jsx';
import UserFormTable from '../views/general/Tables/UserFormTable.jsx';
import DepartmentTable from '../views/general/Tables/DepartmentTable.jsx';
import DesignationTable from '../views/general/Tables/DesignationTable.jsx';
import ProjectTable from '../views/general/Tables/ProjectTable.jsx';
import AttendanceTable from '../views/general/Tables/AttendanceTable.jsx';
import PayrollTable from '../views/general/Tables/PayrollTable.jsx';
import TaskTable from '../views/general/Tables/TaskTable.jsx';
import Sales from '../views/general/Forms/Sales.jsx';
import SalesTable from '../views/general/Tables/SalesTable.jsx';
import LeadLock from '../components/Register/LeadLock.jsx';
import { LocalActivity, Report, TimerOff } from '@material-ui/icons';
import RequestLeave from "../components/Leave/TimeRequestoff"
import ApproveLeaves from "../components/Leave/ApproveLeaves"
import AddLeave from '../components/Leave/AddLeave.jsx';
import Attendancefeature from  '../components/attendence/attendence'

// From CRM

import Ticket from '../views/crm/Ticket/Ticket.jsx';
import AddTicket from '../views/crm/Ticket/AddTicket.jsx';
import EditTicket from '../views/crm/Ticket/EditTicket.jsx';
import TicketLogs from "../views/crm/Ticket/TicketLogs";

// For Sales

import AddLeads from "../views/crm/Sales/AddLeads";
import LeadsView from '../views/crm/Sales/LeadView.jsx';
import Counter from '../views/general/Counter/Counter.jsx';
import Holidays from '../components/holidays/Holidays.jsx';
import travelRequest from '../components/travelRequest/travelRequest.jsx';
import travelUserRequest from '../components/travelRequest/travelUserRequest.jsx';
import UploadUserData from '../views/general/Forms/UploadUserData.jsx';


// For Expense

import NormalViewOfTable from "../components/Expense/strecture/NormalViewOfTable.js";
import ApprovedViewOfTable from "../components/Expense/strecture/Reimbursed.js";
// import ApprovedViewOfTable from "../components/Expense/strecture/ApprovedViewOfTable.js";
import PendingViewOfTable from "../components/Expense/strecture/PendingViewOfTable.js";
import ExpenseAdminView from "../components/Expense/Dashboard/ExpenseAdminView.js";


var BASEDIR = process.env.REACT_APP_BASEDIR;

var userRole = (localStorage.getItem('employeeObject') ? JSON.parse(localStorage.getItem('employeeObject')).userRole : "")
var userDept = (localStorage.getItem('employeeObject') ? JSON.parse(localStorage.getItem('employeeObject')).department : "")
var userid = (localStorage.getItem('employeeObject') ? JSON.parse(localStorage.getItem('employeeObject')).userID : "")


var filterData = []

// Procedure to filter component based on role and department

// 1. Add role to access key e.g (suppose to add Role of vendor then =>> access : [vendor, ...(other roles)])
// 2. Add department to accessDept key e.g (suppose to add Third-party then accessDept : [Third-party, ...(other department)])
// 3. Last set filter value to true if parent and child component has diffrent access levels

// filter value = false means => add whole compoent with child component
// filter value = true means => check for access in both level (parent + child) and add component that has proper access.!



var dashRoutes = [

    // Dashboard
    {
        path: BASEDIR + "/dashboard", name: "Dashboard", icon: "speedometer", component: Dashboard2, access: ["superadmin", "admin", "dept_head", "team_lead", "HR", "employee"], accessDept : ["Project Management","Information Technology", "Digital & Tendering", "Sales & Marketing", "Design", " HR & Admin","Customer Support"], filter: false
    },

    {
        path: BASEDIR + "/counter", name: "Counter", icon: "speedometer", component: Counter, access: ["superadmin", "admin", "dept_head", "team_lead", "HR", "employee"], accessDept : ["Project Management","Information Technology", "Digital & Tendering", "Sales & Marketing", "Design", " HR & Admin","Customer Support"], filter: false
    },

    // Company profile

    {
        path: "#", name: "Company Profile", icon: "people", type: "dropdown", parentid: "pages", access: ["superadmin", "admin", "dept_head", "team_lead", "HR", "employee"], accessDept : ["Project Management","Information Technology", "Digital & Tendering", "Sales & Marketing", "Design", " HR & Admin","Customer Support"], filter: false,
        child: [
            { path: BASEDIR + "/ui-members", name: "Members" },
            // { path: BASEDIR + "/ui-notif", name: "Announcement & Policies" },
        ]
    },
    // NavigationLInks

    { path: BASEDIR + "/ui-profile/:uid", component: UserProfile, type: "child" },
    { path: BASEDIR + "/ui-members", component: Members, type: "child" },
    { path: BASEDIR + "/ui-notif", component: AnnouncementandPolocies, type: "child" },

    // User Profile
    
    {
        path: "#", name: "My Profile", icon: "emotsmile", type: "dropdown", parentid: "pages1", access: ["superadmin", "admin", "dept_head", "team_lead", "HR", "employee"], accessDept : ["Project Management","Information Technology", "Digital & Tendering", "Sales & Marketing", "Design", " HR & Admin","Customer Support"], filter: false,
        child: [
            { path: BASEDIR + "/MyProfile/"+userid, name: "Profile" },
        ]
    },
    // NavigationLInks

    { path: BASEDIR + "/MyProfile/"+userid, component: UserProfile, type: "child" },

    // Calender

    // { path: BASEDIR + "/calendar", name: "Calendar", icon: "calendar", component: Calendar, access: ["superadmin", "admin", "dept_head", "team_lead", "HR", "employee"], filter: false },

    // Forms

    {
        path: "#", name: "Forms", icon: "note", type: "dropdown", parentid: "forms", access: ["superadmin", "admin", "dept_head", "HR"], accessDept : [" HR & Admin"], filter: true,
        child: [

            { path: BASEDIR + "/form-elements-departmentMaster", name: "Department", access: ["superadmin", "admin", "HR"], accessDept : [" HR & Admin"] },
            { path: BASEDIR + "/form-elements-designationMaster", name: "Designation", access: ["superadmin", "admin", "HR"], accessDept : [" HR & Admin"] },
            { path: BASEDIR + "/form-elements-userform", name: "User Form", access: ["superadmin", "admin", "dept_head", "HR"], accessDept : [" HR & Admin"] },
            { path: BASEDIR + "/form-elements-uploadData", name: "Upload User Data", access: ["superadmin", "admin", "dept_head", "HR"], accessDept : [" HR & Admin"] },
            // { path: BASEDIR + "/form-elements-projectlist", name: "Projects", access: ["superadmin", "admin", "dept_head", "team_lead", "employee"] },
        ]
    },
    // NavigationLinks

    { path: BASEDIR + "/form-elements-departmentMaster", component: DepartmentMaster, type: "child" },
    { path: BASEDIR + "/form-elements-designationMaster", component: DesignationMaster, type: "child" },
    { path: BASEDIR + "/form-elements-userform", component: UserForm, type: "child" },
    { path: BASEDIR + "/form-elements-uploadData", component: UploadUserData, type: "child" },
    // { path: BASEDIR + "/form-elements-projectlist", component: ProjectList, type: "child" },

    // Salary structure

    // {
    //     path: "#", name: "Salary Structure", icon: "organization", type: "dropdown", parentid: "salary-structure", access: ["superadmin", "admin", "HR"], filter: true,
    //     child: [

    //         { path: BASEDIR + "/salary-structure-payroll", name: "Payroll", access: ["superadmin", "admin", "HR"] },
    //         { path: BASEDIR + "/salary-structure-generatesalary", name: "Generate Salary", access: ["superadmin", "admin", "dept_head", "team_lead", "HR", "employee"] },
    //     ]
    // },
    // NavigationLinks

    // { path: BASEDIR + "/salary-structure-payroll", component: PayRoll, type: "child" },
    // { path: BASEDIR + "/salary-structure-generatesalary", component: GenerateSalary, type: "child" },"Information Technology", 

    // Leave
    {
        path: "#", name: "Leave", icon: "cursor", type: "dropdown", parentid: 'leave', access: ["superadmin", "admin", "dept_head", "team_lead", "HR", "employee"], accessDept : ["Project Management","Information Technology", "Digital & Tendering", "Sales & Marketing", "Design", " HR & Admin","Customer Support"], filter: true,
        child: [

            { path: BASEDIR + '/leave', name: "Request Leave", access: ["superadmin", "admin", "dept_head", "team_lead", "HR", "employee"], accessDept : ["Project Management","Information Technology", "Digital & Tendering", "Sales & Marketing", "Design", " HR & Admin","Customer Support"] },
            { path: BASEDIR + '/approve-leaves', name: "View Leave", access: ["superadmin", "admin", "dept_head", "team_lead", "HR"], accessDept : ["Project Management","Information Technology", "Digital & Tendering", "Sales & Marketing", "Design", " HR & Admin","Customer Support"] },
            { path: BASEDIR + '/add-leaves', name: "Add Leave", access: ["superadmin", "admin", "dept_head", "team_lead", "HR"], accessDept : [" HR & Admin"] },

        ]
    },
    // NavigationLinks
    { path: BASEDIR + '/leave', component: RequestLeave, type: "child" },
    { path: BASEDIR + '/approve-leaves', component: ApproveLeaves, type: "child" },
    { path: BASEDIR + '/add-leaves', component: AddLeave, type: "child" },
   
    //Attendance feature (Check-in and Check-out)
    // {
    //     path: BASEDIR + "/attendance/"+userid, name: "attendance", icon: "calendar", component: Attendancefeature, access: ["superadmin", "admin", "dept_head", "team_lead", "HR", "employee"], accessDept : ["Project Management","Information Technology", "Digital & Tendering", "Sales & Marketing", "Design", " HR & Admin","Customer Support"], filter: false
    // },

    
    {
        path: BASEDIR + "/attendance", name: "Attendance", icon: "calendar", component: Attendancefeature, access: ["superadmin", "admin", "dept_head", "team_lead", "HR", "employee"], accessDept : ["Project Management","Information Technology", "Digital & Tendering", "Sales & Marketing", "Design", " HR & Admin","Customer Support"], filter: false,
    },
    // { path: BASEDIR + '/attendance', component: Attendancefeature, type: "child" },

    //Holidays feature
    
    {
        path: BASEDIR + "/holidays", name: "Holidays", icon: "calendar", component: Holidays, access: ["superadmin", "admin", "dept_head", "team_lead", "HR", "employee"], accessDept : ["Project Management","Information Technology", "Digital & Tendering", "Sales & Marketing", "Design", " HR & Admin","Customer Support"], filter: false,
    },

    // { path: BASEDIR + '/leave', name: "Request Leave", access: ["superadmin", "admin", "dept_head", "team_lead", "HR", "employee"], accessDept : ["Project Management","Information Technology", "Digital & Tendering", "Sales & Marketing", "Design", " HR & Admin","Customer Support"] },
    // { path: BASEDIR + '/approve-leaves', name: "View Leave", access: ["superadmin", "admin", "dept_head", "team_lead", "HR"], accessDept : ["Project Management","Information Technology", "Digital & Tendering", "Sales & Marketing", "Design", " HR & Admin","Customer Support"] },
    // { path: BASEDIR + '/add-leaves', name: "Add Leave", access: ["superadmin", "admin", "dept_head", "team_lead", "HR"], accessDept : [" HR & Admin"] },
    //travel request 
    {
        path: "#", name: "travel-request", icon: "cursor", type: "dropdown", parentid: 'travel-request', access: ["superadmin", "admin", "dept_head", "team_lead", "HR", "employee"], accessDept : ["Project Management","Information Technology", "Digital & Tendering", "Sales & Marketing", "Design", " HR & Admin","Customer Support"], filter: true,
        child: [

            { path: BASEDIR + '/travel-request', name: "Travel Request", access: ["superadmin", "admin", "dept_head", "team_lead", "HR", "employee"], accessDept : ["Project Management","Information Technology", "Digital & Tendering", "Sales & Marketing", "Design", " HR & Admin","Customer Support"] },
            { path: BASEDIR + '/approve-travel-request', name: "Approve Travel Request", access: ["superadmin", "admin", "dept_head", "team_lead", "HR"], accessDept : ["Project Management","Information Technology", "Digital & Tendering", "Sales & Marketing", "Design", " HR & Admin","Customer Support"] },
        ]
    },
    // NavigationLinks
    { path: BASEDIR + '/travel-request', component: travelUserRequest, type: "child" },
    { path: BASEDIR + '/approve-travel-request', component: travelRequest, type: "child" },
 
     // expense
  {
    path: "#",
    name: "Add Expense",
    icon: "cursor",
    type: "dropdown",
    parentid: "expense",
    access: ["superadmin", "admin", "dept_head", "team_lead", "HR", "employee"],
    accessDept: [
      "Project Management",
      "Information Technology",
      "Digital & Tendering",
      "Sales & Marketing",
      "Design",
      " HR & Admin",
      "Customer Support",
    ],
    filter: true,
    child: [
      {
        path: BASEDIR + "/normalView",
        name: "My Bills",
        access: [
          "superadmin",
          "admin",
          "dept_head",
          "team_lead",
          "HR",
          "employee",
        ],
        accessDept: [
          "Project Management",
          "Information Technology",
          "Digital & Tendering",
          "Sales & Marketing",
          "Design",
          " HR & Admin",
          "Customer Support",
        ],
      },
      {
        path: BASEDIR + "/Admin",
        name: "Admin",
        access: ["superadmin", "admin", "dept_head", "team_lead", "HR"],
        accessDept: ["Project Management","Information Technology", "Digital & Tendering", "Sales & Marketing", "Design", " HR & Admin","Customer Support"],
      },
      // {
      //   path: BASEDIR + "/ApprovedView",
      //   name: "Reimbursed",
      //   access: ["superadmin", "admin", "dept_head", "team_lead", "HR"],
      //   accessDept: [" HR & Admin"],
      // },
      // {
      //   path: BASEDIR + "/PendingView",
      //   name: "Pending",
      //   access: ["superadmin", "admin", "dept_head", "team_lead", "HR"],
      //   accessDept: [" HR & Admin"],
      // },
    ],
  },
  
  // Expense - NavigationLinks
  {
    path: BASEDIR + "/normalView",
    component: NormalViewOfTable,
    type: "child",
  },
  // {
  //   path: BASEDIR + "/ApprovedView",
  //   component: ApprovedViewOfTable,
  //   type: "child",
  // },
  {
    path: BASEDIR + "/Admin",
    component: ExpenseAdminView,
    type: "child",
  },
  // {
  //   path: BASEDIR + "/PendingView",
  //   component: PendingViewOfTable,
  //   type: "child",
  // },

    // {
    //     path: "#", name: "Attendance", icon: "calendar", type: "dropdown", parentid: "Attendance", access: ["superadmin", "admin", "dept_head", "team_lead", "HR", "employee"], accessDept : ["Project Management","Information Technology", "Digital & Tendering", "Sales & Marketing", "Design", " HR & Admin","Customer Support"], filter: false,
    //     child: [
    //         { path: BASEDIR + "/attendance/"+userid, name: "Check In & Check Out" },
    //     ]
    // },

    
    // { path: BASEDIR + '/attendance/', component: Attendancefeature, type: "child" },
    // Sales

    // { path: BASEDIR + '/sales-sales', name: "sales", icon: "chart", component: Sales, access: ["superadmin", "admin", "dept_head", "team_lead", "employee"], filter: false },

    // Attendance

    // { path: BASEDIR + "/attendance", name: "Attendance", icon: "event", component: Attendance, access: ["superadmin", "admin", "dept_head", "team_lead", "HR"], filter: false },

    //  Task

    // { path: BASEDIR + "/task-task", name: "Task", icon: "layers", component: Task, access: ["superadmin", "admin", "dept_head", "team_lead", "employee"], filter: false },

    // Performance

    // {
    //     path: "#", name: "Performance", icon: "graph", type: "dropdown", parentid: "performance", access: ["superadmin", "admin", "dept_head", "team_lead", "employee"], filter: true,
    //     child: [
    //         { path: BASEDIR + "/performance-master", name: "Performance Master", access: ["superadmin", "admin", "team_lead",] },
    //         { path: BASEDIR + "/performance", name: "Performance", access: ["superadmin", "admin", "dept_head", "team_lead", "employee"] },
    //     ]
    // },
    // NavigationLink

    // { path: BASEDIR + "/performance-master", component: PerformanceMaster, type: "child" },
    // { path: BASEDIR + "/performance", component: Performance, type: "child" },

    // Vouchers (Expense payout)

    // { path: BASEDIR + "/expense-payout-fileupload-vouchers", name: "Vouchers", icon: "bag", component: Vouchers, access: ["superadmin", "admin", "team_lead", "HR"], filter: false },

    // Tables

    // {
    //     path: "#", name: "Tables", icon: "grid", type: "dropdown", parentid: "tables", access: ["superadmin", "admin", "dept_head", "team_lead", "HR", "employee"], filter: false,
    //     child: [

    //         { path: BASEDIR + "/tables-departmenttable", name: "Department Table" },
    //         { path: BASEDIR + "/tables-designationtable", name: "Designation Table" },
    //         { path: BASEDIR + "/tables-usertable", name: "Employee Table" },
    //         { path: BASEDIR + "/tables-projecttable", name: "Project Table" },
    //         { path: BASEDIR + "/tables-attendancetable", name: "Attendance Table" },
    //         { path: BASEDIR + "/tables-payrolltable", name: "Payroll Table" },
    //         { path: BASEDIR + "/tables-tasktable", name: "Task Table" },
    //         { path: BASEDIR + "/tables-salestable", name: "Sales Table" },
    //     ]
    // },
    // NavigationLinks

    // { path: BASEDIR + "/tables-departmenttable", component: DepartmentTable, type: "child" },
    // { path: BASEDIR + "/tables-designationtable", component: DesignationTable, type: "child" },
    // { path: BASEDIR + "/tables-usertable", component: UserFormTable, type: "child" },
    // { path: BASEDIR + "/tables-projecttable", component: ProjectTable, type: "child" },
    // { path: BASEDIR + "/tables-attendancetable", component: AttendanceTable, type: "child" },
    // { path: BASEDIR + "/tables-payrolltable", component: PayrollTable, type: "child" },
    // { path: BASEDIR + "/tables-tasktable", component: TaskTable, type: "child" },
    // { path: BASEDIR + "/tables-salestable", component: SalesTable, type: "child" },

    // Ticket
    { 
        path: "#", name: "Tickets", icon: "question", type: "dropdown", parentid: "tickets", access: ["superadmin", "admin", "dept_head", "team_lead", "employee"], accessDept : ["Project Management"], filter: true,
            child: [
                { path: BASEDIR+"/tickets", name: "Tickets", access: ["superadmin", "admin", "dept_head", "team_lead", "employee"], accessDept : ["Project Management"]},
                { path: BASEDIR+"/add-ticket", name: "Add Ticket", access: ["superadmin", "admin", "dept_head", "team_lead", "employee"], accessDept : ["Project Management"]},
                { path: BASEDIR+"/ticketLogs", name: "Ticket Logs", access: ["superadmin", "admin", "dept_head", "team_lead", "employee"], accessDept : ["Project Management"]},
            ]
    },
        { path: BASEDIR+"/tickets", component: Ticket, type: "child"},
        { path: BASEDIR+"/add-ticket", component: AddTicket, type: "child"},
        { path: BASEDIR+"/ticketLogs", component: TicketLogs, type: "child"},

    // Sales
    { 
        path: "#", name: "Sales", icon: "chart", type: "dropdown", parentid: "sales", access: ["superadmin", "admin", "dept_head", "team_lead", "employee"], accessDept : ["Sales & Marketing","Digital & Tendering"], filter: true,
            child: [
                { path: BASEDIR+"/sales", name: "Leads", access: ["superadmin", "admin", "dept_head", "team_lead", "employee"], accessDept : ["Sales & Marketing","Digital & Tendering"]},
                { path: BASEDIR+"/add-leads", name: "Add Leads", access: ["superadmin", "admin", "dept_head", "team_lead", "employee"], accessDept : ["Sales & Marketing","Digital & Tendering"]},
                { path: BASEDIR+"/leadLogs", name: "Lead Logs", access: ["superadmin", "admin", "dept_head", "team_lead", "employee"], accessDept : ["No View"]},
            ]
    },
        { path: BASEDIR+"/sales", component: LeadsView, type: "child"},
        { path: BASEDIR+"/add-leads", component: AddLeads, type: "child"},
        { path: BASEDIR+"/leadLogs", component: TicketLogs, type: "child"},

    // Completed
];

// Filter Component by UserRole and access Array

for (let i = 0; i < dashRoutes.length; i++) {
    let inSideNestedLoop = true
    if (dashRoutes[i].access) {
        if (dashRoutes[i].filter) {
            if (dashRoutes[i].access.includes(userRole)) {
                if(userDept){
                for(let j = 0;j <userDept.length; j++){
                    if(inSideNestedLoop){
                        if(dashRoutes[i].accessDept.includes(userDept[j])){
                            let childData = []
                            dashRoutes[i].child.map(data => {
                                if (data.access.includes(userRole) && data.accessDept.includes(userDept[j])) {
                                    childData.push(data)
                                }
                            })
                            let dataObj = {
                                path: dashRoutes[i].path, name: dashRoutes[i].name, icon: dashRoutes[i].icon, type: dashRoutes[i].type, parentid: dashRoutes[i].parentid, access: dashRoutes[i].access, filter: dashRoutes[i].filter,
                                child: childData
                            }
                            filterData.push(dataObj)
                            inSideNestedLoop =false
                        }
                    }
                }
            }
            }

        } else {
            if (dashRoutes[i].access.includes(userRole)) {
                if(userDept){
                for(let j = 0;j <userDept.length; j++){
                    if(inSideNestedLoop){
                        if(dashRoutes[i].accessDept.includes(userDept[j])){
                            filterData.push(dashRoutes[i])
                            inSideNestedLoop =false
                        }
                    }
                }
            }
            }
        }

    } else if (dashRoutes[i].type === "child") {
        filterData.push(dashRoutes[i])

    }
}
export default filterData;

/* 

access flow by userRole
// Hr => "Designation", "Department",UserForm,PayRoll,Attendance,"Leave",Vouchers

// employee => performance,Task,RequestLeave,GenerateSalary
// team_lead => performance,PerformanceMaster,Attendance,Task,Vouchers,
// dept_head => UserForm,performance,Attendance,Task,"LeaveActivity",
// admin => "all access"
// superadmin => "all access"

Total available designation as on may 2020

// Designation => "Chief Executive Officer"(superadmin), "Vice President"(admin), "Project Manager"(dept_head), "Full Stack Developer"(employee), "Customer Support Manager"(dept_head),"Frontend Developer"(employee),
// "Senior Graphic Designer"(dept_head,team_lead), "Graphic Designer"(team_lead,employee),"Content Writer  "(team_lead,employee), "Regional Sales Manager"(team_lead), "Human Resource"(HR),"Area Sales Manager"(team_lead,employee),"Digital Sales Executive(team_lead,employee)  ", 
// "Project Management Executive"

// Department => "Information Technology","Digital & Tendering","Sales & Marketing","Design"," HR & Admin","Project Management","Customer Support"

// role => "superadmin","admin","dept_head","team_lead","HR","employee"


*/
