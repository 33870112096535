import React from "react";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import ReactDataGrid from "react-data-grid";
import { db } from "../../../Firebase";

const columns = [
    { key: "departmentName", name: "Department", editable: true },
    { key: "email", name: "Employee Email", editable: true },
    { key: "basicPay", name: "Basic Pay", editable: true },
    { key: "hra", name: "HRA(House Rent Allowance)", editable: true },
    { key: "residualPay", name: "Residual Pay", editable: true },
    { key: "grossSalary", name: "Gross Pay", editable: true },
    { key: "pfEmp", name: "Provident Fund(Employee)", editable: true },
    { key: "pfCompany", name: "Provident Fund(Employer)", editable: true },
    { key: "esicEmp", name: "ESIC(Employee)", editable: true },
    { key: "esicCompany", name: "ESIC(Employer)", editable: true },
    { key: "professionalTax", name: "Professional Tax", editable: true },
    { key: "ctc", name: "CTC", editable: true },
    { key: "subTotal", name: "Sub Total", editable: true },
    { key: "netPay", name: "Net Pay", editable: true },
];

export default class PayrollTable extends React.Component {
    constructor() {
        super();

        this.state = {
            payrollData: [],
        }
    }

    componentDidMount() {
        this.onGetPayrollData();
    }

    onGetPayrollData = () => {
        let payrollDataPromise = new Promise((resolve, reject) => {
            db.ref("/payRoll/")
                .on("value", (queryPayroll) => {
                    let payrollArray = [];
                    queryPayroll.forEach((element) => {
                        let object = element.val();
                        object["id"] = element.key;
                        payrollArray.push(object);
                    })
                    resolve(payrollArray);
                })
        })
        payrollDataPromise.then(acceptedData => {
            this.setState({
                payrollData: acceptedData,
            })
        })
    }

    onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        // console.log("From row ", fromRow, "To row", toRow, "Updated", updated);
        // let state = this.state.employeeData;
        // this.setState(employeeData => {
        const rows = this.state.payrollData.slice();
        for (let i = fromRow; i <= toRow; i++) {
            rows[i] = { ...rows[i], ...updated };
        }
        // console.log("Rows", rows)
        this.setState({ payrollData: rows })
        // return { rows };
        // });
    };

    onSaveUpdatedData = () => {
        for(let i = 0; i < this.state.payrollData.length; i++) {
            db.ref("/payRoll/" + this.state.payrollData[i].id).update({
                departmentName:this.state.payrollData[i].departmentName,
                email: this.state.payrollData[i].email,
                basicPay: this.state.payrollData[i].basicPay,
                hra: this.state.payrollData[i].hra,
                residualPay: this.state.payrollData[i].residualPay,
                grossSalary: this.state.payrollData[i].grossSalary,
                ctc: this.state.payrollData[i].ctc,
                esicEmp: this.state.payrollData[i].esicEmp,
                esicCompany: this.state.payrollData[i].esicCompany,
                netPay: this.state.payrollData[i].netPay,
                pfEmp: this.state.payrollData[i].pfEmp,
                pfCompany: this.state.payrollData[i].pfCompany,
                professionalTax: this.state.payrollData[i].professionalTax,
                subTotal: this.state.payrollData[i].subTotal,
            })
        }
    }

    render() {

        return (
            <div>
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>

                            <div className="page-title">
                                <div className="float-left">
                                    <h1 className="title">Payroll Data</h1>
                                </div>
                            </div>



                            <div className="col-12">
                                <section className="box ">
                                    <header className="panel_header">
                                        <h2 className="title float-left">Data</h2>
                                    </header>
                                    <div className="content-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <button className="btn btn-primary btn-sm profile-btn" style={{ marginBottom: "15px" }} onClick={this.onSaveUpdatedData}>Save</button>
                                            </div>
                                            <div className="col-lg-12">
                                                <ReactDataGrid
                                                    // minHeight={this.state.payrollData.length * 25 + 50}
                                                    rowHeight={50}
                                                    columns={columns}
                                                    rowGetter={i => this.state.payrollData[i]}
                                                    rowsCount={50}
                                                    onGridRowsUpdated={this.onGridRowsUpdated}
                                                    enableCellSelect={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>



        );

    }
}