import moment from "moment"; // Example for onSort prop
import React from "react"; // Import React
//import { render } from 'react-dom'; // Import render method
import Datatable from "react-bs-datatable"; // Import this package
import { Row, Col } from "reactstrap";
import { db } from "../../../Firebase";
import noDataImg from "../../../assets/img/NoData.png";
import {
  BottomNavigation,
  BottomNavigationAction,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  withStyles,
  Zoom,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import AttachmentImageDialog from "./AttachmentDialog.jsx";
// import {
//     Pagination,
//     PaginationOpts,
//     TableHeader,
//     TableBody,
//     Filter,
//     useDatatableLifecycle,
//     shouldTableUpdate
//   } from 'react-bs-datatable'

// const CustomTable = React.memo(props => {
//     const {
//       data,
//       rowsPerPageOption,
//       tableHeaders,
//       onChangeFilter,
//       onPageNavigate,
//       classes,
//       onRowsPerPageChange,
//       onSortChange,
//       tableClass,
//       labels,
//       filterable,
//       filterText,
//       rowsPerPage,
//       currentPage,
//       sortedProp,
//       maxPage,
//       Components
//     } = useDatatableLifecycle(props);

//     return (
//       <>
//         <Components.Row className="controlRow__root">
//           <Components.Col xs="12">
//             <Filter
//               classes={classes}
//               tableHeaders={tableHeaders}
//               placeholder={labels.filterPlaceholder}
//               onChangeFilter={onChangeFilter}
//               filterText={filterText}
//               filterable={filterable}
//               components={{
//                 Adornment: Components.Adornment,
//                 Button: Components.Button,
//                 ClearIcon: Components.ClearIcon,
//                 FormControl: Components.FormControl,
//                 InputGroup: Components.InputGroup
//               }}
//             />
//           </Components.Col>
//         </Components.Row>
//         <Components.Row>
//           <Components.Col xs="12">
//             <Components.Table className={tableClass}>
//               <TableHeader
//                 classes={classes}
//                 tableHeaders={tableHeaders}
//                 sortedProp={sortedProp}
//                 onSortChange={onSortChange}
//                 components={{
//                   TableHead: Components.TableHead,
//                   TableCell: Components.TableCell,
//                   TableRow: Components.TableRow
//                 }}
//               />
//               <TableBody
//                 classes={classes}
//                 tableHeaders={tableHeaders}
//                 labels={labels}
//                 data={data}
//                 components={{
//                   TableBody: Components.TableBody,
//                   TableCell: Components.TableCell,
//                   TableRow: Components.TableRow
//                 }}
//               />
//             </Components.Table>
//           </Components.Col>
//         </Components.Row>
//         <Components.Row className="controlRow__root bottom">
//           <Components.Col xs={12} md={4} />
//           <Components.Col xs={12} md={4}>
//             <PaginationOpts
//               classes={classes}
//               labels={labels}
//               onRowsPerPageChange={onRowsPerPageChange}
//               rowsPerPage={rowsPerPage}
//               rowsPerPageOption={rowsPerPageOption}
//               components={{
//                 Form: Components.Form,
//                 FormGroup: Components.FormGroup,
//                 FormControl: Components.FormControl
//               }}
//             />
//           </Components.Col>
//           <Components.Col xs={12} md={4} className="text-right">
//             <Pagination
//               classes={classes}
//               data={data}
//               rowsPerPage={rowsPerPage}
//               currentPage={currentPage}
//               onPageNavigate={onPageNavigate}
//               labels={labels}
//               maxPage={maxPage}
//               components={{
//                 Button: Components.Button,
//                 ButtonGroup: Components.ButtonGroup
//               }}
//             />
//           </Components.Col>
//         </Components.Row>
//       </>
//     );ComplaintSuject: "Demo"
//   }, shouldTableUpdate);

const header = [
  { title: "Ticket #", prop: "ticketNo", sortable: true, filterable: true },
  {
    title: "Customer Name",
    prop: "customerName",
    sortable: true,
    filterable: true,
  },
  {
    title: "Customer Email",
    prop: "customerEmail",
    sortable: true,
    filterable: true,
  },
  {
    title: "Customer Mobile",
    prop: "customerMobile",
    sortable: true,
    filterable: true,
  },
  {
    title: "Product",
    prop: "customerProduct",
    sortable: true,
    filterable: true,
  },
  { title: "Reported On", prop: "createdOn", sortable: true, filterable: true },
  { title: "Ticket Status", prop: "status", sortable: true, filterable: true },
  {
    title: "Complaint Description",
    prop: "complaintDes",
    sortable: true,
    filterable: true,
  },
  { title: "Issue Type", prop: "issueType", sortable: true, filterable: true },
  {
    title: "Issued By",
    prop: "ticketIssuedBy",
    sortable: true,
    filterable: true,
  },
  {
    title: "Assigned To",
    prop: "ticketAssignTo",
    sortable: true,
    filterable: true,
  },
  {
    title: "Assigned By",
    prop: "ticketAssignBy",
    sortable: true,
    filterable: true,
  },
];

const onSortFunction = {
  date(columnValue) {
    // Convert the string date format to UTC timestamp
    // So the table could sort it by number instead of by string
    return moment(columnValue, "Do MMMM YYYY").valueOf();
  },
};

const customLabels = {
  first: "<<",
  last: ">>",
  prev: "<",
  next: ">",
  show: "Display ",
  entries: " rows",
  noResults: "There is no data to be displayed",
};

class Ticket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // New State
      firstName: "",
      shilpmisId: "",
      userRole: "",
      ticketNo: "",
      ticketArray: [],
      value: "",
      employeeData: [],
      assignPopUp: false,
      assignPopUpData: [],
      assignPopUpPreviousData: [],
      attachmentData: [],
      openAttachmentImage: false,
      remark: "",
      submitDataspin: false,
    };
  }

  componentDidMount() {
    this.setState({
      firstName: localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).firstName +
          " " +
          JSON.parse(localStorage.getItem("employeeObject")).lastName
        : " ",
      shilpmisId: localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).email
        : " ",
      userRole: localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).userRole
        : " ",
    });

    this.onCustomerSupportData();
    this.onGetEmployeeData();
  }

  onGetEmployeeData = () => {
    let employeeDataPromise = new Promise((resolve, reject) => {
      db.ref("/employees/").on("value", (queryEmployee) => {
        let employeeArray = [];
        queryEmployee.forEach((element) => {
          if(element.val().status == "active"){

            let object = element.val();
            object["id"] = element.key;
            employeeArray.push(object);
          
          }
        });
        resolve(employeeArray);
      });
    });
    employeeDataPromise.then((acceptedData) => {
      let filterDepartmentData = acceptedData.filter((data) => {
        return data.department.includes("Project Management");
      });
      this.setState(
        {
          employeeData: filterDepartmentData,
        },
        () => {
          console.log("emp Data", this.state.employeeData);
        }
      );
    });
  };

  onCustomerSupportData = () => {
    db.ref("/customer-support/").on("value", (queryTicket) => {
      let ticketData = [];
      let filterTicketData = [];
      queryTicket.forEach((ticket) => {
        let obj = ticket.val();
        obj["id"] = ticket.key;
        ticketData.push(obj);
      });

      if (this.state.userRole == "employee") {
        filterTicketData = ticketData.filter((data) => {
          return data.ticketAssignTo == this.state.shilpmisId;
        });
      } else {
        filterTicketData = ticketData;
      }

      this.setState(
        {
          ticketArray: filterTicketData,
        },
        () => console.log(this.state.ticketArray, "ticketData")
      );
    });
  };

  editTicketData = (
    id,
    assignTo,
    status,
    remark,
    issueType,
    ticketNo,
    issuedBy,
    product,
    cusName,
    compSubject,
    createdOn
  ) => {
    let data = {
      id: id,
      assignTo: assignTo,
      status: status,
      issuedBy: issuedBy,
      ticketNo: ticketNo,
      product: product,
      cusName: cusName,
      compSubject: compSubject,
      createdOn: createdOn,
      issueType: issueType,
    };
    this.setState({
      assignPopUpData: data,
      assignPopUpPreviousData: data,
      assignPopUp: true,
      remark: remark,
    });
    console.log("edit Data =<>", data);
    /*
    issuedBy
ticketNo
product
cusName
compSubject
createdOn
issueType
     */
  };

  openAttachmentImage = (id, attachment, ticketId, description) => {
    if (attachment) {
      let data = {
        id: id,
        imagesCollection: attachment,
        ticketId: ticketId,
        complaintDes: description,
      };
      this.setState({
        attachmentData: [data],
        openAttachmentImage: true,
      });
    } else {
      alert("No attachment available for selected ticket.");
    }
  };

  handleAssignTo = (e) => {
    if (e.target.name == "user") {
      let data = {
        id: this.state.assignPopUpData.id,
        assignTo: e.target.value.assignTo,
        status: "Assign",
        issuedBy: this.state.assignPopUpData.issuedBy,
        ticketNo: this.state.assignPopUpData.ticketNo,
        product: this.state.assignPopUpData.product,
        cusName: this.state.assignPopUpData.cusName,
        compSubject: this.state.assignPopUpData.compSubject,
        createdOn: this.state.assignPopUpData.createdOn,
        issueType: this.state.assignPopUpData.issueType,
      };
      this.setState(
        {
          assignPopUpData: data,
        },
        () => console.log("state data", this.state.assignPopUpData)
      );
    } else if (e.target.name == "status") {
      let data = {
        id: this.state.assignPopUpData.id,
        assignTo: this.state.assignPopUpData.assignTo,
        status: e.target.value.status,
        issuedBy: this.state.assignPopUpData.issuedBy,
        ticketNo: this.state.assignPopUpData.ticketNo,
        product: this.state.assignPopUpData.product,
        cusName: this.state.assignPopUpData.cusName,
        compSubject: this.state.assignPopUpData.compSubject,
        createdOn: this.state.assignPopUpData.createdOn,
        issueType: this.state.assignPopUpData.issueType,
      };
      this.setState(
        {
          assignPopUpData: data,
        },
        () => console.log("state data", this.state.assignPopUpData)
      );
    } else if (e.target.name == "remark") {
      this.setState(
        {
          remark: e.target.value,
        },
        () => console.log("state data", this.state.remark)
      );
    }
  };

  handleAssignSubmit = () => {
    if (this.state.assignPopUpData.status == "") {
      alert("Please select the status of the ticket");
    } else if (this.state.assignPopUpData.assignTo == "") {
      alert("Please assign the responsible employee for this ticket");
    } else if (this.state.remark == "") {
      alert("Please enter the remark for this ticket");
    } else {
      this.setState({
        submitDataspin: true,
      });
      let key = this.state.assignPopUpData.id;
      let user = this.state.assignPopUpData.assignTo;
      let status = this.state.assignPopUpData.status;
      let issuedBy = this.state.assignPopUpData.issuedBy;
      let ticketNo = this.state.assignPopUpData.ticketNo;
      let product = this.state.assignPopUpData.product;
      let cusName = this.state.assignPopUpData.cusName;
      let compSubject = this.state.assignPopUpData.compSubject;
      let createdOn = this.state.assignPopUpData.createdOn;
      let issueType = this.state.assignPopUpData.issueType;

      console.log(
        "in submit",
        key,
        user,
        status,
        issuedBy,
        ticketNo,
        product,
        cusName,
        compSubject,
        createdOn,
        issueType
      );
      new Promise((resolve, reject) => {
        db.ref("/customer-support/" + key)
          .update({
            ticketAssignTo: user,
            ticketAssignBy: this.state.shilpmisId,
            status: status,
            remark: this.state.remark,
            UpdatedOn: new Date().getTime(),
          })
          .then((res) => {
            db.ref("/dashboard-logs")
              .push({
                /*ticketIssuedBy ticketNo customerProduct customerName complaintSuject createdOn  issueType */
                type: "ticket", // for filter
                changeBy: this.state.firstName, //done
                ticketIssuedBy: issuedBy, // for heading done
                ticketAssignTo: user, // done
                ticketAssignBy: this.state.shilpmisId, // done
                ticketNo: ticketNo, // for heading done
                customerProduct: product, // for heading done
                customerName: cusName, // for heading done
                complaintSuject: compSubject, // for heading done
                status: status, // done
                createdOn: createdOn, // for heading done
                UpdatedOn: new Date().getTime(), // done
                issueType: issueType, // for heading done
                remark: this.state.remark, // done
              })
              .then((res) => {
                this.setState({
                  assignPopUp: false,
                  submitDataspin: false,
                });
                console.log(res, "res");
                console.log("Status Updated");
                resolve("success");
              })
              .catch((err) => {
                console.log(err, "err");
              });
          })
          .catch((err) => {
            console.error("error", err);
            reject(err);
          });
      });
    }
  };

  render() {
    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="page-title">
                <div className="float-left">
                  <h1 className="title">Tickets</h1>
                </div>
              </div>

              <div className="col-12">
                <section className="box " style={{ display: "block" }}>
                  <header className="panel_header">
                    <h2 className="title float-left">All Tickets</h2>
                    {/* <BottomNavigation
                      value={this.state.value}
                      onChange={(e, newvalue) =>
                        this.setState({
                          value: newvalue,
                        })
                      }
                      //   className={classes.root}
                      showLabels
                    >
                      <BottomNavigationAction
                        label="Open Ticket"
                        value="Open"
                        onClick={() => console.log("Open")}
                      />
                      <BottomNavigationAction
                        label="Assign"
                        value="Assign"
                        onClick={() => console.log("Assign")}
                      />
                      <BottomNavigationAction
                        label="In Progress"
                        value="InProgress"
                        onClick={() => console.log("InProgress")}
                      />
                      <BottomNavigationAction
                        label="Resolved"
                        value="Resolved"
                        onClick={() => console.log("Resolved")}
                      />
                    </BottomNavigation> */}
                  </header>
                  <div className="content-body">
                    <div className="row">
                      <div className="col-lg-12 dt-disp">
                        {/* <Datatable
                          tableHeader={header}
                          tableBody={this.state.ticketArray}
                          keyName="userTable"
                          tableClass="striped table-hover table-responsive"
                          rowsPerPage={10}
                          rowsPerPageOption={[5, 10, 15, 20]}
                          initialSort={{ prop: "id", isAscending: true }}
                          onSort={onSortFunction}
                          labels={customLabels}
                        /> */}

                        <TableContainer
                          style={
                            this.state.ticketArray.length === 0
                              ? {
                                  height: "400px",
                                  display: "flex",
                                  justifyContent: "center",
                                }
                              : {}
                          }
                        >
                          {this.state.ticketArray.length === 0 && (
                            <img
                              src={noDataImg}
                              style={{ height: "400px" }}
                            ></img>
                          )}
                          {this.state.ticketArray.length > 0 && (
                            <Table aria-label="simple table" id="table-data">
                              <TableHead
                                style={{
                                  fontWeight: "bold",
                                  background: "lightgrey",
                                }}
                              >
                                <TableRow>
                                  <TableCell data-f-bold>
                                    <b>Ticket No</b>
                                  </TableCell>
                                  <TableCell data-f-bold>
                                    <b>Customer Name</b>
                                  </TableCell>
                                  <TableCell data-f-bold>
                                    <b>Customer Email</b>
                                  </TableCell>
                                  <TableCell data-f-bold>
                                    <b>Customer Mobile</b>
                                  </TableCell>
                                  <TableCell data-f-bold>
                                    <b>Product</b>
                                  </TableCell>
                                  <TableCell data-f-bold>
                                    <b>Reported On</b>
                                  </TableCell>
                                  <TableCell data-f-bold>
                                    <b>Ticket Status</b>
                                  </TableCell>
                                  <TableCell data-f-bold>
                                    <b>Complaint Description</b>
                                  </TableCell>
                                  <TableCell data-f-bold>
                                    <b>Issue Type</b>
                                  </TableCell>
                                  <TableCell
                                    data-f-bold
                                    // style={{ display: "none" }}
                                  >
                                    <b>Issued By</b>
                                  </TableCell>
                                  <TableCell
                                    data-f-bold
                                    // style={{ display: "none" }}
                                  >
                                    <b>Assigned To</b>
                                  </TableCell>
                                  <TableCell data-exclude="true">
                                    <b>Assigned By</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>Action</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>View Attachment</b>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.ticketArray.map((data, index) => {
                                  return (
                                    <TableRow
                                      key={index}
                                      style={
                                        data.status == "Assign"
                                          ? {
                                              background:
                                                "lightgoldenrodyellow",
                                            }
                                          : data.status == "Open"
                                          ? { background: "aliceblue" }
                                          : data.status == "Resolved"
                                          ? { background: "#c4e5c4ab" }
                                          : data.status == "InProgress"
                                          ? { background: "#e5737382" }
                                          : {}
                                      }
                                    >
                                      <TableCell>{data.ticketNo}</TableCell>
                                      <TableCell>{data.customerName}</TableCell>
                                      <TableCell>
                                        {data.customerEmail}
                                      </TableCell>
                                      <TableCell>
                                        {data.customerMobile}
                                      </TableCell>
                                      <TableCell style={{}}>
                                        {data.customerProduct.join(`\n`)}
                                      </TableCell>
                                      <TableCell>
                                        {new Date(
                                          data.createdOn
                                        ).toLocaleDateString("en-IN")}
                                      </TableCell>
                                      <TableCell>{data.status}</TableCell>
                                      <TableCell>{data.complaintDes}</TableCell>
                                      <TableCell>{data.issueType}</TableCell>
                                      <TableCell>
                                        {data.ticketIssuedBy}
                                      </TableCell>
                                      <TableCell>
                                        {data.ticketAssignTo == ""
                                          ? "-"
                                          : data.ticketAssignTo.split("@")[0]}
                                      </TableCell>
                                      <TableCell>
                                        {data.ticketAssignBy == ""
                                          ? "-"
                                          : data.ticketAssignBy.split("@")[0]}
                                      </TableCell>
                                      <TableCell data-exclude="true">
                                        <EditIcon
                                          titleAccess="Click to Edit Information"
                                          color="inherit"
                                          onClick={() =>
                                            this.editTicketData(
                                              data.id,
                                              data.ticketAssignTo,
                                              data.status,
                                              data.remark
                                                ? data.remark
                                                : "No Remark",
                                              data.issueType,
                                              data.ticketNo,
                                              data.ticketIssuedBy,
                                              data.customerProduct,
                                              data.customerName,
                                              data.complaintSuject,
                                              data.createdOn
                                            )
                                          }
                                          variant="contained"
                                          size="sm"
                                        />
                                      </TableCell>
                                      <TableCell data-exclude="true">
                                        {/* <LightTooltip
                                          TransitionComponent={Zoom}
                                          title={
                                            data.attachment
                                              ? data.attachment.length > 0
                                                ? "View attachment files"
                                                : "No attachment"
                                              : "No attachment"
                                          }
                                        > */}
                                        <ImageSearchIcon
                                          variant="contained"
                                          color={
                                            data.attachment
                                              ? data.attachment.length > 0
                                                ? "inherit"
                                                : "disabled"
                                              : "disabled"
                                          }
                                          titleAccess={
                                            data.attachment
                                              ? data.attachment.length > 0
                                                ? "View attachment files"
                                                : "No attachment"
                                              : "No attachment"
                                          }
                                          onClick={() =>
                                            this.openAttachmentImage(
                                              data.id,
                                              data.attachment,
                                              data.ticketNo,
                                              data.complaintDes
                                            )
                                          }
                                        />
                                        {/* </LightTooltip> */}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          )}
                        </TableContainer>
                        <Dialog
                          open={this.state.assignPopUp}
                          onClose={() => this.setState({ assignPopUp: false })}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            {"Customer Support Ticket Edit"}
                          </DialogTitle>
                          <DialogContent style={{ height: "250px" }}>
                            <DialogContentText id="alert-dialog-description">
                              {this.state.userRole !== "employee"
                                ? "Please Assign ticket to responsive employee from the available list of employee !!!"
                                : "Please select the status of the ticket"}
                            </DialogContentText>
                            <hr></hr>
                            <Grid container spacing={3}>
                              {this.state.userRole !== "employee" && (
                                <Grid item md={6} xs={12}>
                                  <FormControl
                                    style={{ width: "100%" }}
                                    variant="outlined"
                                  >
                                    <InputLabel id="user-label">
                                      Employee
                                    </InputLabel>
                                    <Select
                                      labelId="user-label"
                                      id="user"
                                      name="user"
                                      label="user"
                                      value={this.state.assignPopUpData}
                                      onChange={(e) => this.handleAssignTo(e)}
                                      renderValue={(dept) => dept.assignTo}
                                    >
                                      {this.state.employeeData.map((data) => {
                                        return (
                                          <MenuItem
                                            value={{
                                              id: "",
                                              assignTo: data.email,
                                              status: "",
                                            }}
                                          >
                                            {data.firstName +
                                              " (" +
                                              data.email +
                                              ")"}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              )}
                              <Grid item md={6} xs={12}>
                                <FormControl
                                  style={{ width: "100%" }}
                                  variant="outlined"
                                >
                                  <InputLabel id="status-label">
                                    Status
                                  </InputLabel>
                                  <Select
                                    labelId="status-label"
                                    id="status"
                                    name="status"
                                    label="status"
                                    value={this.state.assignPopUpData}
                                    onChange={(e) => this.handleAssignTo(e)}
                                    renderValue={(dept) => dept.status}
                                  >
                                    {this.state.userRole !== "employee" && (
                                      <MenuItem
                                        value={{
                                          id: "",
                                          assignTo: "",
                                          status: "Assign",
                                        }}
                                      >
                                        {"Assign"}
                                      </MenuItem>
                                    )}
                                    <MenuItem
                                      value={{
                                        id: "",
                                        assignTo: "",
                                        status: "InProgress",
                                      }}
                                    >
                                      {"InProgress"}
                                    </MenuItem>
                                    <MenuItem
                                      value={{
                                        id: "",
                                        assignTo: "",
                                        status: "Resolved",
                                      }}
                                    >
                                      {"Resolved"}
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <TextField
                                  name="remark"
                                  onChange={(e) => this.handleAssignTo(e)}
                                  value={this.state.remark}
                                  variant="outlined"
                                  label={"Enter Remark"}
                                  autoFocus
                                  type="text"
                                  fullWidth
                                  id="remark"
                                />
                              </Grid>
                            </Grid>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={() =>
                                this.setState({ assignPopUp: false })
                              }
                              color="primary"
                            >
                              Cancel
                            </Button>
                            {this.state.submitDataspin && (
                              <CircularProgress
                                color="primary"
                                style={{ margin: "5px" }}
                                size={20}
                              />
                            )}
                            <Button
                              onClick={() => this.handleAssignSubmit()}
                              color="primary"
                              autoFocus
                            >
                              Submit
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </Col>
          </Row>
          <AttachmentImageDialog
            open={this.state.openAttachmentImage}
            Product={this.state.attachmentData}
            onClose={() => this.setState({ openAttachmentImage: false })}
          />
        </div>
      </div>
    );
  }
}

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);

export default Ticket;
