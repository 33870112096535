import React, { Component } from 'react';
import "./footer.css";

export default class Footer extends Component {
    render() {
        return (
            <div>
                <div class="fixed-bottom" id="footeriframe">
                    <footer>
                        <div class="container">
                            <p class={this.props.variant === "dark" ? "textblack" : "textwhite"}>Powered by <a href="https://melzo.com/" target="_blank"
                                class="underline">melzo.com</a>
                            </p>
                        </div>
                    </footer>
                </div>
            </div>
        )
    }
}
