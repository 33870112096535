import React, { Component } from "react";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import { db } from "../../../Firebase";
import CountTo from 'react-count-to';
//import fs from 'fs';
import '../../general/FileDownloader/PDFDownload.jsx';
import AppPdf from "../../general/FileDownloader/PDFDownload.jsx";
import { PDFDownloadLink } from "@react-pdf/renderer";
import companylogoicon from "../../../assets/img/color.png";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import autoTable from 'jspdf-autotable';
import companyImage from '../../../assets/img/color.png';

//import htmlDocx from 'html-docx-js/dist/html-docx';

export default class PayRoll extends React.Component {
    constructor() {
        super();

        this.state = {
            id: "",
            email: "",
            rateOfWage: "",
            workingDays: "",
            basicPay: 0,
            hra: 0,
            residualPay: 0,
            grossSalary: 0,
            pfEmp: 0,
            pfCompany: 0,
            pfTotal: 0,
            esicEmp: 0,
            esicCompany: 0,
            professionalTax: 0,
            subTotal: 0,
            ctc: 0,
            firstName: "",
            netPay: 0,
            pfNo: "",
            uanNo: "",
            departmentName: "",
            conveyanceAllowance: 0,
            isOpen: false,
            allPayRollData: [],
            allDepartmentData: [],
            payRollList: [],
            monthlyData: [],
            payrollTempData: [],
            filtered: [],
        }
    }

    componentDidMount() {
        // this.getEmpData();
        this.onGetDepartmentData();
    }

    getEmpData = () => {
        let payrollDataPromise = new Promise((resolve, reject) => {
            db.ref("/payRoll/")
                .orderByChild("email")
                .equalTo(localStorage.getItem("email"))
                .on("value", (querySnap) => {
                    console.log("Calledd")
                    let payRollArray = [];
                    console.log("querySnap :: ", querySnap)
                    querySnap.forEach((element) => {
                        console.log("Calledd Dataa :: ", element.val())
                        let obj = element.val();
                        obj["id"] = element.key;
                        payRollArray.push(obj);
                    });
                    resolve(payRollArray);
                });
        });
        payrollDataPromise.then(resolvedData => {
            console.log("resolvedData ::: ", resolvedData)
            if (resolvedData.length > 0) {
                this.setState({
                    payRollList: resolvedData,
                    payrollTempData: resolvedData,
                    basicPay: resolvedData[resolvedData.length - 1].basicPay,
                    rateOfWage: resolvedData[resolvedData.length - 1].rateOfWage,
                    workingDays: resolvedData[resolvedData.length - 1].workingDays,
                    hra: resolvedData[resolvedData.length - 1].hra,
                    residualPay: resolvedData[resolvedData.length - 1].residualPay,
                    grossSalary: resolvedData[resolvedData.length - 1].grossSalary,
                    pfEmp: resolvedData[resolvedData.length - 1].pfEmp,
                    pfCompany: resolvedData[resolvedData.length - 1].pfCompany,
                    pfTotal: resolvedData[resolvedData.length - 1].pfTotal,
                    esicEmp: resolvedData[resolvedData.length - 1].esicEmp,
                    esicCompany: resolvedData[resolvedData.length - 1].esicCompany,
                    professionalTax: resolvedData[resolvedData.length - 1].professionalTax,
                    subTotal: resolvedData[resolvedData.length - 1].subTotal,
                    ctc: resolvedData[resolvedData.length - 1].ctc,
                    netPay: resolvedData[resolvedData.length - 1].netPay,
                    pfNo: resolvedData[resolvedData - 1].pfNo,
                    uanNo: resolvedData[resolvedData - 1].uanNo,
                }, () => {
                    var date = (new Date(this.state.generateDate).toLocaleDateString("en-US")).split('/')
                    console.log("Date :: ", date[0])
                });
            }
        })
    }

    onGetEmployeeData = (event) => {
        this.setState({ departmentName: event.target.value }, () => {
            let employeesDataPromise = new Promise((resolve, reject) => {
                db.ref("/employees/")
                    .on("value", (queryEmploee) => {
                        let empArray = [];
                        queryEmploee.forEach(element => {
                            if (element.val().department != undefined && element.val().status == "active") {
                                element.val().department.map((obj) => {
                                    if (obj === this.state.departmentName) {
                                        let obj1 = element.val();
                                        obj1["id"] = element.key;
                                        empArray.push(obj1);
                                    }
                                })
                            }
                        })
                        resolve(empArray);
                    });
            });
            employeesDataPromise.then(resolvedData => {
                this.setState({
                    allPayRollData: resolvedData,
                }, () => console.log("employee data:", this.state.allPayRollData));
            })
        })
    }

    onGetPayrollData() {
        let payrollDataPromise = new Promise((resolve, reject) => {
            db.ref("/payRoll/")
                .on("value", (querySnap) => {
                    let payRollArray = [];
                    querySnap.forEach((element) => {
                        let obj = element.val();
                        obj["id"] = element.key;
                        payRollArray.push(obj);
                    });
                    resolve(payRollArray);
                })
        })
        payrollDataPromise.then(resolvedData => {
            this.setState({
                payRollList: resolvedData,
                payrollTempData: resolvedData,
            }, () => console.log("payroll data on emp call ::", this.state.payRollList));
        })
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        }, () => {
            console.log("Changed state", this.state)
            let basicPayTemp = parseFloat(this.state.basicPay);
            let hraTemp = parseFloat(this.state.hra);
            let residualPayTemp = parseFloat(this.state.residualPay);
            let conveyanceAllowanceTemp = parseFloat(this.state.conveyanceAllowance);
            let grossSalaryTemp = parseFloat(basicPayTemp + hraTemp + residualPayTemp + conveyanceAllowanceTemp);
            let pfEmpTemp = parseFloat(basicPayTemp * 0.12);
            let pfCompanyTemp = parseFloat(basicPayTemp * 0.12);
            let pfTotalTemp = parseFloat(pfEmpTemp + pfCompanyTemp);
            let esicEmpTemp = parseFloat(this.state.esicEmp);
            let esicCompanyTemp = parseFloat(this.state.esicCompany);
            let esicTemp = parseFloat(esicEmpTemp + esicCompanyTemp);
            let professionalTaxTemp = parseFloat(this.state.professionalTax);
            let subTotalTemp = parseFloat(pfTotalTemp + esicTemp + professionalTaxTemp);
            let ctcTemp = parseFloat(grossSalaryTemp + (esicCompanyTemp + pfCompanyTemp + professionalTaxTemp));
            if (ctcTemp < 21001) {
                let esicEmpTemp = parseFloat(grossSalaryTemp * 0.0075);
                let esicCompanyTemp = parseFloat(grossSalaryTemp * 0.0325);
                this.setState({
                    esicEmp: Math.round(esicEmpTemp),
                    esicCompany: Math.round(esicCompanyTemp),
                })
            } else {
                let esicEmpTemp = 0;
                let esicCompanyTemp = 0;
                this.setState({
                    esicEmp: parseFloat(esicEmpTemp),
                    esicCompany: parseFloat(esicCompanyTemp)
                })
            }
            let netPayTemp = parseFloat(ctcTemp - (professionalTaxTemp + esicTemp + pfTotalTemp));

            this.setState({
                grossSalary: Math.round(grossSalaryTemp),
                pfEmp: Math.ceil(pfEmpTemp),
                pfCompany: Math.ceil(pfCompanyTemp),
                pfTotal: Math.round(pfTotalTemp),
                subTotal: Math.round(subTotalTemp),
                ctc: Math.round(ctcTemp),
                netPay: Math.floor(netPayTemp),
            });
        });
    }

    onGetDepartmentData() {
        let departmentDataPromise = new Promise((resolve, reject) => {
            db.ref("/departmentMaster/").on("value", (queryDepartment) => {
                let departmentArray = [];
                queryDepartment.forEach((element) => {
                    let obj = element.val();
                    obj["id"] = element.key;
                    departmentArray.push(obj);
                })
                resolve(departmentArray);
            });
        });
        departmentDataPromise.then(resolvedData => {
            this.setState({
                allDepartmentData: resolvedData,
            }, () => console.log("department data:", this.state.allDepartmentData));
        })
    }

    handleSubmit = () => {
        db.ref("/payRoll/").push({
            email: this.state.email,
            departmentName: this.state.departmentName,
            basicPay: this.state.basicPay,
            hra: this.state.hra,
            residualPay: this.state.residualPay,
            grossSalary: this.state.grossSalary,
            pfEmp: this.state.pfEmp,
            pfCompany: this.state.pfCompany,
            pfTotal: this.state.pfTotal,
            esicEmp: this.state.esicEmp,
            esicCompany: this.state.esicCompany,
            professionalTax: this.state.professionalTax,
            subTotal: this.state.subTotal,
            ctc: this.state.ctc,
            netPay: this.state.netPay,
            conveyanceAllowance: this.state.conveyanceAllowance,
            pfNo: this.state.pfNo,
            uanNo: this.state.uanNo,
        })
    }

    clearInput = () => {
        this.setState({
            id: "",
            email: "",
            rateOfWage: 0,
            workingDays: 0,
            basicPay: 0,
            hra: 0,
            residualPay: 0,
            grossSalary: 0,
            pfEmp: 0,
            pfCompany: 0,
            pfTotal: 0,
            esicEmp: 0,
            esicCompany: 0,
            professionalTax: 0,
            subTotal: 0,
            ctc: 0,
            firstName: "",
            departmentName: "",
            netPay: 0,
            pfNo: "",
            uanNo: "",
        });
    }

    pdfToHTML = () => {
        const compName = "Shilpmis Technologies Pvt. Ltd.";
        const compAddress = 'Shilp Maitri House, Bhatar Char Rasta, opp. Shiv Dham Temple, Surat, Gujarat 395017 ';

        var doc = new jsPDF();
        doc.addImage(companyImage, 'png', 10, 5, 20, 20);
        doc.setFontSize(20);
        doc.text(compName, 60, 10);
        doc.setFontSize(10);
        doc.text(compAddress, 40, 25);
        var nestedTableHeight = 100;
        var nestedTableCell = {
            content: '',
            styles: { minCellHeight: 43, halign: 'center', }
        }

        doc.autoTable({
            theme: 'plain',
            head: [['Name:', 'Bank Name:'], ['Designation:', 'Bank A/c No.:'], ['Department:', 'P.F. No.:'], ['Location:', 'UAN No.:'], ['Date of Joining:', 'Working Days:']],
            body: [[nestedTableCell]],
            foot: [['Gross Salary:', 'Advance:'], ['', 'Mobile Expanse:'], ['', 'Total:'], ['', 'Net Salary:'], ['', 'CTC:']],
            startY: 50,
            halign: 'center',
            tableLineWidth: 0.3,
            tableLineColor: [0, 0, 0],
            styles: {
                lineColor: [0, 0, 0],
                //lineWidth: 0.3,
            },
            didDrawCell: function (data) {
                if (data.row.index === 0 && data.row.section === 'body' && data.column.index === 0) {
                    doc.autoTable({
                        startY: data.cell.y + 2,
                        margin: { left: data.cell.x },
                        tableWidth: data.cell.width - 4,
                        theme: 'plain',
                        tableLineWidth: 0.3,
                        tableLineColor: [0, 0, 0],
                        styles: {
                            maxCellHeight: 4,
                            // lineColor: [0, 0, 0],
                            // lineWidth: 0.3,
                        },
                        columns: [
                            { dataKey: 'payElement', header: 'Pay Element:' },
                            { dataKey: 'earning', header: 'Earning(RS.):' },
                        ],
                        foot: [['Basic Salary:'], ['Residual Pay:'], ['Allowance:'], ['HRA:']],
                    })
                } else if (data.row.index === 0 && data.row.section === 'body' && data.column.index === 1) {
                    doc.autoTable({
                        startY: data.cell.y + 2,
                        margin: { left: data.cell.x },
                        tableWidth: data.cell.width - 4,
                        theme: 'plain',
                        // tableLineWidth: 0.3,
                        // tableLineColor: [0, 0, 0],
                        styles: {
                            maxCellHeight: 4,
                            // lineColor: [0, 0, 0],
                            // lineWidth: 0.3,
                        },
                        columns: [
                            { dataKey: 'deductElement', header: 'Deduct Element:' },
                            { dataKey: 'deduction', header: 'Deduction(RS.):' },
                        ],
                        foot: [["Employer's PF:"], ["Employee's PF:"], ["Employer's ESIC:"], ["Employee's ESIC"]],
                    })
                }
            },
        })
        doc.save("salarySlip.pdf");
    };

    data(rowCount) {
        rowCount = rowCount || 10
        var body = []
        this.state.payRollList.map((obj) => {
            body.push({
                id: obj.userID,
                name: obj.firstName + obj.lastName,
                email: obj.email,
            })
        })
        return body
    }

    bodyRows(rowCount) {
        rowCount = rowCount || 10
        var body = []
        this.state.payRollList.map((obj) => {
            body.push({
                id: obj.userID,
                name: obj.firstName + obj.lastName,
                email: obj.email,
            })
        })
        return body
    };

    render() {
        return (
            <div>
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>

                            <div className="page-title">
                                <div className="float-left">
                                    <h1 className="title">Salary Structure</h1>
                                </div>
                            </div>

                            <div className="row margin-0">
                                <div className="col-12">
                                    <section className="box">
                                        <header className="panel_header">
                                            <h2 className="title float-left">Account Details</h2>
                                        </header>
                                        <div className="content-body">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-12 col-md-12">
                                                    <form>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="pfNo">P.F. No</label>
                                                                <input type="number" name="pfNo" id="pfNo" className="form-control" placeholder="000000" value={this.state.pfNo} onChange={this.handleChange} />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="uanNo">UAN No</label>
                                                                <input type="number" name="uanNo" id="uanNo" className="form-control" placeholder="000000" value={this.state.uanNo} onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div className="col-12">
                                    <section className="box">
                                        <header className="panel_header">
                                            <h2 className="title float-left">Pay</h2>
                                        </header>
                                        <div className="content-body">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-12 col-md-12">
                                                    <form>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="inputDepartment">Department</label>
                                                                <select id="inputDepartment" className="form-control" value={this.state.departmentName} onChange={this.onGetEmployeeData}>
                                                                    <option>Choose...</option>
                                                                    {this.state.allDepartmentData.map((object, index) => {
                                                                        return (<option key={object.departmentName} value={object.departmentName}>{object.departmentName}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="inputEmp">Employee Name</label>
                                                                <select id="inputEmp" className="form-control" name="email" onChange={this.handleChange}>
                                                                    <option>Choose...</option>
                                                                    {this.state.allPayRollData.map((obj) => {
                                                                        return (<option key={obj.departmentName} value={obj.email}>{obj.firstName + " " + obj.lastName}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-4">
                                                                <label htmlFor="basicPay">Basic Pay</label>
                                                                <input type="number" name="basicPay" id="basicPay" className="form-control" value={this.state.basicPay} onChange={this.handleChange} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label htmlFor="hra">HRA(House Rent Allowance)</label>
                                                                <input type="number" name="hra" id="hra" className="form-control" value={this.state.hra} onChange={this.handleChange} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label htmlFor="residualPay">Residual Pay</label>
                                                                <input type="number" id="residualPay" name="residualPay" className="form-control" value={this.state.residualPay} onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-12">
                                                                <label htmlFor="grossPay">Gross Pay</label>
                                                                <input type="number" id="grossPay" name="grossSalary" className="form-control" value={this.state.grossSalary} onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                            <div className="col-12">
                                <section className="box">
                                    <header className="panel_header">
                                        <h2 className="title float-left">Deduction</h2>
                                    </header>
                                    <div className="content-body">
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 col-12 col-md-12">
                                                <form>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="pfEmp">Provident Fund(Employee)</label>
                                                            <input type="number" id="pfEmp" className="form-control" name="pfEmp" value={this.state.pfEmp} />
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="pfComp">Provident Fund(Employer)</label>
                                                            <input type="number" id="pfComp" className="form-control" name="pfCompany" value={this.state.pfCompany} />
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="esicEmp">ESIC(Employee)</label>
                                                            <input type="number" id="pfEmp" className="form-control" name="esicEmp" value={this.state.esicEmp} />
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="esicComp">ESIC(Employer)</label>
                                                            <input type="number" id="pfComp" className="form-control" name="esicCompany" value={this.state.esicCompany} />
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="professionalTax">Professional Tax</label>
                                                            <input type="number" id="professionalTax" className="form-control" name="professionalTax" value={this.state.professionalTax} onChange={this.handleChange} />
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="subTotal">Sub-Total</label>
                                                            <input type="number" id="subTotal" className="form-control" name="subTotal" value={this.state.subTotal} />
                                                        </div>
                                                    </div>
                                                    {this.state.departmentName === "Sales & Marketing" &&
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="conveyanceAllowance">Conveyance Allowance</label>
                                                                <input type="number" id="conveyanceAllowance" className="form-control" name="conveyanceAllowance" value={this.state.conveyanceAllowance} onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                    }
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div className="col-12">
                                <section className="box">
                                    <header className="panel_header">
                                        <h2 className="title float-left">Total</h2>
                                    </header>
                                    <div className="content-body">
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 col-12 col-md-12">
                                                <form>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="totalCTC">Total CTC</label>
                                                            <input type="number" id="totalCTC" className="form-control" name="ctc" value={this.state.ctc} />
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="netPay">Net Pay</label>
                                                            <input type="number" id="netPay" className="form-control" name="netPay" value={this.state.netPay} />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <button className="btn btn-primary" onClick={this.handleSubmit}>Submit</button>
                                        <button className="btn btn-secondary" onClick={this.clearInput}>Cancel</button>
                                        <button className="btn btn-primary" onClick={this.pdfToHTML}>Download Payment Receipt</button>
                                        {/* <PDFDownloadLink>
                                            <AppPdf />
                                        </PDFDownloadLink> */}
                                    </div>
                                </section>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}