import React from 'react';
import {
    TabContent, TabPane, Nav, NavItem, NavLink, Button, CardText,
    Card, CardTitle, Row, Col,
} from 'reactstrap';

import classnames from 'classnames';

import {

} from '../../../components';
import { db } from '../../../Firebase';

export default class AnnouncementandPolocies extends React.Component {
    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this);
        this.toggle1 = this.toggle1.bind(this);
        this.state = {
            activeTab: '1',
            activeTab1: '1',
            activeTab2: '1',
            activeTab3: '1',
            activeTab4: '1',
            activeTab5: '1',
            announcement: '',
            policies: '',
        };
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    toggle1(tab) {
        if (this.state.activeTab1 !== tab) {
            this.setState({
                activeTab1: tab
            });
        }
    }
    toggle2(tab) {
        if (this.state.activeTab2 !== tab) {
            this.setState({
                activeTab2: tab
            });
        }
    }
    toggle3(tab) {
        if (this.state.activeTab3 !== tab) {
            this.setState({
                activeTab3: tab
            });
        }
    }
    toggle4(tab) {
        if (this.state.activeTab4 !== tab) {
            this.setState({
                activeTab4: tab
            });
        }
    }
    toggle5(tab) {
        if (this.state.activeTab5 !== tab) {
            this.setState({
                activeTab5: tab
            });
        }
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    onRegisterAnnouncement = (
        announcement,
        entryBy,
    ) => {
        db.ref("/announcement/").push({
            announcement: announcement,
            entryBy: JSON.parse(localStorage.getItem('employeeObject')).firstName + JSON.parse(localStorage.getItem('employeeObject')).lastName,
        }, () => this.clearData());
    }

    onSaveAnnouncement = () => {
        this.onRegisterAnnouncement(
            this.state.announcement
        )
    };

    clearData = () => {
        this.setState({
            announcement: "",
            policies: "",
        })
    }

    onSavePolicies = () => {
        db.ref("/policies/").push({
            policies: this.state.policies,
            entryBy: JSON.parse(localStorage.getItem('employeeObject')).firstName + JSON.parse(localStorage.getItem('employeeObject')).lastName,
        },() => this.clearData());
    }

    render() {

        return (
            <div>
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>

                            <div className="page-title">
                                <div className="float-left">
                                    <h1 className="title">Rules</h1>
                                </div>
                            </div>
                            <div className="col-12">
                                <section className="box ">
                                    <header className="panel_header">
                                        <h2 className="title float-left">Company Rules</h2>
                                    </header>
                                    <div className="content-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h5>Notify</h5>
                                                <br />
                                                <div>
                                                    <Nav tabs>
                                                        <NavItem>
                                                            <NavLink
                                                                className={classnames({ active: this.state.activeTab === '1' })}
                                                                onClick={() => { this.toggle('1'); }}
                                                            >
                                                                <i className="i-home"></i> Announcement
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem>
                                                            <NavLink
                                                                className={classnames({ active: this.state.activeTab === '2' })}
                                                                onClick={() => { this.toggle('2'); }}
                                                            >
                                                                <i className="i-user"></i> Policies
                                                        </NavLink>
                                                        </NavItem>
                                                    </Nav>
                                                    <TabContent activeTab={this.state.activeTab}>
                                                        <TabPane tabId="1">
                                                            <Row>
                                                                <Col sm="12">
                                                                    <Card body>
                                                                        <CardTitle>Add Announcement</CardTitle>
                                                                        <textarea type="text" placeholder="Write here..." name="announcement" style={{ width: "100%" }} value={this.state.announcement} onChange={this.handleChange}></textarea>
                                                                        <button className="btn btn-primary" onClick={this.onSaveAnnouncement}>Add</button>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        </TabPane>
                                                        <TabPane tabId="2">
                                                            <Row>
                                                                <Col>
                                                                    <Card body>
                                                                        <CardTitle>Add Policies</CardTitle>
                                                                        <textarea type="text" placeholder="Write here..." name="policies" style={{ width: "100%" }} value={this.state.policies} onChange={this.handleChange}></textarea>
                                                                        <button className="btn btn-primary" onClick={this.onSavePolicies}>Add</button>
                                                                    </Card>
                                                                </Col>                                                        
                                                            </Row>
                                                        </TabPane>
                                                    </TabContent>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

