import React from "react";
//import { DropdownMenu, DropdownItem, } from 'reactstrap';
// used for making the prop types of this component
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import profileStd from "../../../assets/img/profile2.png";

var BASEDIR = process.env.REACT_APP_BASEDIR;

class Memberslist extends React.Component {
  render() {
    var membersList = [];
    for (var i = 0; i < this.props.members.length; i++) {
      membersList.push(
        <div
          className="col-md-6 col-lg-4"
          key={i}
          style={{ paddingBottom: "15px" }}
        >
          {console.log(i)}
          <div
            className="team-member "
            style={{
              width: "100%",
              height: "100%",
              flexWrap: "wrap",
              margin: " 0 0 0 0",
            }}
          >
            <div
              className="team-img"
              style={{ display: "flex", justifyContent: "center", height : "150px" }}
            >
              <img
                className="img-fluid"
                src={
                  this.props.members[i].profilePhoto !== "" && this.props.members[i].profilePhoto !== "Na"
                    ? this.props.members[i].profilePhoto
                    : profileStd
                }
                alt={this.props.members[i].name}
                height="100px"
                width="100px"
                style={{ marginTop: "5px" }}
              />
            </div>
            <div
              className="team-info"
              style={{ display: "grid", justifyContent: "center" }}
            >
              {/* This will redirect to individual user Profile */}
              <h3 style={{ textAlign: "center" }}>
                <NavLink
                  to={BASEDIR + "/ui-profile/" + this.props.members[i].userID}
                >
                  {this.props.members[i].name}
                </NavLink>
              </h3>
              <span style={{ textAlign: "center" }}>
                {this.props.members[i].designation}
              </span>
              <ul
                className="social-icons list-inline list-unstyled"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "initial",
                  margin: "5px 0 0 0",
                }}
              >
                {(this.props.members[i].isSocialMedia && this.props.members[i].isSocialMedia!=="Na") && (
                  <li className="list-inline-item">
                    <a href={this.props.members[i].facebook} target="_blank">
                      <i className="i-social-facebook icon-primary icon-xs"></i>
                    </a>
                  </li>
                )}
                {/* {this.props.members[i].isSocialMedia && (
                  <li className="list-inline-item">
                    <a href={this.props.members[i].twitter} target="_blank">
                      <i className="i-social-twitter icon-primary icon-xs"></i>
                    </a>
                  </li>
                )} */}
                 {(this.props.members[i].isSocialMedia && this.props.members[i].isSocialMedia!=="Na") && (
                  <li className="list-inline-item">
                    <a href={this.props.members[i].linkedIn} target="_blank">
                      <i className="i-social-linkedin icon-primary icon-xs"></i>
                    </a>
                  </li>
                )}
              </ul>
              {/* <p>{this.props.members[i].department[0]}</p> */}
            </div>
          </div>
        </div>
      );
    }
    return <div className="row">{membersList}</div>;
  }
}

Memberslist.propTypes = {
  members: PropTypes.arrayOf(PropTypes.object),
};

export default Memberslist;
