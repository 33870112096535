import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
// import { Tab } from "bootstrap";
import LeadForm from "./LeadForm";
import { db } from "../../Firebase";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import { Skeleton, TabContext } from "@material-ui/lab";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import MuiAlert from "@material-ui/lab/Alert";
import TableToExcel from '@linways/table-to-excel';
import GetAppIcon from '@material-ui/icons/GetApp';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableFooter,
  TablePagination,
  TableRow,
  Typography,
  Tooltip,
  Zoom,
  withStyles,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import noDataImg from "../../assets/img/NoData.png";
import Page from "./page";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import ConfirmDialog from "./ConfirmDialog";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default class LeadLockAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leadLockForm: false,
      allData: [],
      showSnackbar: false,
      snackbarText: "",
      page: 0,
      rowsPerPage: 5,
      searchArray: [],
      formattedAllLeads: [],
      isRowOpen: false,
      noDataImage: false,
      isLoading: true,
      userIdToRemove: "",
      confirmDialog: {
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: () => { },
      },
    };
  }
  componentDidMount = () => {
    this.onGetLeadData();
  };
  componentWillUnmount = () => {
    db.ref("/leadList").off();
  };

  onGetLeadData = () => {
    let getLeadDataPromise = new Promise((resolve, reject) => {
      db.ref("/leadList").on("value", (query) => {
        let leadData = [];
        query.forEach((element) => {
          if (element.val().isEnabled) {
            let obj = element.val();
            obj["id"] = element.key;
            leadData.push(obj);
          }
        });
        resolve(leadData);
      });
    });
    getLeadDataPromise.then((resolvedData) => {
      this.onGetLeadUserData(resolvedData);
    });
  };

  onGetLeadUserData = (partialLeadData) => {
    let getLeadUserDataPromise = new Promise((resolve, reject) => {
      db.ref("/leadUsers").on("value", (query) => {
        let leadUsers = [];
        query.forEach((element) => {
          if (element.val().isEnabled) {
            if (element.val().userRole !== "admin") {
              let obj = element.val();
              obj["id"] = element.key;
              leadUsers.push(obj);
            }
          }
        });
        resolve(leadUsers);
      });
    });
    getLeadUserDataPromise.then((resolve) => {
      // Adding User's data whos record not available in lead database
      let leadData = partialLeadData;
      let data = [];
      resolve.map((userId) => {
        let count = 0;
        leadData.map((leadUserId) => {
          if (leadUserId.cpUserId == userId.id) {
            data.push(leadUserId);
            count += 1;
          }
        });
        if (count == 0) {
          data.push({
            clientCity: "",
            clientEmail: "",
            clientMobileNumber: "",
            clientName: "",
            cpCity: userId.city,
            cpEmail: userId.email,
            cpMobileNumber: userId.mobileNumber,
            cpName: userId.userName,
            cpUserId: userId.id,
            entryTime: 0,
            id: "",
            isEnabled: true,
            leadDate: 0,
            product: "",
            remark: "",
            statusUpdateTime: "",
          });
        }
      });
      // Outside Outer map fuction
      this.setLoader(false);
      const formattedLeads = Object.entries(groupBy(data, "cpEmail")).map(
        (e) => ({
          Leads: e[1],
          Id: e[0],
          cpName: e[1][0].cpName,
          cpEmail: e[1][0].cpEmail,
          cpMobileNumber: e[1][0].cpMobileNumber,
          cpCity: e[1][0].cpCity,
          cpUserId: e[1][0].cpUserId,
          cpLeadCount: e[1][0].entryTime == 0 ? 0 : e[1].length,
        })
      );

      console.log("sorted leads",formattedLeads.sort((a,b) => b.cpLeadCount - a.cpLeadCount));
      if (formattedLeads.length === 0) {
        this.setNoDataImage(true);
      }
      this.setSearchArray(data);
      this.setMainArray(formattedLeads);
    });
  };

  onOpenForm = () => {
    this.setState({
      leadLockForm: true,
    });
  };
  onCloseForm = () => {
    this.setState({
      leadLockForm: false,
    });
  };

  closeFormAndRefresh = () => {
    this.setState({
      leadLockForm: false,
      showSnackbar: true,
      snackbarText: "New lead added successfully!",
    });

    this.onGetLeadData();
  };

  expotData = () => {
    TableToExcel.convert(document.getElementById('table-data'), {
      name: "lead.xlsx",
      sheet: {
        name: "Leads"
      }
    })
  }

  onLogout = () => {
    localStorage.clear();
    window.location.href = "/leads";
  };

  closeSnackbar = () => {
    this.setState({ showSnackbar: false });
  };

  setLoader = (newValue) => {
    this.setState({ isLoading: newValue });
  };

  setPage = (newValue) => {
    this.setState({ page: newValue });
  };

  setRowOpen = (newValue) => {
    this.setState({ isRowOpen: newValue });
  };
  setRowPerPage = (newValue) => {
    this.setState({ rowsPerPage: newValue });
  };

  setNoDataImage = (newValue) => {
    this.setState({ noDataImage: newValue });
  };

  setSearchArray = (newValue) => {
    this.setState({ searchArray: newValue });
  };

  setMainArray = (newValue) => {
    this.setState({ formattedAllLeads: newValue });
  };

  // Searching Functionality
  filterByValue = (array, value) =>
    array.filter(
      (data) =>
        data.projectName.toLowerCase().includes(value.toLowerCase()) ||
        data.userName.toLowerCase().includes(value.toLowerCase()) ||
        data.email.toLowerCase().includes(value.toLowerCase()) ||
        data.mobileNo.toString().includes(value.toLowerCase()) ||
        data.link.toLowerCase().includes(value.toLowerCase())
    );

  setSearch = (e) => {
    this.setPage(0);
    const searchResultData = this.filterByValue(
      this.state.searchArray,
      e.target.value
    );
    const searchFormattedData = Object.entries(
      groupBy(searchResultData, "projectName")
    ).map((e) => ({
      Leads: e[1],
      Id: e[0],
      cpName: e[1][0].cpName,
      cpEmail: e[1][0].cpEmail,
      cpMobileNumber: e[1][0].cpMobileNumber,
      cpCity: e[1][0].cpCity,
      cpUserId: e[1][0].cpUserId,
    }));
    if (searchResultData.length > 0) {
      this.setNoDataImage(false);
      this.setMainArray(searchFormattedData);
    } else {
      this.setNoDataImage(false);
      this.setMainArray(searchFormattedData);
    }
  };

  handleChangePage = (event, newPage) => {
    this.setPage(newPage);
  };

  handleChangeRowsPerPage = (event) => {
    this.setRowPerPage(parseInt(event.target.value, 10));
    this.setPage(0);
  };

  deleteCPUser = (idToRemove) => {
    // console.log("Deleting", idToRemove);
    this.setState({ userIdToRemove: idToRemove });
    this.setConfirmDialog({
      isOpen: true,
      title: "Are you sure you want to delete this User?",
      subTitle: "Once deleted, you can't undo this action",
      onConfirm: () => {
        this.confirmRemoveUser(idToRemove);
      },
    });
  };

  confirmRemoveUser = (userId) => {
    console.log("Confirm Remove", userId);
    this.setConfirmDialog({
      isOpen: false,
      title: "",
      subTitle: "",
      onConfirm: () => { },
    });
    const removeUser = new Promise((resolve, reject) => {
      db.ref("leadUsers/" + userId)
        .update({
          isEnabled: false,
        })
        .then((res) => {
          console.log("Deleting user", res);
          resolve("success");
        })
        .catch((err) => {
          console.error("Delete user error", err);
          reject(err);
        });
    });
    removeUser
      .then((result) => {
        this.onGetLeadData();
      })
      .catch((error) => { });
  };

  setConfirmDialog = (newValue) => {
    this.setState({ confirmDialog: newValue });
  };

  render() {
    return (
      <div>
        {/* {this.state.leadLockForm && <LeadForm closeAddForm={this.onCloseForm} successCallback={this.closeFormAndRefresh} />} */}
        {!this.state.leadLockForm && (
          <Page title="Melzo Leads Dashboard">
            <Container style={{ marginTop: "70px" }}>
              <Box
                display="flex"
                style={{ alignItems: "center", margin: "10px" }}
              >
                <Box flexGrow={1}>
                  <Typography variant="h5">Dashboard</Typography>
                </Box>

                <Box style={{ float: "right", display: "flex" }}>
                  <p style={{ marginTop: "15px" }}>
                    {localStorage.getItem("userName")}
                  </p>
                  <Button
                    style={{ margin: "10px" }}
                    variant="contained"
                    // color="Secondary"
                    onClick={() => this.onLogout()}
                  >
                    Log Out
                  </Button>
                </Box>
                <Button
                  style={{ float: "right", margin: "10px", display: "none" }}
                  variant="contained"
                  color="primary"
                  onClick={() => this.onOpenForm()}
                >
                  Add New Lead
                </Button>
              </Box>
              <Divider />
              <Box mt={3} mb={3}>
                <Card>
                  <Box md={12}>
                    <div className="overflowtable">
                      {this.state.isLoading && (
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Skeleton />
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <Skeleton />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Skeleton />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Skeleton />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Skeleton />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Skeleton />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      )}
                      {this.state.noDataImage && !this.state.isLoading && (
                        <img src={noDataImg} style={{ width: "100%" }}></img>
                      )}
                      {!this.state.isLoading && !this.state.noDataImage && (
                        <div>
                        <div style={{
                          margin: 'auto 0',
                          display: this.state.searchArray.length > 0 ? 'flex' : 'none',
                          position: 'relative',
                          justifyContent: 'flex-end',
                          width: '100%'
                        }}>
                          <LightTooltip
                            TransitionComponent={Zoom}
                            title="Click to download data"
                          >
                            <GetAppIcon style={{}}
                              onClick={() => this.expotData()}
                            />
                          </LightTooltip>
                        </div>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell />
                              <TableCell>
                                <b>{"CP Name"}</b>
                              </TableCell>
                              <TableCell>
                                <b>{"CP Mobile"}</b>
                              </TableCell>
                              <TableCell>
                                <b>{"CP Email"}</b>
                              </TableCell>
                              <TableCell>
                                <b>{"CP City"}</b>
                              </TableCell>
                              <TableCell>
                                <b>{"Lead Count"}</b>
                              </TableCell>
                              <TableCell>
                                <b>{"Action"}</b>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(this.state.rowsPerPage > 0
                              ? this.state.formattedAllLeads.slice(
                                this.state.page * this.state.rowsPerPage,
                                this.state.page * this.state.rowsPerPage +
                                this.state.rowsPerPage
                              )
                              : this.state.formattedAllLeads
                            ).map((row) => (
                              <Row
                                key={row.Id}
                                row={row}
                                deleteUser={this.deleteCPUser}
                              />
                            ))}
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TablePagination
                                rowsPerPageOptions={[
                                  5,
                                  10,
                                  25,
                                  { label: "All", value: -1 },
                                ]}
                                count={this.state.formattedAllLeads.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                SelectProps={{
                                  inputProps: { "aria-label": "rows per page" },
                                  native: true,
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={
                                  this.handleChangeRowsPerPage
                                }
                                ActionsComponent={TablePaginationActions}
                                style={{ overflow: "hidden" }}
                              />
                            </TableRow>
                          </TableFooter>
                        </Table>
                        <Table
                aria-label="simple table"
                id="table-data"
                style={{ display: "none" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell data-f-bold>Sr. No</TableCell>
                    <TableCell data-f-bold>Name</TableCell>
                    <TableCell data-f-bold>Email</TableCell>
                    <TableCell data-f-bold>Mobile Number</TableCell>
                    <TableCell data-f-bold>City</TableCell>
                    <TableCell data-f-bold>Client Name</TableCell>
                    <TableCell data-f-bold>Client Email</TableCell>
                    <TableCell data-f-bold>Client Mobile No</TableCell>
                    <TableCell data-f-bold>Client City</TableCell>
                    <TableCell data-f-bold>Product</TableCell>
                    <TableCell data-f-bold>Lead Date</TableCell>
                    <TableCell data-f-bold>Entry Date</TableCell>
                    <TableCell data-f-bold>Lead Status</TableCell>
                    <TableCell data-f-bold>Status Update Date</TableCell>
                    <TableCell data-f-bold>Remark</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.searchArray.map((data, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{index+1}</TableCell>
                        <TableCell>{data.cpName}</TableCell>
                        <TableCell>{data.cpEmail}</TableCell>
                        <TableCell>{data.cpMobileNumber}</TableCell>
                        <TableCell>{data.cpCity}</TableCell>

                        <TableCell>{data.clientName}</TableCell>
                        <TableCell>{data.clientEmail}</TableCell>
                        <TableCell>{data.clientMobileNumber}</TableCell>
                        <TableCell>{data.clientCity}</TableCell>
                        <TableCell>{data.product}</TableCell>
                        <TableCell>
                          {new Date(data.leadDate).toLocaleDateString("en-IN")}
                        </TableCell>
                        <TableCell>
                          {new Date(data.entryTime).toLocaleDateString("en-IN")}
                        </TableCell>
                        <TableCell>{data.status}</TableCell>
                        <TableCell>
                          {data.statusUpdateTime ? new Date(data.statusUpdateTime).toLocaleDateString("en-IN") : "No Update"}
                        </TableCell>
                        <TableCell>{data.remark}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
                        </div>
                      )}
                    </div>
                  </Box>
                </Card>
              </Box>

              <Snackbar
                open={this.state.showSnackbar}
                autoHideDuration={5000}
                key="topcenter"
                onClose={this.closeSnackbar}
              >
                <Alert onClose={this.closeSnackbar} severity="success">
                  {this.state.snackbarText}
                </Alert>
              </Snackbar>

              <ConfirmDialog
                confirmDialog={this.state.confirmDialog}
                setConfirmDialog={this.setConfirmDialog}
              />
            </Container>
          </Page>
        )}
      </div>
    );
  }
}

function TablePaginationActions(props) {
  // const classes = useStyles();
  // const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ display: "flex" }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}

function Row(props) {
  const { row, deleteUser } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <TableRow
        onClick={() => (row.cpLeadCount == 0 ? "" : setOpen(!open))}
        style={{ cursor: "pointer" }}
      >
        <TableCell>
          <IconButton
            aria-label={"caseHistoryLogs_expandrow"}
            size="small"
            disabled={row.cpLeadCount == 0 ? true : false}
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.cpName}</TableCell>
        <TableCell>{row.cpMobileNumber}</TableCell>
        <TableCell>{row.cpEmail}</TableCell>
        <TableCell>{row.cpCity}</TableCell>
        <TableCell>{row.cpLeadCount}</TableCell>
        <TableCell>
          {<DeleteForeverIcon onClick={() => deleteUser(row.cpUserId)} />}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label={"project social interaction"}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>{"Client Name"}</b>
                    </TableCell>
                    <TableCell>
                      <b>{"Mobile No"}</b>
                    </TableCell>
                    <TableCell>
                      <b>{"Email"}</b>
                    </TableCell>
                    <TableCell>
                      <b>{"City"}</b>
                    </TableCell>
                    <TableCell>
                      <b>{"Product"}</b>
                    </TableCell>
                    <TableCell>
                      <b>{"Date"}</b>
                    </TableCell>
                    <TableCell>
                      <b>{"Status"}</b>
                    </TableCell>
                    <TableCell>
                      <b>{"Update On"}</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.Leads.map((leadRow, index) => (
                    <TableRow key={index}>
                      <TableCell>{leadRow.clientName}</TableCell>
                      <TableCell>{leadRow.clientMobileNumber}</TableCell>
                      <TableCell>{leadRow.clientEmail}</TableCell>
                      <TableCell>{leadRow.clientCity}</TableCell>
                      <TableCell>{leadRow.product}</TableCell>
                      <TableCell>
                        {new Date(leadRow.leadDate).toLocaleDateString("en-IN")}
                      </TableCell>
                      <TableCell>{leadRow.status}</TableCell>
                      <TableCell>
                        {leadRow.statusUpdateTime ? new Date(leadRow.statusUpdateTime).toLocaleDateString("en-IN") : "No Update"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function groupBy(objectArray, property) {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    // Add object to list for given key's value
    acc[key].push(obj);

    return acc;
  }, []);
}

const LightTooltip = withStyles(theme => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 13
  }
}))(Tooltip);