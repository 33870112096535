import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import {
    Card,
    CardContent,
    Divider,
    TableContainer
  } from "@material-ui/core";
import { TabContext } from "@material-ui/lab";
import AppBar from "@material-ui/core/AppBar";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Tab from "@material-ui/core/Tab";
import {MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import Form from "reactstrap/lib/Form";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
import MomentUtils from "@date-io/moment";
import { db } from "../../Firebase";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import noDataImg from "../../assets/img/NoData.png";

const Holidays = () => {
    const [tabValue,setTabValue] = useState("2")
    const [holidayName,setHolidayName] = useState("");
    const [selectDates,setSelectDate]=useState( new Date());
    const [holidayDescription,setHolidayDescription] = useState("");
    const [holidayList,setHolidayList] = useState([]);
    const [showUpdate,setShowUpdate] = useState(false);
    const [updateId,setUpdateId] = useState("");
    const [userStatus,setUserStatus] = useState("");
    
    let currentUserRole = localStorage.getItem("employeeObject")
    ? JSON.parse(localStorage.getItem("employeeObject")).userRole
    : " ";


    const handleChangeTab = (event, newValue) => {
        if (newValue == "2") {
         setTabValue("2")
        } else if (newValue == "1") {
          setTabValue("1")
        } 
      };

    
    const validateData = () =>{
        if(holidayName.trim() == "") {
            alert("Holiday name can not be blank");
            return false;
        } else if (selectDates == new Date().toLocaleString().split(",")[0]) {
            alert("please select another holiday date");
            return false;
        }    
        
        let filterdData = holidayList.filter(singleHolidays => (singleHolidays.name == holidayName));

        if (filterdData.length > 0) {
            alert(holidayName + " Already exist");
            return false;
        }   else {
            return true;
        }
    }

    const onSubmits = (e) =>{
        e.preventDefault();
       
        if (validateData()) {
            db.ref('/holidays').push({
                name:holidayName,
                date:new Date(selectDates).toDateString("en-IN"),
                description:holidayDescription
             })
            
        }
        onGetHolidayData(); 
        handleCancel();
        setTabValue("2");
    }


    const handleCancel = () => {
        setHolidayName("");
        setSelectDate(new Date().toLocaleDateString("en-IN"))
        setHolidayDescription("")
    }

    const onGetHolidayData = () => {
        let holidayDataPromise = new Promise((resolve, reject) => {
            db.ref('/holidays').on('value', querySnapShot => {
                let data = [];
                querySnapShot.forEach(element => {
                    let obj = element.val();
                    obj["id"] = element.key;
                    data.push(obj);
                });
                resolve(data)
            });
        });
        holidayDataPromise.then(acceptedData => {
            setHolidayList(acceptedData.sort(function(a, b) { return new Date(a.date) - new Date(b.date) }))
            onDeleteHolidays(acceptedData);
        })
        
    }

    useEffect(()=>{

      setUserStatus(
        localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).status : ""
      )

        onGetHolidayData();  
       
    },[])


    function checkAvailability(arr, val) {
        
        let res = arr.filter(singleHolidays => ( new Date(singleHolidays.date).toLocaleDateString("en-IN")  == val));
       
        if(res.length > 0){
            return res;
        } else{
            return res;
        }
    }

    const onDeleteHolidays = (acceptedData) =>{
        let res = (checkAvailability(acceptedData, new Date().toLocaleDateString("en-IN")));
        if(res[0]){ 
            db.ref('/holidays').child(res[0].id).remove();
        }
    }

    const handleDelete = (id) =>{
        if (window.confirm("Delete?"))  
        { 
            db.ref('/holidays').child(id).remove();
            onGetHolidayData();  
         }       
    }

    const handleUpdate = (id,name,desc,date) =>{
        setShowUpdate(true)
        setTabValue("1")
        setHolidayName(name)
        setHolidayDescription(desc)
        setSelectDate(date)
        setUpdateId(id)
    }

    const onUpdate = () =>{
        console.log("ID", updateId);

        db.ref("/holidays/" + updateId).update({
            name:holidayName,
            date:new Date(selectDates).toDateString("en-IN"),
            description:holidayDescription
        }).then(()=>{
            onGetHolidayData(); 
            setTabValue("2")
            setShowUpdate(false)
            setHolidayName("")
            setHolidayDescription("")
            setSelectDate(new Date())
            setUpdateId("")
        }); 
    }

  return (
    <>
        <div className="content">
        <Container style={{ marginTop: "20px" }}>
        <Divider />
          <Card style={{ marginTop: "60px" }}>
            <CardContent>
            <TabContext value={tabValue}>
            {currentUserRole === "HR" ?
                  <AppBar
                    position="static"
                    style={{ background: "white", color: "black" }}
                  >
                    <TabList
                       onChange={handleChangeTab}
                      aria-label="profiletab"
                    >
                      <Tab
                        label="Add Holidays"
                        value="1"
                        style={{ fontFamily: "Montserrat", outline: "0px" }}
                      />
                      
                      
                      <Tab
                        label="Holidays"
                        value="2"
                        style={{ fontFamily: "Montserrat", outline: "0px" }}
                      />
                       </TabList>
                  </AppBar>    
                   :
                   <AppBar
                   position="static"
                   style={{ background: "white", color: "black" }}
                 >
                   <TabList
                      onChange={handleChangeTab}
                     aria-label="profiletab"
                   >
                     <Tab
                       label="Holidays"
                       value="2"
                       style={{ fontFamily: "Montserrat", outline: "0px" }}
                     />
                      </TabList>
                 </AppBar>    
                   }
                <TabPanel id="tab-panels" value="1">
                <div className="content-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <Form inline>
                            <FormGroup className="form-group col-md-4">
                                    <Label htmlFor="Name" className="mr-sm-2">Name</Label>
                                    <input type="text" value={holidayName} onChange={(e) => setHolidayName(e.target.value)} name="holidaysName" id="holidaysName" style={{borderBottom:"1px solid #555555"}} placeholder="holidays Name" />
                                </FormGroup>

                                <div className="form-group col-md-4">
                                    <Label htmlFor="Name" className="mr-sm-2">Date</Label>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk
                                        variant="inline"
                                        inputVariant="filled"
                                        name="fromDate"
                                        format="MM/DD/yyyy"
                                        animateYearScrolling
                                        style={{width:"90%"}}
                                        id="datetime-local2"
                                        value={selectDates}
                                        InputAdornmentProps={{ position: "start" }}
                                        inputProps={{ style: { margin: "0" } }}
                                        onChange={(date) => setSelectDate(date)}
                                    />
                                     </MuiPickersUtilsProvider>
                                </div>

                                <div className="form-group col-md-4">
                                    <Label htmlFor="designationCode" className="mr-sm-2">Description</Label>
                                    <textarea value={holidayDescription} name="comments" variant="outlined" aria-labelledby="comment" aria-label="nkflvfn" id="modal-textfield"
                                    required autoComplete="off" placeholder="Enter Holidays Description"  style={{borderBottom:"1px solid #555555",fontSize:"15px"}}
                                    onChange={(e)=>setHolidayDescription(e.target.value)}
                                    ></textarea>
                                </div>
                                
                            </Form>
                            {
                            !showUpdate ? 
                            <button className="btn btn-primary" onClick={onSubmits} style={{ marginTop: "10px" }}>Submit</button>
                            :
                            <button className="btn btn-primary" onClick={onUpdate} style={{ marginTop: "10px" }}>Update</button>
                            }
                            <button className="btn btn-secondary" onClick={handleCancel}  style={{ marginTop: "10px" }}>Cancel</button>
                        </div>
                    </div>
                </div>
                </TabPanel>

                <TabPanel value="2">
                <TableContainer>
                {(holidayList.length == 0 && userStatus=="active") && (
                  <img
                    src={noDataImg}
                    style={{
                      height: "300px",
                      margin: "20px auto 5px auto",
                      display: "flex",
                    }}
                  ></img>
                )}

          {(holidayList.length > 0 && userStatus=="active") && (
              <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                      <TableCell></TableCell>
                      <TableCell align="center"><b>Name</b></TableCell>
                      <TableCell align="center"><b>Date</b></TableCell>
                      <TableCell align="center"><b>Description</b></TableCell>
                      {(currentUserRole === "HR") && (<TableCell align="center"><b>Action</b></TableCell>)}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                    holidayList.map((data, index)=>(
                      <TableRow key={data.id}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{data.name}</TableCell>
                      <TableCell align="center">
                      {new Date(data.date).toLocaleDateString("en-IN")}
                      </TableCell>
                      <TableCell align="center">{data.description}</TableCell>
                      {(currentUserRole === "HR") && ( <TableCell align="center">
                      <button className="ApproveBtn" style={{padding:"2px 4px 2px 4px"}} onClick={()=>{handleDelete(data.id)}}>Delete</button>
                      &nbsp; &nbsp;
                      <button className="CancelBtn" style={{padding:"2px 4px 2px 4px"}} onClick={()=>{handleUpdate(data.id,data.name,data.description,data.date)}}>Update</button>
                      </TableCell>)}
 
                      </TableRow>
                    ))}
                    </TableBody>
            </Table>
            )}
           </TableContainer>
                </TabPanel> 
                </TabContext>
            </CardContent>
          </Card>
        </Container>
        </div>
    </>
  )
}

export default Holidays