import React from "react";
import { Row, Col } from "reactstrap";
import { useState } from "react";
import * as XLSX from "xlsx";
import { db } from "../../../Firebase";
import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import melzoIcon from "../../../assets/img/color.png";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

let cnt=0;
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "maxContent",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
  },
  titleIcon: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
    },
  },
}));

const UploadUserData = () => {
  const [excelFile, setExcelFile] = useState(null);
  const [excelFileError, setExcelFileError] = useState(null);
  const [excelData, setExcelData] = useState(null);
  const [employeeData, setEmployeeData] = useState([]);
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  // handle File
  const fileType = ["application/vnd.ms-excel", "*.xlsx"];
  const handleFile = (e) => {
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      // console.log(selectedFile.type);
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFileError(null);
          setExcelFile(e.target.result);
        };
      } else {
        setExcelFileError("Please select only excel file types");
        setExcelFile(null);
      }
    } else {
      console.log("plz select your file");
    }
  };

  // submit function
  const handleSubmit = (e) => {
    e.preventDefault();

    
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: "buffer", cellDates: true, cellNF: false, cellText: false, raw:false,dateNF:'dd/mm/yyyy' });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);
      checkPresentOrNot(data);
    } else {
      setExcelData(null);
    }
  };

  const onGetEmployeeData = () => {
    
    db.ref("/employees/").child("-NADGkBgXykebw-oA3zp").remove();
    
    let employeeDataPromise = new Promise((resolve, reject) => {
      db.ref("/employees/").on("value", (queryEmployee) => {
        let employeeArray = [];
        queryEmployee.forEach((element) => {
          if (element.val().status == "active") {
            let object = element.val();
            object["id"] = element.key;
            employeeArray.push(object);
          }
        });
        resolve(employeeArray);
      });
    });
    employeeDataPromise.then((acceptedData) => {
      setEmployeeData(acceptedData);
    });
  };

  console.log("EMP DATA", employeeData);

  const checkPresentOrNot = (data) => {
    let Users = data.filter((itemA) => {
      return !employeeData.find((itemB) => {
        return itemA.userID == itemB.userID;
      });
    });

    function toTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }
    function isNumber(n) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); } 

    function epoch (date) {
      //let data = date.split("")
       return  (Date.parse(date) + 21669) 
     // console.log("data",Date.parse(date))
    }

    for (let i = 0; i < Users.length; i++) {
      if (!isNumber(Users[i].altContact) || !isNumber(Users[i].mobile)) {
        alert("Enter Mobile number are not Valid Number");
      } else {
        cnt+=1;
        db.ref("/employees")
          .push({
            //Users[i]  new Date('2016-06-15').getTime()
            address: Users[i].address ? Users[i].address : "",
            altContact: Users[i].altContact ? Users[i].altContact : "",
            dateOfBirth: Users[i].dateOfBirth
              ? epoch(Users[i].dateOfBirth)
              : "",
            department: Users[i].department ? Users[i].department.split(",") : "",
            designation: Users[i].designation
              ? toTitleCase(Users[i].designation)
              : "",
            email: Users[i].email ? Users[i].email : "",
            emergencyContactName: Users[i].emergencyContactName
              ? toTitleCase(Users[i].emergencyContactName)
              : "",
            entryBy: Users[i].entryBy ? Users[i].entryBy : "",
            entryData: Users[i].entryData ? Users[i].entryData : "",
            entryTime: Users[i].entryTime ? Users[i].entryTime : "",
            firstName: Users[i].firstName
              ? toTitleCase(Users[i].firstName)
              : "",
            gmail: Users[i].gmail ? Users[i].gmail : "",
            image: Users[i].image ? Users[i].image : "",
            joiningDate: Users[i].joiningDate
              ? epoch(Users[i].joiningDate)
              : "",
            lastName: Users[i].lastName ? toTitleCase(Users[i].lastName) : "",
            leaveTime: Users[i].leaveTime ? Users[i].leaveTime : "",
            mobile: Users[i].mobile ? Users[i].mobile : "",
            password: Users[i].password ? Users[i].password : "",
            pref: Users[i].pref ? Users[i].pref : "",
            relation: Users[i].relation ? Users[i].relation : "",
            remotely: Users[i].remotely ? Users[i].remotely : "",
            reportingTo: Users[i].reportingTo ? Users[i].reportingTo : "",
            status: Users[i].status ? Users[i].status : "",
            updateBy: Users[i].updateBy ? Users[i].updateBy : "",
            updateDate: Users[i].updateDate
              ?  epoch(Users[i].updateDate)
              : "",
            userID: Users[i].userID ? Users[i].userID : "",
            userRole: Users[i].userRole ? Users[i].userRole : "",
            attendanceId: Users[i].attendanceId ? Users[i].attendanceId : "",
            facebook: Users[i].facebook ? Users[i].facebook : "",
            isPublic: Users[i].isPublic ? Users[i].isPublic : "",
            isSocialMedia: Users[i].isSocialMedia
              ? Users[i].isSocialMedia
              : "",
            linkedIn: Users[i].linkedIn ? Users[i].linkedIn : "",
            resignedDate: Users[i].resignedDate
              ? epoch(Users[i].resignedDate)
              : "",
          })
          .then(() => {
            if (i == Users.length - 1) {
              setOpen(true);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const CustomModal = () => {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper}>
          <div
            disableRipple
            className={classes.titleIcon}
            style={{ background: "transparent",marginLeft:"40%" }}
          >
            {/* <InfoIcon color="primary" /> */}
            <img src={melzoIcon} width="50px"  style={{marginTop:"5%" }} ></img>
          </div>

          <div className="modalFields">
            <h3>{cnt} Data sucessfully uploaded</h3>
          </div>
        </div>
      </Modal>
    );
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    onGetEmployeeData();
  }, []);

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Upload User Data</h1>
              </div>
            </div>

            <div className="row margin-0">
              <div className="col-12">
                <section className="box">
                  <header className="panel_header">
                    <h2 className="title float-left">Upload User Data</h2>
                  </header>
                  <div className="content-body">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-12 col-md-12">
                        {/* upload file section */}
                        <div className="form">
                          <form
                            className="form-group"
                            autoComplete="off"
                            onSubmit={handleSubmit}
                          >
                            <label>
                              <h5>Upload Excel file</h5>
                            </label>
                            <br></br>
                            <input
                              type="file"
                              className="form-control"
                              onChange={handleFile}
                              required
                            ></input>
                            {/* {excelFileError&&<div className='text-danger'
                            style={{marginTop:5+'px'}}>{excelFileError}</div>} */}
                            <button
                              type="submit"
                              className="btn btn-success"
                              style={{ marginTop: 5 + "px" }}
                            >
                              Submit
                            </button>
                          </form>
                        </div>

                        <br></br>
                        <hr></hr>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <CustomModal />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UploadUserData;
