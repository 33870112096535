import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import { Card, CardContent, Divider, TableContainer } from "@material-ui/core";
import { TabContext } from "@material-ui/lab";
import AppBar from "@material-ui/core/AppBar";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Tab from "@material-ui/core/Tab";
import { db } from "../../Firebase";
import noDataImg from "../../assets/img/NoData.png";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Pagination from '@material-ui/lab/Pagination';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "maxContent",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
  },
}));

const travelRequest = () => {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();
  const [tabValue, setTabValue] = useState("1");
  const [travelList, setTravelList] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [pendingRequest, setPendingRequest] = useState([]);
  const [approveRequest, setApproveRequest] = useState([]);
  const [open, setOpen] = useState(false);
  const [tickets, setTickets] = useState("");

  const handleChangeTab = (event, newValue) => {
    if (newValue == "1") {
      handleTabChangeData(travelList, "Pending");
      setTabValue("1");
    } else if (newValue == "2") {
      handleTabChangeData(travelList, "Approved");
      setTabValue("2");
    } else if (newValue == "3") {
      setTabValue("3");
    }
  };

  const handleTabChangeData = (travelList, status) => {
    console.log("in tab change", travelList);
    let filterRequest;
    if (userRole === "HR") {
      filterRequest = travelList.filter((newData) => {
        return newData.status == status;
      });
    } else {
      filterRequest = travelList.filter((newData) => {
        return newData.deptHeadStatus == status;
      });
    }
    if (status == "Approved") {
      //---setApproveRequest(filterRequest);
      
      let employeeData = JSON.parse(localStorage.getItem("employeeObject"));                                                                    

      if (employeeData.userRole == "dept_head") {
        let teamLeadData = filterRequest.filter((filterData) => {
         
          return (
            filterData.role === "dept_head" ||
            filterData.role === "employee" ||
            filterData.role === "HR"
          );
        });
        let data = [];
               
         teamLeadData.map((LeaveData) => {
          let insideMapLoop = false;
          if (LeaveData.deparmentName) {
            
            let dataStored = true;
          
            for (let i = 0; i < employeeData.department.length; i++) {
              if (dataStored) {
                if (LeaveData.deparmentName.includes(employeeData.department[i])) {
                  insideMapLoop = false
                  data.push(LeaveData);
                }
              }
            }
          }
        });
        setApproveRequest(data.sort((a, b) => a - b).reverse());
      } else if (employeeData.userRole == "team_lead") {
        let data = [];
        let teamLeadData = filterRequest.filter((filterData) => {
          return filterData.role === "employee";
        });
  
        teamLeadData.map((LeaveData) => {
          let insideMapLoop = false;
          if (LeaveData.deparmentName) {
            let dataStored = true;
            for (let i = 0; i < employeeData.department.length; i++) {
              if (dataStored) {
                if (LeaveData.deparmentName.includes(employeeData.department[i])) {
                
                  data.push(LeaveData);
                }
              }
            }
          }
        });
        setApproveRequest(data.sort((a, b) => a - b).reverse());
      } else if (employeeData.userRole == "admin") {
        let data = [];
        let teamLeadData = filterRequest.filter((filterData) => {
          return (
            filterData.role === "dept_head" ||
            filterData.role === "team_lead" ||
            filterData.role === "employee" ||
            filterData.role === "HR"
          );
        });
  
        teamLeadData.map((LeaveData) => {
          let insideMapLoop = false;
          if (LeaveData.deparmentName) {
            let dataStored = true;
            for (let i = 0; i < employeeData.department.length; i++) {
              if (dataStored) {
                if (LeaveData.deparmentName.includes(employeeData.department[i])) {
                  data.push(LeaveData);
                }
              }
            }
          }
        });
  
        setApproveRequest(data.sort((a, b) => a - b).reverse());
      } else if(employeeData.userRole == "HR") {
        let data = [];
        console.log("filterRequest",filterRequest)
        filterRequest.map((LeaveData) => {
          if (LeaveData.deparmentName) {
            let dataStored = true;
            for (let i = 0; i < employeeData.department.length; i++) {
              if (dataStored) {
                  data.push(LeaveData);
              }
            }
          }
        });
        console.log("data",data)
        setApproveRequest(data.sort((a, b) => a - b).reverse());
      }

    } else {

      //-- setApproveRequest(filterRequest)--
      let employeeData = JSON.parse(localStorage.getItem("employeeObject"));                                                                    

      if (employeeData.userRole == "dept_head") {
        let teamLeadData = filterRequest.filter((filterData) => {
         
          return (
            filterData.role === "dept_head" ||
            filterData.role === "employee" ||
            filterData.role === "HR"
          );
        });
        let data = [];
         teamLeadData.map((LeaveData) => {
          let insideMapLoop = false;
          if (LeaveData.deparmentName) {
            
            let dataStored = true;
          
            for (let i = 0; i < employeeData.department.length; i++) {
              if (dataStored) {
                if (LeaveData.deparmentName.includes(employeeData.department[i])) {
                  insideMapLoop = false
                  data.push(LeaveData);
                }
              }
            }
          }
        });
        setPendingRequest(data.sort((a, b) => a - b).reverse());
      } else if (employeeData.userRole == "team_lead") {
        let data = [];
        let teamLeadData = filterRequest.filter((filterData) => {
          return filterData.role === "employee";
        });
  
        teamLeadData.map((LeaveData) => {
          let insideMapLoop = false;
          if (LeaveData.deparmentName) {
            let dataStored = true;
            for (let i = 0; i < employeeData.department.length; i++) {
              if (dataStored) {
                if (LeaveData.deparmentName.includes(employeeData.department[i])) {
                
                  data.push(LeaveData);
                }
              }
            }
          }
        });
        setPendingRequest(data.sort((a, b) => a - b).reverse());
      } else if (employeeData.userRole == "admin") {
        let data = [];
        let teamLeadData = filterRequest.filter((filterData) => {
          return (
            filterData.role === "dept_head" ||
            filterData.role === "team_lead" ||
            filterData.role === "employee" ||
            filterData.role === "HR"
          );
        });
  
        teamLeadData.map((LeaveData) => {
          let insideMapLoop = false;
          if (LeaveData.deparmentName) {
            let dataStored = true;
            for (let i = 0; i < employeeData.department.length; i++) {
              if (dataStored) {
                if (LeaveData.deparmentName.includes(employeeData.department[i])) {
                  data.push(LeaveData);
                }
              }
            }
          }
        });
  
        setPendingRequest(data.sort((a, b) => a - b).reverse());
      } else if(employeeData.userRole == "HR") {
        let data = [];
        console.log("filterRequest",filterRequest)
        filterRequest.map((LeaveData) => {
          if (LeaveData.deparmentName) {
            let dataStored = true;
            for (let i = 0; i < employeeData.department.length; i++) {
              if (dataStored) {
                  data.push(LeaveData);
              }
            }
          }
        });
        console.log("data",data)
        setPendingRequest(data.sort((a, b) => a - b).reverse());
      }
    }
  };

  const onGetTravelData = () => {
    let travelDataPromise = new Promise((resolve, reject) => {
      db.ref("/requestedTravel").on("value", (querySnapShot) => {
        let data = [];
        querySnapShot.forEach((element) => {
          let obj = element.val();
          obj["id"] = element.key;
          data.push(obj);
        });
        resolve(data);
      });
    });
    travelDataPromise.then((acceptedData) => {
     
      setTravelList(acceptedData.sort((a, b) => a - b).reverse());
    });
  };

  useEffect(()=>{
    handleTabChangeData(travelList, "Pending");
  },[travelList])

  useState(() => {
    setUserRole(
      localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).userRole
        : " "
    );

    onGetTravelData();
  }, []);

  const hrAction = (id, req) => {
    if (userRole == "HR") {
      if (req == "Approved") {
        db.ref("/requestedTravel/" + id)
          .update({
            status: "Approved",
          })
          .then(() => {
            onGetTravelData();
          });
      } else if (req == "Rejected") {
        db.ref("/requestedTravel/" + id)
          .update({
            status: "Rejected",
          })
          .then(() => {
            onGetTravelData();
          });
      }
    } else if (userRole !== "HR") {
      if (req == "Approved") {
        db.ref("/requestedTravel/" + id)
          .update({
            deptHeadStatus: "Approved",
          })
          .then(() => {
            onGetTravelData();
          });
      } else if (req == "Rejected") {
        db.ref("/requestedTravel/" + id)
          .update({
            deptHeadStatus: "Rejected",
          })
          .then(() => {
            onGetTravelData();
          });
      }
    }
  };

  const handleOpen = (tickets) => {
    setOpen(true);
    setTickets(tickets);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const CustomModal = () => {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper}>
          <div className="modalFields">
            <img src={tickets} alt="Tickets" />
          </div>
          <div className="modalFields" style={{marginTop:"10px"}}>
            <Button href={tickets} variant="contained" color="primary" className="marginrightbtn" target="_blank" download>
              Download
            </Button>

            <Button
              id="submit"
              variant="contained"
              color="primary"
              className="marginrightbtn"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div>
      <div className="content">
        <Container style={{ marginTop: "20px" }}>
          <Divider />
          <Card style={{ marginTop: "60px" }}>
            <CardContent>
              <TabContext value={tabValue}>
                {(userRole === "HR" || userRole === "dept_head") && (
                  <AppBar
                    position="static"
                    style={{ background: "white", color: "black" }}
                  >
                    <TabList onChange={handleChangeTab} aria-label="profiletab">
                      <Tab
                        label="Pending Request List"
                        value="1"
                        style={{ fontFamily: "Montserrat", outline: "0px" }}
                      />

                      <Tab
                        label="Approve Request List"
                        value="2"
                        style={{ fontFamily: "Montserrat", outline: "0px" }}
                      />

                      <Tab
                        label="Request List"
                        value="3"
                        style={{ fontFamily: "Montserrat", outline: "0px" }}
                      />
                    </TabList>
                  </AppBar>
                )}

                <TabPanel id="tab-panels" value="1">
                  
                  <TableContainer>
                    {pendingRequest.length == 0 && (
                      <img
                        src={noDataImg}
                        style={{
                          height: "300px",
                          margin: "20px auto 5px auto",
                          display: "flex",
                        }}
                      ></img>
                    )}

                    {pendingRequest.length > 0 && (
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                           
                            <TableCell align="center">
                              <b>Name</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>Email</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>Date</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>No. of Days</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>Location From</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>Location To</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>Planings</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>Meeting Details</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>Hr Status</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>Manager Status</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>Action</b>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {pendingRequest.map((data, index) => (
                            <TableRow key={data.id}>
                              {/* <TableCell align="center">{index + 1}</TableCell> */}
                              <TableCell align="center">{data.name}</TableCell>
                              <TableCell align="center">{data.email}</TableCell>
                              <TableCell align="center">
                                {new Date(data.date).toLocaleDateString(
                                  "en-IN"
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {data.noOfDays}
                              </TableCell>
                              <TableCell align="center">
                                {data.locationFrom}
                              </TableCell>
                              <TableCell align="center">
                                {data.locationTo}
                              </TableCell>
                              <TableCell align="center">
                              {Object.keys(data.planings).filter(k => data.planings[k] === true).join(" ")}
                              </TableCell>
                              <TableCell align="center">
                                {data.meetingDetails}
                              </TableCell>
                              <TableCell align="center">
                                {data.status}
                              </TableCell>
                              <TableCell align="center">
                                {data.deptHeadStatus}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ display: "flex",flexDirection:"column" }}
                              >
                                <button
                                  className="ApproveBtn"
                                  style={{ width:"115%",margin:"auto" }}
                                  disabled={ userRole === "HR" && data.deptHeadStatus == "Pending"}
                                  title = {userRole === "HR" && data.deptHeadStatus == "Pending" ? "Head Approval Pending" : "Approve Request"}
                                  onClick={(e) => {
                                    hrAction(data.id, "Approved");
                                  }}
                                >
                                  Approve
                                </button>
                                &nbsp; &nbsp;
                                <button
                                  disabled={userRole === "HR" && data.deptHeadStatus == "Pending"}
                                  title = {userRole === "HR" && data.deptHeadStatus == "Pending" ? "Head Approval Pending" : "Reject Request"}
                                  className="CancelBtn"
                                  style={{ width:"115%",margin:"auto" }}
                                  onClick={(e) => {
                                    hrAction(data.id, "Rejected");
                                  }}
                                >
                                  Reject
                                </button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    )}
                  </TableContainer>
                  {/* <Pagination count={10} color="primary" /> */}
                </TabPanel>

                <TabPanel id="tab-panels" value="2">
                  <TableContainer>
                    {approveRequest.length == 0 && (
                      <img
                        src={noDataImg}
                        style={{
                          height: "300px",
                          margin: "20px auto 5px auto",
                          display: "flex",
                        }}
                      ></img>
                    )}

                    {approveRequest.length > 0 && (
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                          
                            <TableCell align="center">
                              <b>Name</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>Email</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>Date</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>No. of Days</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>Location From</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>Location To</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>Planings</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>Meeting Details</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>Hr Status</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>Manager Status</b>
                            </TableCell>

                            <TableCell align="center">
                              <b>View Tickets</b>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {approveRequest.map((data, index) => (
                            <TableRow key={data.id}>
                              {/* <TableCell align="center">{index + 1}</TableCell> */}
                              <TableCell align="center">{data.name}</TableCell>
                              <TableCell align="center">{data.email}</TableCell>
                              <TableCell align="center">
                                {new Date(data.date).toLocaleDateString(
                                  "en-IN"
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {data.noOfDays}
                              </TableCell>
                              <TableCell align="center">
                                {data.locationFrom}
                              </TableCell>
                              <TableCell align="center">
                                {data.locationTo}
                              </TableCell>
                              <TableCell align="center">
                              {Object.keys(data.planings).filter(k => data.planings[k] === true).join(" ")}
                              </TableCell>
                              <TableCell align="center">
                                {data.meetingDetails}
                              </TableCell>
                              <TableCell align="center">
                                {data.status}
                              </TableCell>
                              <TableCell align="center">
                                {data.deptHeadStatus}
                              </TableCell>
                              <TableCell align="center">
                             { data.tickets.length!==0 ?
                                <Button
                                  id="UploadBtns"
                                  onClick={() => {
                                    handleOpen(data.tickets);
                                  }}
                                  variant="contained"
                                >
                                  View Ticket
                                </Button>
                                : data.status== "Pending" ? "Pending at HR Stage" :
                                data.status== "Rejected" ? "Rejected at HR Stage" :
                                 data.deptHeadStatus== "Pending" ? "Pending at Department Head Stage" :
                                data.deptHeadStatus== "Rejected" ? "Rejected at  Department Head Stage" :  
                                "Ticket Not Yet uploaded"}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    )}
                  </TableContainer>
                  <CustomModal />
                </TabPanel>

                <TabPanel id="tab-panels" value="3">
                  <TableContainer>
                    {travelList.length == 0 && (
                      <img
                        src={noDataImg}
                        style={{
                          height: "300px",
                          margin: "20px auto 5px auto",
                          display: "flex",
                        }}
                      ></img>
                    )}

                    {travelList.length > 0 && (
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                           
                            <TableCell align="center">
                              <b>Name</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>Email</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>Date</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>No. of Days</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>Location From</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>Location To</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>Planings</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>Meeting Details</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>Hr Status</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>Manager Status</b>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {travelList.map((data, index) => (
                            <TableRow key={data.id}>
                              {/* <TableCell align="center">{index + 1}</TableCell> */}
                              <TableCell align="center">{data.name}</TableCell>
                              <TableCell align="center">{data.email}</TableCell>
                              <TableCell align="center">
                                {new Date(data.date).toLocaleDateString(
                                  "en-IN"
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {data.noOfDays}
                              </TableCell>
                              <TableCell align="center">
                                {data.locationFrom}
                              </TableCell>
                              <TableCell align="center">
                                {data.locationTo}
                              </TableCell>
                              <TableCell align="center">
                              {Object.keys(data.planings).filter(k => data.planings[k] === true).join(" ")}
                              </TableCell>
                              <TableCell align="center">
                                {data.meetingDetails}
                              </TableCell>
                              <TableCell align="center">
                                {data.status}
                              </TableCell>
                              <TableCell align="center">
                                {data.deptHeadStatus}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    )}
                  </TableContainer>
                </TabPanel>
              </TabContext>
            </CardContent>
          </Card>
        </Container>
      </div>
    </div>
  );
};

export default travelRequest;
