import React, { useEffect, useState } from "react";
import { getDataOfApprovedView } from "../API`s/apis";
import ExpenseFormV2 from "../Dashboard/expenseFormV2";
import { db, firebaseApp } from "../../../Firebase";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";

import "../styling/table.css";

import {
  Box,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    margin: theme.spacing(4),
    display: "flex",
    justifyContent: "CENTER",
  },

  table: {
    minWidth: 700,
  },
}));

const Reimbursed = () => {
  const [dataForNormalView, setDataForApprovedView] = useState();
  let [showViewOnlyForm, setShowViewOnlyForm] = useState(false);
  const [userInfo, setUserInfo] = useState({ email: "", designation: "" });

  const [skeleton, setSkeleton] = useState(false);
  const [showNoBills, setShowNoBills] = useState(false);

  useEffect(() => {
    setSkeleton(true);
    let employeeData = JSON.parse(localStorage.getItem("employeeObject"));

    console.log("employee data", {
      email: employeeData.email,
      designation: employeeData.designation,
    });

    setUserInfo({
      ...userInfo,
      email: employeeData.email,
      designation: employeeData.designation,
    });

    let promiseAllArray = [];

    // Get data from firebase
    let requestedExpenseDataPromise = new Promise((resolve, reject) => {
      db.ref("ExpenseBill").on("value", (queryRequest) => {
        let requestLeaveArray = [];
        queryRequest.forEach((element) => {
          if (element.val().status == "reimbursed") {
            promiseAllArray.push({ key: element.key, ...element.val() });
          }
        });
        resolve(promiseAllArray);
      });
    });

    requestedExpenseDataPromise.then((response) => {
      setSkeleton(false);
      const sortedData = promiseAllArray;
      sortedData.sort((a, b) => {
        return b.timeinMiliSecond - a.timeinMiliSecond;
      });

      console.log("unsorted data", promiseAllArray);

      console.log("sorted data", sortedData);

      if (sortedData.length == 0) {
        setShowNoBills(true);
      }

      setDataForApprovedView(sortedData);
    });
  }, []);

  const onViewFormHandler = (idOfBill) => {
    console.log("VIEWBUTTON click");
    console.log("VIEWBUTTON idOfBill", idOfBill);
    // setSwitchView(!switchView);
    localStorage.setItem("readOnlyFormID", idOfBill);
    setShowViewOnlyForm(true);
    // window.location.href = "/viewonlyform";
  };

  console.log(dataForNormalView);

  const classes = useStyles();

  return (
    <>
      <div className="tableView">
        <Box component="main">
          {/* <Toolbar /> */}
          <TableContainer component={Paper}>
            <Table aria-label="simple table" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="right" style={{ width: "10%" }}>
                    Name
                  </TableCell>
                  <TableCell align="right" style={{ width: "10%" }}>
                    Title
                  </TableCell>
                  <TableCell align="right" style={{ width: "10%" }}>
                    Location
                  </TableCell>
                  <TableCell align="right" style={{ width: "10%" }}>
                    Date of Added
                  </TableCell>
                  <TableCell align="right" style={{ width: "10%" }}>
                    From
                  </TableCell>
                  <TableCell align="right" style={{ width: "10%" }}>
                    Till
                  </TableCell>
                  <TableCell align="right" style={{ width: "10%" }}>
                    Amount
                  </TableCell>
                  <TableCell align="right" style={{ width: "10%" }}>
                    Status
                  </TableCell>
                  <TableCell align="right" style={{ width: "30%" }}>
                    View
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataForNormalView && dataForNormalView.length > 0
                  ? dataForNormalView.map((arrayItem) => {
                      return (
                        <TableRow
                          key={arrayItem.key}
                          // sx={{
                          //   "&:last-child td, &:last-child th": { border: 0 },
                          // }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ width: "10%" }}
                          >
                            {arrayItem.name}
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {arrayItem.expanceCategory}
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {arrayItem.location}
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {arrayItem.currentDate}
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {arrayItem.selectDate}
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {arrayItem.selectDateTill}
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {arrayItem.amount}
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {" "}
                            <ListItem disablePadding>
                              <ListItemIcon>
                                <CheckCircleOutlineIcon />
                              </ListItemIcon>
                              <ListItemText primary={arrayItem.status} />
                            </ListItem>
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            <Button
                              variant="outlined"
                              onClick={() => onViewFormHandler(arrayItem.key)}
                            >
                              View
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : ""}

                {skeleton &&
                  (() => {
                    const arr = [];
                    for (let i = 0; i < 5; i++) {
                      arr.push(
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ width: "10%" }}
                          >
                            {console.log("Table form")}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center" style={{ width: "12%" }}>
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center" style={{ width: "10%" }}>
                            {" "}
                            <Skeleton />
                          </TableCell>
                        </TableRow>
                      );
                    }
                    return arr;
                  })()}
              </TableBody>
            </Table>
          </TableContainer>

          {showNoBills && <div className={classes.root}>{"No Bills !"}</div>}

          {showViewOnlyForm && (
            <ExpenseFormV2
              viewOnly={true}
              formCloseFunction={setShowViewOnlyForm}
            />
          )}
        </Box>
      </div>
    </>
  );
};

export default Reimbursed;
