import React from "react";
import { Row, Col, Label, Input } from "reactstrap";

import { Memberslist } from "../../../components";
import Members from "./Members";
import PropTypes from "prop-types";
import { db, firebaseApp } from "../../../Firebase";
import {
  Avatar,
  Checkbox,
  CircularProgress,
  FormControl,
  MenuItem,
  Paper,
  Popper,
  Select,
  TextField,
} from "@material-ui/core";
// import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import ReactInputMask from "react-input-mask";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Button } from "react-bootstrap";
import profileStd from "../../../assets/img/profile2.png";
// import classes from "*.module.css";
import "./userprofile.css";
import { makeStyles } from "@material-ui/core/styles";

// const useStyles = makeStyles({
//   paper: {
//     border: "5px solid black",
//     top: "100px",
//     display: "Flew",
//     position: "absolute",
//   },
// });

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
// let department = "";
var IMGDIR = process.env.REACT_APP_IMGDIR;

const departmentData = ["IT", "Design"];

class UserProfile extends React.Component {
  constructor() {
    super();

    this.state = {
      empData: [],
      name: "",
      //   designation: "",
      position: "",
      //   department: "",
      //   Basic Info
      pref: "",
      firstName: "",
      lastName: "",
      email: "",
      mobile: 91,
      image: "",
      dateOfBirth: moment(),
      //   Other Details
      address: "",
      altContact: 91,
      emergencyContactName: "",
      relation: "",
      gmail: "",
      //   Privacy
      password: "",
      confirmPassword: "",
      allowedSocial: false,
      isPublic: true,
      facebook: "",
      linkedIn: "",
      // Office Details
      remotely: "",
      leaveTime: "",
      joiningDate: moment(),
      relievingDate: moment(),
      relievingReason: "",
      designation: "",
      department: [],
      userID: "SMIS",
      reportingTo: "",
      reportingEmp: [],
      status: "",
      userRole: "",
      //   Data Entry Permission
      isEditable: false,
      isHR: false,
      // Backend Usage
      entryData: moment(),
      entryTime: "",
      updateBy: "",
      id: "",
      updateDate: "",
      entryBy: "",
      //   Other
      customerProduct: [],
      isValidClientEmail: true,
      emailHelperText: "",
      shilpmisId: "",
      currenUserRole: "",
    };
  }

  componentDidMount() {
    console.log("inside did mount");
    const projectId = /[^/]*$/.exec(window.location.href)[0];

    let currentUserId = localStorage.getItem("employeeObject")
      ? JSON.parse(localStorage.getItem("employeeObject")).userID
      : " ";

    let shilpmisEmail = localStorage.getItem("employeeObject")
      ? JSON.parse(localStorage.getItem("employeeObject")).email
      : " ";

    let currenUserRole = localStorage.getItem("employeeObject")
      ? JSON.parse(localStorage.getItem("employeeObject")).userRole
      : " ";

    let editable = currentUserId == projectId ? true : false;
    let isHR = currenUserRole == "HR" ? true : false;

    console.log("OUT THEN :: ", projectId);
    db.ref("/employees/")
      .orderByChild("/userID")
      .equalTo(projectId)
      .on("value", (queryEmp) => {
        let empData = [];
        queryEmp.forEach((e) => {
          if (e.val().status == "active") {
            let obj = e.val();
            obj["id"] = e.key;
            empData.push(obj);
          }
        });
        console.log("emp data::", empData);
        this.setState(
          {
            empData: empData,
            address: empData[0].address,
            altContact: empData[0].altContact,
            dateOfBirth: moment(empData[0].dateOfBirth),
            department: empData[0].department,
            designation: empData[0].designation,
            email: empData[0].email,
            emergencyContactName: empData[0].emergencyContactName,
            entryBy: empData[0].entryBy,
            entryData: empData[0].entryData,
            entryTime: empData[0].entryTime,
            firstName: empData[0].firstName,
            gmail: empData[0].gmail,
            id: empData[0].id,
            image: empData[0].image,
            joiningDate: moment(empData[0].joiningDate),
            lastName: empData[0].lastName,
            leaveTime: empData[0].leaveTime,
            mobile: empData[0].mobile,
            password: empData[0].password,
            confirmPassword: empData[0].password,
            pref: empData[0].pref,
            relation: empData[0].relation,
            remotely: empData[0].remotely,
            reportingTo: empData[0].reportingTo,
            status: empData[0].status,
            updateBy: empData[0].updateBy,
            updateDate: empData[0].updateDate,
            userID: empData[0].userID,
            userRole: empData[0].userRole,
            isEditable: editable,
            shilpmisId: shilpmisEmail,
            isPublic: editable ? true : empData[0].isPublic ? true : false,
            allowedSocial: empData[0].isSocialMedia,
            facebook: empData[0].facebook,
            linkedIn: empData[0].linkedIn,
            isHR: isHR,
            currenUserRole : currenUserRole,
          },
          () => {
            setTimeout(() => {
              if (editable) {
                const password = document.querySelector("#password");
                const togglePassword =
                  document.querySelector("#togglePassword");
                togglePassword.addEventListener("click", function (e) {
                  // toggle the type attribute
                  const type =
                    password.getAttribute("type") === "password"
                      ? "text"
                      : "password";
                  password.setAttribute("type", type);
                  // toggle the eye slash icon
                  this.classList.toggle("fa-eye-slash");
                });

                const confirmPassword =
                  document.querySelector("#confirmPassword");
                const toggleConfiremPassword = document.querySelector(
                  "#toggleConfirmPassword"
                );
                toggleConfiremPassword.addEventListener("click", function (e) {
                  // toggle the type attribute
                  const type =
                    confirmPassword.getAttribute("type") === "password"
                      ? "text"
                      : "password";
                  confirmPassword.setAttribute("type", type);
                  // toggle the eye slash icon
                  this.classList.toggle("fa-eye-slash");
                });
              }
              this.deparetmentChange("e", this.state.department);
            }, 1000);
          }
        );
      });
  }

  componentWillUnmount() {
    if (this.state.isEditable) {
      const togglePassword = document.querySelector("#togglePassword");
      togglePassword.removeEventListener("click", function (e) {
        console.log("event removed");
      });
      const toggleConfiremPassword = document.querySelector(
        "#toggleConfirmPassword"
      );
      toggleConfiremPassword.removeEventListener("click", function (e) {
        console.log("event removed");
      });
    }
  }

  handleChange(e, type) {
    console.log(e, e.target);
    if (type == "checkbox") {
      this.setState({
        [e.target.name]: e.target.checked,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  deparetmentChange = (e, newValue) => {
    let valuesForReportTo = [];
    let fetchedReportData = [];
    let flilteredFetchedReportData = [];

    // selected multiple checkbox
    this.setState({
      department: newValue,
    });

    // selected multiple checkbox
    let selectedDepartment = newValue;

    //repeat this for every UserRole(from userrole dropdown) Will optimize later within one if condition but later
    if (this.state.userRole === "employee") {
      //db reference to employees data. Where condition applied on field userRole to fetch tech_lead enteries
      // if employees -> tech_lead
      let ref = db.ref("employees");
      ref.orderByChild("userRole").on("child_added", (snapshot) => {
        // pushes feched data from db in fetchedReportData
        if (
          snapshot.val().userRole == "team_lead" ||
          snapshot.val().userRole == "dept_head" ||
          snapshot.val().userRole == "admin"
        ) {
          fetchedReportData.push({
            name: snapshot.val().firstName + " " + snapshot.val().lastName,
            department: snapshot.val().department,
            email: snapshot.val().email,
          });
        }
        // filetered data based on department head dropdown
        // you will get errors for previously added department data which was not in form of array
        flilteredFetchedReportData = fetchedReportData.filter((el) =>
        {el.department && el.department.some((entry) => selectedDepartment.includes(entry))}
        );

        // final result settled in state to show enteries in Report to
        this.setState({
          reportingEmp: flilteredFetchedReportData,
        });
      });
    } else if (this.state.userRole === "team_lead") {
      let ref = db.ref("employees");
      ref
        .orderByChild("userRole")
        .equalTo("dept_head")
        .on("child_added", (snapShot) => {
          fetchedReportData.push({
            name: snapShot.val().firstName + " " + snapShot.val().lastName,
            department: snapShot.val().department,
            email: snapShot.val().email,
          });
          console.log("fetchedReportData",fetchedReportData);
          flilteredFetchedReportData = fetchedReportData.filter((el) =>
       //   if(el.department!=="" || el.department!=="Na"){
        {el.department && el.department.some((entry) => selectedDepartment.includes(entry))}
       //   }
          );

          this.setState(
            {
              reportingEmp: flilteredFetchedReportData,
            },
            () => {
              if (flilteredFetchedReportData.length == 0) {
                ref
                  .orderByChild("userRole")
                  .equalTo("admin")
                  .on("child_added", (snapShotData) => {
                    fetchedReportData.push({
                      name:
                        snapShotData.val().firstName +
                        " " +
                        snapShotData.val().lastName,
                      department: snapShotData.val().department,
                      email: snapShotData.val().email,
                    });
                    console.log("filterd Data :: ", fetchedReportData);
                    flilteredFetchedReportData = fetchedReportData.filter(
                      (el) =>
                        el.department.some((entry) =>
                          selectedDepartment.includes(entry)
                        )
                    );
                    this.setState(
                      {
                        reportingEmp: flilteredFetchedReportData,
                      },
                      () => {
                        console.log(this.state.reportingEmp);
                      }
                    );
                  });
              }
            }
          );
        });
    } else if (this.state.userRole === "dept_head") {
      let ref = db.ref("employees");
      ref
        .orderByChild("userRole")
        .equalTo("admin")
        .on("child_added", (snapShots) => {
          fetchedReportData.push({
            name: snapShots.val().firstName + " " + snapShots.val().lastName,
            department: snapShots.val().department,
            email: snapShots.val().email,
          });
          flilteredFetchedReportData = fetchedReportData.filter((ele) =>
            ele.department.some((entry) => selectedDepartment.includes(entry))
          );

          this.setState({
            reportingEmp: flilteredFetchedReportData,
          });
        });
    } else if (this.state.userRole === "dept_head") {
      let ref = db.ref("employees");
      ref
        .orderByChild("userRole")
        .equalTo("superadmin")
        .on("child_added", (snapShots) => {
          fetchedReportData.push({
            name: snapShots.val().firstName + " " + snapShots.val().lastName,
            department: snapShots.val().department,
            email: snapShots.val().email,
          });
          flilteredFetchedReportData = fetchedReportData.filter((ele) =>
            ele.department.some((entry) => selectedDepartment.includes(entry))
          );

          this.setState({
            reportingEmp: flilteredFetchedReportData,
          });
        });
    } else if (this.state.userRole === "admin") {
      let ref = db.ref("employees");
      ref
        .orderByChild("userRole")
        .equalTo("superadmin")
        .on("child_added", (snapShot) => {
          fetchedReportData.push({
            name: snapShot.val().firstName + " " + snapShot.val().lastName,
            department: snapShot.val().department,
            email: snapShot.val().email,
          });
          flilteredFetchedReportData = fetchedReportData.filter((element) =>
            element.department.some((entry) =>
              selectedDepartment.includes(entry)
            )
          );

          this.setState({
            reportingEmp: flilteredFetchedReportData,
          });
        });
    } else if (this.state.userRole === "HR") {
      let ref = db.ref("employees");
      ref
        .orderByChild("userRole")
        .equalTo("dept_head")
        .on("child_added", (snapShot) => {
          fetchedReportData.push({
            name: snapShot.val().firstName + " " + snapShot.val().lastName,
            department: snapShot.val().department,
            email: snapShot.val().email,
          });
          console.log("Department :: ", fetchedReportData);
          flilteredFetchedReportData = fetchedReportData.filter((element) =>
          {element.department && element.department.some((entry) =>
              selectedDepartment.includes(entry)
            )}
          );

          this.setState(
            {
              reportingEmp: flilteredFetchedReportData,
            },
            () => {
              if (flilteredFetchedReportData.length == 0) {
                ref
                  .orderByChild("userRole")
                  .equalTo("admin")
                  .on("child_added", (snapShot) => {
                    fetchedReportData.push({
                      name:
                        snapShot.val().firstName +
                        " " +
                        snapShot.val().lastName,
                      department: snapShot.val().department,
                      email: snapShot.val().email,
                    });
                    console.log("Departmebnt :: ", fetchedReportData);
                    flilteredFetchedReportData = fetchedReportData.filter(
                      (element) =>
                      {element.department &&  element.department.some((entry) =>
                          selectedDepartment.includes(entry)
                        )}
                    );
                    this.setState(
                      {
                        reportingEmp: flilteredFetchedReportData,
                      },
                      () => {
                        if (flilteredFetchedReportData.length == 0) {
                          ref
                            .orderByChild("userRole")
                            .equalTo("superadmin")
                            .on("child_added", (snapShot) => {
                              fetchedReportData.push({
                                name:
                                  snapShot.val().firstName +
                                  " " +
                                  snapShot.val().lastName,
                                department: snapShot.val().department,
                                email: snapShot.val().email,
                              });
                              console.log("Departmebnt :: ", fetchedReportData);
                              flilteredFetchedReportData =
                                fetchedReportData.filter((element) =>
                                 {element.department && element.department.some((entry) =>
                                    selectedDepartment.includes(entry)
                                  )}
                                );
                              this.setState({
                                reportingEmp: flilteredFetchedReportData,
                              });
                            });
                        }
                      }
                    );
                  });
              }
            }
          );
        });
    }
  };

  handleProfileImage(e) {
    let file = e.target.files;
    console.log(file[0]);
    this.uploadFileToFirebase(file[0]);
  }

  uploadFileToFirebase = (file) =>
    new Promise((resolve, reject) => {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }

      const guid = "#SMIS-" + s4() + "-" + s4() + "-" + s4();

      const storageURL = firebaseApp.storage(
        "gs://melzo-dashboard.appspot.com"
      );
      const storageRef = storageURL.ref();

      const uploadTask = storageRef
        .child("images")
        .child("Melzo-Dashboard")
        .child("ProfilePhoto")
        .child(file.name)
        .put(file);

      uploadTask.on(
        "state_changed",
        (snapShot) => {},
        (err) => {
          console.log(err);
          reject(err);
        },
        () => {
          firebaseApp
            .storage("gs://melzo-dashboard.appspot.com")
            .ref()
            .child("images")
            .child("Melzo-Dashboard")
            .child("ProfilePhoto")
            .child(file.name)
            .getDownloadURL()
            .then((firebaseUrl) => {
              if (firebaseUrl) {
                this.setState({
                  image: firebaseUrl,
                });
              }
              resolve(firebaseUrl);
            })
            .catch((err) => {
              console.log("caught error::", err);
            });
        }
      );
    });

  handleMailCheck(type) {
    const patternEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (!patternEmail.test(this.state.email) && this.state.email) {
      alert("Please Enter Valid Email");
    } else if (this.state.email && type == "shilpmis") {
      let email = this.state.email.split("@")[1];
      if (email !== "shilpmis.com") {
        alert("Please Enter Shilpmis E-mail Id");
      }
    }
  }

  hadlePasswordCheck(type) {
    if (type == "password" && this.state.password) {
      if (this.state.password.length < 6) {
        alert("Atleast 6 character required.!!");
      }
    } else if (type == "confirmPass" && this.state.confirmPassword) {
      if (this.state.password !== this.state.confirmPassword) {
        this.setState({
          confirmPassword: "",
        });
        alert("Password not matched.!!\nTry Again...");
      }
    }
  }

  OnSubmitData() {
    if (this.validateUserProfile()) {
      console.log(this.state);
      db.ref("/employees/" + this.state.id)
        .update({
          address: this.state.address,
          altContact: this.state.altContact,
          dateOfBirth: new Date(this.state.dateOfBirth).getTime(),
          department: this.state.department,
          designation: this.state.designation,
          email: this.state.email,
          emergencyContactName: this.state.emergencyContactName,
          firstName: this.state.firstName,
          gmail: this.state.gmail,
          image: this.state.image,
          joiningDate: new Date(this.state.joiningDate).getTime(),
          lastName: this.state.lastName,
          leaveTime: this.state.leaveTime,
          mobile: this.state.mobile,
          password: this.state.password,
          pref: this.state.pref,
          relation: this.state.relation,
          remotely: this.state.remotely,
          reportingTo: this.state.reportingTo,
          status: this.state.status,
          updateBy: this.state.shilpmisId,
          updateDate: new Date(),
          userID: this.state.userID,
          userRole: this.state.userRole,
          isPublic: this.state.isPublic ? this.state.isPublic : false,
          isSocialMedia: this.state.allowedSocial
            ? this.state.allowedSocial
            : false,
          facebook: this.state.facebook ? this.state.facebook : "",
          linkedIn: this.state.linkedIn ? this.state.linkedIn : "",
        })
        .then((res) => {
          if (this.state.currenUserRole == "HR") {
            window.location.href = "/ui-members";
          } else {
            alert("Updated Successfully");
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  }

  validateUserProfile() {
    if (this.state.pref == "") {
      alert("Please select name prefix");
      return false;
    } else if (this.state.firstName == "") {
      alert("Please enter firstname ");
      return false;
    } else if (this.state.lastName == "") {
      alert("Please enter lastname ");
      return false;
    } else if (this.state.email == "") {
      alert("Please enter shilpmis E-mail Id ");
      return false;
    } else if (this.state.mobile == 91) {
      alert("Please enter mobile no ");
      return false;
    } else if (this.state.dateOfBirth == "") {
      alert("Please selct your Date of Birth ");
      return false;
    } else if (this.state.address == "") {
      alert("Please enter you resident address ");
      return false;
    } else if (this.state.emergencyContactName == "") {
      alert("Please enter emergency contact person name ");
      return false;
    } else if (this.state.altContact == 91) {
      alert("Please enter emergency contact person contact number ");
      return false;
    } else if (this.state.relation == "") {
      alert("Please select relation with emergency contact person ");
      return false;
    } else if (this.state.password == "") {
      alert("Please enter password ");
      return false;
    } else if (this.state.confirmPassword == "") {
      alert("Please enter confirm password ");
      return false;
    } else if (this.state.joiningDate == 91) {
      alert("Please select joining date of the employee ");
      return false;
    } else if (this.state.userRole == "") {
      alert("Please select the employee role ");
      return false;
    } else if (this.state.designation == "") {
      alert("Please select the designation of the employee ");
      return false;
    } else if (this.state.userID == "SMIS") {
      alert("Please enter the SMIS UserId ");
      return false;
    } else if (this.state.reportingTo == "") {
      alert("Please select the reporting person for the employee ");
      return false;
    } else if (this.state.status == "") {
      alert("Please select the employee status ");
      return false;
    } else if (this.state.allowedSocial && this.state.facebook == "") {
      alert("Please enter facebook link ");
      return false;
    } else if (this.state.allowedSocial && this.state.linkedIn == "") {
      alert("Please enter LinkedIn link ");
      return false;
    } else {
      return true;
    }
  }

  render() {
    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="page-title">
                <div className="float-left">
                  <h1 className="title">User Profile</h1>
                </div>
              </div>

              <div className="row margin-0">
                <div className="col-12">
                  <section className="box ">
                    {/* <header className="panel_header">
                      <h2 className="title float-left">Basic Details</h2>
                    </header> */}

                    <header
                      className="panel_header"
                      style={{
                        // background: "lightgrey",
                        display: "flex",
                        justifyContent: "center",
                        margin: "10px",
                        height: "120px",
                        borderBottom: "1px dashed black",
                      }}
                    >
                      {this.state.isEditable && (
                        <i
                          className="fa fa-trash"
                          style={{
                            position: "absolute",
                            bottom: "20px",
                            left: "610px",
                          }}
                          aria-hidden="true"
                          onClick={() =>
                            this.setState({
                              image: "",
                            })
                          }
                        ></i>
                      )}
                      <label
                        htmlFor="profileImage"
                        style={{
                          padding: "0px",
                          margin: "0px",
                        }}
                      >
                        <input
                          style={{ display: "none" }}
                          id="profileImage"
                          accept="image/png, image/jpeg"
                          name="image"
                          type="file"
                          disabled={!this.state.isEditable}
                          onChange={(e) => this.handleProfileImage(e)}
                        />
                        <div style={{ position: "relative" }}>
                          <img
                            height="100px"
                            width="100px"
                            src={
                              this.state.image ? this.state.image : profileStd
                            }
                            style={{
                              borderRadius: "50%",
                              background: "lightyellow",
                              //   outlineStyle: "double",
                              border: "1px solid white",
                            }}
                          ></img>
                          {this.state.isEditable && (
                            <i
                              className="fa fa-camera"
                              style={{
                                position: "absolute",
                                bottom: "0px",
                                right: "-25px",
                              }}
                              aria-hidden="true"
                            ></i>
                          )}
                        </div>
                      </label>
                    </header>

                    <div className="content-body">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <form>
                            <div className="form-row">
                              {/* User Prefix e.g Mr., Mrs. etc... */}
                              <div className="form-group col-md-2">
                                <label htmlFor="pref">Pref.</label>
                                <FormControl
                                  style={{ width: "100%" }}
                                  variant="outlined"
                                >
                                  <Select
                                    id="pref"
                                    name="pref"
                                    value={this.state.pref}
                                    onChange={(e) => this.handleChange(e)}
                                    disabled={!this.state.isEditable}
                                  >
                                    <MenuItem value="Mr">Mr</MenuItem>
                                    <MenuItem value="Mrs">Mrs</MenuItem>
                                    <MenuItem value="Miss">Miss</MenuItem>
                                    <MenuItem value="Ms">Ms</MenuItem>
                                  </Select>
                                </FormControl>
                              </div>

                              <div className="form-group col-md-5">
                                <label htmlFor="firstName">First Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="firstName"
                                  placeholder=""
                                  name="firstName"
                                  value={this.state.firstName}
                                  onChange={(e) => this.handleChange(e)}
                                  disabled={!this.state.isEditable}
                                />
                              </div>

                              <div
                                className="form-group col-md-5"
                                style={{ display: "flex" }}
                              >
                                <div style={{ width: "100%" }}>
                                  <label htmlFor="lastName">Last Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="lastName"
                                    placeholder=""
                                    name="lastName"
                                    value={this.state.lastName}
                                    disabled={!this.state.isEditable}
                                    onChange={(e) => this.handleChange(e)}
                                  ></input>
                                </div>
                              </div>

                              <div className="form-group col-md-4">
                                <Label htmlFor="email">Company Email Id</Label>
                                <input
                                  type="email"
                                  className="form-control"
                                  id="email"
                                  placeholder=""
                                  name="email"
                                  value={this.state.email}
                                  disabled={!this.state.isEditable}
                                  onChange={(e) => this.handleChange(e)}
                                  onBlur={() =>
                                    this.handleMailCheck("shilpmis")
                                  }
                                ></input>
                              </div>

                              <div className="form-group col-md-4">
                                <Label htmlFor="mobileNo">Mobile No</Label>
                                <ReactInputMask
                                  id="mobileNo"
                                  className="form-control"
                                  mask="99999 99999"
                                  maskChar="_"
                                  name="mobile"
                                  disabled={!this.state.isEditable}
                                  value={this.state.mobile}
                                  onChange={(e) => this.handleChange(e)}
                                />
                              </div>

                              <div className="form-group col-md-4">
                                <label>Date Of Birth (MM-DD-YYYY)</label>
                                <div
                                  className="controls"
                                  style={{ margin: "0px", background: "white" }}
                                >
                                  <DatePicker
                                    selected={this.state.dateOfBirth}
                                    value={this.state.dateOfBirth}
                                    style={{ background: "white" }}
                                    disabled={!this.state.isEditable}
                                    onChange={(e) => {
                                      this.setState({
                                        dateOfBirth: moment(e),
                                      });
                                    }}
                                  />
                                </div>
                              </div>

                              {this.state.isPublic && (
                                <header className="panel_header">
                                  <h2 className="title float-left">
                                    Other Details
                                  </h2>
                                </header>
                              )}

                              {this.state.isPublic && (
                                <div className={"form-group col-md-4"}>
                                  <Label htmlFor="address">Address</Label>
                                  <Input
                                    type="textarea"
                                    name="address"
                                    id="address"
                                    disabled={!this.state.isEditable}
                                    value={this.state.address}
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                </div>
                              )}
                              {this.state.isPublic && (
                                <div className="form-group col-md-4">
                                  <Label htmlFor="alternateNo">
                                    Emergency Contact Person No.
                                  </Label>
                                  <ReactInputMask
                                    id="alternateNo"
                                    className="form-control"
                                    mask="99999 99999"
                                    maskChar="_"
                                    name="altContact"
                                    value={this.state.altContact}
                                    disabled={!this.state.isEditable}
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                </div>
                              )}
                              {this.state.isPublic && (
                                <div
                                  className="form-group col-md-4"
                                  style={{ display: "flex" }}
                                >
                                  <div style={{ width: "100%" }}>
                                    <label htmlFor="EmergencyContactName">
                                      Emergency Contact Person Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="EmergencyContactName"
                                      placeholder=""
                                      disabled={!this.state.isEditable}
                                      name="emergencyContactName"
                                      value={this.state.emergencyContactName}
                                      onChange={(e) => this.handleChange(e)}
                                    ></input>
                                  </div>
                                </div>
                              )}
                              {this.state.isPublic && (
                                <div className="form-group col-md-4">
                                  <label htmlFor="relation">Relation</label>
                                  <FormControl
                                    style={{ width: "100%" }}
                                    variant="outlined"
                                  >
                                    <Select
                                      id="relation"
                                      disabled={!this.state.isEditable}
                                      name="relation"
                                      value={this.state.relation}
                                      onChange={(e) => this.handleChange(e)}
                                    >
                                      <MenuItem value="father">Father</MenuItem>
                                      <MenuItem value="mother">Mother</MenuItem>
                                      <MenuItem value="brother">
                                        Brother
                                      </MenuItem>
                                      <MenuItem value="sister">Sister</MenuItem>
                                      <MenuItem value="husband">
                                        Husband
                                      </MenuItem>
                                      <MenuItem value="wife">Wife</MenuItem>
                                      <MenuItem value="son">Son</MenuItem>
                                      <MenuItem value="daughter">
                                        Daughter
                                      </MenuItem>
                                      <MenuItem value="localGuardian">
                                        Local Guardian
                                      </MenuItem>
                                      <MenuItem value="friend">Friend</MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                              )}

                              {/* <div className="form-group col-md-4">
                                <Label htmlFor="field-11">Select Country</Label>
                                <CountryDropdown
                                  style={{ width: "100%" }}
                                  value={this.state.country}
                                  onChange={(val) => this.selectCountry(val)}
                                />
                              </div>

                              <div className="form-group col-md-4">
                                <Label htmlFor="field-11">Select State</Label>
                                <RegionDropdown
                                  style={{ width: "100%" }}
                                  country={this.state.country}
                                  value={this.state.region}
                                  placeholder="select Country First"
                                  onChange={(val) => this.selectRegion(val)}
                                />
                              </div> */}
                              {this.state.isPublic && (
                                <div className="form-group col-md-4">
                                  <Label htmlFor="gmail">Other Email Id</Label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    disabled={!this.state.isEditable}
                                    id="gmail"
                                    placeholder=""
                                    name="gmail"
                                    value={this.state.gmail}
                                    onChange={(e) => this.handleChange(e)}
                                    onBlur={() => this.handleMailCheck("other")}
                                  ></input>
                                </div>
                              )}

                              {/* ******************  Security ****************** */}
                              {this.state.isEditable && (
                                <header className="panel_header">
                                  <h2 className="title float-left">
                                    Security and Privacy
                                  </h2>
                                </header>
                              )}
                              {this.state.isEditable && (
                                <div className="form-group col-md-6">
                                  <label htmlFor="password">Password</label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    placeholder=""
                                    name="password"
                                    value={this.state.password}
                                    onChange={(e) => this.handleChange(e)}
                                    onBlur={() =>
                                      this.hadlePasswordCheck("password")
                                    }
                                  />
                                  <i
                                    className="far fa-eye"
                                    style={{
                                      position: "absolute",
                                      top: "50px",
                                      right: "30px",
                                    }}
                                    id="togglePassword"
                                  ></i>
                                </div>
                              )}
                              {this.state.isEditable && (
                                <div className="form-group col-md-6">
                                  <label htmlFor="confirmPassword">
                                    Confirm Password
                                  </label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="confirmPassword"
                                    placeholder=""
                                    name="confirmPassword"
                                    value={this.state.confirmPassword}
                                    onChange={(e) => this.handleChange(e)}
                                    onBlur={() =>
                                      this.hadlePasswordCheck("confirmPass")
                                    }
                                    disabled={
                                      this.state.password
                                        ? this.state.password.length > 5
                                          ? false
                                          : true
                                        : true
                                    }
                                  />
                                  <i
                                    className="far fa-eye"
                                    style={{
                                      position: "absolute",
                                      top: "50px",
                                      right: "30px",
                                    }}
                                    id="toggleConfirmPassword"
                                  ></i>
                                </div>
                              )}
                              {this.state.isEditable && (
                                <div
                                  className="form-group col-md-4"
                                  style={{ display: "grid" }}
                                >
                                  <label htmlFor="socialMedia">
                                    Social Media Preference
                                  </label>
                                  <input
                                    type="checkbox"
                                    className="form-control"
                                    id="socialMedia"
                                    placeholder=""
                                    name="allowedSocial"
                                    value={this.state.allowedSocial}
                                    onChange={(e) =>
                                      this.handleChange(e, "checkbox")
                                    }
                                    title="If you Enable then other employee can view and redirected to your page"
                                  />
                                </div>
                              )}
                              {this.state.allowedSocial &&
                                this.state.isEditable && (
                                  <div className="form-group col-md-4">
                                    <label htmlFor="facebook">Facebook</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="facebook"
                                      placeholder=""
                                      name="facebook"
                                      value={this.state.facebook}
                                      onChange={(e) => this.handleChange(e)}
                                    />
                                  </div>
                                )}

                              {this.state.allowedSocial &&
                                this.state.isEditable && (
                                  <div className="form-group col-md-4">
                                    <label htmlFor="linkedIn">LinkedIn</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="linkedIn"
                                      placeholder=""
                                      name="linkedIn"
                                      value={this.state.linkedIn}
                                      onChange={(e) => this.handleChange(e)}
                                    />
                                  </div>
                                )}
                              {this.state.isEditable && (
                                <div
                                  className="form-group col-md-4"
                                  style={{ display: "grid" }}
                                >
                                  <label htmlFor="Public">
                                    Visible for Public
                                  </label>
                                  <input
                                    type="checkbox"
                                    className="form-control"
                                    id="Public"
                                    placeholder=""
                                    name="isPublic"
                                    value={this.state.isPublic}
                                    onChange={(e) =>
                                      this.handleChange(e, "checkbox")
                                    }
                                    title="If you Enable then other employee can only view your profile."
                                  />
                                </div>
                              )}
                              {/* ******************  Office Details  ****************** */}

                              <header className="panel_header">
                                <h2 className="title float-left">
                                  WorkSpace Details
                                </h2>
                              </header>
                              {/* Date of Join */}
                              <div className="form-group col-md-4">
                                <label>Date Of Joining (MM-DD-YYYY)</label>
                                <div
                                  className="controls"
                                  style={{ margin: "0px", background: "white" }}
                                >
                                  <DatePicker
                                    selected={this.state.joiningDate}
                                    value={this.state.joiningDate}
                                    style={{ background: "white" }}
                                    disabled={!this.state.isHR}
                                    onChange={(e) => {
                                      this.setState({
                                        joiningDate: moment(e),
                                      });
                                    }}
                                  />
                                </div>
                              </div>

                              {/* UserID */}
                              <div className="form-group col-md-4">
                                <label htmlFor="userId">
                                  User Id (Shilpmis Id)
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="userId"
                                  placeholder=""
                                  disabled={!this.state.isHR}
                                  name="userID"
                                  value={this.state.userID}
                                  onChange={(e) => this.handleChange(e)}
                                />
                              </div>

                              {/* Role */}
                              <div className="form-group col-md-4">
                                <label htmlFor="role">Role</label>
                                <FormControl
                                  style={{ width: "100%" }}
                                  variant="outlined"
                                >
                                  <Select
                                    id="role"
                                    name="userRole"
                                    value={this.state.userRole}
                                    disabled={!this.state.isHR}
                                    onChange={(e) => this.handleChange(e)}
                                  >
                                    <MenuItem value="employee">
                                      Employee
                                    </MenuItem>
                                    <MenuItem value="HR">HR</MenuItem>
                                    <MenuItem value="team_lead">
                                      Team Lead
                                    </MenuItem>
                                    <MenuItem value="dept_head">
                                      Depart Head
                                    </MenuItem>
                                    <MenuItem value="admin">
                                      Vice President
                                    </MenuItem>
                                    <MenuItem value="superadmin">CEO</MenuItem>
                                  </Select>
                                </FormControl>
                              </div>

                              {/* Designation */}
                              <div className="form-group col-md-4">
                                <label htmlFor="designation">Designation</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="designation"
                                  placeholder=""
                                  disabled={!this.state.isHR}
                                  name="designation"
                                  value={this.state.designation}
                                  onChange={(e) => this.handleChange(e)}
                                />
                              </div>

                              {/* Department */}
                              <div className="form-group col-md-4">
                                <label htmlFor="department">Department</label>
                                <Autocomplete
                                  multiple
                                  limitTags={2}
                                  id="checkboxes-tags-demo"
                                  options={[
                                    "Information Technology",
                                    "Digital & Tendering",
                                    "Sales & Marketing",
                                    "Design",
                                    " HR & Admin",
                                    "Project Management",
                                    "Customer Support",
                                  ]}
                                  //   disableCloseOnSelect
                                  getOptionLabel={(option) => option}
                                  value={this.state.department}
                                  //   classes={{popper : 'bhavik'}}

                                  disabled={!this.state.isHR}
                                  name="department"
                                  //   PaperComponent={(props) => {
                                  //     return (
                                  //       <Paper
                                  //         elevation={8}
                                  //         {...props}
                                  //       />
                                  //     );
                                  //   }}
                                  // PopperComponent={({ style, ...props }) => (
                                  //     <Popper
                                  //     enchore
                                  //       {...props}
                                  //       style={{ ...style}} // width is passed in 'style' prop
                                  //     />
                                  //   )}
                                  //   ListboxProps={{ style: { maxHeight: '30vh' } }}
                                  onChange={(e, newValue) =>
                                    this.deparetmentChange(e, newValue)
                                  }
                                  renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                      <Checkbox
                                        id="checkboxes-tags-demo"
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        // style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option}
                                    </React.Fragment>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      //   style={{ width: "100%" }}
                                      id="checkboxes-tags-demo"
                                      variant="outlined"
                                      //   label="department"
                                      placeholder=""
                                    />
                                  )}
                                />
                              </div>
                              {/* Reporting To */}
                              <div className="form-group col-md-4">
                                <label htmlFor="reportingTo">
                                  Reporting To
                                </label>
                                {/* <input
                                  type="text"
                                  className="form-control"
                                  id="reportingTo"
                                  placeholder=""
                                  name="reportingTo"
                                  disabled={!this.state.isHR}
                                  value={this.state.reportingTo}
                                  onChange={(e) => this.handleChange(e)}
                                /> */}
                                <FormControl
                                  style={{ width: "100%" }}
                                  variant="outlined"
                                >
                                  <Select
                                    id="reportingTo"
                                    placeholder=""
                                    name="reportingTo"
                                    disabled={!this.state.isHR}
                                    value={this.state.reportingTo}
                                    onChange={(e) => this.handleChange(e)}
                                  >
                                    {this.state.reportingEmp.map((emp) => {
                                      return (
                                        <MenuItem value={emp.email}>
                                          {emp.name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </div>
                              {/* Remote Work Checkbox */}
                              <div
                                className="form-group col-md-4"
                                style={{ display: "grid" }}
                              >
                                <label htmlFor="remotely">Remote Work</label>
                                <input
                                  type="checkbox"
                                  className="form-control"
                                  id="remotely"
                                  placeholder=""
                                  name="remotely"
                                  disabled={!this.state.isHR}
                                  value={this.state.remotely}
                                  onChange={(e) =>
                                    this.handleChange(e, "checkbox")
                                  }
                                  title="This display's your current working mode.!"
                                />
                              </div>
                              {/* Employment Status */}
                              <div className="form-group col-md-4">
                                <label htmlFor="pref">Employment Status</label>
                                <FormControl
                                  style={{ width: "100%" }}
                                  variant="outlined"
                                >
                                  <Select
                                    id="employmentStatus"
                                    name="status"
                                    value={this.state.status}
                                    disabled={!this.state.isHR}
                                    onChange={(e) => this.handleChange(e)}
                                  >
                                    <MenuItem value="active">Employed</MenuItem>
                                    <MenuItem value="Resigned">
                                      Resigned
                                    </MenuItem>
                                    <MenuItem value="Retired">Retired</MenuItem>
                                    <MenuItem value="Terminated">
                                      Terminated
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                              {/* Relieving Date */}
                              {this.state.status !== "active" && (
                                <div className="form-group col-md-4">
                                  <label>Date Of Relieving (MM-DD-YYYY)</label>
                                  <div
                                    className="controls"
                                    style={{
                                      margin: "0px",
                                      background: "white",
                                    }}
                                  >
                                    <DatePicker
                                      selected={this.state.relievingDate}
                                      value={this.state.relievingDate}
                                      style={{ background: "white" }}
                                      disabled={!this.state.isHR}
                                      onChange={(e) => {
                                        this.setState({
                                          relievingDate: moment(e),
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                              {/* Reason for Leaving */}
                              {this.state.status !== "active" && (
                                <div className="form-group col-md-4">
                                  <label htmlFor="reasonOfLeaving">
                                    Reason for Leaving
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="reasonOfLeaving"
                                    placeholder=""
                                    name="relievingReason"
                                    disabled={!this.state.isHR}
                                    value={this.state.relievingReason}
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                </div>
                              )}
                            </div>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => this.OnSubmitData()}
                            >
                              Submit
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

UserProfile.propTypes = {
  members: PropTypes.arrayOf(PropTypes.object),
};

export default UserProfile;
