import { TextField } from "@material-ui/core";
import React, { Component } from "react";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import Table from "reactstrap/lib/Table";
import { db } from "../../../Firebase";


var year = new Date().getFullYear();
var month = new Date().getMonth();


export default class GenerateSalary extends React.Component {
    constructor() {
        super();

        this.state = {
            selectedMonths: "",
            selectedYears: "",
            salaryRateOfWage: "",
            salaryWorkingDays: "",
            salaryBasicPay: "",
            salaryHRA: "",
            salaryResidualPay: "",
            salaryGrossPay: "",
            salaryPFCompany: "",
            salaryPFEmp: "",
            salaryEsicEmp: "",
            salaryEsicCompany: "",
            salaryProfessionalTax: "",
            salaryNetPay: "",
            salaryCTC: "",
            salarySubTotal: "",
            salaryMid: "",
            generateEmployeeArray: [],
            leaveData: [],
            payRollData: [],
            monthlySalaryData: [],
            requestedLeaveData: [],
        }
        this.years = Array.from(new Array(40), (val, index) => index + year);
        this.months = Array.from(new Array(12), (val, index) => index + 1);
    }

    componentDidMount() {
        this.onGetEmployeeData();
        this.onGetLeaveData();
    }

    // to count days and sundays in a month
    daysInAMonth(year, month) {
        this.onGetPayrollData().then(acceptedData => {
            // console.log("Accepted Data", acceptedData)
            this.setState({ payRollData: acceptedData, }, () => {
               
                console.log("payroll data::", this.state.payRollData);
                var days = new Date(year, month, 0).getDate();
                var sundays = [(8 - (new Date(month + '/01/' + year).getDay())) % 7];
                console.log("sundays ::: ", sundays)
                let finalDays = [];
                if (sundays[0] !== 0) {
                    finalDays.push(sundays[0])
                }
                for (var i = sundays[0] + 7; i <= days; i += 7) {
                    finalDays.push(i);
                }
                console.log("sundays ::: ", finalDays)

                let counter = finalDays.length;
                console.log("counter:", counter)
                console.log("days::", days)
                let tempPayRollData = [];
                this.state.payRollData.forEach((obj) => {
                    if (obj.ctc < 12001) {
                        let countDays = days - counter;
                        if (countDays === 27) {
                            obj.basicPay = 9437;
                        }
                        let tempROW = (obj.basicPay / countDays);
                        obj.salaryRateOfWage = tempROW.toFixed(2);
                        console.log("ROW::", obj.email + obj.salaryRateOfWage)
                    } else {
                        let countDays = days;
                        let ROWTemp = (obj.basicPay / countDays)
                        obj.salaryRateOfWage = ROWTemp.toFixed(2);;
                        console.log("rate of wage::", obj.email + obj.salaryRateOfWage)
                    }
                    obj["deduction"] = 0;
                    obj["advance"] = 0;
                    obj["effectiveNetPay"] = obj.netPay;
                    tempPayRollData.push(obj)
                });
                this.dateDifference();
                console.log("temp payroll data::", tempPayRollData);
                this.setState({
                    payRollData: tempPayRollData,
                })
            })

        });
    }

    // to count difference between date
    dateDifference() {
        // Take the difference between the dates and divide by milliseconds
        // Round to nearest whole number
        let tempSalaryWorkingDays = "";
        this.state.generateEmployeeArray.map((obj) => {
            var joindate = new Date(obj.joiningDate);
            let joinedMonth = new Date(joindate).getMonth() + 1;
            var tempResignDate = new Date(obj.resignedDate);
            let resignedMonth = new Date(tempResignDate).getMonth() + 1;
            let todayDate = new Date();
            let tempMonth = new Date(todayDate).getMonth() + 1;
            let tempYear = new Date(todayDate).getFullYear();
            console.log("temp month", tempMonth);
            console.log("temp year", tempYear);

            if (joinedMonth === tempMonth) {
                let differenceDate = Math.round((todayDate - joindate) / (1000 * 60 * 60 * 24));
                console.log("difference", differenceDate);
                tempSalaryWorkingDays = differenceDate;

            } else if (resignedMonth === tempMonth) {
                let differentiateDays = Math.round((todayDate - tempResignDate) / (1000 * 60 * 60 * 24));
                console.log("resign difference", differentiateDays);
                tempSalaryWorkingDays = differentiateDays;

            } else {
                var days = new Date(this.state.selectedYears, this.state.selectedMonths, 0).getDate();
                console.log("final difference::", days);
                tempSalaryWorkingDays = days;

            }
            return tempSalaryWorkingDays;
        })
        this.state.payRollData.map((obj) => {
            obj["salaryWorkingDays"] = tempSalaryWorkingDays;
        })
    }


    onGetEmployeeData() {
        let getEmplyeeDataPromise = new Promise((resolve, reject) => {
            db.ref("/employees/")
                .orderByChild("department")
                .on("value", (querySnapShot) => {
                    let employeeArray = [];
                    querySnapShot.forEach((element) => {
                        if(element.val().status == "active"){

                            let object = element.val();
                            object["id"] = element.key;
                            employeeArray.push(object);
                        
                        }
                    })
                    resolve(employeeArray)
                })
        })
        getEmplyeeDataPromise.then(acceptedData => {
            if (acceptedData.length > 0) {
                this.setState({
                    generateEmployeeArray: acceptedData,
                }, () => {
                    console.log("Employee data:", this.state.generateEmployeeArray);
                })
            }
        })
    }

    onGetMonthlySalary = () => {
        let monthlySalaryDataPromise = new Promise((resolve, reject) => {
            db.ref("/monthlySalary/")
                .on("value", (querySalary) => {
                    let monthlySalaryArray = [];
                    querySalary.forEach((element) => {
                        let obj = element.val();
                        obj["id"] = element.key;
                        monthlySalaryArray.push(obj);
                    })
                    resolve(monthlySalaryArray);
                })
        })
        monthlySalaryDataPromise.then(acceptedData => {
            this.setState({
                monthlySalaryData: acceptedData,
            }, () => console.log("monthly salary data::", this.state.monthlySalaryData));
        })
    }

    onGetPayrollData = () => {
        return new Promise((resolve, reject) => {
            db.ref("/payRoll/")
            .on("value", (queryPayroll) => {
                let payrollArray = [];
                queryPayroll.forEach(element => {
                    let obj = element.val();
                    obj["id"] = element.key;
                    payrollArray.push(obj)
                })
                resolve(payrollArray);
            })
        })
    }

    onGetLeaveData() {
        let leaveDataPromise = new Promise((resolve, reject) => {
            db.ref("/leaves/")
                .orderByChild("employeeEmail")
                .on("value", (queryLeave) => {
                    let leaveArray = [];
                    queryLeave.forEach((element) => {
                        let obj = element.val();
                        obj["id"] = element.key;
                        leaveArray.push(obj);
                    });
                    resolve(leaveArray);
                })
        })
        leaveDataPromise.then(acceptedData => {
            this.setState({
                leaveData: acceptedData,
            }, () => {
                console.log("leave data ::", this.state.leaveData);
                //this.onCountLeaves();
            })
        })
    }

    onRequestLeaveData = () => {
        let requestLeaveDataPromise = new Promise((resolve, reject) => {
            db.ref("/requestedLeaves/")
                .on("value", (queryRequest) => {
                    let requestLeaveArray = [];
                    queryRequest.forEach(element => {
                        let obj = element.val();
                        obj["id"] = element.key;
                        requestLeaveArray.push(obj);
                    });
                    resolve(requestLeaveArray);
                })
        })
        requestLeaveDataPromise.then(resolvedData => {
            this.setState({
                requestedLeaveData: resolvedData,
            })
        })
    }

    onCountLeaves = () => {
        this.state.leaveData.map((obj) => {
            console.log("object leave::", obj)
            if (obj.totalLeave > 30) {
                console.log("total leaves", obj.totalLeave);

                let tempTotalLeave = (parseFloat(obj.totalLeave) - 30);
                let deduction = tempTotalLeave * this.state.salaryRateOfWage;
                return deduction;
            } else {
                console.log("no deduction");
            }
        })
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        }, () => console.log("month::", parseInt(this.state.selectedMonths), "year::", parseInt(this.state.selectedYears)));
    }

    handleAdvance = (id, event) => {
        let myPayrollData = this.state.payRollData;
        myPayrollData[id].advance = event.target.value;
        myPayrollData[id].effectiveNetPay = myPayrollData[id].netPay - ((parseInt(myPayrollData[id].deduction) >= 0 ? parseInt(myPayrollData[id].deduction) : 0) + (parseInt(myPayrollData[id].advance) >= 0 ? parseInt(myPayrollData[id].advance) : 0))
        this.setState({
            payRollData: myPayrollData,

        })
    }

    handleDeduction = (id, event) => {
        let myPayrollData = this.state.payRollData;
        myPayrollData[id].deduction = event.target.value;
        myPayrollData[id].effectiveNetPay = myPayrollData[id].netPay - ((parseInt(myPayrollData[id].deduction) >= 0 ? parseInt(myPayrollData[id].deduction) : 0) + (parseInt(myPayrollData[id].advance) >= 0 ? parseInt(myPayrollData[id].advance) : 0))
        this.setState({
            payRollData: myPayrollData
        })
    }

    deleteCollection = () => {
        db.ref("/monthlySalary/" + this.state.selectedYears + "-" + this.state.selectedMonths + this.state.id).remove();
        this.clearInput();
    }

    handleSubmit = () => {
        let tempMainData = [];
        this.state.payRollData.forEach((obj) => {
            let myFinalObject = {
                email: obj.email,
                salaryRateOfWage: parseFloat(obj.salaryRateOfWage),
                // salaryWorkingDays: parseFloat(obj.salaryWorkingDays),
                salaryBasicPay: parseFloat(obj.basicPay),
                salaryCTC: parseFloat(obj.ctc),
                salaryEsicEmp: parseFloat(obj.esicEmp),
                salaryEsicCompany: parseFloat(obj.esicCompany),
                salaryGrossPay: parseFloat(obj.grossSalary),
                salaryHRA: parseFloat(obj.hra),
                salaryNetPay: parseFloat(obj.netPay),
                salaryProfessionalTax: parseFloat(obj.professionalTax),
                salaryResidualPay: parseFloat(obj.residualPay),
                salarySubTotal: parseFloat(obj.subTotal),
                salaryPFEmp: parseFloat(obj.pfEmp),
                salaryPFCompany: parseFloat(obj.pfCompany),
                salaryDeduction: parseFloat(obj.deduction),
                salaryAdvanceCut: parseFloat(obj.advance),
                salaryEffectiveNetPay: parseFloat(obj.effectiveNetPay)
            };
            tempMainData.push(myFinalObject);
        })
        this.setState({
            payRollData: tempMainData,
        }, () => {
            // console.log("After submit", this.state.payRollData);
            db.ref("/monthlySalary/" + this.state.selectedYears + "-" + this.state.selectedMonths).push(this.state.payRollData).then(response => {
                this.clearInput();
            });
        })

    }

    validateSalary = () => {
        if (this.state.selectedMonths === "") {
            alert("Please select month");
            return false;
        } else if (this.state.selectedYears === "") {
            alert("Please select year");
            return false;
        } else {
            return true;
        }
    }

    calculateSalary = (e) => {
        e.preventDefault();
        //this.onCountLeaves();
        if (this.validateSalary()) {
            this.daysInAMonth(parseInt(this.state.selectedYears), parseInt(this.state.selectedMonths));
        }
    }

    clearInput = () => {
        this.setState({
            selectedMonths: "",
            selectedYears: "",
            payRollData: []
        })
    }

    deleteCollection = () => {
        db.ref("/monthlySalary/" + this.state.selectedYears + "-" + this.state.selectedMonths + this.state.id).remove();
        this.clearInput();
    }

    render() {
        return (
            <div>
                <div className="content">
                    <Row>
                        <Col>
                            <div className="page-title">
                                <div className="float-left">
                                    <h1 className="title">Generate Salary</h1>
                                </div>
                            </div>
                            <div className="row margin-0">
                                <div className="col-12">
                                    <section className="box">
                                        <header className="panel_header">
                                            <h2 className="title float-left">Input</h2>
                                        </header>
                                        <div className="content-body">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-12 col-md-12">
                                                    <div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="Month">Month</label>
                                                                <select id="Month" className="form-control" name="selectedMonths" value={this.state.selectedMonths} onChange={this.handleChange}>
                                                                    <option>Choose...</option>
                                                                    {this.months.map((month, index) => {
                                                                        return (<option value={month}>{month}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="Year">Year</label>
                                                                <select id="Year" name="selectedYears" value={this.state.selectedYears} onChange={this.handleChange} className="form-control">
                                                                    <option>Choose...</option>
                                                                    {this.years.map((year, index) => {
                                                                        return (<option value={year}>{year}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <button className="btn btn-primary" onClick={this.calculateSalary}>Generate Salary</button>
                                                        <button className="btn btn-primary" onClick={this.handleSubmit}>Verify</button>
                                                    </div>
                                                    <Table responsive aria-label="simple table">
                                                        <thead>
                                                            <tr>
                                                                <th>Employee</th>
                                                                <th>Rate of Wage</th>
                                                                <th>Working Days</th>
                                                                <th>Basic Pay</th>
                                                                <th>HRA(House Rent Allowance)</th>
                                                                <th>Residual Pay</th>
                                                                <th>Gross Pay</th>
                                                                <th>PF(Employee)</th>
                                                                <th>PF(Company)</th>
                                                                <th>ESIC</th>
                                                                <th>Professional Tax</th>
                                                                <th>Total CTC</th>
                                                                <th>Net Pay</th>
                                                                <th>Deduction</th>
                                                                <th>Advance</th>
                                                                <th>Effective Net Pay</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.payRollData.map((dbValue, index) => {
                                                                return (<tr key={dbValue.id}>
                                                                    <td>{dbValue.email}</td>
                                                                    <td>{dbValue.salaryRateOfWage}</td>
                                                                    <td>{dbValue.salaryWorkingDays}</td>
                                                                    <td>{dbValue.basicPay}</td>
                                                                    <td>{dbValue.hra}</td>
                                                                    <td>{dbValue.residualPay}</td>
                                                                    <td>{dbValue.grossSalary}</td>
                                                                    <td>{dbValue.pfEmp}</td>
                                                                    <td>{dbValue.pfCompany}</td>
                                                                    <td>{dbValue.esicEmp + dbValue.esicCompany}</td>
                                                                    <td>{dbValue.professionalTax}</td>
                                                                    <td>{dbValue.ctc}</td>
                                                                    <td>{dbValue.netPay}</td>
                                                                    <td>
                                                                        <TextField id="deduction"
                                                                            name="deduction"
                                                                            onChange={(event) => this.handleDeduction(index, event)}
                                                                            value={dbValue.deduction}
                                                                        ></TextField>
                                                                    </td>
                                                                    <td>
                                                                        <TextField id="advance"
                                                                            name="advance"
                                                                            onChange={(event) => this.handleAdvance(index, event)}
                                                                            value={dbValue.advance}></TextField>
                                                                    </td>
                                                                    <td>{dbValue.effectiveNetPay}</td>
                                                                </tr>)
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-12 col-md-12">
                                                    <button type="reset" className="btn btn-primary" onClick={this.deleteCollection}>Cancel Submission</button>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}