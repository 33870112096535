import "./expenseForm.css";
import React, { useEffect, useState } from "react";
import { db, firebaseApp } from "../../../Firebase";
import { addBillToDataBase } from "../API`s/apis";
import { v4 as uuid } from "uuid";
import { DropzoneArea } from "material-ui-dropzone";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import axios from "axios";
import fileDownload from "js-file-download";

// import { storage } from "../firebase";

import pdfImage_Url from "../img/pdfImage800.jpg";

import AlertDialog from "../strecture/alertDilog";
import {
  CircularProgress,
  ImageList,
  ImageListItem,
  styled,
  Typography,
} from "@material-ui/core";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";
import Grid from "@material-ui/core/Grid";

const expanseCategories = [
  "Food & Beverages",
  "IT and Internet Expense",
  "Travelling",
  "Office Expense",
  "Stationery & Printing",
  "Computer Expense",
  "Fuel Expense",
  "Accommodation Expense",
  "Courier Expense",
  "Automobile Expense",
  "Other Expense",
];

const paymentModeList = [
  "Cash",
  "Bank Transfer",
  "UPI Payment",
  "Company Credit Card",
  "Cheque",
  "Others",
];

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    minHeight: "0px",
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    // padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  TextField: {
    marginTop: "0px",
    backgroundColor: "black",
  },
}));

export default function ExpenseFormV2(props) {
  // const isInstancePresent = localStorage.getItem("instanceId");

  const classes = useStyles();
  let uniqueID = React.useRef(uuid());
  uniqueID = uniqueID.current.slice(0, 10);

  const [loader, setLoader] = React.useState(false);
  const [dilogValue, setDilogValue] = React.useState(false);

  const [arrayOfBillFiles, setArrayOfBillFiles] = React.useState([]);
  const [arrayOfDownloadableUrl, setArrayOfDownloadableUrl] = React.useState(
    []
  );

  const [showDilog, setShowDilog] = React.useState(false);
  const [showDilogMessage, setShowDilogMessage] =
    React.useState("I am testing");

  const idOfInstanse = localStorage.getItem("readOnlyFormID");
  const [valueUpdated, setValueUpdate] = useState([]);

  const [open, setOpen] = React.useState(props.viewOnly || false);
  // const [open, setOpen] = React.useState(false);

  const [collectionOfStates, setCollectionOfStates] = React.useState({
    name: "",
    merchantName: "",
    currentDate: new Date().toJSON().slice(0, 10),
    timeinMiliSecond: new Date().getTime(),
    selectDate: "",
    selectDateTill: "",
    expanceCategory: "",
    amount: "",
    amountMode: "",
    billInvoice: "no",
    billFiles: [],
    location: "",
    descriptionOfExpense: "",
    status: "Not Approved",
    managerApproved: false,
    hrApproved: false,
    reimbursementApproved: false,
    uniqueID: uniqueID,
    email: "",
    userRole:"",
    designation: "",
    firstName: "",
    gstNumber: "",
    rejectResone: "",
  });
  // const [userInfo, setUserInfo] = useState({
  //   email: "",
  //   designation: "",
  //   firstName: "",
  // });

  const [dilog, setDilog] = React.useState({
    message: "",
    isOpen: "",
    isAgree: "",
  });

  const [alertDialog, setAlertDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    result: "false",
    dual: false,
  });

  const [alertDialog2, setAlertDialog2] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    dual: false,
    result: false,
  });

  React.useEffect(() => {
    console.log("props", props);
    let employeeData = JSON.parse(localStorage.getItem("employeeObject"));

    setCollectionOfStates({
      ...collectionOfStates,
      email: employeeData.email,
      designation: employeeData.designation,
      name: employeeData.firstName,
      deparmentName:employeeData.department,
      userRole:employeeData.userRole
    });
    if (props.viewOnly) {
      console.log("view popup");
      const selectedId = localStorage.getItem("readOnlyFormID");
      console.log("selectedId", selectedId);
      let starCountRef = db.ref("ExpenseBill/" + selectedId);
      starCountRef.on("value", (snapshot) => {
        const data = snapshot.val();
        console.log("data", data);
        setCollectionOfStates({ ...collectionOfStates, ...data });
      });
    }

    return () => {
      setCollectionOfStates(Object.assign([]));
    };
  }, [valueUpdated]);

  // dialog box Function

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    console.log("i am trigred");
    // console.log("formCloseFunction", props && props.formCloseFunction);
    props.formCloseFunction();
  };

  // dialog box Function ends hear

  const onSettingStatesHandler = (event) => {
    console.dir(event);
    console.log("event happned");
    const key = event.target.name;
    console.log("=>key", key);
    let value = event.target.value;

    if (event.target.type === "checkbox") {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }

    setCollectionOfStates({
      ...collectionOfStates,
      [key]: value,
    });
  };

  const setAlertDialogfunc = () => {
    // uploadInFirebase();
    setAlertDialog({
      ...alertDialog,
      isOpen: false,
      result: true,
      dual: false,
    });
    console.log("jjjj");
  };

  const setCloseDilog = () => {
    setAlertDialog2({
      ...alertDialog2,
      isOpen: false,
      result: true,
      dual: false,
    });
    console.log("jjjj");
  };

  const onFormSubmitHandler = (event) => {
    event.preventDefault();
    setLoader(true);
   // console.log("collection", collectionOfStates);

    if (
      !collectionOfStates.name ||
      !collectionOfStates.location ||
      !collectionOfStates.selectDateTill ||
      !collectionOfStates.selectDate ||
      !collectionOfStates.expanceCategory ||
      !collectionOfStates.amount ||
      !collectionOfStates.amountMode
    ) {
      setAlertDialog({
        ...alertDialog,
        isOpen: true,
        title: "Alert",
        subTitle: "Fill all the details",
        dual: false,
        result: false,
      });

      setLoader(false);
    } else {
      const asyncFunction = async () => {
        let arrayOfDownloadableUrl = [];
        let arrayOfPromise = [];
        let myPromise;
        let arrayOfPromiseNested = [];

        const mainFunctionTouploadImageandGettingURL = async (file, i) => {
          return await new Promise((resolve, reject) => {
            const storageRefOfFirebase = firebaseApp.storage().ref();

            const storageRef = storageRefOfFirebase.child(
              "filesOfExpenseBill" + file.lastModified + file.name
            );

            storageRef
              .put(file)
              .then((res) => {
                console.log(
                  "file is uploaed in firestore storage of index: ",
                  i
                );

                storageRef
                  .getDownloadURL()
                  .then((url) => {
                    resolve(url);
                    arrayOfDownloadableUrl.push(url);
                    console.log("url", url);
                  })
                  .catch((err) => {
                    reject(err);
                    console.log(err);
                  });
              })
              .catch((err) => console.log(err));
          });
        };

        arrayOfBillFiles.map((file, i) => {
          const promiseFunction = mainFunctionTouploadImageandGettingURL(
            file,
            i
          );
          arrayOfPromise.push(promiseFunction);
        });

        Promise.all(arrayOfPromise).then((response) => {
        //  console.log("response of Promise.all new method", arrayOfPromise);

          updateValues();
        });

        const updateValues = () => {
         // console.log("arrayOfDownloadableUrl", arrayOfDownloadableUrl);

          const data = {
            ...collectionOfStates,
            ["billFiles"]: arrayOfDownloadableUrl,
          };

          // console.group(
          //   "last step of update in cloud firestore started data ",
          //   data
          // );
          addBillToDataBase(data)
            .then((response) => {
              setLoader(false);
              console.log(" 2 response of addbill!", response);
              console.log(" 1 data of addbill !", data);
              props.dataGetingFunction(data);
              // alert("Bill Added");

              setCollectionOfStates({
                name: "",
                merchantName: "",
                currentDate: new Date().toJSON().slice(0, 10),
                timeinMiliSecond: new Date().getTime(),
                deparmentName: "",
                userRole:"",
                selectDate: "",
                selectDateTill: "",
                expanceCategory: "",
                amount: "",
                amountMode: "",
                billInvoice: "no",
                billFiles: [],
                location: "",
                descriptionOfExpense: "",
                status: "Not Approved",
                managerApproved: false,
                hrApproved: false,
                reimbursementApproved: false,
                uniqueID: uniqueID,
                email: "",
                designation: "",
                firstName: "",
              })

              setOpen(false);
              setValueUpdate([..."I am "]);

              props.formCloseFunction();

              // setTimeout(() => {

              // }, 1500);
            })
            .catch((error) => {
              console.log("error", error.message);
              alert("Something went wrong!");
              setOpen(false);
              // setAlertDialog({
              //   ...alertDialog,
              //   isOpen: true,
              //   title: "Error",
              //   subTitle: "Something went wrong!",
              //   dual: false,
              //   result: false,
              // });
              props.formCloseFunction();
              setValueUpdate([..."I am "]);
            });
        };
      };

      asyncFunction();
    }
  };

  const firstDay = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    1
  );

  const newdate3 = firstDay.toString().split(" ");

  let dateLimit = `${newdate3[3]}-${
    newdate3[1] === "Jan"
      ? "01"
      : false || newdate3[1] === "Feb"
      ? "02"
      : false || newdate3[1] === "Mar"
      ? "03"
      : false || newdate3[1] === "Apr"
      ? "04"
      : false || newdate3[1] === "May"
      ? "05"
      : false || newdate3[1] === "Jun"
      ? "06"
      : false || newdate3[1] === "Jul"
      ? "07"
      : false || newdate3[1] === "Aug"
      ? "08"
      : false || newdate3[1] === "Sep"
      ? "09"
      : false || newdate3[1] === "Oct"
      ? "10"
      : false || newdate3[1] === "Nov"
      ? "11"
      : false || newdate3[1] === "Dec"
      ? "12"
      : false
  }-${newdate3[2]}`;

  const DownloadFile = (item, filename) => {
    // cors = require("cors");
    // const corsOptions = {
    //   origin: "http://localhost:3000",
    //   credentials: true, //access-control-allow-credentials:true
    //   optionSuccessStatus: 200,
    // };

    axios
      .get(item, {
        responseType: "blob",
        origin: "http://localhost:3000",
        credentials: true,
      })
      .then((res) => {
        fileDownload(res.data, "Image1245");
      });

    // const fileName = url.split("/").pop();
    // var el = document.createElement("a");
    // el.setAttribute("href", url);
    // el.setAttribute("download", fileName);
    // document.body.appendChild(el);
    // el.click();
    // el.remove();

    // var xhr = new XMLHttpRequest();
    // xhr.responseType = "blob";
    // xhr.onload = (event) => {
    //   var blob = xhr.response;
    // };
    // xhr.open("GET", url);
    // xhr.send();
    // axios
    //   .get(url, {
    //     responseType: "blob",
    //   })
    //   .then((res) => {
    //     fileDownload(res.data, filename);
    //   });
  };

  dateLimit = dateLimit.toString();

  const Input = styled("input")({
    display: "none",
  });

  return (
    <>
      <div className="expenseForm">
        {!props.viewOnly && (
          <div className="expenseButton">
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClickOpen}
            >
              Add Expense
            </Button>
          </div>
        )}

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
          <DialogContent>
            <div className={classes.root}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    id="nameOf-Form"
                    defaultValue={collectionOfStates.name}
                    className="dropdown"
                    disabled={props.viewOnly ? true : false}
                    name="name"
                    label="FirstName"
                    value={collectionOfStates.name}
                    fullWidth
                    onChange={onSettingStatesHandler}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="location"
                    name="location"
                    disabled={props.viewOnly ? true : false}
                    defaultValue={collectionOfStates.location}
                    className="inputFilds"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={collectionOfStates.location}
                    label="location"
                    onChange={onSettingStatesHandler}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="expence-category"
                    select
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className="dropdown"
                    name="expanceCategory"
                    disabled={props.viewOnly ? true : false}
                    label="ExpenceCategory"
                    value={collectionOfStates.expanceCategory}
                    fullWidth
                    input={<BootstrapInput />}
                    onChange={onSettingStatesHandler}
                    // variant="standard"
                    // sx={{ display: otherExpenseCategory }}
                  >
                    {expanseCategories.map((option) => (
                      <MenuItem key={option.toString()} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label htmlFor="eventDatestarted">
                    <TextField
                      required
                      id="eventDatestarted"
                      name="selectDate"
                      className="inputFilds"
                      disabled={props.viewOnly ? true : false}
                      label="Starting Date"
                      type="date"
                      input={<BootstrapInput />}
                      InputProps={{
                        inputProps: {
                          // min: moment().format("YYYY-MM-DD"),
                          max: dateLimit,
                        },
                      }}
                      value={collectionOfStates.selectDate}
                      format="dd/MM/yyyy"
                      onChange={onSettingStatesHandler}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="standard"
                      style={{
                        width: "100%",
                        marginLeft: "0",
                        marginRight: "0",
                      }}
                    />
                  </label>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label htmlFor="eventDateended">
                    <TextField
                      required
                      fullWidth
                      id="eventDateended"
                      className="inputFilds"
                      disabled={props.viewOnly ? true : false}
                      label="Ending Date"
                      input={<BootstrapInput />}
                      type="date"
                      InputProps={{
                        inputProps: {
                          // min: moment().format("YYYY-MM-DD"),
                          max: dateLimit,
                        },
                      }}
                      value={collectionOfStates.selectDateTill}
                      name="selectDateTill"
                      format="dd/MM/yyyy"
                      onChange={onSettingStatesHandler}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="standard"
                      style={{
                        width: "100%",
                        marginLeft: "0",
                        marginRight: "0",
                      }}
                    />
                  </label>
                </Grid>
                {props.viewOnly ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="status"
                        fullWidth
                        className="inputFilds"
                        label="Bill Status"
                        name="status"
                        input={<BootstrapInput />}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={true}
                        value={collectionOfStates.status}
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        id="rejectResone"
                        fullWidth
                        className="inputFilds"
                        label="Rejection Reason"
                        name="rejectResone"
                        input={<BootstrapInput />}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={true}
                        value={collectionOfStates.rejectResone}
                        variant="standard"
                      />
                    </Grid>
                  </>
                ) : null}
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="merchantName"
                    fullWidth
                    className="inputFilds"
                    label="Merchant Name"
                    name="merchantName"
                    input={<BootstrapInput />}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={props.viewOnly ? true : false}
                    value={collectionOfStates.merchantName}
                    onChange={onSettingStatesHandler}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="gstNumber"
                    fullWidth
                    className="inputFilds"
                    label="GST Number"
                    name="gstNumber"
                    input={<BootstrapInput />}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={props.viewOnly ? true : false}
                    value={collectionOfStates.gstNumber}
                    onChange={onSettingStatesHandler}
                    variant="standard"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    type="number"
                    id="outlined-Amount"
                    className="inputFilds"
                    name="amount"
                    fullWidth
                    input={<BootstrapInput />}
                    disabled={props.viewOnly ? true : false}
                    value={collectionOfStates.amount}
                    label="Rs"
                    onChange={onSettingStatesHandler}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="outlined-Payment-Mode"
                    required
                    select
                    className="inputFilds"
                    label="Payment Mode"
                    name="amountMode"
                    input={<BootstrapInput />}
                    disabled={props.viewOnly ? true : false}
                    fullWidth
                    value={collectionOfStates.amountMode}
                    onChange={onSettingStatesHandler}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {paymentModeList.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  {props.viewOnly || (
                    <>
                      <DropzoneArea
                        filesLimit={20}
                        className="inputFilds"
                        dropzoneText="Upload Images up to 20... (Supported format : .jpeg .jpg .png .pdf)"
                        acceptedFiles={[".png", ".jpeg", ".jpg", ".pdf"]}
                        onChange={(files) => {
                          console.log("Files:", files);
                          setArrayOfBillFiles(files);
                        }}
                      />
                      <Typography
                        variant="subtitle1"
                        textAlign="center"
                        gutterBottom
                        style={{ textAlign: "center", fontWeight: 700 }}
                      >
                        Upload Bills
                      </Typography>{" "}
                    </>
                  )}
                </Grid>

                {props.viewOnly && (
                  <Grid item xs={12} className="imgGallery">
                    <ImageList id="ImageList">
                      {collectionOfStates.billFiles &&
                        collectionOfStates.billFiles.map((item, i) => (
                          <ImageListItem key={i}>
                            <img
                              src={`${
                                /.pdf/.test(item) ? pdfImage_Url : item
                              }?w=248&fit=crop&auto=format`}
                              srcSet={`${
                                /.pdf/.test(item) ? pdfImage_Url : item
                              }?w=248&fit=crop&auto=format&dpr=2 2x`}
                              alt={i}
                              loading="lazy"
                            />
                            <div className="ImageGalleryButtons">
                              <a
                                className="Download"
                                href={item}
                                download="file"
                                target="_blank"
                              >
                                Download
                              </a>
                              {/* <a
                                className="view"
                                href={item}
                                download
                                // target="_blank"
                              >
                                view
                              </a> */}
                              <button
                                className="view"
                                // href={item + "?force=true"}
                                // // download="file"
                                // // target="_blank"
                                // download
                                onClick={() => DownloadFile(item, item)}
                              >
                                view
                              </button>
                            </div>
                          </ImageListItem>
                        ))}
                    </ImageList>
                  </Grid>
                )}

                <Grid item xs={12} sm={12}>
                  <Typography variant="subtitle2" component="h2">
                    Description
                  </Typography>
                  <TextareaAutosize
                    // type="tel"
                    id="descriptionOfExpense"
                    className="inputFilds"
                    name="descriptionOfExpense"
                    fullWidth
                    style={{ width: "100%" }}
                    maxRows={20}
                    input={<BootstrapInput />}
                    disabled={props.viewOnly ? true : false}
                    value={collectionOfStates.descriptionOfExpense}
                    label="Description"
                    onChange={onSettingStatesHandler}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <div className="submit-button">
                    <Button
                      className={
                        props.viewOnly
                          ? "submitButton-Mui-disabled"
                          : "submitButton"
                      }
                      disabled={props.viewOnly}
                      variant="contained"
                      onClick={onFormSubmitHandler}
                      disableElevation
                    >
                      Submit
                      {loader && (
                        <CircularProgress
                          className="loader"
                          style={{ color: "#ffffff" }}
                          size={25}
                        />
                      )}
                    </Button>
                  </div>
                  {/* <alertDilog type="info" message="Fill the all fields" /> */}
                </Grid>
              </Grid>
            </div>
          </DialogContent>
          <AlertDialog
            alertDialog={alertDialog}
            setAlertDialog={setAlertDialogfunc}
          />
        </Dialog>
      </div>
    </>
  );
}

ExpenseFormV2.defaultProps = {
  viewOnly: false,
};
