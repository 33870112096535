import firebase from "firebase";

let firebaseConfig = {
  apiKey: "AIzaSyBdK8lKxZhpSNwZL971bB5HO9SubRnitJs",
  authDomain: "melzo-dashboard.firebaseapp.com",
  databaseURL: "https://melzo-dashboard-default-rtdb.firebaseio.com",
  projectId: "melzo-dashboard",
  storageBucket: "melzo-dashboard.appspot.com",
  messagingSenderId: "979030977578",
  appId: "1:979030977578:web:58a6f133333d09b3786753",
  measurementId: "G-Q29395VTHF"
};


const firebaseApp = firebase.initializeApp(firebaseConfig,"sampleApp");
const db = firebaseApp.database()
// second firebase database

// let firebaseConfig2 = {
//   apiKey: 'AIzaSyCZom22SCaq1uuerI2mDcdpTxqwE0od9CA',
//   authDomain: 'ppt-vr.firebaseapp.com',
//   databaseURL: 'https://ppt-vr.firebaseio.com',
//   projectId: 'ppt-vr',
//   storageBucket: 'ppt-vr.appspot.com'
// };

// const firebaseApp2 = firebase.initializeApp(firebaseConfig2, "ppt-vr");
// const db2 = firebaseApp2.database()

export {firebaseApp,db};
