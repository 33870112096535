import React from "react";
import { db } from "../../../Firebase";
import AlertDialog from "./alertDilog";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./formFildEditing.css";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(45),
      padding: theme.spacing(2),
      // height: theme.spacing(16),
    },
  },
}));

const useStylesForText = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

export default function FormFildsForEditForm({
  idOfInstanse,
  droverFunction,
  droverState,
}) {
  const classes = useStyles();
  const classForText = useStylesForText();
  // console.log("id in form", idOfInstanse);
  const isInstancePresent = localStorage.getItem("instanceId");

  const [collectionOfStates, setCollectionOfStates] = React.useState({});
  const [loader, setLoader] = React.useState(false);
  const [submitForm, setSubmitForm] = React.useState({
    confirmation: false,
    dataUpdateSucessFully: false,
  });

  const [alertDialog, setAlertDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    dual: false,
    result: "false",
  });

  React.useEffect(() => {
    console.log("function called");
    const selectedKey = localStorage.getItem("selectedObject");

    let promiseAllArray = [];
    // db.ref("/ExpenseBill/").remove();
    // Get data from firebase
    let requestedExpenseDataPromise = new Promise((resolve, reject) => {
      db.ref("ExpenseBill").on("value", (queryRequest) => {
        let requestLeaveArray = [];
        queryRequest.forEach((element) => {
          if (element.key == selectedKey)
            // let obj = element.val();
            // obj["id"] = element.key;
            promiseAllArray.push({ key: element.key, ...element.val() });
        });
        resolve(promiseAllArray);
      });
    });

    const closedrover = () => {
      droverFunction(false);
      console.log("Normal view");
    };
    requestedExpenseDataPromise.then((response) => {
      setCollectionOfStates(...promiseAllArray);
      // console.log("data", response);
      console.log("data array", promiseAllArray);
    });
  }, []);

  const onSettingStatesHandler = (event) => {
    console.dir(event);
    console.log("event happned");
    const key = event.target.name;
    console.log("=>key", key);
    let value = event.target.value;

    if (event.target.type === "checkbox") {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }

    setCollectionOfStates({
      ...collectionOfStates,
      [key]: value,
    });
  };

  const onFormSubmitHandler = (event) => {
    event.preventDefault();
    setLoader(true);

    console.log("collection", collectionOfStates);
    const selectedKey = localStorage.getItem("selectedObject");

    if (
      !collectionOfStates.name ||
      !collectionOfStates.location ||
      !collectionOfStates.merchantName
    ) {
      setAlertDialog({
        ...alertDialog,
        isOpen: true,
        title: "Alert",
        subTitle: "Fill the all fields",
        dual: false,
        result: false,
      });
      setLoader(false);
      // alert("Fill the all fields");
    } else {
      db.ref("ExpenseBill/")
        .child(selectedKey)
        .update(collectionOfStates, (err) => {
          if (err) {
            console.log("data is not updated");
          } else {
            setLoader(false);
            droverFunction();
            console.log("data is  updated");
          }
        });
    }
  };

  const closeDilogOnSave = () => {
    setAlertDialog({
      ...alertDialog,
      isOpen: false,
      title: "",
      subTitle: "",
      dual: false,
      result: "false",
    });
    // droverFunction();
    // window.location.href = "/dashboard";
    console.log("closebutton");
  };

  const closeButton = () => {
    // setAlertDialog({
    //   ...alertDialog,
    //   isOpen: false,
    //   title: "",
    //   subTitle: "",
    //   dual: false,
    //   result: "false",
    // });
    droverFunction();
    // window.location.href = "/dashboard";
    console.log("closebutton");
  };

  // const Input = styled("input")({
  //   display: "none",
  // });

  console.log("collectionOfStates", collectionOfStates);
  return (
    <React.Fragment>
      <div className={classes.root}>
        <Paper>
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                {console.log("name 1:", collectionOfStates)}
                {console.log("name fild:", collectionOfStates.name)}
                <TextField
                  required
                  id="standard-select-currency"
                  defaultValue={collectionOfStates.name}
                  name="name"
                  label="Name"
                  value={
                    collectionOfStates.name ? collectionOfStates.name : "null"
                  }
                  fullWidth
                  onChange={onSettingStatesHandler}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  required
                  id="location-edit"
                  name="location"
                  label="location"
                  defaultValue={collectionOfStates.location}
                  value={
                    collectionOfStates.location
                      ? collectionOfStates.location
                      : ""
                  }
                  onChange={onSettingStatesHandler}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  id="stateOfMerchantNameEdit"
                  fullWidth
                  required
                  label="Merchant Name"
                  defaultValue={collectionOfStates.merchantName}
                  name="merchantName"
                  value={
                    collectionOfStates.merchantName
                      ? collectionOfStates.merchantName
                      : ""
                  }
                  onChange={onSettingStatesHandler}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={5} margin="auto">
                <Button
                  variant="contained"
                  onClick={onFormSubmitHandler}
                  // disableElevation
                >
                  Submit {loader && <CircularProgress size={20} />}
                </Button>
              </Grid>

              <Grid item xs={12} sm={6} margin="auto">
                <Button variant="contained" onClick={closeButton}>
                  Back
                </Button>
              </Grid>
              <AlertDialog
                alertDialog={alertDialog}
                setAlertDialog={closeDilogOnSave}
                closeAlertDilog={closeButton}
              />
            </Grid>
          </div>
        </Paper>
      </div>
    </React.Fragment>
  );
}
