import React from "react";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import { db } from "../../../Firebase";
import ReactDataGrid from "react-data-grid";

const columns = [
    { key: "designationCode", name: "Designation Code", editable: true },
    { key: "designationName", name: "Designation Name", editable: true },
]

export default class DesignationTable extends React.Component {
    constructor() {
        super();

        this.state = {
            designationData: [],
        }
    }

    componentDidMount() {
        this.onGetDesignationData();
    }

    onGetDesignationData() {
        let designationDataPromise = new Promise((resolve, reject) => {
            db.ref("/designationMaster/").on("value", (querySnapShot) => {
                let designationArray = [];
                querySnapShot.forEach(element => {
                    let obj = element.val();
                    obj["id"] = element.key;
                    designationArray.push(obj);
                });
                resolve(designationArray);
            })
        });
        designationDataPromise.then(acceptedData => {
            this.setState({
                designationData: acceptedData,
            }, () => console.log("designation data::", this.state.designationData))
        })
    }

    onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        // console.log("From row ", fromRow, "To row", toRow, "Updated", updated);
        // let state = this.state.employeeData;
        // this.setState(employeeData => {
        const rows = this.state.designationData.slice();
        for (let i = fromRow; i <= toRow; i++) {
            rows[i] = { ...rows[i], ...updated };
        }
        // console.log("Rows", rows)
        this.setState({ designationData: rows })
        // return { rows };
        // });
    };

    onSaveUpdatedData = () => {
        for (let i =0 ; i < this.state.designationData.length ; i++) {
            db.ref("/designationMaster/" + this.state.designationData[i].id).update({
                designationCode: this.state.designationData[i].designationCode,
                designationName: this.state.designationData[i].designationName,
            })
        }
    }


    render() {

        return (
            <div>
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>

                            <div className="page-title">
                                <div className="float-left">
                                    <h1 className="title">Designation Table</h1>
                                </div>
                            </div>

                            <div className="col-12">
                                <section className="box ">
                                    <header className="panel_header">
                                        <h2 className="title float-left">Table</h2>
                                    </header>
                                    <div className="content-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <button className="btn btn-primary btn-sm profile-btn" onClick={this.onSaveUpdatedData} style={{ marginBottom: "15px" }}>Save</button>
                                            </div>
                                            <div className="col-lg-12">
                                                <ReactDataGrid
                                                   // minHeight={this.state.designationData.length * 25 + 50}
                                                    rowHeight={50}
                                                    columns={columns}
                                                    rowGetter={i => this.state.designationData[i]}
                                                    rowsCount={50}
                                                    onGridRowsUpdated={this.onGridRowsUpdated}
                                                    enableCellSelect={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}