import ReactPDF from '@react-pdf/renderer';
import React from 'react';
import ReactDOM from 'react-dom';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import melzologo from "../../../assets/img/color.png";

const styles = StyleSheet.create({
    body:{
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    page: {
        flexDirection: 'row',
        backgroundColor: '#ffffff',
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow:1,
    },
    text: {
        color: '#00000',
    }
})

function AppPdf() {
    console.log("in Functional component");
    return(
        <div>
            <Document file="/home/heli/receipt.pdf">
                <Page size="A4" style={styles.page}>
                    <View style={styles.section}>
                        {/* <Image  src={melzologo}></Image> */}
                        <Text style={styles.text}>Shilpmis Technologies Pvt. Ltd.</Text>
                    </View>
                </Page>
            </Document>
        </div>
    )
}

export default AppPdf;