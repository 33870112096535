import React from "react";
import { Form, FormGroup, Input } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

import { Favcontacts, Allcontacts, Chatgroups } from "../../../components";

import {
  favcontacts,
  allcontacts,
  chatgroups,
} from "../../../variables/topbar.jsx";

import { db } from "../../../Firebase";
import { Button } from "react-bootstrap";
import ClearAllIcon from "@material-ui/icons/ClearAll";
// import { Title } from "react-bootstrap/lib/Modal";
var ps;

class ChatSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notification: [],
      userDept: [],
      shilpmisId: "",
      userRole: "",
      firstName: "",
    };
    this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  componentDidMount() {
    this.setState(
      {
        shilpmisId: localStorage.getItem("employeeObject")
          ? JSON.parse(localStorage.getItem("employeeObject")).email
          : " ",
        userRole: localStorage.getItem("employeeObject")
          ? JSON.parse(localStorage.getItem("employeeObject")).userRole
          : " ",
        userDept: localStorage.getItem("employeeObject")
          ? JSON.parse(localStorage.getItem("employeeObject")).department
          : " ",
        firstName: localStorage.getItem("employeeObject")
          ? JSON.parse(localStorage.getItem("employeeObject")).firstName +
            " " +
            JSON.parse(localStorage.getItem("employeeObject")).lastName
          : " ",
      },
      () => {
        this.onGetLeaveMsg();
      }
    );

    if (this.props.chatwidth === "open") {
      document.documentElement.classList.add("nav-toggle-chat");
    } else {
      document.documentElement.classList.remove("nav-toggle-chat");
    }

    if (this.props.chattype === "squeeze") {
      document.documentElement.classList.add("nav-chat-squeeze");
    } else {
      document.documentElement.classList.remove("nav-chat-squeeze");
    }

    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.chatbar, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }

  onGetLeaveMsg = () => {
    const self = this;
    db.ref("/notification/").on("value", (queryLeave) => {
      let leaveResultData = [];
      queryLeave.forEach((element) => {
        let obj = element.val();
        obj["id"] = element.key;
        leaveResultData.push(obj);
      });
      let filterNotification = [];

      if (self.state.userRole != "HR") {
        filterNotification = leaveResultData.filter((msgdata) => {
          return (
            msgdata.msg != "" &&
            msgdata.userView == false &&
            msgdata.shilpmisId == self.state.shilpmisId
          );
        });
      }
      if (self.state.userRole == "HR") {
        filterNotification = leaveResultData.filter((msgdata) => {
          return msgdata.msg != "" && msgdata.HRView == false;
        });
      }
      if (self.state.userRole == "team_lead") {
        filterNotification = leaveResultData.filter((msgdata) => {
          let dept = "Default";

          for (let i = 0; i < this.state.userDept.length; i++) {
            if (msgdata.userDept.includes(this.state.userDept[i])) {
              dept = this.state.userDept[i];
            }
          }
          return (
            msgdata.msg != "" &&
            msgdata.HeadView == false &&
            msgdata.userRole == "employee" &&
            msgdata.userDept == dept
          );
        });
      }
      if (self.state.userRole == "dept_head") {
        filterNotification = leaveResultData.filter((msgdata) => {
          let dept = "Default";

          for (let i = 0; i < this.state.userDept.length; i++) {
            if (msgdata.userDept.includes(this.state.userDept[i])) {
              dept = this.state.userDept[i];
            }
          }
          return (
            msgdata.msg != "" &&
            msgdata.HeadView == false &&
            (msgdata.userRole == "team_lead" || msgdata.userRole == "HR") &&
            msgdata.userDept == dept
          );
        });
      }
      if (self.state.userRole == "admin") {
        filterNotification = leaveResultData.filter((msgdata) => {
          let dept = "Default";

          for (let i = 0; i < this.state.userDept.length; i++) {
            if (msgdata.userDept.includes(this.state.userDept[i])) {
              dept = this.state.userDept[i];
            }
          }
          return (
            msgdata.msg != "" &&
            msgdata.HeadView == false &&
            msgdata.userRole == "team_head" &&
            msgdata.userDept == dept
          );
        });
      }
      if (self.state.userRole == "superadmin") {
        filterNotification = leaveResultData.filter((msgdata) => {
          let dept = "Default";

          for (let i = 0; i < this.state.userDept.length; i++) {
            if (msgdata.userDept.includes(this.state.userDept[i])) {
              dept = this.state.userDept[i];
            }
          }
          return (
            msgdata.msg != "" &&
            msgdata.HeadView == false &&
            msgdata.userRole == "admin" &&
            msgdata.userDept == dept
          );
        });
      }

      self.setState(
        {
          notification: filterNotification,
        },
        () => {
          // console.log("leave new database fav", self.state.notification);
        }
      );
    });
  };

  clearAllNotification = () => {
    this.state.notification.map((notificaton) => {
      let id = notificaton.id;
      if (this.state.userRole != "HR") {
        if (notificaton.shilpmisId == this.state.shilpmisId) {
          db.ref("/notification/" + id).update({
            userView: true,
          });
        } else {
          db.ref("/notification/" + id).update({
            HeadView: true,
          });
        }
      }
      if (this.state.userRole == "HR") {
        db.ref("/notification/" + id).update({
          HRView: true,
        });
      }
    });
  };

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    return (
      <div className="sidebar chatbar">
        <div className="sidebar-wrapper" ref="chatbar">
          {/* <Form className="chat-search">
                        <FormGroup>
                            <Input type="text" name="search" id="chatsearch" placeholder="Search" />
                        </FormGroup>
                    </Form> */}
          <br></br>
          <h4 className="group-head">Groups</h4>
          <Chatgroups chatgroups={chatgroups} />
          <hr style={{ color: "wheat", borderBottom: "1px solid" }}></hr>
          <div style={{ display: "flex" }}>
            <h4
              className="group-head"
              style={{ width: "100%", margin: "auto" }}
            >
              Notification Zone
            </h4>
            {this.state.notification.length > 0 && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <ClearAllIcon
                  style={{
                    cursor: "pointer",
                    marginRight: "20px",
                    color: "white",
                  }}
                  titleAccess="Clear All Notification"
                  onClick={this.clearAllNotification}
                ></ClearAllIcon>
              </div>
            )}
          </div>
          <Favcontacts favcontacts={[]} />
          {/* <h4 className="group-head">All Contacts</h4>
                                <Allcontacts allcontacts={allcontacts}/> */}
        </div>
      </div>
    );
  }
}

export default ChatSidebar;
