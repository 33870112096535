import React, { Component } from "react";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import { db } from "../../../Firebase";

export default class Task extends React.Component {
    constructor() {
        super();

        this.state = {
            taskData: [],
            projectName: "",
            department: "",
            userEmail: "",
            taskTitle: "",
            taskDetail: "",
            taskDate: "",
            completionDate: "",
            remarks: "",
            status: "Pending",
            approved: "false",
            userId: "",
            id: "",
            projectArray: [],

            empData: [{ employeeInvolved: [] }],
            departmentData: [],
        };
    }

    componentDidMount() {
        this.onGetProjectData();
    }

    onGetProjectData() {
        let projectDataPromise = new Promise((resolve, reject) => {
            db.ref("/projectMaster/")
                .on("value", (queryProject) => {
                    let projectData = [];
                    queryProject.forEach((element) => {
                        let object = element.val();
                        object["id"] = element.key;
                        projectData.push(object);
                    });
                    resolve(projectData);
                })
        })
        projectDataPromise.then(acceptedData => {
            this.setState({
                projectArray: acceptedData,
            }, () => console.log("all project data::", this.state.projectArray));
        })
    }

    empChange = (event) => {
        console.log(event.target.value)
        this.setState({
            projectName: event.target.value
        }, () => {
          db.ref("/projectMaster/")
            .orderByChild("projectName")
            // .equalTo(event.target.value)
            .on("value", (queryProject) => {
              let empArray = [];
              queryProject.forEach((element) => {
                    let obj = element.val();
                    obj["id"] = element.key;
                    empArray.push(obj);
                 
              })
              this.setState({ empData: empArray },() => console.log("emp data::", this.state.empData))
            })
        });
      }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onGetTaskData = () => {
        let taskDataPromise = new Promise((resolve,reject) => {
            db.ref("/task/").on("value", (queryTask) => {
                let taskArray = [];
                queryTask.forEach(element => {
                    let obj = element.val();
                    obj["id"] = element.key;
                    taskArray.push(obj);
                });
                resolve(taskArray);
            })
        })
    }

    saveTask = () => {
        db.ref("/task/").push({
            projectName:this.state.projectName,
            email: this.state.userEmail,
            taskTitle: this.state.taskTitle,
            taskDetail: this.state.taskDetail,
            taskDate: this.state.taskDate,
            completionDate: this.state.completionDate,
            remarks: this.state.remarks,
            status:  this.state.status,
            approved:this.state.approved.toString()
           
        })  
    }

    cancelTask = () => {
        this.setState({
            projectName: "",
            department: "",
            userEmail: "",
            taskTitle: "",
            taskDetail: "",
            taskDate: "",
            completionDate: "",
            remarks: "",
            status: "Pending",
            approved: "false",
            userId: "",
            id: "", 
        })
    }

    render() {
        return (
            <div>
                <div className="content">
                    <Row>
                        <Col>
                            <div className="page-title">
                                <div className="float-left">
                                    <h1 className="title">Task Assignment</h1>
                                </div>
                            </div>
                            <div className="row margin-0">
                                <div className="col-12">
                                    <section className="box">
                                        <header className="panel_header">
                                            <h2 className="title float-left">About Task</h2>
                                        </header>
                                        <div className="content-body">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-12 col-md-12">
                                                    <form>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="projectName">Project Name</label>
                                                                <select id="projectName" className="form-control" name="projectName" value={this.state.projectName} onChange={this.empChange}>
                                                                    <option>Choose..</option>
                                                                    {this.state.projectArray.map((obj) => {
                                                                        return (<option value={obj.projectName}>{obj.projectName}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="empId">Employee</label>
                                                                <select id="empId" name="userEmail" className="form-control" value={this.state.userEmail} onChange={this.handleChange}>
                                                                    <option>Choose..</option>
                                                                    {this.state.empData[0].employeeInvolved.map((obj) => {
                                                                        return(<option value={obj}>{obj}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="taskName">Task Name</label>
                                                                <input type="text" id="taskName" name="taskTitle" className="form-control" value={this.state.taskTitle} onChange={this.handleChange} />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="taskDetails">Task Details</label>
                                                                <textarea id="taskDetails" className="form-control" placeholder="Details" rows="1" name="taskDetail" value={this.state.taskDetail} onChange={this.handleChange} ></textarea>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                            <div className="row margin-0">
                                <div className="col-12">
                                    <section className="box">
                                        <header className="panel_header">
                                            <h2 className="title float-left">Other Details</h2>
                                        </header>
                                        <div className="content-body">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-12 col-md-12">
                                                    <form>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="AssignedDate">Assigned Date</label>
                                                                <input type="date" className="form-control" id="AssignedDate" name="taskDate" value={this.state.taskDate} onChange={this.handleChange} ></input>
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="completionDate">Completion Date</label>
                                                                <input type="date" className="form-control" id="completionDate" name="completionDate" value={this.state.completionDate} onChange={this.handleChange} ></input>
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="taskStatus">Task Status</label>
                                                                <select className="form-control" id="taskStatus" name="taskStatus">
                                                                    <option>Choose...</option>
                                                                    <option value="pending">Pending</option>
                                                                    <option value="running">Running</option>
                                                                    <option value="bug">Bug</option>
                                                                    <option value="complete">Completed</option>
                                                                </select>
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="completionDate">Task Remarks</label>
                                                                <input type="text" className="form-control" id="completionDate" ></input>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="col-sm-2"></div>
                                                            <div className="col-sm-10">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" id="approved" />
                                                                    <label className="form-check-label" htmlFor="approved">Approved
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-primary" onClick={this.saveTask}>Submit</button>
                                            <button type="submit" className="btn btn-secondary" onClick={this.cancelTask}>Cancel</button>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}