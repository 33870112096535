import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
// import "../Register/leadlock.css";
import { db } from "../../Firebase";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import { TabContext } from "@material-ui/lab";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Page from "../Register/page";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import Container from "reactstrap/lib/Container";
import "./leadlock.css";

export default class LeadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientName: "",
      email: "",
      mobileNumber: "",
      city: "",
      product: "",
      remark: "",
      leadDate: "",
      otherProduct: "",
      showOtherProductField: false,
      isValidClientName: true,
      isValidClientEmail: true,
      isValidClientMobile: true,
      isValidClientCity: true,
      isValidProduct: true,
      isValidLeadStatus: true,
      isValidOtherProduct: true,
      mobileNumberHelperText: "",
      emailHelperText: "",
      clientNameHelperText: "",
      clientCityHelperText: "",
      productHelperText: "Please select product",
      leadStatusHelperText: "Please select lead status",
      otherProductHelperText: "",
      leadSpin: false,
      leadStatus: "Connected ",
      preLeadStatus: "",
    };
  }

  componentDidMount() {
    this.setTodayDate();
    if (this.props.selectedLead) {
      console.log("Selected lead", this.props.selectedLead);
      let getLeadDataPromise = new Promise((resolve, reject) => {
        db.ref("/leadList")
          .child(this.props.selectedLead)
          .get()
          .then((snapshot) => {
            let obj = snapshot.val();
            obj["id"] = snapshot.key;
            resolve(obj);
          });
      });
      getLeadDataPromise.then((resolvedData) => {
        console.log("All values", resolvedData);
        let productName = resolvedData.product;
        let otherProductName = "";
        let shouldShowOtherText = false;
        if (productName.startsWith("Other")) {
          productName = "Other";
          shouldShowOtherText = true;
          otherProductName = resolvedData.product.substring(
            resolvedData.product.indexOf("-") + 2
          );
        }
        console.log(productName, " ::: ", otherProductName);

        const fullDateObj = new Date(resolvedData.leadDate);
        const leadYear = fullDateObj.getFullYear();
        const leadMonth = (fullDateObj.getMonth() + 1)
          .toString()
          .padStart(2, "0");
        const leadFinalDate = fullDateObj.getDate().toString().padStart(2, "0");
        const finalDate = leadYear + "-" + leadMonth + "-" + leadFinalDate;

        this.setState({
          clientName: resolvedData.clientName,
          email: resolvedData.clientEmail,
          mobileNumber: resolvedData.clientMobileNumber,
          city: resolvedData.clientCity,
          product: productName,
          otherProduct: otherProductName,
          remark: resolvedData.remark,
          leadDate: finalDate,
          showOtherProductField: shouldShowOtherText,
          leadStatus: resolvedData.status,
          preLeadStatus: resolvedData.status,
        });
      });
    }
  }

  setTodayDate = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = (new Date().getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const currentDate = new Date().getDate().toString().padStart(2, "0");
    this.setState({
      leadDate: currentYear + "-" + currentMonth + "-" + currentDate,
    });
  };

  handleChange = (event) => {
    const eventName = event.target.name;
    const eventValue = event.target.value;
    this.setState({
      [event.target.name]: event.target.value,
    });
    this.removeErrorMessages();

    if (eventName === "product") {
      if (eventValue === "Other") {
        this.setState({ showOtherProductField: true });
      } else {
        this.setState({ showOtherProductField: false });
      }
    }
  };

  removeErrorMessages = () => {
    this.setState({
      isValidClientName: true,
      isValidClientEmail: true,
      isValidClientMobile: true,
      isValidClientCity: true,
      isValidProduct: true,
      isValidLeadStatus: true,
    });
  };

  isExistingUserEmail = () => {
    const emailToCheck = this.state.email;
    const emailPromise = new Promise((resolve, reject) => {
      db.ref("/leadList")
        .orderByChild("clientEmail")
        .equalTo(emailToCheck)
        .on("value", (query) => {
          let leadData = [];
          query.forEach((element) => {
            let obj = element.val();
            obj["id"] = element.key;
            leadData.push(obj);
          });
          resolve(leadData);
        });
    });
    emailPromise.then((res) => {
      if (res.length > 0) {
        // alert("Email already exist");
        this.setState({
          isValidClientEmail: false,
          emailHelperText: "Client email already exists",
        });
      } else {
        this.setState({ isValidClientEmail: true });
      }
    });
  };
  isExistingUserName = () => {
    const userNameToCheck = this.state.clientName;
    const namePromise = new Promise((resolve, reject) => {
      db.ref("/leadList")
        .orderByChild("clientName")
        .equalTo(userNameToCheck)
        .on("value", (query) => {
          let leadData = [];
          query.forEach((element) => {
            let obj = element.val();
            obj["id"] = element.key;
            leadData.push(obj);
          });
          resolve(leadData);
        });
    });

    namePromise.then((res) => {
      if (res.length > 0) {
        // alert("Mobile No. already exist");
        this.setState({
          isValidClientName: false,
          clientNameHelperText: "Client name already exists",
        });
      } else {
        this.setState({ isValidClientName: true });
      }
    });
  };
  isExistingMobileNo = () => {
    const mobileNoToCheck = this.state.mobileNumber;
    const mobileNoPromise = new Promise((resolve, reject) => {
      db.ref("/leadList")
        .orderByChild("clientMobileNumber")
        .equalTo(mobileNoToCheck)
        .on("value", (query) => {
          let leadData = [];
          query.forEach((element) => {
            let obj = element.val();
            obj["id"] = element.key;
            leadData.push(obj);
          });
          resolve(leadData);
        });
    });
    mobileNoPromise.then((res) => {
      console.log(res);
      if (res.length > 0) {
        // alert("Mobile No. already exist");
        this.setState({
          isValidClientMobile: false,
          mobileNumberHelperText: "Client mobile number already exists",
        });
      } else {
        this.setState({ isValidClientMobile: true });
      }
    });
  };
  onSubmit = () => {
    let email = this.state.email;
    let clientName = this.state.clientName;
    let phoneNo = this.state.mobileNumber;
    let city = this.state.city;
    let product = this.state.product;
    let leadStatus = this.state.leadStatus;
    let otherProduct = this.state.otherProduct;
    let remark = this.state.remark;
    let leadDate = this.state.leadDate;
    let leadDateValue = new Date(this.state.leadDate).getTime();
    // console.log(leadDateValue)

    const patternIndianMob = /^[6789]{1}[0-9]{9}$/;
    const patternEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (clientName === "") {
      // alert("Please enter a User Name !");
      this.setState({
        isValidClientName: false,
        clientNameHelperText: "Please enter client name",
      });
    } else if (email === "") {
      // alert("Please enter a valid Email-id");
      this.setState({
        isValidClientEmail: false,
        emailHelperText: "Please enter client email",
      });
    } else if (!patternEmail.test(email)) {
      // alert("Please enter a valid Email-id");
      this.setState({
        isValidClientEmail: false,
        emailHelperText: "Please enter valid client email",
      });
    } else if (phoneNo === "") {
      // alert("Please enter a valid Phone number");
      this.setState({
        isValidClientMobile: false,
        mobileNumberHelperText: "Please enter client mobile number",
      });
    } else if (phoneNo.length < 8) {
      this.setState({
        isValidClientMobile: false,
        mobileNumberHelperText: "Please enter valid client mobile number",
      });
    } else if (city === "") {
      // alert("Please enter a city name");
      this.setState({
        isValidClientCity: false,
        clientCityHelperText: "Please enter city name of client",
      });
    } else if (product === "") {
      // alert("Please enter a product name");
      this.setState({
        isValidProduct: false,
        productHelperText: "Please select product",
      });
    } else if (product === "Other" && otherProduct === "") {
      // alert("Please enter a product name");
      this.setState({
        isValidOtherProduct: false,
        otherProductHelperText: "Please enter product name",
      });
    } else if (leadDate === "") {
      alert("Please Select a date");
    } else if (leadStatus === "") {
      alert("Please select lead status");
    }
    // else if (!patternIndianMob.test(phoneNo) || parseInt(phoneNo[0]) < 6) {
    //   console.warn("Not valid indian number", phoneNo);
    //   this.setState({ isValidClientMobile: false, mobileNumberHelperText: "Please enter a valid client mobile number" })
    //   // alert("Please enter valid 10 digit mobile number");
    // }
    else {
      if (
        this.state.isValidClientName &&
        this.state.isValidClientMobile &&
        this.state.isValidClientEmail &&
        this.state.isValidClientCity &&
        this.state.isValidProduct &&
        this.state.isValidLeadStatus
      ) {
        if (product === "Other") {
          product = "Other - " + otherProduct;
        }
        this.setState({ leadSpin: true });
        const self = this;
        if (this.props.selectedLead) {
          db.ref("leadList/" + this.props.selectedLead)
            .set({
              clientName: clientName,
              clientEmail: email,
              clientMobileNumber: phoneNo,
              clientCity: city,
              product: product,
              status: leadStatus,
              remark: remark,
              leadDate: leadDateValue,
              entryTime: new Date().getTime(),
              cpName: localStorage.getItem("userName"),
              cpEmail: localStorage.getItem("email"),
              cpMobileNumber: localStorage.getItem("mobileNumber"),
              cpCity: localStorage.getItem("city"),
              cpUserId: localStorage.getItem("userDocId"),
              isEnabled: true,
              statusUpdateTime:
                this.state.preLeadStatus == this.state.leadStatus
                  ? ""
                  : new Date().getTime(),
            })
            .then(() => {
              console.log("Data submited");

              self.setState({ leadSpin: false });
              this.addNewEntrySuccess();
            })
            .catch((error) => {
              self.setState({ leadSpin: false });
              console.error("New Lead Error", error);
            });
        } else {
          db.ref("leadList")
            .push({
              clientName: clientName,
              clientEmail: email,
              clientMobileNumber: phoneNo,
              clientCity: city,
              product: product,
              remark: remark,
              leadDate: leadDateValue,
              entryTime: new Date().getTime(),
              cpName: localStorage.getItem("userName"),
              cpEmail: localStorage.getItem("email"),
              cpMobileNumber: localStorage.getItem("mobileNumber"),
              cpCity: localStorage.getItem("city"),
              cpUserId: localStorage.getItem("userDocId"),
              isEnabled: true,
            })
            .then(() => {
              console.log("Data submited");

              self.setState({ leadSpin: false });
              this.addNewEntrySuccess();
            })
            .catch((error) => {
              self.setState({ leadSpin: false });
              console.error("New Lead Error", error);
            });
        }
      } else {
        this.setState({ leadSpin: false });
        console.warn("Client details missing");
      }
    }
  };

  goBackToLeadList = () => {
    this.props.closeAddForm();
  };

  addNewEntrySuccess = () => {
    this.props.successCallback();
  };

  handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      switch (e.target.name) {
        case "clientName":
          document.getElementById("email").focus();
          break;
        case "email":
          document.getElementById("mobileNumber").focus();
          break;
        case "mobileNumber":
          document.getElementById("city").focus();
          break;
        case "city":
          document.getElementById("product").focus();
          break;
        case "product":
          document.getElementById("date").focus();
          break;
      }
    }
  };

  render() {
    return (
      <div>
        <Page title="Melzo New Leads">
          <Container style={{ marginTop: "70px" }}>
            <Card>
              <Box
                display="flex"
                style={{ alignItems: "center", margin: "10px" }}
              >
                <Box flexGrow={1} style={{ padding: "10px" }}>
                  <Typography variant="h5">
                    <ArrowBackIcon
                      variant="contained"
                      onClick={() => this.goBackToLeadList()}
                      style={{ margin: "5px" }}
                    />
                    New Lead
                    <div
                      style={{
                        float: "right",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {this.state.leadSpin && (
                        <CircularProgress
                          color="primary"
                          style={{ margin: "5px" }}
                          size={20}
                        />
                      )}
                      <Button
                        variant="contained"
                        disabled={this.state.leadSpin}
                        color="primary"
                        id="leadSubmitButton"
                        onClick={this.onSubmit}
                      >
                        Submit
                      </Button>
                    </div>
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <CardContent>
                <TabContext value="1">
                  <AppBar position="static">
                    <TabList aria-label="profiletab">
                      <Tab
                        label="Lead Form"
                        value="1"
                        style={{ fontFamily: "Montserrat" }}
                      />
                    </TabList>
                  </AppBar>
                  <TabPanel value="1">
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          type="text"
                          name="clientName"
                          id="clientName"
                          value={this.state.clientName}
                          label="Client Name"
                          variant="outlined"
                          error={!this.state.isValidClientName}
                          helperText={
                            this.state.isValidClientName
                              ? ""
                              : this.state.clientNameHelperText
                          }
                          onChange={this.handleChange}
                          onKeyUp={this.handleKeyUp}
                          onBlur={this.isExistingUserName}
                          style={{ width: "100%" }}
                          required
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          type="email"
                          name="email"
                          id="email"
                          value={this.state.email}
                          label="Client Email"
                          variant="outlined"
                          error={!this.state.isValidClientEmail}
                          helperText={
                            this.state.isValidClientEmail
                              ? ""
                              : this.state.emailHelperText
                          }
                          onChange={this.handleChange}
                          onKeyUp={this.handleKeyUp}
                          onBlur={this.isExistingUserEmail}
                          style={{ width: "100%" }}
                          required
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          type="number"
                          name="mobileNumber"
                          value={this.state.mobileNumber}
                          id="mobileNumber"
                          label="Client Mobile No"
                          variant="outlined"
                          error={!this.state.isValidClientMobile}
                          helperText={
                            this.state.isValidClientMobile
                              ? ""
                              : this.state.mobileNumberHelperText
                          }
                          onChange={this.handleChange}
                          onKeyUp={this.handleKeyUp}
                          onBlur={this.isExistingMobileNo}
                          style={{ width: "100%" }}
                          // maxLength="10"
                          required
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          type="text"
                          name="city"
                          id="city"
                          value={this.state.city}
                          error={!this.state.isValidClientCity}
                          helperText={
                            this.state.isValidClientCity
                              ? ""
                              : this.state.clientCityHelperText
                          }
                          label="Client City"
                          variant="outlined"
                          style={{ width: "100%" }}
                          onKeyUp={this.handleKeyUp}
                          onChange={this.handleChange}
                          style={{ width: "100%" }}
                          required
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FormControl
                          style={{ width: "100%" }}
                          variant="outlined"
                        >
                          <InputLabel id="product-label">Product</InputLabel>
                          <Select
                            labelId="product-label"
                            id="product"
                            name="product"
                            label="Product"
                            value={this.state.product}
                            onChange={this.handleChange}
                            error={!this.state.isValidProduct}
                            helperText={this.state.productHelperText}
                          >
                            <MenuItem value="Noor">Noor</MenuItem>
                            <MenuItem value="Mela">Mela</MenuItem>
                            <MenuItem value="Udyog">Udyog</MenuItem>
                            <MenuItem value="Kalaa">Kalaa</MenuItem>
                            <MenuItem value="Aakar">Aakar</MenuItem>
                            <MenuItem value="Walkthrough">Walkthrough</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FormControl
                          style={{ width: "100%" }}
                          variant="outlined"
                        >
                          <InputLabel id="leadStatus-label">
                            Lead Status
                          </InputLabel>
                          <Select
                            labelId="leadStatus-label"
                            id="leadStatus"
                            name="leadStatus"
                            label="Lead Status"
                            value={this.state.leadStatus}
                            onChange={this.handleChange}
                            error={!this.state.isValidLeadStatus}
                            helperText={this.state.leadStatusHelperText}
                          >
                            <MenuItem value="Connected ">Connected </MenuItem>
                            <MenuItem value="Demo schedule ">
                              Demo schedule{" "}
                            </MenuItem>
                            <MenuItem value="Demo Completed">
                              Demo Completed
                            </MenuItem>
                            <MenuItem value="Proposal Sent">
                              Proposal Sent
                            </MenuItem>
                            <MenuItem value="Price Negotiation">
                              Price Negotiation
                            </MenuItem>
                            <MenuItem value="★ Win ★">★ Win ★</MenuItem>
                            <MenuItem value="〆 Lost 〆 ">〆 Lost 〆 </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {this.state.showOtherProductField && (
                        <Grid item md={6} xs={12}>
                          <TextField
                            type="text"
                            name="otherProduct"
                            id="otherProduct"
                            value={this.state.otherProduct}
                            error={!this.state.isValidOtherProduct}
                            helperText={
                              this.state.isValidOtherProduct
                                ? ""
                                : this.state.otherProductHelperText
                            }
                            label="Please specify other product"
                            variant="outlined"
                            style={{ width: "100%" }}
                            onKeyUp={this.handleKeyUp}
                            onChange={this.handleChange}
                            style={{ width: "100%" }}
                            required
                          />
                        </Grid>
                      )}
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="date"
                          type="date"
                          label="Date"
                          name="leadDate"
                          variant="outlined"
                          onChange={this.handleChange}
                          value={this.state.leadDate}
                          defaultValue="2021-05-24"
                          style={{ width: "100%" }}
                          required
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          type="text"
                          name="remark"
                          id="remark"
                          value={this.state.remark}
                          label="Remark"
                          variant="outlined"
                          style={{ width: "100%" }}
                          // error={!this.state.isValidProduct}
                          // helperText={this.state.isValidProduct ? "" : this.state.productHelperText}
                          onChange={this.handleChange}
                          onKeyUp={this.handleKeyUp}
                        />
                      </Grid>
                      {/* <Grid item md={6} xs={12}>
                      </Grid> */}
                    </Grid>
                  </TabPanel>
                </TabContext>
              </CardContent>
            </Card>
          </Container>
        </Page>
      </div>
    );
  }
}
