import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import {
    Router,
    Route,
    Switch
} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
/*import 'font-awesome/css/font-awesome.min.css';*/
import './assets/scss/zest-admin.css';
import './assets/fonts/simple-line-icons.css';

import indexRoutes from './routes/index.jsx';
import Leads from './components/Register/Leads';
// import Dashboard2 from './views/general/Dashboard/Dashboard2';
import Dashboard1 from './views/general/Dashboard/dashboard1';
import RequestLeave from './components/Leave/TimeRequestoff';
import ApproveLeaves from './components/Leave/ApproveLeaves';
import Attendancefeature from './components/attendence/attendence';
import AddLeave from './components/Leave/AddLeave';
import dashboard1 from './views/general/Dashboard/dashboard1.jsx'
//import DesignationMaster from './views/general/Forms/DesignationMaster';

const hist = createBrowserHistory();

ReactDOM.render(
    
    <Router history={hist} basename={process.env.REACT_APP_BASEDIR}>
        <Switch>
            {
                <Route
                path="/leads"
                component={Leads}
            />  
            }
            {/* {
                <Route
                path="/leave"
                component={RequestLeave}
            />
            } */}
            {/* {
                <Route
                path="/approve-leaves"
                component={ApproveLeaves}
            />
            } */}
             {
                <Route
                path="/addleaves"
                component={AddLeave}
            />
            }
            {
                <Route
                path="/dashboardAdmin"
                component={dashboard1}
                />
            }

            {/* {
                <Route
                path="/attendance"
                component={Attendancefeature}
            />
            } */}

            {
                indexRoutes.map((prop,key) => {
                    // console.log(prop.path + key);
                    return ( 

                        <Route
                            path={prop.path}
                            key={key}
                            component={prop.component}
                        />
                    );
                })

            }
        
        </Switch>
    </Router>
, document.getElementById('root'));
