import React from "react";
import { Row, Col, Label, Input } from "reactstrap";

import InputMask from "react-input-mask";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import axios from "axios";
import firebaseStore from "../../../Firebase2";
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { db, firebaseApp } from "../../../Firebase";
import uploadFiles from "../../../components/uploadFile";

class AddTicket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // New State
      firstName: "",
      shilpmisId: "",
      userRole: "",
      ticketNo: this.guid(),
      customerName: "",
      customerEmail: "",
      customerProduct: "",
      customerMobile: 91,
      ComplaintSuject: "",
      DateOfCreation: moment(),
      issueType: "",
      complaintDes: "",
      FileCollection: [],
      ticketStatus: "Open",
      loading: false,
      attachment: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.OnSubmitData = this.OnSubmitData.bind(this);
    this.fillOtherData = this.fillOtherData.bind(this);
  }

  componentDidMount() {
    this.setState({
      firstName: localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).firstName +
          " " +
          JSON.parse(localStorage.getItem("employeeObject")).lastName
        : " ",
      shilpmisId: localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).email
        : " ",
      userRole: localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).userRole
        : " ",
    });

    db.ref("/customer-support/").on("value", (queryTicket) => {
      let ticketArray = [];
      queryTicket.forEach((ticket) => {
        let obj = ticket.val();
        obj["id"] = ticket.key;
        ticketArray.push(obj);
      });
      console.log(ticketArray, "ticketArray");
    });
  }

  guid = () => {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return String("#SMIS-" + s4() + "-" + s4() + "-" + s4());
  };

  handleChange(e, file) {
    if (file) {
      console.log("handle change ", e.target.name, e.target);
      this.setState({
        [e.target.name]: e.target.file,
      });
    } else {
      console.log("handle change ", e.target.value, e.target.name);
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  OnSubmitData() {
    if (this.validateSubmit()) {
      const promises = [];

      for (let i = 0; i < this.state.FileCollection.length; i++) {
        let file = this.state.FileCollection[i];
        promises.push(this.uploadFileToFirebase(file));
      }

      Promise.all(promises).then((res) => {
        console.log("urls", this.state.attachment);
        db.ref("/customer-support")
          .push({
            type: "TicketRaised",
            ticketIssuedBy: this.state.firstName,
            ticketAssignTo: "",
            ticketAssignBy: "",
            ticketNo: this.state.ticketNo,
            customerName: this.state.customerName,
            customerEmail: this.state.customerEmail,
            customerProduct: this.state.customerProduct,
            customerMobile: this.state.customerMobile,
            complaintSuject: this.state.ComplaintSuject,
            complaintDes: this.state.complaintDes,
            issueType: this.state.issueType,
            status: this.state.ticketStatus,
            attachment: this.state.attachment,
            createdOn: new Date().getTime(),
            UpdatedOn: new Date().getTime(),
            remark: "",
          })
          .then((res) => {
            db.ref("/dashboard-logs")
              .push({
                type: "ticket", // for filter
                changeBy: this.state.firstName,
                ticketIssuedBy: this.state.firstName, // for heading
                ticketAssignTo: "",
                ticketAssignBy: "",
                ticketNo: this.state.ticketNo, // for heading
                customerProduct: this.state.customerProduct, // for heading
                customerName: this.state.customerName, // for heading
                complaintSuject: this.state.ComplaintSuject, // for heading
                status: this.state.ticketStatus,
                createdOn: new Date().getTime(), // for heading
                UpdatedOn: new Date().getTime(),
                issueType: this.state.issueType, // for heading
                remark: "",
              })
              .then((res) => {
                console.log(res, "res");
                console.log(res.key);
                this.clearInput();
              })
              .catch((err) => {
                console.log(err, "err");
              });
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  }

  uploadFileToFirebase = (file) =>
    new Promise((resolve, reject) => {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }

      const guid = "#SMIS-" + s4() + "-" + s4() + "-" + s4();

      const tokenId = this.state.ticketNo;
      const storageURL = firebaseApp.storage(
        "gs://melzo-dashboard.appspot.com"
      );
      const storageRef = storageURL.ref();

      const uploadTask = storageRef
        .child("images")
        .child("Melzo-Dashboard")
        .child(tokenId)
        .child(file.name)
        .put(file);

      uploadTask.on(
        "state_changed",
        (snapShot) => {},
        (err) => {
          console.log(err);
          reject(err);
        },
        () => {
          firebaseApp
            .storage("gs://melzo-dashboard.appspot.com")
            .ref()
            .child("images")
            .child("Melzo-Dashboard")
            .child(tokenId)
            .child(file.name)
            .getDownloadURL()
            .then((firebaseUrl) => {
              if (firebaseUrl) {
                this.state.attachment.push({
                  id: guid,
                  url: firebaseUrl,
                });
              }
              resolve(firebaseUrl);
            })
            .catch((err) => {
              console.log("caught error::", err);
            });
        }
      );
    });

  validateSubmit = () => {
    if (this.state.customerName === "") {
      alert("Please Enter Customer Name");
      return false;
    } else if (this.state.customerEmail == "") {
      alert("Please Enter Customer Email");
      return false;
    } else if (this.state.customerProduct == "") {
      alert("Please Enter Customer Product");
      return false;
    } else if (this.state.customerMobile == "") {
      alert("Please Enter Customer Mobile no.");
      return false;
    } else if (this.state.ComplaintSuject == "") {
      alert("Please Enter Customer Complaint Subject");
      return false;
    } else if (this.state.complaintDes == "") {
      alert("Please Enter Customer complaint Description");
      return false;
    } else if (this.state.issueType == "") {
      alert("Please Enter Customer Issue Type");
      return false;
    } else if (this.state.ticketStatus == "") {
      alert("Please Enter Ticket Status");
      return false;
    } else {
      return true;
    }
  };

  clearInput = () => {
    this.setState({
      ticketNo: this.guid(),
      customerName: "",
      customerEmail: "",
      customerProduct: "",
      customerMobile: 91,
      ComplaintSuject: "",
      DateOfCreation: moment(),
      issueType: "",
      complaintDes: "",
      FileCollection: [],
      ticketStatus: "Open",
      loading: false,
      attachment: [],
    });
  };

  sendNotification = (ticketId) => {
    db.ref("/notification")
      .push({
        type: "TicketRaised",
        msg: "New Support Ticket Raised by " + this.state.firstName,
        date: Date.now(),
        userRole: this.state.userRole,
        userDept: ["Project Management"],
        shilpmisId: this.state.shilpmisId,
        firstName: this.state.firstName,
        userView: false,
        HeadView: false,
        ticketId: ticketId,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fillOtherData(e) {
    console.log("fill other details", e.target.value);
    this.setState({
      loading: true,
    });
    let userEmail = e.target.value;
    axios
      .post("https://ptpvr.com/users/getUserDetails", {
        userEmail: userEmail,
        getAllUsers: false,
      })
      .then((res) => {
        console.log("axios data", res.data);
        this.setState({
          customerName: res.data.displayName,
          customerProduct: res.data.subscriptionModule,
          loading: false,
        });
      })
      .catch((err) => {
        alert(err);
      });
  }

  render() {
    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="page-title">
                <div className="float-left">
                  <h1 className="title">Add Ticket</h1>
                </div>
              </div>

              <div className="row margin-0">
                <div className="col-12">
                  <section className="box ">
                    <header className="panel_header">
                      <h2 className="title float-left">Basic Info</h2>
                    </header>
                    <div className="content-body">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                          <form>
                            <div className="form-row">
                              <div className="form-group col-md-12">
                                <label htmlFor="inputname4">
                                  Ticket Number
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="inputname4"
                                  placeholder=""
                                  value={this.state.ticketNo}
                                  disabled
                                />
                              </div>

                              <div className="form-group col-md-12">
                                <label htmlFor="inputname423">
                                  Customer Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="inputname423"
                                  placeholder=""
                                  name="customerName"
                                  value={this.state.customerName}
                                  onChange={(e) => this.handleChange(e)}
                                />
                              </div>

                              <div
                                className="form-group col-md-12"
                                style={{ display: "flex" }}
                              >
                                <div style={{ width: "100%" }}>
                                  <label htmlFor="inputname425">
                                    Customer Email
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="inputname425"
                                    placeholder=""
                                    name="customerEmail"
                                    value={this.state.customerEmail}
                                    onChange={(e) => this.handleChange(e)}
                                    onBlur={(e) => this.fillOtherData(e)}
                                  ></input>
                                </div>
                                <div
                                  style={{
                                    position: "relative",
                                    top: "50px",
                                    left: "-30px",
                                  }}
                                >
                                  {this.state.loading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                </div>
                              </div>

                              {/* <div className="form-group col-md-12">
                                <label htmlFor="inputname425">Customer Product Name</label>
                                <input type="text" className="form-control" id="inputname425" placeholder="" />
                              </div> */}

                              <div className="form-group col-md-12">
                                <label htmlFor="product">
                                  Customer Product Name
                                </label>
                                <FormControl
                                  variant="outlined"
                                  style={{ width: "100%" }}
                                >
                                  {/* <InputLabel id="demo-simple-select-label">
                                    Customer Product Name
                                  </InputLabel> */}
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="product"
                                    name="customerProduct"
                                    // label=" Customer Product Name"
                                    title={this.state.customerProduct}
                                    value={this.state.customerProduct}
                                    onChange={(e) => this.handleChange(e)}
                                    // disabled
                                    renderValue={(dept) => dept.join(",")}
                                  >
                                    <MenuItem disabled>
                                      <em>Select Customer Product</em>
                                    </MenuItem>
                                    <MenuItem value={["Noor"]}>Noor</MenuItem>
                                    <MenuItem value={["Mela"]}>Mela</MenuItem>
                                    <MenuItem value={["Auto"]}>Auto</MenuItem>
                                    <MenuItem value={["Udyog"]}>Udyog</MenuItem>
                                    <MenuItem value={["Kala"]}>Kala</MenuItem>
                                    <MenuItem value={["Bro"]}>Bro</MenuItem>
                                    <MenuItem value={["Walkthrough"]}>
                                      Walkthrough
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                                {/* <Label htmlFor="product">
                                  Customer Product Name
                                </Label>
                                <Input
                                  type="select"
                                  name="customerProduct"
                                  id="product"
                                  value={this.state.customerProduct}
                                >
                                  <option>Select</option>
                                  <optgroup label="Mela">
                                    <option>Expo</option>
                                    <option>Event</option>
                                    <option>Mela (All)</option>
                                  </optgroup>
                                  <optgroup label="Noor">
                                    <option>360</option>
                                    <option>3D Model</option>
                                    <option>TryOn</option>
                                    <option>Ecommerce</option>
                                    <option>Noor (All)</option>
                                  </optgroup>
                                  <optgroup label="Auto">
                                    <option>Customiser</option>
                                  </optgroup>
                                  <optgroup label="Udyog">
                                    <option>AR</option>
                                    <option>VR</option>
                                    <option>Hologram</option>
                                    <option>Udyog(All)</option>
                                  </optgroup>
                                  <optgroup label="Kala">
                                    <option>Creator</option>
                                  </optgroup>
                                </Input> */}
                              </div>

                              <div className="form-group col-md-12">
                                <Label htmlFor="field-11">Mobile No</Label>
                                <InputMask
                                  id="field-11"
                                  className="form-control"
                                  mask="+99 99999 99999"
                                  maskChar="_"
                                  name="customerMobile"
                                  value={this.state.customerMobile}
                                  onChange={(e) => this.handleChange(e)}
                                />
                              </div>

                              <div className="form-group col-md-12">
                                <label htmlFor="inputname42">
                                  Complaint Subject
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="inputname42"
                                  placeholder=""
                                  name="ComplaintSuject"
                                  value={this.state.ComplaintSuject}
                                  onChange={(e) => this.handleChange(e)}
                                />
                              </div>

                              <div className="form-group col-md-12">
                                <label>Date of Creation</label>
                                <div className="controls">
                                  <DatePicker
                                    selected={this.state.DateOfCreation}
                                    disabled
                                  />
                                </div>
                              </div>

                              <div className="form-group col-md-12">
                                <Label htmlFor="exampleSelect">Type</Label>
                                <Input
                                  type="select"
                                  name="issueType"
                                  id="exampleSelect"
                                  value={this.state.issueType}
                                  onChange={(e) => this.handleChange(e)}
                                >
                                  <option>Select</option>
                                  <option>Login Issue</option>
                                  <option>File Upload Not Working</option>
                                  <option>Wrong Details</option>
                                  <option>Software Related Issue</option>
                                  <option>Other</option>
                                </Input>
                              </div>

                              <div className="form-group col-md-12">
                                <Label htmlFor="exampleText">Description</Label>
                                <Input
                                  type="textarea"
                                  name="complaintDes"
                                  id="exampleText"
                                  value={this.state.complaintDes}
                                  onChange={(e) => this.handleChange(e)}
                                />
                              </div>

                              <div className="form-group col-md-12">
                                <Label htmlFor="exampleFile">Attachments</Label>
                                <Input
                                  type="file"
                                  name="FileCollection"
                                  id="exampleFile"
                                  multiple
                                  onChange={(event) => {
                                    this.setState(
                                      {
                                        FileCollection: event.target.files,
                                      },
                                      () =>
                                        console.log(
                                          this.state.FileCollection,
                                          "file collection"
                                        )
                                    );
                                  }}
                                />
                              </div>

                              <div className="form-group col-md-12">
                                <Label htmlFor="exampleSelect">
                                  Ticket Status
                                </Label>
                                <Input
                                  type="select"
                                  name="select"
                                  id="exampleSelect"
                                  value={this.state.ticketStatus}
                                  disabled
                                >
                                  <option>Select</option>
                                  <option>Open</option>
                                  <option>Assign</option>
                                  <option>InProgress</option>
                                  <option>Resolved</option>
                                </Input>
                              </div>
                            </div>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => this.OnSubmitData()}
                            >
                              Submit
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default AddTicket;
