import React, { Component } from 'react';
import $ from 'jquery';
import firebase from '../../../Firebase2'
import '../Counter/Counter.css'

export default class Counter extends Component {

    componentDidMount() {
        this.counterRun();
    }

    counterRun() {
        var db = firebase.firestore();


        db.collection("Counter").doc("Website")
            .onSnapshot((doc) => {
                console.log("Current data: ", doc.data());
                var data = doc.data();
                var counters = $("#count");
               // console.log("run", document.getElementById('count1').innerHTML);
                var countersQuantity = counters.length;
                let startValue = 0;
                var count = function (start, value, id) {
                    if (start != startValue) {
                        startValue = start
                        var localStart = start;
                        if(id){
                        setInterval(function () {
                            if (localStart < value) {
                                localStart++;
                               // console.log("ID",id)
                                document.getElementById(id).innerHTML = localStart.toLocaleString(
                                    "en-US")
                            }
                        }, 1);
                        }
                    }
                }
                count(data.users - 75, data.users, 'count1');
                count(data.views - 75, data.views, 'count2');
                count(data.projects - 75, data.projects, 'count3');
            });


    }

    render() {
        return (
            <div className="container" style={{ height: "100vh" }}>
                <div class="row" id="Features">
                    <div class="col-12 mx-auto my-5">
                        <div class="row mx-auto my-5">
                            <div class="col-12 col-lg-12 col-md-12">
                                <div class="row mx-auto mb-4" id="CounterEnd">
                                    <div class="col-12 mb-4 text-center">
                                        <h1 style={{ color: "#75756d", fontFamily: 'Montserrat' }}>Using Melzo</h1>
                                    </div>
                                </div>
                                <div class="row" id="CounterStart">
                                    <div class="col-12 col-md-4 col-lg-4 my-4">
                                        <div class="countersection">
                                            <span id="count1" class="count" value="45">0</span>
                                        </div>
                                        <div class="countersection">
                                            Business and creators engaged with
                                    </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4 my-4">
                                        <div class="countersection">
                                            <span id="count2" class="count" value="100">0</span>
                                        </div>
                                        <div class="countersection">
                                            viewers across
                                    </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-4 my-4">
                                        <div class="countersection">
                                            <span id="count3" class="count" value="115">0</span>
                                        </div>
                                        <div class="countersection">
                                            immersive and
                                            interactive experiences
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
