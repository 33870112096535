/* eslint-disable */

import React, { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  IconButton,
  Button,
  TextField,
  DialogContentText,
  Fab,
  Avatar,
  CircularProgress,
} from "@material-ui/core";
import moment from "moment";
import CancelIcon from "@material-ui/icons/Cancel";
import AlertDialog from "../../../components/Register/AlertDialog";
import SendIcon from "@material-ui/icons/Send";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { ImageSearch } from "@material-ui/icons";
// import ImageZoom from "js-image-zoom";
import "./attachmentDialog.css";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    textAlign: "center",
  },
  dialogContent: {
    textAlign: "center",
  },
  dialogAction: {
    justifyContent: "center",
  },
  titleIcon: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
    },
  },
}));

export default function ProductImageDialog(props, {}) {
  var options = {
    width: 400,
    height: 250,
    zoomWidth: 500,
    // scale: 1.5,
    zoomLensStyle: {
      width: 5,
      heigth: 5,
      // backgroundColor : 'red'
    },
    zoomStyle: {
      opacity: 0.5,
    },
    // zoomPosition : 'right',
    offset: { vertical: 0, horizontal: 10 },
  };
  // imageZoom("myimage","myresult");
  const { open, Product, onClose } = props;
  const classes = useStyles();
  const [alertDialog, setAlertDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const setAlertDialogfunc = () => {
    setAlertDialog({
      ...alertDialog,
      isOpen: false,
      title: "",
      subTitle: "",
    });
  };
  const [productData, setProduct] = useState({
    productImage: [],
    ticketId: "",
    id: "",
    complaintDes: "",
  });

  const [productImage, setProductImage] = useState({
    image1: "",
    image2: "",
    image3: "",
    image4: "",
    image5: "",
    image6: "",
  });

  const [imageSelect, setImageSelect] = useState(1);

  const [meetingSpin, setmeetingSpin] = useState(false);

  useEffect(() => {
    console.log("product-imageDialog", Product[0]);
    setProduct({
      ticketId: Product[0] ? Product[0].ticketId : "Ticket Not Found",
      id: Product[0] ? Product[0].id : "Id Not Found",
      complaintDes: Product[0] ? Product[0].complaintDes : "Id Not Found",
    });
    setProductImage({
      image1: Product[0]
        ? Product[0].imagesCollection[0]
          ? Product[0].imagesCollection[0].url
          : ""
        : "",
      image2: Product[0]
        ? Product[0].imagesCollection[1]
          ? Product[0].imagesCollection[1].url
          : ""
        : "",
      image3: Product[0]
        ? Product[0].imagesCollection[2]
          ? Product[0].imagesCollection[2].url
          : ""
        : "",
      image4: Product[0]
        ? Product[0].imagesCollection[3]
          ? Product[0].imagesCollection[3].url
          : ""
        : "",
      image5: Product[0]
        ? Product[0].imagesCollection[4]
          ? Product[0].imagesCollection[4].url
          : ""
        : "",
      image6: Product[0]
        ? Product[0].imagesCollection[0]
          ? Product[0].imagesCollection[0].url
          : ""
        : "",
    });
    setImageSelect(1);
  }, [Product]);

  const handleImageChange = (index) => {
    let imagelink = productImage["image" + index];
    setProductImage({
      ...productImage,
      image6: imagelink,
    });
    setImageSelect(index);
  };

  const handleClose = () => {
    props.onClose();
  };

  const handleCloseBuyNow = () => {
    props.onClose();
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth
        fullScreen
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <h1 className="titleschedule">
            {"Ticket No ::" + productData.ticketId}
          </h1>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item md={8} xs={12}>
              <div
                className="img-zoom-container"
                style={{ borderColor: "black" }}
              >
                <img className="myimage" src={productImage.image6}></img>
                {/* <Avatar
                  variant="square"
                  id="myimage"
                  className={classes.square}
                  style={{
                    width: "max-content",
                    height: "max-content",
                    border: "1px solid black",
                  }}
                  src={productImage.image6}
                /> */}
              </div>
              <Grid item md={12} xs={12}>
                <div
                  id="templates"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "5px",
                    gap: "10px",
                  }}
                >
                  {productImage.image1 !== "" && (
                    <Button
                      style={
                        imageSelect == 1
                          ? {
                              border: "2px",
                              borderStyle: "solid",
                              borderColor: "#36cca5",
                            }
                          : {}
                      }
                      onClick={() => handleImageChange("1")}
                    >
                      <Avatar
                        variant="square"
                        className={classes.square}
                        style={{
                          width: "50px",
                          height: "50px",
                          cursor: "pointer",
                        }}
                        src={productImage.image1}
                      />
                    </Button>
                  )}
                  {productImage.image2 !== "" && (
                    <Button
                      style={
                        imageSelect == 2
                          ? {
                              border: "2px",
                              borderStyle: "solid",
                              borderColor: "#36cca5",
                            }
                          : {}
                      }
                      onClick={() => handleImageChange("2")}
                    >
                      <Avatar
                        variant="square"
                        className={classes.square}
                        style={{
                          width: "50px",
                          height: "50px",
                          cursor: "pointer",
                        }}
                        src={productImage.image2}
                      />
                    </Button>
                  )}
                  {productImage.image3 !== "" && (
                    <Button
                      style={
                        imageSelect == 3
                          ? {
                              border: "2px",
                              borderStyle: "solid",
                              borderColor: "#36cca5",
                            }
                          : {}
                      }
                      onClick={() => handleImageChange("3")}
                    >
                      <Avatar
                        variant="square"
                        className={classes.square}
                        style={{
                          width: "50px",
                          height: "50px",
                          cursor: "pointer",
                        }}
                        src={productImage.image3}
                      />
                    </Button>
                  )}
                  {productImage.image4 !== "" && (
                    <Button
                      style={
                        imageSelect == 4
                          ? {
                              border: "2px",
                              borderStyle: "solid",
                              borderColor: "#36cca5",
                            }
                          : {}
                      }
                      onClick={() => handleImageChange("4")}
                    >
                      <Avatar
                        variant="square"
                        className={classes.square}
                        style={{
                          width: "50px",
                          height: "50px",
                          cursor: "pointer",
                        }}
                        src={productImage.image4}
                      />
                    </Button>
                  )}
                  {productImage.image5 !== "" && (
                    <Button
                      style={
                        imageSelect == 5
                          ? {
                              border: "2px",
                              borderStyle: "solid",
                              borderColor: "#36cca5",
                            }
                          : {}
                      }
                      onClick={() => handleImageChange("5")}
                    >
                      <Avatar
                        variant="square"
                        className={classes.square}
                        style={{
                          width: "50px",
                          height: "50px",
                          cursor: "pointer",
                        }}
                        src={productImage.image5}
                      />
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography
                variant="h5"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {"Description : " + productData.complaintDes}
              </Typography>
              <br></br>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            className="btn-glow btn-hover-shine fontschedule"
          >
            <CancelIcon style={{ marginRight: "5px" }} />
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        alertDialog={alertDialog}
        setAlertDialog={setAlertDialogfunc}
      />
    </React.Fragment>
  );
}

function sendEmail(
  customerFName,
  customerLName,
  customerEmail,
  customerMsg,
  product
) {
  var customerName = customerFName + " " + customerLName;
  const regMessage =
    "Dear Admin," +
    "<br><br/>" +
    `<p> Hello Admin, you get this email because one of your customer inquired for your products. </p>` +
    `<br></br>` +
    `<p> Details are as follow :: </p>` +
    `<br></br><br></br>` +
    `<table style="width:100%">
    <tr>
      <th>Customer Name</th>
      <th>Customer Email</th>
      <th>Inquiry For</th>
      <th>Inquiry Message</th>
    </tr>
    <tr>
      <td>${customerName}</td>
      <td>${customerEmail}</td>
      <td>${product}</td>
      <td>${customerMsg}</td>
    </tr>
  </table>`;
  // const regMessage =
  //   "Dear Dr./Prof. " +
  //   regName +
  //   "," +
  //   "<br/><br/>" +
  //   `<p>Thank you for registering with us, for Merz Aesthetics Anatomy E-workshop.</p>

  // <p>To join the event please visit <a href="https://merzanatomyworkshop.com">https://merzanatomyworkshop.com</a></p>
  //  <p>On 9th & 10th April 2021. <a href="https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20210409T120000Z%2F20210410T173000Z&details=Experience%20the%20most%20informative%20e-Workshop%20on%20advanced%20anatomy%20and%20live%20injection%20course%20in%20UAE.%20%0AJoin%20us%20to%20enhance%20your%20knowledge%20and%20understand%20the%20intricacies%20of%20the%20Human%20Anatomy%20and%20its%20enhancement.&location=Online&text=Merz%20Aesthetics%20Anatomy%20E-workshop" title="Save Event in my Calendar" >Add to Calendar</a></p>
  // <br/>
  // <p>As per your registration form, your login credentials are - </p>
  // <p>Login ID : <strong>${regEmail}</strong></p>
  // <p>Password : <strong>${regPassword}</strong></p>
  // <br/>
  // <p> Join Meeting : <a href=${regPassword}>${regPassword}</a></p>
  // <br/>
  // <p>Thank you once again and have a wonderful day! </p>
  // <br/>
  // <b>If you have any queries please reply to this mail.</b>
  // <br/>
  // <p>Thank you,</p>
  // <br/>
  // <p>Merz Aesthtics Anatomy E-workshop</p>
  // <br/>
  // <img src="https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/merz/merz-logo.jpeg" alt="Merz Logo" style="height: 40px">
  // <br/>
  // <p  style="font-size: 10px;">Disclaimer : Please note that if you submit information to Merz all materials submitted by electronic or physical communication will be deemed non confidential. Do not submit any information or other materials that you consider to be confidential or proprietary. While we have no intention to publicly disclose any details of your proposal, we may refer to select experts within or outside the Merz network for evaluation.</p>
  // <br/>

  // <br/>`;
  // console.log("Email:: ", tempEmail);

  // var obj = {
  //   useremail: localStorage.getItem("email"),
  //   message: regMessage,
  //   subject: `Inquiry for ${product} by ${customerEmail} `,
  //   SiteType: expoName,
  // };

  var obj = {
    useremail: "admin mail",
    message: regMessage,
    subject: `Inquiry for ${product} by ${customerEmail} `,
    SiteType: "productInquiry",
  };

  console.log("Mail data => ", regMessage);
  //http://localhost:5000/sendtoemail-3610d/us-central1/expoRegistration
  // axios
  //   .post(
  //     "https://us-central1-sendtoemail-3610d.cloudfunctions.net/anglesSendemail",
  //     obj,
  //     {
  //       headers: {
  //         // 'x-access-token': token
  //       },
  //     }
  //   )
  //   .then(function () {
  //     // window.location.href = 'website.html'
  //     console.log("Done", regEmail);
  //     // IncrementVisitorCounter();
  //   })
  //   .catch((error) => {
  //     console.log("Not DOne", error);
  //   });
}

// function imageZoom(imgID, resultID, lensDiv) {
//   console.log(imgID, resultID, lensDiv);
//   var img, lens, result, cx, cy;
//   img = document.getElementsByClassName(imgID);
//   result = document.getElementsByClassName(resultID);
//   /*create lens:*/
//   // lens = document.createElement("DIV");
//   lens = document.getElementsByClassName(lensDiv);
//   // lens.setAttribute("className", "img-zoom-lens");
//   /*insert lens:*/
//   // img.parentElement.insertBefore(lens, img);
//   /*calculate the ratio between result DIV and lens:*/
//   cx = result.offsetWidth / lens.offsetWidth;
//   cy = result.offsetHeight / lens.offsetHeight;
//   /*set background properties for the result DIV:*/
//   result.style.backgroundImage = "url('" + img.src + "')";
//   result.style.backgroundSize = img.width * cx + "px " + img.height * cy + "px";
//   /*execute a function when someone moves the cursor over the image, or the lens:*/
//   lens.addEventListener("mousemove", moveLens);
//   img.addEventListener("mousemove", moveLens);
//   /*and also for touch screens:*/
//   lens.addEventListener("touchmove", moveLens);
//   img.addEventListener("touchmove", moveLens);

//   function moveLens(e) {
//     var pos, x, y;
//     /*prevent any other actions that may occur when moving over the image:*/
//     e.preventDefault();
//     /*get the cursor's x and y positions:*/
//     pos = getCursorPos(e);
//     /*calculate the position of the lens:*/
//     x = pos.x - lens.offsetWidth / 2;
//     y = pos.y - lens.offsetHeight / 2;
//     /*prevent the lens from being positioned outside the image:*/
//     if (x > img.width - lens.offsetWidth) {
//       x = img.width - lens.offsetWidth;
//     }
//     if (x < 0) {
//       x = 0;
//     }
//     if (y > img.height - lens.offsetHeight) {
//       y = img.height - lens.offsetHeight;
//     }
//     if (y < 0) {
//       y = 0;
//     }
//     /*set the position of the lens:*/
//     lens.style.left = x + "px";
//     lens.style.top = y + "px";
//     /*display what the lens "sees":*/
//     result.style.backgroundPosition = "-" + x * cx + "px -" + y * cy + "px";
//   }
//   function getCursorPos(e) {
//     var a,
//       x = 0,
//       y = 0;
//     e = e || window.event;
//     /*get the x and y positions of the image:*/
//     a = img.getBoundingClientRect();
//     /*calculate the cursor's x and y coordinates, relative to the image:*/
//     x = e.pageX - a.left;
//     y = e.pageY - a.top;
//     /*consider any page scrolling:*/
//     x = x - window.pageXOffset;
//     y = y - window.pageYOffset;
//     return { x: x, y: y };
//   }
// }
