import React, { Component } from 'react'
import Footer from './Footer';
import Header from './Header';
import LeadLock from './LeadLock';
import LeadLockAdmin from './LeadLockAdmin';
import RegisterLogin from './RegisterLogin';

export default class Leads extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isUserLoggedIn: false,
            isAdminRole: false
        }
    }

    componentDidMount() {
        if (localStorage.getItem('isLogged') && localStorage.getItem('isLogged') === "true") {
            if (localStorage.getItem('userRole') === "admin") {
                this.setState({ isAdminRole: true })
            }
            this.setState({ isUserLoggedIn: true })
        } else {
            this.setState({ isUserLoggedIn: false })
        }
    }

    render() {
        return (
            <div>

                <Header />
                {(this.state.isUserLoggedIn && !this.state.isAdminRole) &&
                    <LeadLock />
                }
                {(this.state.isUserLoggedIn && this.state.isAdminRole) &&
                    <LeadLockAdmin />
                }
                {!this.state.isUserLoggedIn &&
                    <RegisterLogin />
                }
                <Footer variant="dark" />
            </div>
        )
    }
}
