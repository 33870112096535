import React from "react";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  InputGroup,
  InputGroupAddon,
  Input,
} from "reactstrap";

import { Messages, Notifications } from "../../../components";
import axios from "axios";
import dashboardRoutes from "../../../routes/general.jsx";

import { messages } from "../../../variables/topbar.jsx";
import { notifications } from "../../../variables/topbar.jsx";
import profileStd from "../../../assets/img/profile2.png";
import { db } from "../../../Firebase";
import Attendancefeature from "../../../components/attendence/attendence";
import Button from "@material-ui/core/Button";

var IMGDIR = process.env.REACT_APP_IMGDIR;
var userName = " ";
var myInterval;
var timer;
var seconds = 0;
var automaticallyCallInterval;
var automaticallyCallTimer;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      userddOpen: false,
      searchOpen: false,
      messagesddOpen: false,
      sidebarddOpen: true,
      chatddOpen: false,
      notificationsddOpen: false,
      userRole: "",
      userDept: [],
      shilpmisId: "",
      notification: 0,
      color: "primary",
      profilename: "Eric Nelson",
      profileimg: IMGDIR + "/images/profile/profile.jpg",
      // attendence
      checkIn: false,
      empDetail: "",
      attendanceData: [],
      filterData: [],
      checkStatus: false,
      checkRegisterStatus: "",
      checkDuration: "",
      updateAttendance: false,
      dbCheckInTime: "",
      pastTotalTime: "",
      allEmpData:[],
      registerDetail:[],

    };
    this.toggle = this.toggle.bind(this);
    this.userddToggle = this.userddToggle.bind(this);
    this.messagesddToggle = this.messagesddToggle.bind(this);
    this.notificationsddToggle = this.notificationsddToggle.bind(this);
    this.searchToggle = this.searchToggle.bind(this);
    this.logout = this.logout.bind(this);
  }
  toggle() {
    if (this.state.isOpen) {
      this.setState({
        color: "primary",
      });
    } else {
      this.setState({
        color: "white",
      });
    }
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  userddToggle(e) {
    this.setState({
      userddOpen: !this.state.userddOpen,
    });
  }
  logout() {
    localStorage.clear();
  }
  searchToggle() {
    //this.refs.searchbarToggle.classList.toggle('toggled');
    this.setState({
      searchOpen: !this.state.searchOpen,
    });
    //console.log(this.state.searchOpen);
    //this.refs.searchbarToggle.classList.toggle('opened');
  }
  messagesddToggle(e) {
    this.setState({
      messagesddOpen: !this.state.messagesddOpen,
    });
  }
  chatddToggle(e) {
    this.setState({
      chatddOpen: !this.state.chatddOpen,
    });
  }
  sidebarddToggle(e) {
    this.setState({
      sidebarddOpen: !this.state.sidebarddOpen,
    });
  }
  notificationsddToggle(e) {
    this.setState({
      notificationsddOpen: !this.state.notificationsddOpen,
    });
  }
  getBrand() {
    var name;
    dashboardRoutes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      } else {
        if (prop.redirect) {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        } else {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        }
      }
      return null;
    });
    return name;
  }
  openSidebar() {
    document.documentElement.classList.toggle("nav-toggle");
    this.refs.sidebarToggle.classList.toggle("toggled");
    // this.setState({
    //   sidebarddOpen: !this.state.sidebarddOpen,
    // });

    // close chat bar if open on smaller screens
    if (window.innerWidth < 993) {
      document.documentElement.classList.remove("nav-toggle-chat");
      this.refs.chatToggle.classList.remove("toggled");
    }
  }

  openChat() {
    document.documentElement.classList.toggle("nav-toggle-chat");
    // this.setState({
    //   chatddOpen: !this.state.chatddOpen,
    // });
    // this.refs.chatToggle.classList.toggle('toggled');

    // close menu bar if open on smaller screens
    if (window.innerWidth < 993) {
      document.documentElement.classList.remove("nav-toggle");
      this.refs.sidebarToggle.classList.remove("toggled");
    }
  }
  toggle_grid() {
    document.documentElement.classList.toggle("toggle-grid");
  }

  openStyle() {
    document.documentElement.classList.toggle("nav-toggle-style");
    // this.refs.chatToggle.classList.toggle('toggled');

    // close menu bar if open on smaller screens
    /*if(window.innerWidth < 993){
            document.documentElement.classList.remove('nav-toggle');
            this.refs.sidebarToggle.classList.remove('toggled');
        }*/
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor() {
    if (window.innerWidth < 993 && this.state.isOpen) {
      this.setState({
        color: "primary",
      });
    } else {
      this.setState({
        color: "primary",
      });
    }
  }
  componentDidMount() {
    
    this.setState({
      userRole: localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).userRole
        : " ",
      shilpmisId: localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).email
        : " ",
      userDept: localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).department
        : " ",
    });

    // ------------------

    let data = [];
  
    db.ref("/attendence").on("value", (val) => {
      val.forEach((element) => {
        let obj = element.val();
        obj["id"] = element.key;
        data.push(obj);
      });

      const finaldata = data.filter((item) => {
        return item.userId === currentUserId;
      });

      // console.log(finaldata);
      if (finaldata[finaldata.length - 1]) {
        this.setState({
          checkRegisterStatus: finaldata[finaldata.length - 1],
        });
        localStorage.setItem("uid", finaldata[finaldata.length - 1].id);

        this.setState({
          checkDuration: finaldata[finaldata.length - 1].checkInTime,
        });
      }
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");

      const result = data.filter((item) => {
        return (
          item.userId === currentUserId &&
          new Date(item.checkInTime)
            .toLocaleString()
            .split(",")[0]
            .split("/")[0] == dd
        );
      });

     // console.log("RESULTS", result);
      let TotalTime =0;
      for (let i = 0; i < result.length; i++) {
        if(result[i].checkOut!==""){
        TotalTime += result[i].duration;
      }
      }

        this.setState({
          pastTotalTime: TotalTime,
        });

    });

    // db.ref()

    if (localStorage.getItem("uid")) {
      this.setState({
        checkStatus: true,
      });
    }

    if (localStorage.getItem("IsloggedIn") === "true") {
      this.timerFunc();
    }

    userName = localStorage.getItem("employeeObject")
      ? JSON.parse(localStorage.getItem("employeeObject")).firstName +
        " " +
        JSON.parse(localStorage.getItem("employeeObject")).lastName
      : "";
    if (this.props.navtype === "mini") {
      document.documentElement.classList.add("nav-toggle");
      // this.refs.sidebarToggle.classList.add('toggled');
    } else {
      document.documentElement.classList.remove("nav-toggle");
      // this.refs.sidebarToggle.classList.remove('toggled');
    }
    window.addEventListener("resize", this.updateColor.bind(this));

    let currentUserId = localStorage.getItem("employeeObject")
      ? JSON.parse(localStorage.getItem("employeeObject")).userID
      : " ";
    new Promise((res, rej) => {
      db.ref("/employees/")
        .orderByChild("/userID")
        .equalTo(currentUserId)
        .on("value", (queryEmp) => {
          let empData = [];
          queryEmp.forEach((e) => {
            let obj = e.val();
            obj["id"] = e.key;
            empData.push(obj);
          });
          this.setState({
            profileimg: empData[0].image !== "" ? empData[0].image : profileStd,
          });
          res(empData[0].image);
        });
    })
      .then((res) => {
        if (this.props.admintype === "general") {
          this.setState({
            profilename: "Nancy Spencer",
          });
        } else if (this.props.admintype === "hospital") {
          this.setState({
            profilename: "Dianna Austin",
          });
        } else if (this.props.admintype === "university") {
          this.setState({
            profilename: "Henry Gibson",
            // profileimg: IMGDIR + "/images/profile/profile-university.jpg",
          });
        } else if (this.props.admintype === "crm") {
          this.setState({
            profilename: "Rick  Woods",
            // profileimg: IMGDIR + "/images/profile/profile-crm.jpg",
          });
        } else if (this.props.admintype === "music") {
          this.setState({
            profilename: "Kerry Flores",
            // profileimg: IMGDIR + "/images/profile/profile-music.jpg",
          });
        } else if (this.props.admintype === "blog") {
          this.setState({
            profilename: "Alice Gross",
            // profileimg: IMGDIR + "/images/profile/profile-blog.jpg",
          });
        } else if (this.props.admintype === "ecommerce") {
          this.setState({
            profilename: "Jake  Daniel",
            // profileimg: IMGDIR + "/images/profile/profile-ecommerce.jpg",
          });
        } else if (this.props.admintype === "freelance") {
          this.setState({
            profilename: "Eric Nelson",
            // profileimg: IMGDIR + "/images/profile/profile-freelance.jpg",
          });
        } else if (this.props.admintype === "social") {
          this.setState({
            profilename: "Penny Taylor",
            // profileimg: IMGDIR + "/images/profile/profile-social.jpg",
          });
        } else {
          this.setState({
            profilename: "Nancy Spencer",
            // profileimg: IMGDIR + "/images/profile/profile-general.jpg",
          });
        }
        this.onGetLeaveMsg();
      })
      .catch((err) => {
        console.log("error", err);
      });
    this.getdata();
     
  

  }

  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-toggle") !== -1
    ) {
      document.documentElement.classList.toggle("nav-toggle");
      // this.refs.sidebarToggle.classList.toggle("toggled");
    }
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-toggle-chat") !== -1
    ) {
      document.documentElement.classList.toggle("nav-toggle-chat");
    }
  }

  getdata = () => {
    let currentUserId = localStorage.getItem("employeeObject")
      ? JSON.parse(localStorage.getItem("employeeObject")).userID
      : " ";
    db.ref("/employees/")
      .orderByChild("/userID")
      .equalTo(currentUserId)
      .on("value", (queryEmp) => {
        let empData = [];
        queryEmp.forEach((e) => {
          if (e.val().status == "active") {
            let obj = e.val();
            obj["id"] = e.key;
            empData.push(obj);
          }
        });
        this.setState({
          empDetail: empData,
        });
      });
     
      let employeeDataPromise = new Promise((resolve, reject) => {
        db.ref("/employees/").on("value", (queryEmployee) => {
          let employeeArray = [];
          queryEmployee.forEach((element) => {
            if (element.val().status == "active") {
              let object = element.val();
              object["id"] = element.key;
              employeeArray.push(object);
            }
          });
          resolve(employeeArray);
        });
      });
      employeeDataPromise.then((acceptedData) => {
        this.setState({
        allEmpData:acceptedData
       })
      });
  
      let Regdata = [];
      db.ref("/attendence").on("value", (val) => {
        val.forEach((element) => {
          let obj = element.val();
          obj["id"] = element.key;
          Regdata.push(obj);
        });
        if (Regdata) {
         
          var today = new Date();
          var dd = String(today.getDate()).padStart(2, "0");
    
          const result = Regdata.filter((item) => {
            return (
              new Date(item.checkInTime)
                .toLocaleString()
                .split(",")[0]
                .split("/")[0] == dd
            );
          });
//          console.log("TODAY",result);
              this.setState({
                registerDetail:result
              })
        }
      });
  };

  getAttendenceData = () =>{
    let currentUserId = localStorage.getItem("employeeObject")
    ? JSON.parse(localStorage.getItem("employeeObject")).userID
    : " ";
    
    let data = [];
  
    db.ref("/attendence").on("value", (val) => {
      val.forEach((element) => {
        let obj = element.val();
        obj["id"] = element.key;
        data.push(obj);
      });

      const finaldata = data.filter((item) => {
        return item.userId === currentUserId;
      });

      // console.log(finaldata);
      if (finaldata[finaldata.length - 1]) {
        this.setState({
          checkRegisterStatus: finaldata[finaldata.length - 1],
        });
        localStorage.setItem("uid", finaldata[finaldata.length - 1].id);

        this.setState({
          checkDuration: finaldata[finaldata.length - 1].checkInTime,
        });
      }
  })
  };

  getLocation = () => {
    // console.log(this.empDetail)

    if (!navigator.geolocation) {
      alert("Geolocation is not supported by your browser");
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.fetchCity(position.coords.latitude, position.coords.longitude);
        },
        () => {
          alert("Unable to retrieve your location please allow location");
        }
      );
    }
  };

  fetchCity = async (lat, lng) => {
    try {
      const res = await axios.get(
        `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lng}&APPID=7ffb4de22f4a115872ffb0b3df9e8bd5`
      );
      this.clickedToCheckIn(res.data.name);
    } catch (err) {
      console.error(err);
    }
  };

  clickedToCheckIn = (location) => {
    localStorage.setItem("IsloggedIn", false);
  
    this.timerFunc();
    db.ref("/attendence")
      .push({
        userId: this.state.empDetail[0].userID,
        userRole: this.state.empDetail[0].userRole,
        name:
          this.state.empDetail[0].firstName +
          " " +
          this.state.empDetail[0].lastName,
        location: location,
        checkInTime: Date.parse(new Date()),
        checkOut: "",
        duration: "",
        today:new Date().toLocaleDateString()
      })
      .then((data) => {
        this.getAttendenceData()
        localStorage.setItem("uid", data.path.pieces_[1]);
        this.setState({
          checkStatus: true,
        });
        this.onChecks()
        this.timerFunc();
       
        if (localStorage.getItem("IsloggedIn") === "true") {
          this.timerFunc();
        
        }
        localStorage.setItem("checkin", Date.parse(new Date()));
        localStorage.setItem("IsloggedIn", true);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //43200

  // automaticallyCallFunction = ()=>{
  //   window.setInterval(function(){ 
      // var date = new Date(); 
      //   if(date.getHours() === 18 && date.getMinutes() === 6){
      //     alert("HELLO");
      // }
  //   }, 60000); 
  // }




  timerFunc = () => {

    myInterval = setInterval(() => {
      if (this.state.checkRegisterStatus.checkOut === "") {
        timer =parseInt(Date.now() - parseInt(this.state.checkDuration)) / 1000;

        var spanTimer = document.getElementById("timer-span");
        if (document.getElementById("timer-span")) {
          if (isNaN(timer)) {
            spanTimer.innerHTML = `Check In 00:00`;
          } else {
           if(parseInt(Math.trunc(timer)) + parseInt(this.state.pastTotalTime)!=82800){
            spanTimer.innerHTML = `${this.secondsToHms(parseInt(Math.trunc(timer)) + parseInt(this.state.pastTotalTime))}`;
          } else{
            clearInterval(myInterval);
            timer=0;
            
            this.onCheckOuts();
            spanTimer.innerHTML = `${this.secondsToHms(this.state.pastTotalTime)}`;
            this.setState({
              pastTotalTime: 0,
            });
          }
          }
        }
      }
    }, 1000);
   
    var now = new Date();
    var millisTill10 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 18, 0, 0, 0) - now;
    if (millisTill10 < 0) {
        millisTill10 += 86400000; // it's after 6pm, try 6pm tomorrow.
    }
    setTimeout(()=>{
   
    this.automaticallyEntery();
    }, millisTill10);


    var now = new Date();
    var millisTill10 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 22, 0, 0, 0) - now;
    if (millisTill10 < 0) {
        millisTill10 += 86400000; // it's after 6pm, try 6pm tomorrow.
    }
    setTimeout(()=>{
      clearInterval(myInterval);
      timer=0;
      var spanTimer = document.getElementById("timer-span");
      this.onCheckOuts();
      spanTimer.innerHTML = `${this.secondsToHms(this.state.pastTotalTime)}`;
      this.setState({
        pastTotalTime: 0,
      });
    }, millisTill10);

   
  };


  automaticallyEntery(){
    
     let Users = this.state.allEmpData.filter((itemA)=> {
      return !this.state.registerDetail.find((itemB)=> {
        return itemA.userID == itemB.userId && itemB.today == new Date().toLocaleString().split(",")[0]
      })
    })
   
    for(let i=0;i<Users.length;i++){
        db.ref("/attendence")
        .push({
          userId: Users[i].userID,
          userRole: Users[i].userRole,
          name:Users[i].firstName + " " + Users[i].lastName,
          location: "No Location Found",
          checkInTime: "Absent",
          checkOut: "Absent",
          duration: "",
          today:new Date().toLocaleDateString(),
        })
      }
  }

  time_convert = (num) => {
    var hours = Math.floor(num / 60);
    var minutes = num % 60;
    return hours + ":" + minutes;
  };

  secondsToHms = (seconds) =>
    { // day, h, m and s
      var days     = Math.floor(seconds / (24*60*60));
          seconds -= days    * (24*60*60);
      var hours    = Math.floor(seconds / (60*60));
          seconds -= hours   * (60*60);
      var minutes  = Math.floor(seconds / (60));
          seconds -= minutes * (60);
      return ((0<days)?(days+" day, "):"")+hours+"h: "+minutes+"m : "+seconds+"s";
    }


  onChecks = () => {
    let currentUserId = localStorage.getItem("employeeObject")
      ? JSON.parse(localStorage.getItem("employeeObject")).userID
      : " ";
    let data = [];
    db.ref("/attendence").on("value", (val) => {
      val.forEach((element) => {
        let obj = element.val();
        obj["id"] = element.key;
        data.push(obj);
      });

      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");

      const result = data.filter((item) => {
        return (
          item.userId === currentUserId &&
          new Date(item.checkInTime)
            .toLocaleString()
            .split(",")[0]
            .split("/")[0] == dd
        );
      });
      let TotalTime=0;

      for (let i = 0; i < result.length; i++) {
        if(result[i].checkOut!==""){
        TotalTime += result[i].duration;
        }
      }
      var spanTimer = document.getElementById("timer-span");
      spanTimer.innerHTML = `${this.secondsToHms(this.state.pastTotalTime)}`;
     
        this.setState({
          pastTotalTime: TotalTime,
        });
    });
  };

  onCheckOuts = () => {
    //this.onChecks();
   // clearInterval(myInterval);
    // var spanTimer = document.getElementById("timer-span");
    // spanTimer.innerHTML = `Check In 00:00`;
    localStorage.setItem("IsloggedIn", true);
    db.ref("/attendence/" + this.state.checkRegisterStatus.id)
      .update({
        checkOut: Date.parse(new Date()),
        duration: Math.trunc(parseInt(timer)),
      })
      .then(() => {
        clearInterval(myInterval);
        this.onChecks()
        this.setState({
          checkStatus: false,
        });
        // spanTimer.style.display = "none";
        localStorage.removeItem("uid");
        localStorage.removeItem("IsloggedIn");
        localStorage.setItem("IsloggedIn", false);
        localStorage.removeItem("checkin");
        var spanTimer = document.getElementById("timer-span");
        spanTimer.innerHTML = `${this.secondsToHms(this.state.pastTotalTime)}`;
      })
      .catch((e) => {
        console.log(e);
      });
  };

  componentWillUnmount() {
    if (localStorage.getItem("IsloggedIn") === "true") {
      this.timerFunc();

    }
  }

  onGetLeaveMsg = () => {
    const self = this;
    db.ref("/notification/").on("value", (snapshot) => {
      let leaveResultData = [];
      snapshot.forEach((element) => {
        let obj = element.val();
        obj["id"] = element.key;
        leaveResultData.push(obj);
      });
      let filterNotification = [];

      if (self.state.userRole != "HR") {
        filterNotification = leaveResultData.filter((msgdata) => {
          return (
            msgdata.msg != "" &&
            msgdata.userView == false &&
            msgdata.shilpmisId == self.state.shilpmisId
          );
        });
      }
      if (self.state.userRole == "HR") {
        filterNotification = leaveResultData.filter((msgdata) => {
          return msgdata.msg != "" && msgdata.HRView == false;
        });
      }
      if (self.state.userRole == "team_lead") {
        filterNotification = leaveResultData.filter((msgdata) => {
          let dept = "Default";

          for (let i = 0; i < this.state.userDept.length; i++) {
            if (msgdata.userDept.includes(this.state.userDept[i])) {
              dept = this.state.userDept[i];
            }
          }
          return (
            msgdata.msg != "" &&
            msgdata.HeadView == false &&
            msgdata.userRole == "employee" &&
            msgdata.userDept == dept
          );
        });
      }
      if (self.state.userRole == "dept_head") {
        filterNotification = leaveResultData.filter((msgdata) => {
          let dept = "Default";

          for (let i = 0; i < this.state.userDept.length; i++) {
            if (msgdata.userDept.includes(this.state.userDept[i])) {
              dept = this.state.userDept[i];
            }
          }
          return (
            msgdata.msg != "" &&
            msgdata.HeadView == false &&
            (msgdata.userRole == "team_lead" || msgdata.userRole == "HR" || msgdata.userRole == "employee") &&
            msgdata.userDept == dept
          );
        });
      }
      if (self.state.userRole == "admin") {
        filterNotification = leaveResultData.filter((msgdata) => {
          let dept = "Default";

          for (let i = 0; i < this.state.userDept.length; i++) {
            if (msgdata.userDept.includes(this.state.userDept[i])) {
              dept = this.state.userDept[i];
            }
          }
          return (
            msgdata.msg != "" &&
            msgdata.HeadView == false &&
            msgdata.userRole == "team_head" &&
            msgdata.userDept == dept
          );
        });
      }
      if (self.state.userRole == "superadmin") {
        filterNotification = leaveResultData.filter((msgdata) => {
          let dept = "Default";

          for (let i = 0; i < this.state.userDept.length; i++) {
            if (msgdata.userDept.includes(this.state.userDept[i])) {
              dept = this.state.userDept[i];
            }
          }
          return (
            msgdata.msg != "" &&
            msgdata.HeadView == false &&
            msgdata.userRole == "admin" &&
            msgdata.userDept == dept
          );
        });
      }

      self.setState(
        {
          notification: filterNotification.length,
        },
        () => {
          //  console.log("leave new database", self.state.notification);
        }
      );
    });
  };



  render() {
    return (
      <Navbar
        expand="lg"
        className={
          this.props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "navbar-absolute fixed-top"
            : "navbar-absolute fixed-top "
        }
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button
                type="button"
                ref="sidebarToggle"
                className="navbar-toggler"
                onClick={() => this.openSidebar()}
              >
                <i className="i-menu"></i>
              </button>
            </div>

            {/* <Dropdown
              nav
              isOpen={this.state.sidebarddOpen}
              toggle={(e) => this.openSidebar(e)}
              className="navbardd"
            >
              <DropdownToggle caret nav>
                <i className="i-menu"></i>
              </DropdownToggle>
            </Dropdown> */}

            {/* <Dropdown
                nav
                isOpen={this.state.chatddOpen}
                toggle={(e) => this.openChat(e)}
                className="navbardd"
              >
                <DropdownToggle caret nav>
                  <i className="i-bubbles"></i>
                  <span className="badge badge-pill badge-primary">
                    {this.state.notification}
                  </span>
                </DropdownToggle>
              </Dropdown> */}

            {/* <Dropdown
              nav
              isOpen={this.state.messagesddOpen}
              toggle={(e) => this.messagesddToggle(e)}
              className="navbardd"
            >
              <DropdownToggle caret nav>
                <i className="i-envelope"></i>
                <span
                  className="badge badge-pill badge-primary"
                  style={{ background: "yellowgreen" }}
                >
                  {this.state.notification}
                </span>
              </DropdownToggle>
              <Messages messages={messages} />
            </Dropdown> */}

            {/* <Dropdown nav isOpen={this.state.notificationsddOpen} toggle={(e) => this.notificationsddToggle(e)} className="navbardd">
                                <DropdownToggle caret nav>
                                    <i className="i-bell"></i>
                                    <span className="badge badge-pill badge-primary"></span>
                                </DropdownToggle>
                                <Notifications notifications={notifications}/>
                            </Dropdown> */}

            {/* <form className="topbar-search-form">
                                <InputGroup className={"topbar-search "+(this.state.searchOpen === true ? "open":"")}>
                                    <InputGroupAddon addonType="append" onClick={() => this.searchToggle()}><i className="i-magnifier"></i></InputGroupAddon>
                                    <Input placeholder="Search..." />
                                </InputGroup>
                            </form>


                        {/* <NavbarBrand href="/">{this.getBrand()}</NavbarBrand> */}
          </div>

          <Collapse isOpen={this.state.isOpen} navbar className="navbar-right">
            <Nav navbar>
              <Dropdown
                nav
                isOpen={this.state.userddOpen}
                toggle={(e) => this.userddToggle(e)}
                className="userdd"
              >
                <DropdownToggle caret nav>
                  <img
                    src={this.state.profileimg}
                    alt="react-logo"
                    className="avatar-image"
                  />{" "}
                  <span>{userName}</span>
                </DropdownToggle>
                <DropdownMenu right>
                  {/* <DropdownItem tag="a"><i className="i-wrench" href="#!"></i> Settings</DropdownItem>
                                    <DropdownItem tag="a"><i className="i-user" href="#!"></i> Profile</DropdownItem>
                                    <DropdownItem tag="a"><i className="i-info" href="#!"></i> Help</DropdownItem> */}
                  <DropdownItem
                    tag="a"
                    className=""
                    href="/"
                    onClick={() => this.logout()}
                  >
                    <i className="i-lock"></i> Logout
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              {/* <Dropdown
                nav
                isOpen={this.state.chatddOpen}
                toggle={(e) => this.openChat(e)}
                className="navbardd"
              >
                <DropdownToggle caret nav>
                  <i className="i-bubbles"></i>
                  <span className="badge badge-pill badge-primary">
                    {this.state.notification}
                  </span>
                </DropdownToggle>
              </Dropdown> */}
              <NavItem>
                <div className="navbar-toggle chat-toggle">
                  <button
                    type="button"
                    ref="chatToggle"
                    className="navbar-toggler"
                    onClick={() => this.openChat()}
                  >
                    <i className="i-bubbles"></i>
                    <span className="badge badge-pill badge-primary">
                      {this.state.notification}
                    </span>
                  </button>
                </div>
              </NavItem>
            </Nav>
            <div>
              
              <div className="headeFlex">
                {this.state.checkRegisterStatus.checkOut !== "" ? (
                  <div className="headeFlex">
                    <Button
                      id="checkIn"
                      variant="contained"
                      color="primary"
                      className="marginrightbtns1"
                      onClick={() => this.getLocation()}
                    >
                      Check In
                    </Button>

                    <span
                        className="span1"
                        id="timer-span"
                        style={{ backgroundColor: "00FFFFFF", color: "#26a69a",marginTop:"5px",marginLeft:"-20px" }}
                      >{this.secondsToHms(this.state.pastTotalTime)}</span>
                  </div>
                ) : (
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      id="checkOut"
                      variant="contained"
                      color="secondary"
                      className="marginrightbtns1"
                      onClick={() => this.onCheckOuts()}
                    >
                      Check Out
                      
                    </Button>

                    <span
                        className="span1"
                        id="timer-span"
                        style={{ backgroundColor: "00FFFFFF", color: "#26a69a",marginTop:"5px" ,marginLeft:"-20"}}
                      ></span>
                  </div>
                )}

              {/* <div id="PolicyDiv">
                <a href='http://www.africau.edu/images/default/sample.pdf' target='_blank' rel='noopener noreferrer'>
                  <Button variant="contained"  id="Policy" >Policy</Button>
                </a>
              </div> */}
              </div>
            </div>

            {/* <div id="PolicyDivOutside">
                <a href='http://www.africau.edu/images/default/sample.pdf' target='_blank' rel='noopener noreferrer'>
                  <Button variant="contained"  id="Policy" >Policy</Button>
                  </a>
              </div> */}
            <div className="screensize" onClick={() => this.toggle_grid()}></div>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default Header;
