import React, { Component } from "react";
import "../Register/register.css";
import vr1Img from "../../assets/img/vr1.jpeg";
import vr2Img from "../../assets/img/vr2.jpeg";
import { db } from "../../Firebase";
import CryptoJS from "crypto-js";
import Page from "./page";
import { CircularProgress } from "@material-ui/core";

const key = "V6NiO7OMHC";
export default class RegisterLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      password: "",
      email: "",
      city: "",
      mobileNumber: "",
      loginSpin: false,
      registerSpin: false,
    };
  }
  componentDidMount = () => {
    // this.onGetLeadData()
  };

  isExistingUserEmail = (emailToCheck) => {
    return new Promise((resolve, reject) => {
      db.ref("/leadUsers")
        .orderByChild("email")
        .equalTo(emailToCheck)
        .on("value", (query) => {
          let leadData = [];
          query.forEach((element) => {
            let obj = element.val();
            obj["id"] = element.key;
            leadData.push(obj);
          });
          resolve(leadData);
        });
    });
  };

  toggleForm = () => {
    const container = document.querySelector(".container");
    container.classList.toggle("active");
  };
  handlechange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  //const string2=password-online.replace(/[_]/g,'/');
  // const decrypted = CryptoJS.AES.decrypt(string2, key);
  //
  handleSubmit = () => {
    let name = this.state.userName;
    let email = this.state.email;
    let phoneNo = this.state.mobileNumber;

    const patternIndianMob = /^[6789]{1}[0-9]{9}$/;
    const patternEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (name === "") {
      alert("Please enter your name");
    } else if (email === "") {
      alert("Please enter your email id");
    } else if (this.state.password === "") {
      alert("Please enter password");
    } else if (phoneNo === "") {
      alert("Please enter your mobile number");
    } else if (!patternEmail.test(email)) {
      alert("Please enter a valid email id");
    } else if (!patternIndianMob.test(phoneNo) || parseInt(phoneNo[0]) < 6) {
      console.warn("Not valid indian number", phoneNo);
      alert("Please enter valid 10 digit mobile number");
    } else {
      this.setState({ registerSpin: true });
      const self = this;
      this.isExistingUserEmail(email).then((response) => {
        if (response.length > 0) {
          self.setState({ registerSpin: false });
          alert("You are already registered");
        } else {
          const encrypted = CryptoJS.AES.encrypt(this.state.password, key);
          const encrypted_string = encrypted.toString().replace(/[/]/g, "_");
          db.ref("leadUsers")
            .push({
              userName: this.state.userName,
              email: this.state.email,
              mobileNumber: this.state.mobileNumber,
              city: this.state.city,
              password: encrypted_string,
              dateTime: new Date().getTime(),
              isEnabled: true,
              userRole: "cp",
              manager: "melzo",
            })
            .then((res) => {
              console.log("Register Sucessfull", res.key);
              localStorage.setItem("userDocId", res.key);
              localStorage.setItem("userName", this.state.userName);
              localStorage.setItem("email", this.state.email);
              localStorage.setItem("mobileNumber", this.state.mobileNumber);
              localStorage.setItem("city", this.state.city);
              localStorage.setItem("userRole", "cp");
              localStorage.setItem("isLogged", 'true');
              this.setState({
                userName: "",
                password: "",
                email: "",
                city: "",
                mobileNumber: "",
              });
              window.location.href = "/leads";
              self.setState({ registerSpin: false });
            })
            .catch((error) => {
              console.error("Register Error", error);
              self.setState({ registerSpin: false });
            });
        }
      });
    }
  };
  handleLogin = () => {
    if (this.state.email === "") {
      alert("Please enter your email");
    } else if (this.state.password === "") {
      alert("Please enter your password")
    } else {
      this.setState({ loginSpin: true });
      const self = this;
      this.isExistingUserEmail(this.state.email)
        .then((response) => {
          if (response.length > 0) {
            const string2 = response[0].password.replace(/[_]/g, "/");
            const decrypted = CryptoJS.AES.decrypt(string2, key);
            if (this.state.password === decrypted.toString(CryptoJS.enc.Utf8)) {
              console.log("Password match");
              localStorage.setItem("userName", response[0].userName);
              localStorage.setItem("email", response[0].email);
              localStorage.setItem("mobileNumber", response[0].mobileNumber);
              localStorage.setItem("city", response[0].city);
              localStorage.setItem("userDocId", response[0].id);
              localStorage.setItem("userRole", response[0].userRole);
              localStorage.setItem("isLogged", 'true');
              window.location.href = "/leads";
              self.setState({ loginSpin: false });
            } else {
              console.log(
                "Password didn't match",
                this.state.password,
                " : ",
                decrypted.toString(CryptoJS.enc.Utf8)
              );
              self.setState({ loginSpin: false });
              alert("Your password is incorrect");
            }
          } else {
            self.setState({ loginSpin: false });
            alert("Couldn't find the details, Please Register First");
          }
        })
        .catch((error) => {
          console.error("Login Error", error);
          self.setState({ loginSpin: false });
          alert("Couldn't find the details");
        });
    }
  };

  handleKeyUp = (e) => {
    if (e.keyCode === 13) {

      switch (e.target.id) {
        case "loginEmail":
          document.getElementById('loginPassword').focus();
          break;
        case "loginPassword":
          document.getElementById('loginButton').click();
          break;
        case "registerName":
          document.getElementById('registerEmail').focus();
          break;
        case "registerEmail":
          document.getElementById('registerPassword').focus();
          break;
        case "registerPassword":
          document.getElementById('registerMobile').focus();
          break;
        case "registerMobile":
          document.getElementById('registerCity').focus();
          break;
        case "registerCity":
          document.getElementById('registerButton').click();
          break;

        default:

          break;
      }
    }
  }

  render() {
    return (
      <div>

        <Page title="Melzo Leads Login">
          <section>
            <div className="container">
              <div className="user signinBx">
                <div className="imgBx">
                  <img
                    src={vr1Img}
                    alt
                  />
                </div>
                <div className="formBx">
                  <div className="form-A">
                    <h2>Sign In</h2>
                    <input
                      type="email"
                      name="email"
                      id="loginEmail"
                      onKeyUp={this.handleKeyUp}
                      value={this.state.email}
                      onChange={this.handlechange}
                      placeholder="Email"
                      required
                    />
                    <input
                      type="password"
                      name="password"
                      id="loginPassword"
                      onKeyUp={this.handleKeyUp}
                      value={this.state.password}
                      onChange={this.handlechange}
                      placeholder="Password"
                      required
                    />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        type="submit"
                        defaultValue="Login"
                        id="loginButton"
                        disabled={this.state.loginSpin}
                        onKeyUp={this.handleKeyUp}
                        onClick={this.handleLogin}
                      />
                      {this.state.loginSpin &&
                        <CircularProgress
                          color="primary"
                          style={{ margin: "15px" }}
                          size={20}
                        />
                      }
                    </div>
                    <p className="signup">
                      Don't have an account ?
                    <div className="linktochangeform" onClick={this.toggleForm}>
                        Sign Up.
                    </div>
                    </p>
                  </div>
                </div>
              </div>
              <div className="user signupBx">
                <div className="formBx">
                  <div className="form-A">
                    <h2>Create an account</h2>
                    <input
                      type="text"
                      name="userName"
                      id="registerName"
                      onKeyUp={this.handleKeyUp}
                      value={this.state.userName}
                      onChange={this.handlechange}
                      placeholder="Name"
                      required
                    />
                    <input
                      type="email"
                      name="email"
                      id="registerEmail"
                      onKeyUp={this.handleKeyUp}
                      value={this.state.email}
                      onChange={this.handlechange}
                      placeholder="Email Address"
                      required
                    />
                    <input
                      type="password"
                      name="password"
                      id="registerPassword"
                      onKeyUp={this.handleKeyUp}
                      value={this.state.password}
                      onChange={this.handlechange}
                      placeholder="Password"
                      required
                    />

                    <input
                      type="number"
                      name="mobileNumber"
                      id="registerMobile"
                      onKeyUp={this.handleKeyUp}
                      value={this.state.mobileNumber}
                      onChange={this.handlechange}
                      placeholder="Mobile No."
                      required
                    // maxLength="10"
                    />
                    <input
                      type="text"
                      name="city"
                      id="registerCity"
                      onKeyUp={this.handleKeyUp}
                      value={this.state.city}
                      onChange={this.handlechange}
                      placeholder="City"
                    />

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        type="submit"
                        id="registerButton"
                        disabled={this.state.registerSpin}
                        onKeyUp={this.handleKeyUp}
                        defaultValue="Sign Up"
                        onClick={this.handleSubmit}
                      />
                      {this.state.registerSpin &&
                        <CircularProgress
                          color="primary"
                          style={{ margin: "15px" }}
                          size={20}
                        />
                      }
                    </div>
                    <p className="signups">
                      Already have an account ?
                    <div className="linktochangeform" onClick={this.toggleForm}>
                        Sign in.
                    </div>
                    </p>
                  </div>
                </div>
                <div className="imgBx">
                  <img
                    src={vr2Img}
                    alt
                  />
                </div>
              </div>
            </div>
          </section>
        </Page>
      </div>
    );
  }
}
