import React from "react";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import { db } from "../../../Firebase";
import ReactDataGrid from "react-data-grid";


const columns = [
  { key: "departmentCode", name: "Department Code", editable: true },
  { key: "departmentName", name: "Department Name", editable: true },
]

export default class DepartmentTable extends React.Component {
  constructor() {
    super();
    this.state = {
      departmentData: [],
      id: "",
    };
  }

  componentDidMount() {
    this.onGetDepartmentData();
  }

  onGetDepartmentData() {
    let departmentDataPromise = new Promise((resolve, reject) => {
      db.ref("/departmentMaster/").on("value", (querySnap) => {
        let departmentArray = [];
        querySnap.forEach(element => {
          let obj = element.val();
          obj["id"] = element.key;
          departmentArray.push(obj);
        });
        resolve(departmentArray);
      })
    });
    departmentDataPromise.then(acceptedData => {
      this.setState({
        departmentData: acceptedData,
      })
    })
  }

  onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    // console.log("From row ", fromRow, "To row", toRow, "Updated", updated);
    // let state = this.state.employeeData;
    // this.setState(employeeData => {
    const rows = this.state.departmentData.slice();
    for (let i = fromRow; i <= toRow; i++) {
      rows[i] = { ...rows[i], ...updated };
    }
    // console.log("Rows", rows)
    this.setState({ departmentData: rows })
    // return { rows };
    // });
  };

  onSaveUpdatedData = () => {    
    for (let i = 0; i < this.state.departmentData.length; i++) {
      db.ref("/departmentMaster/"+ this.state.departmentData[i].id ).update({
        departmentName: this.state.departmentData[i].departmentName,
        departmentCode: this.state.departmentData[i].departmentCode,
      })
    }
  }

  render() {

    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>

              <div className="page-title">
                <div className="float-left">
                  <h1 className="title">Department Table</h1>
                </div>
              </div>



              <div className="col-12">
                <section className="box ">
                  <header className="panel_header">
                    <h2 className="title float-left">Table</h2>

                  </header>
                  <div className="content-body">
                    <div className="row">
                      <div className="col-lg-12">
                        <button className="btn btn-primary btn-sm profile-btn" onClick={this.onSaveUpdatedData} style={{ marginBottom: "15px" }}>Save</button>
                      </div>
                      <div className="col-lg-12">

                        <ReactDataGrid
                          //minHeight={this.state.departmentData.length * 25 + 50}
                          rowHeight={50}
                          columns={columns}
                          rowGetter={i => this.state.departmentData[i]}
                          rowsCount={50}
                          onGridRowsUpdated={this.onGridRowsUpdated}
                          enableCellSelect={true}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );

  }
}