import React from "react";
import Button from "reactstrap/lib/Button";
import Col from "reactstrap/lib/Col";
import Form from "reactstrap/lib/Form";
import FormGroup from "reactstrap/lib/FormGroup";
import Input from "reactstrap/lib/Input";
import Label from "reactstrap/lib/Label";
import Row from "reactstrap/lib/Row";
import { db } from "../../../Firebase";


export default class DepartmentMaster extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            departmentName: '',
            departmentCode: '',
            id: "",

            departmentData: [],
            departmentTempData: [],
            filtered: [],
            showPopup: false,
        }
    }

    componentDidMount() {
        this.onGetDepartmentData();
    }

    onGetDepartmentData() {
        let departmentDataPromise = new Promise((resolve, reject) => {
            db.ref('/departmentMaster').on('value', querySnapShot => {
                let data = [];
                querySnapShot.forEach(element => {
                    let obj = element.val();
                    obj["id"] = element.key;
                    data.push(obj);
                });
                resolve(data)
            });
        });
        departmentDataPromise.then(acceptedData => {
            this.setState({
                departmentData: acceptedData,
                departmentTempData: acceptedData,
            })
        })
    }

    validateData = () => {
        if (this.state.departmentName.trim() == "") {
            alert("Department name can not be blank");
            return false;
        } else if (this.state.departmentCode.trim() == "") {
            alert("Department Code can not be blank");
            return false;
        }

        let filterdData = this.state.departmentData.filter(singleDepartment => (singleDepartment.departmentName == this.state.departmentName));

        if (filterdData.length > 0) {
            alert(this.state.departmentName + " Already exist");
            return false;
        }
        else {
            return true;
        }
    }

    handleSubmit = () => {

        if (this.validateData()) {
            let today = new Date().getTime();

            db.ref('/departmentMaster').push({
                departmentName: this.state.departmentName,
                departmentCode: this.state.departmentCode,
                entryData: today,
                entryBy: localStorage.getItem('email'),
                updateDate: '',
                updateBy: ''
            });

        }
    }

    handleCancel = () => {
        this.setState({
            departmentName: '',
            departmentCode: '',
            id: "",
        })
    }


    render() {
        return (
            <div className="container-fluid">
                <div className="content">
                    <Row>
                        <Col>
                            <div className="page-title">
                                <div className="float-left">
                                    <h1 className="title">Department Master</h1>
                                </div>
                            </div>
                            <div className="col-12">
                                <section className="box">
                                    <header className="panel_header">
                                        <h2 className="title float-left">Department Master</h2>
                                    </header>

                                    <div className="content-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <Form inline>
                                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                                        <Label htmlFor="departmentName" className="mr-sm-2">Department Name</Label>
                                                        <input type="text" onChange={(e) => this.setState({ departmentName: e.target.value })} name="departmentName" id="departmentName" placeholder="Department Name" />
                                                    </FormGroup>
                                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                                        <Label htmlFor="departmentCode" className="mr-sm-2">Department Code</Label>
                                                        <input type="text" onChange={(e) => this.setState({ departmentCode: e.target.value })} name="departmentCode" id="departmentCode" placeholder="Department Code" />
                                                    </FormGroup>
                                                </Form>
                                                <button className="btn btn-primary" onClick={this.handleSubmit} style={{marginTop : "10px"}}>Submit</button>
                                                <button className="btn btn-secondary" onClick={this.handleCancel} style={{marginTop : "10px"}}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}
