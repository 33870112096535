import tableToExcel from '@linways/table-to-excel';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import GetAppIcon from "@material-ui/icons/GetApp";
import { Button } from 'react-bootstrap';
import {

    Row, Col,
} from 'reactstrap';

import {
    Memberslist
} from '../../../components';
import { db } from '../../../Firebase';

//import { members } from '../../../variables/general/members.jsx';


class Members extends React.Component {
    constructor() {
        super();

        this.state = {
            employeeData: [],
            members: [],
            userRole: ""
        };
    }

    componentDidMount() {
        this.setState({
            userRole: localStorage.getItem('employeeObject') ? (JSON.parse(localStorage.getItem('employeeObject')).userRole) : " ",
        })
        this.onGetEmployeeData();
    }




    onGetEmployeeData() {
        let employeeDataPromise = new Promise((resolve, reject) => {
            db.ref("/employees/")
                .on("value", (queryEmployee) => {
                    let employeeArray = [];
                    queryEmployee.forEach(element => {
                        if(element.val().status == "active"){

                            let object = element.val();
                            object["id"] = element.key;
                            employeeArray.push(object);
                        
                        }
                    })
                    resolve(employeeArray);
                })
        });
        employeeDataPromise.then(acceptedData => {
            this.setState({
                employeeData: acceptedData,
            }, () => {
                console.log("emp Data", this.state.employeeData);
                let membersList = [];
                this.state.employeeData.map((obj) => {
                    let data = { name: obj.firstName + " " + obj.lastName, designation: obj.designation, department: obj.department, userID: obj.userID, docId :obj.id, profilePhoto : obj.image, facebook : obj.facebook ? obj.facebook : "", linkedIn : obj.linkedIn ? obj.linkedIn : "", isSocialMedia : obj.isSocialMedia ? obj.isSocialMedia : false }
                    membersList.push(data);
                    //console.log(membersList);
                    this.setState({
                        members: membersList,
                    }, () => console.log("members::", this.state.members));
                })
            })
        })
    }

    exportToExcel() {
        tableToExcel.convert(document.getElementById("table1"), {
            name: "EmployeeList.xlsx",
            sheet: {
                name: "EmployeeData"
            }
        });
    }

    render() {

        return (
            <div>
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>

                            <div className="page-title">
                                <div className="float-left">
                                    <h1 className="title">Members</h1>
                                </div>
                            </div>

                            <div className="col-xl-12">
                                <section className="box ">
                                    <header className="panel_header">
                                        <Grid container spacing={3}>
                                            <Grid item md={11} xs={12}>
                                                <h2 className="title float-left">Melzo Members</h2>
                                            </Grid>
                                            <Grid item md={1} ms={12} style={{display : 'flex'}}>
                                                {this.state.userRole == "HR" && (<GetAppIcon onClick={this.exportToExcel} style={{margin: 'auto', cursor : 'pointer'}} titleAccess="Download">
                                                    Excel Download
                                                </GetAppIcon>)}
                                            </Grid>
                                        </Grid>
                                    </header>
                                    {/* Member List is other Class component used for display employee cards */}
                                    <div className="content-body">
                                        <Memberslist members={this.state.members} />
                                    </div>
                                </section>
                            </div>

                            <Table aria-label="simple table" id="table1" data-cols-width = {"10,20,30,20,20,50,20,20,20,30,30,20,10,30,20,30,10,20,30,20,10,10,30"} style={{ display: 'none' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell data-f-bold = {true}>UserID</TableCell>
                                        <TableCell data-f-bold = {true}>Employee Name</TableCell>
                                        <TableCell data-f-bold = {true}>Email</TableCell>
                                        <TableCell data-f-bold = {true}>Mobile</TableCell>
                                        <TableCell data-f-bold = {true}>Alternate Contact</TableCell>
                                        <TableCell data-f-bold = {true}>Department</TableCell>
                                        <TableCell data-f-bold = {true}>Designation</TableCell>
                                        <TableCell data-f-bold = {true}>UserRole</TableCell>
                                        <TableCell data-f-bold = {true}>ReportingTo</TableCell>

                                        <TableCell data-f-bold = {true}>DateOfBirth</TableCell>
                                        <TableCell data-f-bold = {true}>Address</TableCell>
                                        <TableCell data-f-bold = {true}>EmergencyContactName</TableCell>
                                        <TableCell data-f-bold = {true}>Relation</TableCell>

                                        <TableCell data-f-bold = {true}>Status</TableCell>
                                        <TableCell data-f-bold = {true}>UpdateDate</TableCell>
                                        <TableCell data-f-bold = {true}>UpdateBy</TableCell>
                                        <TableCell data-f-bold = {true}>EntryTime</TableCell>
                                        <TableCell data-f-bold = {true}>EntryData</TableCell>
                                        <TableCell data-f-bold = {true}>EntryBy</TableCell>

                                        <TableCell data-f-bold = {true}>JoiningDate</TableCell>
                                        <TableCell data-f-bold = {true}>LeaveTime</TableCell>
                                        <TableCell data-f-bold = {true}>Remotely?</TableCell>
                                        <TableCell data-f-bold = {true}>DataBase_id</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.employeeData.map((row) => (
                                        <TableRow key={row.name}>
                                            <TableCell component="th" scope="row">{row.userID}</TableCell>
                                            <TableCell data-a-v = {"middle"}>{row.firstName + " " + row.lastName}</TableCell>
                                            <TableCell data-a-v = {"middle"}>{row.email}</TableCell>
                                            <TableCell data-a-v = {"middle"}>{row.mobile}</TableCell>
                                            <TableCell data-a-v = {"middle"}>{row.altContact}</TableCell>
                                            <TableCell data-a-wrap = {true} data-a-v = {"middle"}>{row.department!=="Na" ? row.department && row.department.join(", ") : ""}</TableCell>
                                            <TableCell data-a-v = {"middle"}>{row.designation}</TableCell>
                                            <TableCell data-a-v = {"middle"}>{row.userRole}</TableCell>
                                            <TableCell data-a-v = {"middle"}>{row.reportingTo}</TableCell>

                                            <TableCell data-a-v = {"middle"}>{new Date(row.dateOfBirth).toLocaleDateString('en-IN')}</TableCell>
                                            <TableCell data-a-v = {"middle"}>{row.address}</TableCell>
                                            <TableCell data-a-v = {"middle"}>{row.emergencyContactName}</TableCell>
                                            <TableCell data-a-v = {"middle"}>{row.relation}</TableCell>

                                            <TableCell data-a-v = {"middle"}>{row.status}</TableCell>
                                            <TableCell data-a-v = {"middle"}>{new Date(row.updateDate).toLocaleString('en-IN')}</TableCell>
                                            <TableCell data-a-v = {"middle"}>{row.updateBy}</TableCell>
                                            <TableCell data-a-v = {"middle"}>{row.entryTime}</TableCell>
                                            <TableCell data-a-v = {"middle"}>{new Date(row.entryData).toLocaleString('en-IN')}</TableCell>
                                            <TableCell data-a-v = {"middle"}>{row.entryBy}</TableCell>

                                            <TableCell data-a-v = {"middle"}>{new Date(row.joiningDate).toLocaleDateString('en-IN')}</TableCell>
                                            <TableCell data-a-v = {"middle"}>{row.leaveTime}</TableCell>
                                            <TableCell data-a-v = {"middle"}>{row.remotely}</TableCell>
                                            <TableCell data-a-v = {"middle"}>{row.id}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default Members;
