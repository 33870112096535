import React, { Component } from 'react';
import "./header.css";
import melzoLogo from "../../assets/img/melzologo.png";

export default class Header extends Component {
    render() {
        return (
            <div>
                <div className="positionlogo">
                    <img src={melzoLogo} alt="logo" className="logowidth" />
                </div>
            </div>
        )
    }
}
