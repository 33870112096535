import React from "react";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import ReactDataGrid from "react-data-grid";
import { db } from "../../../Firebase";

const columns = [
    { key: "date", name: "Date", editable: true },
    { key: "dealDoneBy", name: "Deal Done By", editable: true },
    { key: "dealName", name: "Deal Name", editable: true },
    { key: "dealValue", name: "Deal Value", editable: true },
];

export default class SalesTable extends React.Component {
    constructor() {
        super();

        this.state = {
            salesData: [],
        }
    }

    componentDidMount() {
        this.onGetSalesData();
    }

    onGetSalesData = () => {
        let salesDataPromise = new Promise((resolve, reject) => {
            db.ref("/sales/")
                .on("value", (querySales) => {
                    let salesArray = [];
                    querySales.forEach((element) => {
                        let object = element.val();
                        object["id"] = element.key;
                        salesArray.push(object);
                    })
                    resolve(salesArray);
                })
        })
        salesDataPromise.then(acceptedData => {
            this.setState({
                salesData: acceptedData,
            },() => console.log("sales table::", this.state.salesData))
        })
    }

    onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
       
        const rows = this.state.salesData.slice();
        for (let i = fromRow; i <= toRow; i++) {
            rows[i] = { ...rows[i], ...updated };
        }
       
        this.setState({ salesData: rows })

    };

    onSaveUpdatedData = () => {
        for(let i = 0; i < this.state.salesData.length; i++) {
            db.ref("/sales/" + this.state.salesData[i].id).update({
                dealName: this.state.salesData[i].dealName, 
                dealDoneBy: this.state.salesData[i].dealDoneBy,
                dealName: this.state.salesData[i].dealName,
                dealValue: this.state.salesData[i].dealValue,
            })
        }
    }

    render() {

        return (
            <div>
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>

                            <div className="page-title">
                                <div className="float-left">
                                    <h1 className="title">Sales Data</h1>
                                </div>
                            </div>



                            <div className="col-12">
                                <section className="box ">
                                    <header className="panel_header">
                                        <h2 className="title float-left">Data</h2>
                                    </header>
                                    <div className="content-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <button className="btn btn-primary btn-sm profile-btn" style={{ marginBottom: "15px" }} onClick={this.onSaveUpdatedData }>Save</button>
                                            </div>
                                            <div className="col-lg-12">
                                                <ReactDataGrid
                                                    // minHeight={this.state.payrollData.length * 25 + 50}
                                                    rowHeight={50}
                                                    columns={columns}
                                                    rowGetter={i => this.state.salesData[i]}
                                                    rowsCount={50}
                                                    onGridRowsUpdated={this.onGridRowsUpdated}
                                                    enableCellSelect={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );

    }
}