import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  IconButton,
  Button,
  TextField,
  TextareaAutosize,
} from "@material-ui/core";
import NotListedLocationIcon from "@material-ui/icons/NotListedLocation";
import "./alertDilog.css";

const useStyles = makeStyles((theme) => ({
  dialog: {
    height: "auto !important",
    width: "auto !important",
    minWidth: "100px !important",
  },
  dialogTitle: {
    textAlign: "center",
  },
  dialogContent: {
    textAlign: "center",
  },
  dialogAction: {
    justifyContent: "center",
  },
  titleIcon: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
    },
  },
}));

export default function AlertDialog(props) {
  const { alertDialog, setAlertDialog, closeAlertDilog, getDataofTextFild } =
    props;

  const classes = useStyles();
  const [openDilog, setOpenDilog] = useState("");
  const [testFild, setTextFild] = useState({ reasonOfRejection: "" });

  const functionToSaveTextFild = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setTextFild({ ...testFild, ["reasonOfRejection"]: value });
  };

  // useEffect(() => {}, []);

  // let openTo = alertDialog.isOpen;

  return (
    <Dialog
      open={openDilog || alertDialog.isOpen}
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <IconButton disableRipple className={classes.titleIcon}>
          <NotListedLocationIcon color="primary" />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="subtitle1">{alertDialog.title}</Typography>
        <Typography variant="subtitle2">{alertDialog.subTitle}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        {alertDialog.reasonFild == true ||
          (!alertDialog.dual == true && (
            <Button
              name="true"
              text="Ok"
              color="primary"
              variant="contained"
              onClick={() => setAlertDialog(testFild)}
            >
              Ok
            </Button>
          ))}

        {alertDialog.dual == true && (
          <>
            <Button
              name="true"
              text="Ok"
              color="primary"
              variant="contained"
              onClick={() => setAlertDialog()}
            >
              Ok
            </Button>

            <Button
              name="true"
              text="Ok"
              color="primary"
              variant="contained"
              onClick={() => closeAlertDilog(testFild)}
            >
              Cancel
            </Button>
          </>
        )}

        {alertDialog.reasonFild == true && (
          <div>
            {/* <TextField
              autoFocus
              margin="dense"
              id="reason"
              label="Bill rejected reason"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              onChange={functionToSaveTextFild}
            /> */}
            <Typography variant="subtitle2" component="h2">
              Bill rejected reason
            </Typography>
            <TextareaAutosize
              id="reason"
              // className="inputFilds"
              name="descriptionOfExpense"
              fullWidth
              maxRows={20}
              value={testFild.reasonOfRejection}
              label="Bill rejected reason"
              onChange={functionToSaveTextFild}
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div id="button">
              <Button
                name="true"
                text="Ok"
                color="primary"
                variant="contained"
                onClick={() => {
                  setAlertDialog(testFild);
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
}
