import React from 'react';
import {
    Row, Col,
} from 'reactstrap';

import {

} from '../../../components';
import { firebaseApp, db } from '../../../Firebase';
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import { InputLabel } from '@material-ui/core';
import Dropzone from 'react-dropzone';

class UserForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            attendanceId: 0,
            userID: 0,
            firstName: "",
            lastName: "",
            gmail: "",
            email: "",
            contact1: "",
            contact2: "",
            address: "",
            imageURL: [],
            department: "",
            reportingTo: "",
            joiningDate: "",
            designation: "",
            pref: "Mr",
            dateOfBirth: "",
            resignedDate: "",
            userRole: "",
            employeeList: [],
            entryTime: "10:00",
            leaveTime: "18:30",
            bankName: "",
            accHolderName: "",
            bankAccNo: "",
            ifsc: "",
            designationData: [],
            deptData: [],
            reportingEmp: [],

            filtered: [],
            remotely: false,
            password: "",
            confirmPassword: "",
            isConfirmPass: false,
            isShowPassword: false,
            emergencyContactName: "",
            relation: "",
            id: "",
            edit: false,
            showPopup: false,
            status: "",

            demoData: [],
            departmentalSelectedCheckBox: [],

        };
    }

    componentDidMount() {
        this.onGetDesignationData();
        this.onGetEmployeeData();
        this.onGetDepartmentData();
    }


    onGetDepartmentData() {
        let departmentDataPromise = new Promise((resolve, reject) => {
            db.ref("/departmentMaster/").on("value", (queryDept) => {
                let deptData = [];
                queryDept.forEach((element) => {
                    let obj = element.val();
                    obj["id"] = element.key;
                    deptData.push(obj);
                });
                resolve(deptData);
            })
        })
        departmentDataPromise.then(acceptedData => {
            this.setState({
                deptData: acceptedData,
            }, () => console.log("department data::", this.state.deptData));
        })
    }

    onGetDesignationData() {
        let designationDataPromise = new Promise((resolve, reject) => {
            db.ref("/designationMaster/").on("value", (queryDesignation) => {
                let designationArray = [];
                queryDesignation.forEach((element) => {
                    let object = element.val();
                    object["id"] = element.key;
                    designationArray.push(object);
                });
                resolve(designationArray);
            })
        })
        designationDataPromise.then(acceptedData => {
            this.setState({
                designationData: acceptedData,
            })
        })
    }

    onGetEmployeeData() {
        let employeeDataPromise = new Promise((resolve, reject) => {
            db.ref("/employees/").on("value", (querySnapShot) => {
                let data = [];
                querySnapShot.forEach((element) => {
                    if(element.val().status == "active"){

                        let obj = element.val();
                        obj["id"] = element.key;
                        data.push(obj);
                    
                    }
                });
                resolve(data);
            })
        });
        employeeDataPromise.then(acceptedData => {
            this.setState({
                employeeList: acceptedData,
            })
        })
    }

    clearInput = () => {
        this.setState(
            {
                firstName: "",
                lastName: "",
                gmail: "",
                email: "",
                contact1: "",
                contact2: "",
                relation: "",
                emergencyContactName: "",
                address: "",
                imageURL: "",
                departmentalSelectedCheckBox: [],
                reportingTo: "",
                joiningDate: "",
                resignedDate: "",
                designation: "",
                pref: "Mr",
                dateOfBirth: "",
                userRole: "",
                password: "",
                confirmPassword: "",
                id: "",
                remotely: false,
                status: "",
                bankName: "",
                accHolderName: "",
                bankAccNo: "",
                ifsc: "",

                userID:
                    parseInt(
                        this.state.employeeList[
                            this.state.employeeList.length - 1
                        ].userID.split("SMIS")[1]
                    ) + 1,
                attendanceId:
                    parseInt(
                        this.state.employeeList[
                            this.state.employeeList.length - 1
                        ].attendanceId
                    ) + 1,
                entryTime: "09:00",
                leaveTime: "19:00",
            }, () => {
                console.log("Attebnd ID :: ", this.state.attendanceId)
            }
        );
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    chkRemotely = (event) => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    departmentCheckBoxChange = (e) => {
        let valuesForReportTo = []
        let fetchedReportData = []
        let flilteredFetchedReportData = []

        // selected multiple checkbox
        this.setState({
            departmentalSelectedCheckBox: e.target.value
        })

        // selected multiple checkbox
        let selectedDepartment = e.target.value

        //repeat this for every UserRole(from userrole dropdown) Will optimize later within one if condition but later
        if (this.state.userRole === 'employee') {
            //db reference to employees data. Where condition applied on field userRole to fetch tech_lead enteries 
            // if employees -> tech_lead
            let ref = db.ref("employees");
            ref.orderByChild("userRole").
                on("child_added", (snapshot) => {

                    // pushes feched data from db in fetchedReportData
                    if (snapshot.val().userRole == "team_lead" || snapshot.val().userRole == "dept_head" || snapshot.val().userRole == "admin") {
                        fetchedReportData.push({
                            name: snapshot.val().firstName,
                            department: snapshot.val().department
                        })
                    }
                    // filetered data based on department head dropdown
                    // you will get errors for previously added department data which was not in form of array
                    flilteredFetchedReportData = fetchedReportData.filter(el => el.department.some(entry => selectedDepartment.includes(entry)))

                    // final result settled in state to show enteries in Report to
                    this.setState({
                        reportingEmp: flilteredFetchedReportData
                    })
                });
        } else if (this.state.userRole === 'team_lead') {
            let ref = db.ref("employees");
            ref.orderByChild("userRole").equalTo('dept_head').on("child_added", (snapShot) => {

                fetchedReportData.push({
                    name: snapShot.val().firstName,
                    department: snapShot.val().department
                })
                flilteredFetchedReportData = fetchedReportData.filter(el => el.department.some(entry => selectedDepartment.includes(entry)))

                this.setState({
                    reportingEmp: flilteredFetchedReportData
                }, () => {
                    if (flilteredFetchedReportData.length == 0) {
                        ref.orderByChild("userRole").equalTo('admin').on("child_added", (snapShotData) => {
                            fetchedReportData.push({
                                name: snapShotData.val().firstName,
                                department: snapShotData.val().department
                            })
                            console.log("filterd Data :: ", fetchedReportData)
                            flilteredFetchedReportData = fetchedReportData.filter(el => el.department.some(entry => selectedDepartment.includes(entry)))
                            this.setState({
                                reportingEmp: flilteredFetchedReportData
                            }, () => {
                                console.log(this.state.reportingEmp)
                            })
                        })
                    }
                })
            });
        } else if (this.state.userRole === 'dept_head') {
            let ref = db.ref("employees");
            ref.orderByChild("userRole").equalTo('admin').on("child_added", (snapShots) => {
                fetchedReportData.push({
                    name: snapShots.val().firstName,
                    department: snapShots.val().department
                })
                flilteredFetchedReportData = fetchedReportData.filter(ele => ele.department.some(entry => selectedDepartment.includes(entry)))

                this.setState({
                    reportingEmp: flilteredFetchedReportData
                })
            });
        } else if (this.state.userRole === 'dept_head') {
            let ref = db.ref("employees");
            ref.orderByChild("userRole").equalTo('superadmin').on("child_added", (snapShots) => {
                fetchedReportData.push({
                    name: snapShots.val().firstName,
                    department: snapShots.val().department
                })
                flilteredFetchedReportData = fetchedReportData.filter(ele => ele.department.some(entry => selectedDepartment.includes(entry)))

                this.setState({
                    reportingEmp: flilteredFetchedReportData
                })
            });
        }
        else if (this.state.userRole === 'admin') {
            let ref = db.ref("employees");
            ref.orderByChild("userRole").equalTo('superadmin').on("child_added", (snapShot) => {
                fetchedReportData.push({
                    name: snapShot.val().firstName,
                    department: snapShot.val().department
                })
                flilteredFetchedReportData = fetchedReportData.filter(element => element.department.some(entry => selectedDepartment.includes(entry)))

                this.setState({
                    reportingEmp: flilteredFetchedReportData
                })
            })
        }
        else if (this.state.userRole === 'HR') {
            let ref = db.ref("employees");
            ref.orderByChild("userRole").equalTo('dept_head').on("child_added", (snapShot) => {
                fetchedReportData.push({
                    name: snapShot.val().firstName,
                    department: snapShot.val().department
                })
                console.log("Department :: ", fetchedReportData)
                flilteredFetchedReportData = fetchedReportData.filter(element => element.department.some(entry => selectedDepartment.includes(entry)))

                this.setState({
                    reportingEmp: flilteredFetchedReportData
                }, () => {
                    if (flilteredFetchedReportData.length == 0) {
                        ref.orderByChild("userRole").equalTo('admin').on("child_added", (snapShot) => {
                            fetchedReportData.push({
                                name: snapShot.val().firstName,
                                department: snapShot.val().department
                            })
                            console.log("Departmebnt :: ", fetchedReportData)
                            flilteredFetchedReportData = fetchedReportData.filter(element => element.department.some(entry => selectedDepartment.includes(entry)))
                            this.setState({
                                reportingEmp: flilteredFetchedReportData
                            }, () => {
                                if (flilteredFetchedReportData.length == 0) {
                                    ref.orderByChild("userRole").equalTo('superadmin').on("child_added", (snapShot) => {
                                        fetchedReportData.push({
                                            name: snapShot.val().firstName,
                                            department: snapShot.val().department
                                        })
                                        console.log("Departmebnt :: ", fetchedReportData)
                                        flilteredFetchedReportData = fetchedReportData.filter(element => element.department.some(entry => selectedDepartment.includes(entry)))
                                        this.setState({
                                            reportingEmp: flilteredFetchedReportData
                                        })
                                    })
                                }
                            })
                        })
                    }
                })
            })
        }
    }

    deparetmentChange = (event) => {
        this.setState({ department: event.target.value }, () => {
            db.ref("/employees/").on("value", (querySnap) => {
                let empArray = [];
                querySnap.forEach((empEle) => {
                    if(empEle.val().status == "active"){

                        let empData = empEle.val();
                        empData["id"] = empEle.key;
                        empArray.push(empData);
                    
                    }
                });
                setTimeout(() => {
                    // eslint-disable-next-line array-callback-return
                    // console.log("Employee Data ::: ", empArray);
                    let filterdData = empArray.map((employee) => {
                        if (this.state.userRole === "team_lead") {
                            if (
                                (employee.userRole === "dept_head" ||
                                    employee.userRole === "superadmin" ||
                                    employee.userRole === "admin") &&
                                employee.department === this.state.department
                            )
                                return employee;
                        } else if (this.state.userRole === "dept_head") {
                            if (
                                employee.userRole === "superadmin" ||
                                employee.userRole === "admin"
                            )
                                return employee;
                        } else if (
                            this.state.userRole === "superadmin" ||
                            this.state.userRole === "admin"
                        ) {
                            return undefined;
                        } else {
                            return employee;
                        }
                    });

                    filterdData = filterdData.filter(function (element) {
                        return element !== undefined;
                    });
                    this.setState({ reportingEmp: filterdData });
                }, 1000);
            });
        });
    };

    checkUser = () => {
        if (this.isExist()) {
            alert(`${this.state.email} already exist, please create new one.`);
        } else {
            if (this.validate()) {
                this.RegisterEmployee(
                    this.state.userID,
                    this.state.attendanceId,
                    this.state.pref,
                    this.state.firstName,
                    this.state.lastName,
                    this.state.userRole,
                    this.state.email,
                    this.state.gmail,
                    this.state.contact1,
                    this.state.emergencyContactName,
                    this.state.relation,
                    this.state.contact2,
                    this.state.departmentalSelectedCheckBox,
                    this.state.designation,
                    this.state.imageURL,
                    this.state.reportingTo,
                    this.state.address,
                    this.state.entryTime,
                    this.state.leaveTime,
                    this.state.joiningDate,
                    this.state.resignedDate,
                    this.state.dateOfBirth,
                    this.state.status,
                    this.state.bankName,
                    this.state.accHolderName,
                    this.state.bankAccNo,
                    this.state.ifsc,
                );
            }
        }
    };

    RegisterEmployee = (
        userID,
        attendanceId,
        pref,
        firstName,
        lastName,
        userRole,
        email,
        gmail,
        mobile,
        emergencyContactName,
        relation,
        altContact,
        department,
        designation,
        image,
        reportingTo,
        address,
        entryTime,
        leaveTime,
        joiningDate,
        resignedDate,
        dateOfBirth,
        status,
        bankName,
        accHolderName,
        bankAccNo,
        ifsc,
    ) => {
        let self = this;

        let today = new Date().getTime();
        let tmpJoiningDate = new Date(this.state.joiningDate).getTime();
        let tmpDateOfBirth = new Date(this.state.dateOfBirth).getTime();
        let tmpResignedDate = new Date().getTime();
        //const uploadImage = storage.ref('image/${image.name}').put(image);
        self.setState({ joiningDate: "", dateOfBirth: "" });
        db.ref("/employees").push(
            {
                userID: "SMIS" + userID.toString().padStart(3, 0),
                attendanceId: attendanceId.toString().padStart(3, 0),
                pref: pref,
                firstName: firstName,
                lastName: lastName,
                userRole: userRole,
                email: email,
                gmail: gmail,
                mobile: mobile,
                emergencyContactName: emergencyContactName,
                relation: relation,
                altContact: altContact,
                department: this.state.departmentalSelectedCheckBox,
                designation: designation,
                image: image,
                reportingTo: reportingTo,
                address: address,
                entryTime: entryTime,
                leaveTime: leaveTime,
                joiningDate: tmpJoiningDate,
                resignedDate: tmpResignedDate,
                dateOfBirth: tmpDateOfBirth,
                password: this.state.password,
                status: this.state.status,
                bankName: bankName,
                accHolderName: accHolderName,
                bankAccNo: bankAccNo,
                ifsc: ifsc,

                entryData: today,
                entryBy: localStorage.getItem("email"),
                remotely: this.state.remotely,
                updateDate: "",
                updateBy: "",
            },
            () => {
                self.clearInput();
            }
        );
    };

    validate = () => {
        this.setState({
            email: this.state.email.toLowerCase(),
            gmail: this.state.gmail.toLowerCase(),
        });

        const patternPhoneNo = /^[6789]{1}[0-9]{9}$/;
        // eslint-disable-next-line no-useless-escape
        const patternGmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // eslint-disable-next-line no-useless-escape
        const patternEmail = /^([a-zA-Z0-9_\.\-])+\@((shilpmis)+\.)+(com)+$/;
        const patternBankAccNo = /^\d{9,18}$/;

        if (this.state.firstName === "") {
            alert("Please enter First name");
            return false;
        } else if (this.state.lastName === "") {
            alert("Please enter Last name");
            return false;
        }
        if (this.state.gmail === "") {
            alert("Please enter your Gmail ID");
            document.getElementById("email1").focus();
            return false;
        } else if (!patternGmail.test(this.state.gmail)) {
            alert("Please enter proper gmail ID");
            document.getElementById("email1").focus();
            return false;
        } else if (this.state.email === "") {
            alert("Please enter your Shilpmis ID");
            document.getElementById("email2").focus();
            return false;
        } else if (!patternEmail.test(this.state.email)) {
            alert("Please enter proper shilpmis ID");
            document.getElementById("email2").focus();
            return false;
        } else if (this.state.contact1 === "") {
            alert("Please enter mobile no.");
            document.getElementById("contact1").focus();
            return false;
        } else if (!patternPhoneNo.test(this.state.contact1)) {
            alert("Please enter valid mobile number");
            document.getElementById("contact1").focus();
            return false;
        } else if (this.state.designation === "") {
            alert("Please enter your designation");
            return false;
        } else if (this.state.userRole === "") {
            alert("Please select your Role");
            return false;
        } else if (
            this.state.reportingTo === "" &&
            this.state.userRole !== "superadmin" &&
            this.state.userRole !== "admin"
        ) {
            alert("Please enter who you are reporting");
            return false;
        } else if (this.state.departmentalSelectedCheckBox.length == 0) {
            alert("Please select your department");
            return false;
        } else if (
            this.state.reportingTo === "" &&
            this.state.userRole !== "superadmin" &&
            this.state.userRole !== "admin"
        ) {
            alert("Please enter who you are reporting");
            return false;
        } else if (this.state.address === "") {
            alert("Please enter your address");
            document.getElementById("address").focus();
            return false;
        } else if (this.state.joiningDate === "") {
            alert("Please enter your joining date");
            return false;
        } else if (this.state.bankName === "") {
            alert("Please add Bank Name");
            return false;
        } else if (this.state.accHolderName === "") {
            alert("Please add Account Holder Name");
            return false;
        } else if (this.state.bankAccNo === "") {
            alert("Please add Bank Account No");
            return false;
        } else if (this.state.ifsc === "") {
            alert("Please add Bank IFSC");
            return false;
        } else if (this.state.password === "") {
            alert("Please enter your password"+ " " + this.state.firstName);
            return false;
        } else if (this.state.password !== this.state.confirmPassword) {
            alert("Password Not Matched ");
            return false;
        } else if (!patternBankAccNo.test(this.state.bankAccNo)) {
            alert("Please add Proper Bank Account No");
            return false;
        } else {
            return true;
        }
    };

    isExist = () => {
        let filterdData = this.state.employeeList.filter(
            (employee) => employee.email === this.state.email
        );

        if (filterdData.length > 0) {
            return true;
        } else {
            return false;
        }
    };

    onDrop(files) {
        this.setState({
            files,
        }, () => console.log("files:::", files));
    }

    onHandleUpload = (myUserID) => {
        let myPromise = new Promise((resolve, reject) => {
          const myGuid = myUserID;
          const storageURL = firebaseApp.storage("gs://melzo-dashboard.appspot.com");
          const storageRef = storageURL.ref();
    
          const uploadTask = storageRef
            .child("images")
            .child("Melzo-Dashboard")
            .child(myGuid)
            .put(this.state.imageURL[0]);
    
          uploadTask.on("state_changed", (snapShot) => {
    
          }, (err) => {
            console.log(err);
            reject(err);
          }, () => {
            firebaseApp
              .storage("gs://melzo-dashboard.appspot.com")
              .ref()
              .child("images")
              .child("Melzo-Dashboard")
              .child(myGuid)
              .getDownloadURL()
              .then((firebaseUrl) => {
                resolve(firebaseUrl)
              })
              .catch((err) => {
                console.log("caught error::", err);
              })
          })
        });
        myPromise
          .then((url) => {
           // console.log(url);
            this.setState({
                imageURL: url,
            },() => {
                console.log("image url with state", this.state.imageURL);
                this.checkUser();
            })
          });
      }


    render() {

        return (
            <div>
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>

                            <div className="page-title">
                                <div className="float-left">
                                    <h1 className="title">Employee Form</h1>
                                </div>
                            </div>

                            <div className="row margin-0">
                                <div className="col-12">
                                    <section className="box">
                                        <header className="panel_header">
                                            <h2 className="title float-left">Personal Details</h2>
                                        </header>
                                        <div className="content-body">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-12 col-md-12">

                                                    <form>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-2">
                                                                <label htmlFor="inputPrefix">Prefix</label>
                                                                <select id="inputPrefix" className="form-control" name="pref" value={this.state.pref} onChange={this.handleChange}>
                                                                    <option>Choose</option>
                                                                    <option value="Mr">Mr.</option>
                                                                    <option value="Mrs">Mrs.</option>
                                                                    <option value="Ms">Ms.</option>
                                                                </select>
                                                            </div>
                                                            <div className="form-group col-md-5">
                                                                <label htmlFor="inputFirstName">First Name</label>
                                                                <input type="text" className="form-control" id="inputFirstName" placeholder="First Name" name="firstName" value={this.state.firstName} onChange={this.handleChange} />
                                                            </div>
                                                            <div className="form-group col-md-5">
                                                                <label htmlFor="inputLastName">Last Name</label>
                                                                <input type="text" className="form-control" id="inputLastName" placeholder="Last Name" value={this.state.lastName} name="lastName" onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="inputAddress">Address</label>
                                                            <textarea className="form-control" id="inputAddress" placeholder="1234 Main St" rows="3" value={this.state.address} name="address" onChange={this.handleChange} />
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-4">
                                                                <label htmlFor="inputContact">Contact Number</label>
                                                                <input type="number" className="form-control" id="inputContact" value={this.state.contact1} name="contact1" onChange={this.handleChange} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label htmlFor="InputEmail">Email ID</label>
                                                                <input type="email" id="InputEmail" className="form-control" value={this.state.gmail} name="gmail" onChange={this.handleChange} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label htmlFor="inputBirthday">Date of Birth</label>
                                                                <input type="date" className="form-control" id="inputBirthday" value={this.state.dateOfBirth} name="dateOfBirth" onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-4">
                                                                <label htmlFor="inputAltContact">Alternate Contact Name</label>
                                                                <input type="text" className="form-control" id="inputAltContact" name="emergencyContactName" value={this.state.emergencyContactName} onChange={this.handleChange} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label htmlFor="inputRelation">Relation</label>
                                                                <select id="inputRelation" className="form-control" name="relation" value={this.state.relation} onChange={this.handleChange}>
                                                                    <option>Choose...</option>
                                                                    <option value="father">Father</option>
                                                                    <option value="mother">Mother</option>
                                                                    <option value="husband">Husband</option>
                                                                    <option value="wife">Wife</option>
                                                                    <option value="brother">Brother</option>
                                                                    <option value="sister">Sister</option>
                                                                </select>
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label htmlFor="inputAltNumber">Alternate Contact</label>
                                                                <input type="number" className="form-control" id="inputAltNumber" name="contact2" value={this.state.contact2} onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                    </form>

                                                </div>
                                            </div>

                                        </div>
                                    </section>
                                </div>

                                <div className="col-12">
                                    <section className="box ">
                                        <header className="panel_header">
                                            <h2 className="title float-left">Workspace Details</h2>
                                        </header>
                                        <div className="content-body">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-12 col-md-12">
                                                    <form>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-4">
                                                                <label htmlFor="inputUserRole">User-role</label>
                                                                <select id="inputUserRole" className="form-control" name="userRole" value={this.state.userRole} onChange={this.handleChange} >
                                                                    <option>Choose...</option>
                                                                    <option value="superadmin">Super Admin</option>
                                                                    <option value="admin">Admin</option>
                                                                    <option value="dept_head">Department Head</option>
                                                                    <option value="hr">HR</option>
                                                                    <option value="team_lead">Team Lead</option>
                                                                    <option value="employee">Employee</option>
                                                                </select>
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label htmlFor="inputDesignation">Designation</label>
                                                                <select id="inputDesignation" className="form-control" name="designation" value={this.state.designation} onChange={this.handleChange} >
                                                                    <option>Choose...</option>
                                                                    {this.state.designationData.map((obj) => {
                                                                        return (<option value={obj.designationName}>{obj.designationName}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <InputLabel id="demo-mutiple-checkbox-label">Department</InputLabel>
                                                                <Select
                                                                    className="form-control"
                                                                    labelId="demo-mutiple-checkbox-label"
                                                                    id="demo-mutiple-checkbox"
                                                                    multiple
                                                                    fullWidth
                                                                    value={this.state.departmentalSelectedCheckBox}
                                                                    onChange={this.departmentCheckBoxChange}
                                                                    renderValue={(selected) => selected.join(', ')}
                                                                    name=""
                                                                >
                                                                    {this.state.deptData.map((obj, index) => (
                                                                        <MenuItem key={obj.departmentName} value={obj.departmentName}>
                                                                            {obj.departmentName}
                                                                            <Checkbox checked={this.state.departmentalSelectedCheckBox.indexOf(obj.departmentName) > -1} />
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-4">
                                                                <label htmlFor="inputReporting">Reporting To</label>
                                                                <select id="inputReporting" className="form-control" name="reportingTo" value={this.state.reportingTo} onChange={this.handleChange} >
                                                                    <option>Choose...</option>
                                                                    {this.state.reportingEmp.map((reportingObj) => (
                                                                        <option value={reportingObj.name}>
                                                                            {reportingObj.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label htmlFor="inputSMIS">Shilpmis ID</label>
                                                                <input type="email" id="inputSMIS" className="form-control" name="email" value={this.state.email} onChange={this.handleChange} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label htmlFor="inputStatusEmp">Status of Employement</label>
                                                                <select id="inputStatusEmp" className="form-control" name="status" value={this.state.status} onChange={this.handleChange} >
                                                                    <option>Choose...</option>
                                                                    <option value="active">Employed</option>
                                                                    <option value="terminated">Terminated</option>
                                                                    <option value="resigned">Resigned</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="inputJoinedDate">Joining Date</label>
                                                                <input type="date" id="inputJoinedDate" className="form-control" name="joiningDate" value={this.state.joiningDate} onChange={this.handleChange} />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="inputLeftDate">Leaving Date</label>
                                                                <input type="date" id="inputLeftDate" className="form-control" name="resignedDate" value={this.state.resignedDate} onChange={this.handleChange} />
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <div className="col-sm-2"></div>
                                                            <div className="col-sm-10">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" id="gridCheck1" />
                                                                    <label className="form-check-label" htmlFor="gridCheck1" name="remotely" value={this.state.remotely} onChange={this.chkRemotely} >Remote
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div className="col-12">
                                    <section className="box ">
                                        <header className="panel_header">
                                            <h2 className="title float-left">Bank Details</h2>
                                        </header>
                                        <div className="content-body">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-12 col-md-12">
                                                    <form>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="bankName">Bank Name</label>
                                                                <input type="text" id="bankName" className="form-control" placeholder="XXXX Bank" value={this.state.bankName} name="bankName" onChange={this.handleChange} />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="accHolder">Account Holder Name</label>
                                                                <input type="text" id="accHolder" className="form-control" placeholder="XXXX" value={this.state.accHolderName} name="accHolderName" onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-12">
                                                                <label htmlFor="accNo">Account No.</label>
                                                                <input type="number" id="accNo" className="form-control" placeholder="000000" value={this.state.bankAccNo} name="bankAccNo" onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-12">
                                                                <label htmlFor="ifsc">IFSC</label>
                                                                <input type="number" id="ifsc" className="form-control" placeholder="000000" value={this.state.ifsc} name="ifsc" onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-12 col-md-12">
                                    <section className="box ">
                                        <header className="panel_header">
                                            <h2 className="title float-left">Other Details</h2>

                                        </header>
                                        <div className="content-body">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <form>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="inputPassword">Password</label>
                                                                <input type="password" id="inputPassword" className="form-control" name="password" value={this.state.password} onChange={this.handleChange} />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="inputConfrimPassword">Confrim Password</label>
                                                                <input type="password" id="inputConfrimPassword" className="form-control" name="confirmPassword" value={this.state.confirmPassword} onChange={this.handleChange} />
                                                            </div>
                                                        </div>


                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                            <label for="actual-btn">Display Picture</label>
                                                                <div className="dropzone">
                                                                    <Dropzone onDrop={this.onDrop.bind(this)} className="droparea">
                                                                        <p>Try dropping some files here, or click to select files to upload.</p>
                                                                    </Dropzone>
                                                                </div>
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label for="actual-btn">Document</label>
                                                                <div className="dropzone">
                                                                    <Dropzone onDrop={this.onDrop.bind(this)} className="droparea">
                                                                        <p>Try dropping some files here, or click to select files to upload.</p>
                                                                    </Dropzone>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <button type="submit" className="btn btn-primary" onClick={this.checkUser}>Submit</button>
                                                    <button type="submit" className="btn btn-secondary" onClick={this.clearInput}>Cancel</button>
                                                </div>
                                            </div>

                                        </div>
                                    </section>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default UserForm;