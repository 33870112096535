import React, { Component } from "react";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import { db } from "../../../Firebase";

export default class ProjectList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: "",
            projectName: "",
            projectDescription: "",
            projectClient: "",
            projectStatus: "",
            assignedDate: "",
            endDate: "",
            projectArray: [],
            employeeData: [],
            employeeSelectedDropDownCheckBox: [],
            createdBy: "",
        };
    }

    componentDidMount() {
        this.onGetEmployeeData();
    }

    onGetEmployeeData() {
        let employeeDataPromise = new Promise((resolve, reject) => {
            db.ref("/employees/")
                .on("value", (querySelector) => {
                    let employeeArray = [];
                    querySelector.forEach((element) => {
                        if(element.val().status == "active"){

                            let obj = element.val();
                            obj["id"] = element.key;
                            employeeArray.push(obj);
                        
                        }
                    });
                    resolve(employeeArray);
                })
        });
        employeeDataPromise.then(acceptedData => {
            this.setState({
                employeeData: acceptedData,
            }, () => console.log("employee data::", this.state.employeeData));
        })
    }

    validateFields = () => {
        // if (this.state.employeeSelectedDropDownCheckBox.length == 0) {
        //     alert("Please select employee to start");
        //     return false;
        // } 
         if (this.state.projectName === "") {
            alert("Please add Project Name");
            document.getElementById("projectName").focus();
            return false;
        } else if (this.state.projectClient === "") {
            alert("Please add Client's name");
            document.getElementById("projectClient").focus();
            return false;
        } else if (this.state.projectStatus === "") {
            alert("Select status of the Project");
            document.getElementById("projectStatus").focus();
            return false;
        } else if (this.state.assignedDate === "") {
            alert("Select Assigning date of the Project");
            document.getElementById("assignedDate").focus();
            return false;
        } else {
            return true;
        }
    }

    handleSubmit = () => {
        let tempAssignedDate = new Date(this.state.assignedDate).getTime();
        let tempEndDate = new Date().getTime();

        if (this.validateFields()) {
            db.ref("/projectMaster/").push({
                projectName: this.state.projectName,
                projectDescription: this.state.projectDescription,
                projectClient: this.state.projectClient,
                employeeInvolved: this.state.employeeSelectedDropDownCheckBox,
                projectStatus: this.state.projectStatus,
                assignedDate: tempAssignedDate,
                endDate: tempEndDate,
            },() => this.handleCancel());
        }
    }

    handleCancel = () => {
        this.setState({
            projectName: "",
            projectDescription: "",
            projectClient: "",
            projectStatus: "",
            assignedDate: "",
            endDate: "",
        })
    }

    render() {
        return (
            <div>
                <div className="content">
                    <Row>
                        <Col>
                            <div className="page-title">
                                <div className="float-left">
                                    <h1 className="title">Projects</h1>
                                </div>
                            </div>
                            <div className="row margin-0">
                                <div className="col-12">
                                    <section className="box">
                                        <header className="panel_header">
                                            <h2 className="title float-left">About Project</h2>
                                        </header>
                                        <div className="content-body">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-12 col-md-12">

                                                    <form>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="projectName">Project Name</label>
                                                                <input type="text" className="form-control" id="projectName" placeholder="Project Name" onChange={(e) => this.setState({projectName: e.target.value})} />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="projectClient">Project Client</label>
                                                                <input type="text" className="form-control" id="projectClient" placeholder="Project Client" onChange={(e) => this.setState({projectClient: e.target.value})}/>
                                                            </div>                                                            
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="inputDetails">Project Description</label>
                                                            <textarea className="form-control" id="inputDetails" rows="3" onChange={(e) => this.setState({projectDescription: e.target.value})} />
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>

                                <div className="col-12">
                                    <section className="box ">
                                        <header className="panel_header">
                                            <h2 className="title float-left">Project Details</h2>

                                        </header>
                                        <div className="content-body">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-12 col-md-12">
                                                    <form>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="inputEmpInvolved">Employees</label>
                                                                <select id="inputEmpInvolved" className="form-control">
                                                                    <option>Choose...</option>
                                                                    {this.state.employeeData.map((obj) => {
                                                                       return (<option value={obj.email}>{obj.firstName + " " + obj.lastName}
                                                                       </option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                            
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="projectStatus">Project Status</label>
                                                                <select id="projectStatus" className="form-control" onChange={(e) => this.setState({projectStatus: e.target.value})}>
                                                                    <option>Choose...</option>
                                                                    <option value="started">Started</option>
                                                                    <option value="ongoing">On-going</option>
                                                                    <option value="completed">Completed</option>
                                                                    <option value="cancelled">Cancelled</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="form-row">
                                                            <div className="form-group col-md-4">
                                                                <label htmlFor="assignedDate">Assigned Date</label>
                                                                <input type="date" id="assignedDate" className="form-control" onChange={(e) => this.setState({assignedDate: e.target.value})} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label htmlFor="endDate">Completed Date</label>
                                                                <input type="date" id="endDate" className="form-control" onChange={(e) => this.setState({endDate: e.target.value})} />
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <button className="btn btn-primary" onClick={this.handleSubmit} style={{ marginTop: "10px" }}>Submit</button>
                                                    <button className="btn btn-secondary" onClick={this.handleCancel} style={{ marginTop: "10px" }}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}