import moment from "moment"; // Example for onSort prop
import React from "react"; // Import React
//import { render } from 'react-dom'; // Import render method
import Datatable from "react-bs-datatable"; // Import this package
import { Row, Col } from "reactstrap";
import { db } from "../../../Firebase";
import noDataImg from "../../../assets/img/NoData.png";
import {
  BottomNavigation,
  BottomNavigationAction,
  Button,
  IconButton,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  TextField,
  Tooltip,
  withStyles,
  Zoom,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import AttachmentImageDialog from "./AttachmentDialog.jsx";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";

class TicketLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // New State
      firstName: "",
      shilpmisId: "",
      userRole: "",

      // New State
      ticketLogs: [],
      rowsPerPage: 5,
      page: 0,

      ticketNo: "",
      ticketArray: [],
      value: "",
      employeeData: [],
      assignPopUp: false,
      assignPopUpData: [],
      assignPopUpPreviousData: [],
      attachmentData: [],
      openAttachmentImage: false,
      remark: "",
      submitDataspin: false,
    };
  }

  componentDidMount() {
    this.setState({
      firstName: localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).firstName +
          " " +
          JSON.parse(localStorage.getItem("employeeObject")).lastName
        : " ",
      shilpmisId: localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).email
        : " ",
      userRole: localStorage.getItem("employeeObject")
        ? JSON.parse(localStorage.getItem("employeeObject")).userRole
        : " ",
    });

    this.onGetEmployeeData();
  }

  onGetEmployeeData = () => {
    let ticketLogsPromise = new Promise((resolve, reject) => {
      db.ref("/dashboard-logs/").on("value", (ticketLogs) => {
        let ticketLogsArray = [];
        ticketLogs.forEach((element) => {
          if (element.val().type == "ticket") {
            let object = element.val();
            object["id"] = element.key;
            ticketLogsArray.push(object);
          }
        });
        resolve(ticketLogsArray);
      });
    });
    ticketLogsPromise.then((acceptedData) => {
      const formatedTicketLogs = Object.entries(
        groupBy(acceptedData, "ticketNo")
      ).map((e) => ({
        logs: e[1],
        Id: e[0],
        // table orderwise
        ticketNo: e[1][0].ticketNo,
        issuedBy: e[1][0].ticketIssuedBy,
        cusName: e[1][0].customerName,
        product: e[1][0].customerProduct,
        issueType: e[1][0].issueType,
        complaintSub: e[1][0].complaintSuject,
        createdOn: e[1][0].createdOn,
        // To get latest update
        lastAssignTo: e[1][e[1].length - 1].ticketAssignTo,
        lastAssignBy: e[1][e[1].length - 1].ticketAssignBy,
        lastStatus: e[1][e[1].length - 1].status,
        lastChangedBy: e[1][e[1].length - 1].changeBy,
        lastRemark: e[1][e[1].length - 1].remark,
        lastUpdatedOn: e[1][e[1].length - 1].UpdatedOn,
      }));

      this.setState(
        {
          ticketLogs: formatedTicketLogs,
        },
        () => {
          console.log("emp Data", this.state.ticketLogs);
        }
      );
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  render() {
    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="page-title">
                <div className="float-left">
                  <h1 className="title">Tickets</h1>
                </div>
              </div>

              <div className="col-12">
                <section className="box " style={{ display: "block" }}>
                  <header className="panel_header">
                    <h2 className="title float-left">All Tickets Logs</h2>
                  </header>
                  <div className="content-body">
                    <div className="row">
                      <div className="col-lg-12 dt-disp">
                        <TableContainer
                          style={
                            this.state.ticketLogs.length === 0
                              ? {
                                  height: "400px",
                                  display: "flex",
                                  justifyContent: "center",
                                }
                              : {}
                          }
                        >
                          {this.state.ticketLogs.length === 0 && (
                            <img
                              src={noDataImg}
                              style={{ height: "400px" }}
                            ></img>
                          )}
                          {this.state.ticketLogs.length > 0 && (
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell />
                                  <TableCell>
                                    <b>{"Ticket No"}</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>{"Issued By"}</b>
                                    {/* lastChangedBy */}
                                  </TableCell>
                                  <TableCell>
                                    <b>{"Customer Name"}</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>{"Product"}</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>{"Issue Type"}</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>{"Complaint"}</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>{"Assign To"}</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>{"Assign By"}</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>{"Status"}</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>{"Modified By"}</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>{"Remark"}</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>{"Updated On"}</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>{"Created On"}</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>{"Days Taken"}</b>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(this.state.rowsPerPage > 0
                                  ? this.state.ticketLogs.slice(
                                      this.state.page * this.state.rowsPerPage,
                                      this.state.page * this.state.rowsPerPage +
                                        this.state.rowsPerPage
                                    )
                                  : this.state.ticketLogs
                                ).map((row) => (
                                  <NestedRow
                                    key={row.Id}
                                    row={row}
                                    // deleteUser={this.deleteCPUser}
                                  />
                                ))}
                              </TableBody>
                              <TableFooter>
                                <TableRow>
                                  <TablePagination
                                    rowsPerPageOptions={[
                                      5,
                                      10,
                                      25,
                                      { label: "All", value: -1 },
                                    ]}
                                    count={this.state.ticketLogs.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    SelectProps={{
                                      inputProps: {
                                        "aria-label": "rows per page",
                                      },
                                      native: true,
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={
                                      this.handleChangeRowsPerPage
                                    }
                                    ActionsComponent={TablePaginationActions}
                                    style={{ overflow: "hidden" }}
                                  />
                                </TableRow>
                              </TableFooter>
                            </Table>
                          )}
                        </TableContainer>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </Col>
          </Row>
          <AttachmentImageDialog
            open={this.state.openAttachmentImage}
            Product={this.state.attachmentData}
            onClose={() => this.setState({ openAttachmentImage: false })}
          />
        </div>
      </div>
    );
  }
}

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ display: "flex" }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}

function NestedRow(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <TableRow
        onClick={() => (row.logs.length == 1 ? "" : setOpen(!open))}
        style={{ cursor: "pointer" }}
      >
        <TableCell>
          <IconButton
            aria-label={"expand row"}
            size="small"
            disabled={row.logs.length == 1 ? true : false}
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.ticketNo}</TableCell>
        <TableCell>{row.issuedBy}</TableCell>
        <TableCell>{row.cusName}</TableCell>
        <TableCell>{row.product}</TableCell>
        <TableCell>{row.issueType}</TableCell>
        <TableCell>{row.complaintSub}</TableCell>
        <TableCell>{row.lastAssignTo}</TableCell>
        <TableCell>{row.lastAssignBy}</TableCell>
        <TableCell>{row.lastStatus}</TableCell>
        <TableCell>{row.lastChangedBy}</TableCell>
        <TableCell>{row.lastRemark}</TableCell>
        <TableCell>
          {new Date(row.lastUpdatedOn).toLocaleString("en-IN")}
        </TableCell>
        <TableCell>{new Date(row.createdOn).toLocaleString("en-IN")}</TableCell>
        <TableCell>
          {Math.ceil(
            Math.abs(row.lastUpdatedOn - row.createdOn) / (1000 * 60 * 60 * 24)
          ) + 1}
        </TableCell>
        {/* <TableCell>
          {<DeleteForeverIcon onClick={() => deleteUser(row.cpUserId)} />}
        </TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label={"project social interaction"}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>{"Assign TO"}</b>
                    </TableCell>
                    <TableCell>
                      <b>{"Assign By"}</b>
                    </TableCell>
                    <TableCell>
                      <b>{"Status"}</b>
                    </TableCell>
                    <TableCell>
                      <b>{"Modified By"}</b>
                    </TableCell>
                    <TableCell>
                      <b>{"Remark"}</b>
                    </TableCell>
                    <TableCell>
                      <b>{"Updated On"}</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.logs.map((leadRow, index) => (
                    <TableRow key={index}>
                      <TableCell>{leadRow.ticketAssignTo}</TableCell>
                      <TableCell>{leadRow.ticketIssuedBy}</TableCell>
                      <TableCell>{leadRow.status}</TableCell>
                      <TableCell>{leadRow.changeBy}</TableCell>
                      <TableCell>{leadRow.remark}</TableCell>
                      <TableCell>
                        {new Date(leadRow.UpdatedOn).toLocaleDateString(
                          "en-IN"
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function groupBy(objectArray, property) {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    // Add object to list for given key's value
    acc[key].push(obj);

    return acc;
  }, []);
}

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);

export default TicketLogs;
